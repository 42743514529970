import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { map } from 'lodash';

const { ORDER_COMPLETED } = ANALYTICS_EVENTS;

const TIKTOK_PIXEL_EVENTS = {
  [ORDER_COMPLETED]: 'PlaceAnOrder',
};

const TIKTOK_PIXEL_CONTENT_TYPE = {
  PRODUCT: 'product',
};

const currency = process.env.currencyCode;

let tiktokPixel;
let isPixedLoaded = false;

export const tiktokPlugin = (userConfig = {}) => {
  return {
    name: 'tiktok',

    config: userConfig,

    initialize: async () => {
      if (typeof window === 'undefined') {
        return;
      }

      const { default: ReactTiktokPixel } = await import('tiktok-pixel');
      tiktokPixel = ReactTiktokPixel;

      if (userConfig.enabled && !isPixedLoaded) {
        tiktokPixel.init(userConfig.tiktokPixelId);

        isPixedLoaded = true;
      }
    },

    page: () => {
      tiktokPixel.pageView();
    },

    track: ({ payload }) => {
      switch (payload.event) {
        case ORDER_COMPLETED: {
          return tiktokPixel.track(TIKTOK_PIXEL_EVENTS[payload.event], {
            currency,
            content_type: TIKTOK_PIXEL_CONTENT_TYPE.PRODUCT,
            contents: map(payload.properties.products, product => ({
              content_id: product.product_id,
              content_name: product.name,
              quantity: product.quantity,
              price: product.price,
            })),
            value: payload.properties.total,
          });
        }

        default:
          return () => {};
      }
    },

    loaded: () => {
      return isPixedLoaded;
    },
  };
};
