import { compact, map, reject } from 'lodash';
import { flow, getEnv, getSnapshot, types } from 'mobx-state-tree';
import gql from 'graphql-tag';

import AlternativePaymentMethods from '../alternative-payment-methods';
import CreditCard from '../../models/credit-card';
import Payment from '../../models/payment';
import StoredPaymentMethod from '../../models/stored-payment-method';

const STORED_PAYMENT_METHODS_QUERY = gql`
  query PaymentMethods($channel: String!, $restaurantId: String, $isPurchasingGiftCard: Boolean) {
    paymentMethods(
      input: { channel: $channel, restaurantId: $restaurantId, isPurchasingGiftCard: $isPurchasingGiftCard }
    ) {
      availableCreditCardTypes
      alternativePaymentMethods
      storedPaymentMethods {
        brand
        expiryMonth
        expiryYear
        id
        name
        lastFour
      }
    }
  }
`;

const REMOVE_RECURRING_PAYMENT_MUTATION = gql`
  mutation removeRecurringPaymentMethod($input: RecurringPaymentInput!) {
    removeRecurringPaymentMethod(input: $input) {
      success
    }
  }
`;

const getStoredPaymentMethods = async ({
  client,
  platform = 'Android',
  restaurantId,
  isPurchasingGiftCard = false,
}) => {
  try {
    const { data } = await client.query({
      query: STORED_PAYMENT_METHODS_QUERY,
      variables: { channel: platform, restaurantId, isPurchasingGiftCard },
    });
    return {
      data: {
        storedPaymentMethods: data.paymentMethods.storedPaymentMethods,
        availableCreditCardTypes: data.paymentMethods.availableCreditCardTypes,
        alternativePaymentMethods: data.paymentMethods.alternativePaymentMethods,
      },
    };
  } catch (error) {
    return { error };
  }
};

const removeRecurringPaymentMethod = async ({ client, values }) => {
  const { data } = await client.mutate({ mutation: REMOVE_RECURRING_PAYMENT_MUTATION, variables: { input: values } });
  return data.removeRecurringPaymentMethod;
};

const defaultCreditCards = ['visa', 'mc'];

const PaymentsStore = types
  .model('PaymentsStore', {
    loading: false,
    loaded: false,
    error: false,
    alternativePaymentMethods: types.optional(AlternativePaymentMethods, {}),
    availableCreditCardTypes: types.optional(types.array(types.string), defaultCreditCards),
    storedPaymentMethods: types.optional(types.array(StoredPaymentMethod), []),
    selectedPaymentMethod: types.maybe(
      types.reference(StoredPaymentMethod, {
        get(identifier, parent) {
          return parent.storedPaymentMethods.find(({ id }) => id === identifier) || null;
        },
        set(value) {
          return value.id;
        },
      })
    ),
    creditCard: types.maybe(CreditCard),
    payment: types.optional(Payment, {}),
    paymentResult: types.maybe(types.string),
    paymentData: types.maybe(types.frozen()),
  })
  .actions(self => {
    const { getApiClient, logger, platform, checkPlatformSpecificPaymentMethods } = getEnv(self);
    return {
      clearSessionData() {
        self.storedPaymentMethods = [];
        self.selectedPaymentMethod = undefined;
        self.creditCard = undefined;
        self.payment = {};
        self.paymentResult = undefined;
        self.paymentData = undefined;
        self.availableCreditCardTypes = undefined;
      },
      setSelectedPaymentMethod(selectedPaymentMethod) {
        self.selectedPaymentMethod = selectedPaymentMethod;
      },
      setCreditCard(creditCard) {
        self.creditCard = creditCard;
      },
      setError(displayError) {
        self.error = displayError;
      },
      loadPaymentMethods: flow(function*({ restaurantId, isPurchasingGiftCard = false }) {
        self.error = false;
        self.loading = true;
        try {
          const client = yield getApiClient();
          const result = yield getStoredPaymentMethods({ client, platform, restaurantId, isPurchasingGiftCard });
          const { data, error } = result;
          if (error) {
            throw error;
          }

          const { storedPaymentMethods, availableCreditCardTypes, alternativePaymentMethods } = data;

          self.storedPaymentMethods = storedPaymentMethods || [];
          self.availableCreditCardTypes = availableCreditCardTypes || defaultCreditCards;
          self.alternativePaymentMethods.setSupportedAlternativePaymentMethods(alternativePaymentMethods);

          if (checkPlatformSpecificPaymentMethods) {
            const { applePay, googlePay, paypal } = yield checkPlatformSpecificPaymentMethods({
              availableCreditCardTypes: getSnapshot(self.availableCreditCardTypes),
              availableCreditCardTypesForGooglePay: self.availableCreditCardTypesForGooglePay,
            });
            if (applePay) {
              self.alternativePaymentMethods.setApplePayIsAvailableOnPlatform(true);
            }
            if (googlePay) {
              self.alternativePaymentMethods.setGooglePayIsAvailableOnPlatform(true);
            }
            if (paypal) {
              self.alternativePaymentMethods.setPaypalIsAvailableOnPlatform(true);
            }
          }

          self.loaded = true;
        } catch (error) {
          logger.error(`Error fetching payment methods`, error);
          self.error = true;
        } finally {
          self.loading = false;
        }
        return self.availableCreditCardTypes;
      }),
      removeRecurringPaymentMethod: flow(function*({ recurringDetailReference }) {
        try {
          const client = yield getApiClient();
          const { success } = yield removeRecurringPaymentMethod({ client, values: { recurringDetailReference } });

          if (success) {
            self.storedPaymentMethods = reject(self.storedPaymentMethods, ({ id }) => id === recurringDetailReference);
          }

          return { success };
        } catch (error) {
          logger.error('Error removing recurring payment method', { error });
          return { success: false };
        }
      }),
    };
  })
  .views(self => ({
    get availableCreditCardTypesForGooglePay() {
      const mapCardTypeForAndroid = cardType => ({ visa: 'VISA', mc: 'MASTERCARD', amex: 'AMEX' }[cardType]);
      return compact(map(self.availableCreditCardTypes, mapCardTypeForAndroid));
    },
    get availableCreditCardTypesForApplePay() {
      const mapCardTypeForApple = cardType => ({ visa: 'VISA', mc: 'MASTERCARD', amex: 'AMEX' }[cardType]);
      return compact(map(self.availableCreditCardTypes, mapCardTypeForApple));
    },
  }));

export default PaymentsStore;
