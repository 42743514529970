const Share = props => (
  <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Share</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3536 1.15096C12.1583 0.955702 11.8417 0.955702 11.6464 1.15096L7.64645 5.15096C7.45118 5.34623 7.45118 5.66281 7.64645 5.85807L8.35355 6.56518C8.54882 6.76044 8.8654 6.76044 9.06066 6.56518L11 4.62584V15.5C11 15.7761 11.2239 16 11.5 16H12.5C12.7761 16 13 15.7761 13 15.5V4.62584L14.9393 6.56518C15.1346 6.76044 15.4512 6.76044 15.6464 6.56518L16.3536 5.85807C16.5488 5.66281 16.5488 5.34623 16.3536 5.15096L12.3536 1.15096ZM8 8C8.27614 8 8.5 8.22386 8.5 8.5V9.5C8.5 9.77614 8.27614 10 8 10H6C5.44772 10 5 10.4477 5 11V19C5 19.5523 5.44772 20 6 20H18C18.5523 20 19 19.5523 19 19V11C19 10.4477 18.5523 10 18 10H16C15.7239 10 15.5 9.77614 15.5 9.5V8.5C15.5 8.22386 15.7239 8 16 8H18C19.6569 8 21 9.34315 21 11V19C21 20.6569 19.6569 22 18 22H6C4.34315 22 3 20.6569 3 19V11C3 9.34315 4.34315 8 6 8H8Z"
    />
  </svg>
);

export { Share };
