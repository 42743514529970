import invokeMap from 'lodash/invokeMap';
import isFunction from 'lodash/isFunction';
import map from 'lodash/map';
import Rollbar from 'rollbar';

import packageConfig from '../../package.json';

const ROLLBAR_IGNORE_MESSAGES = [
  'Cannot read property of undefined/null expression startOrder',
  'PaymentRequest is not defined',
  'Timeout',
  'Script error.',
  // Probably Outlook: https://github.com/getsentry/sentry-javascript/issues/3440
  /Object Not Found Matching Id:\d, MethodName:.*?, ParamCount:\d/,
  // Known issue in Next.js + webpack - caused by rapid reloads or outdated chunk indexes
  /Loading chunk \d+ failed/,
  // Only happens on iOS safari. This doesn't seem to actually cause any issues
  `Can't find variable: _AutofillCallbackHandler`,
];

const ROLLBAR_IGNORE_NESTED_PATTERNS = [
  // Network outage is not an immediately useful signal
  /Network Error/,
  /Request aborted/,
  /timeout exceeded/,
];

/** tests body.error.errors[].message against ignore patterns */
const checkIgnoreNested = (description, body) => {
  const errors = body?.error?.errors || [];

  return errors.some(error => ROLLBAR_IGNORE_NESTED_PATTERNS.some(pattern => pattern.test(error.message)));
};

const checkIgnore = (_isUncaught, args) => {
  const [description, body] = args;

  if (checkIgnoreNested(description, body)) {
    console.log('Rollbar: Ignoring error', { description, body });
    return true;
  }

  return false;
};

const getOutputs = () => {
  const isServerSide = typeof window === 'undefined';

  // Storybook & Tests
  if (!process.env.rollbar || !process.env.rollbar.accessToken) {
    return [console];
  }

  // @NOTE: do not use lodash get here as it does not work when target is serverless
  const accessToken = !isServerSide ? process.env.rollbar.accessToken.client : process.env.rollbar.accessToken.server;

  // Temp fix as nps pulls in the as part of the components so ignore so we don't add rollbar twice
  if (!accessToken) {
    return [console];
  }

  const client = new Rollbar({
    accessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.rollbar.environment || 'development',
    reportLevel: 'warning',
    // only allow errors from scripts that originate from our own code
    // this reduces noise and allows us to ignore errors from third party scripts
    hostSafeList: ['https://rollbar.com', 'https://api.rollbar.com', process.env.web.url],

    ignoredMessages: ROLLBAR_IGNORE_MESSAGES,
    checkIgnore,

    payload: {
      client: {
        javascript: {
          code_version: process.env.rollbar.codeVersion || packageConfig.version,
          guess_uncaught_frames: true,
        },
      },
    },
  });

  if (!isServerSide && process.env.web.environment === 'production') {
    return [client];
  }

  return [console, client];
};

const outputs = getOutputs();
const logger = {
  setPerson: id =>
    map(outputs, output => {
      if (isFunction(output.configure)) {
        output.configure({ payload: { person: { id } } });
      }
    }),
  clearPerson: () =>
    map(outputs, output => {
      if (isFunction(output.configure)) {
        output.configure({ payload: { person: { id: null } } });
      }
    }),
  error: (...args) => invokeMap(outputs, 'error', ...args),
  warn: (...args) => invokeMap(outputs, 'warn', ...args),
  info: (...args) => invokeMap(outputs, 'info', ...args),
};

export { getOutputs, logger, checkIgnore };
