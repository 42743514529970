const ChevronLeft = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      d="m6.474 13.079 6.787 6.47c.3.288.71.451 1.137.451.427 0 .836-.163 1.136-.451.621-.596.621-1.557 0-2.152l-5.667-5.401 5.667-5.402c.621-.595.621-1.556 0-2.151A1.643 1.643 0 0 0 14.399 4a1.643 1.643 0 0 0-1.137.443l-6.787 6.47A1.49 1.49 0 0 0 6 11.995c0 .406.17.796.474 1.083Z"
      clipRule="evenodd"
    />
  </svg>
);

export { ChevronLeft };
