import { ANALYTICS_EVENTS, GA_PROMOTION_SLUGS, MARKET } from '@nandosaus/constants';
import { snakeCase } from 'lodash';
import googleAnalyticsPlugin from '@analytics/google-analytics';

const currency = process.env.currencyCode;
/* eslint-disable prefer-destructuring */
const googleFloodlightId = process.env.googleFloodlightId;
const market = process.env.market;
/* eslint-enable prefer-destructuring */

const {
  CART_VIEWED,
  CHECKOUT_STARTED,
  GROUP_ORDER_COMPLETED,
  ORDER_COMPLETED,
  PAYMENT_INFO_ENTERED,
  PRODUCT_ADDED,
  PRODUCT_CLICKED,
  PRODUCT_LIST_VIEWED,
  PRODUCT_REMOVED,
  PRODUCT_VIEWED,
  RECOMMENDED_CART_ADJUSTED_ADDED,
  RECOMMENDED_CART_ADJUSTED_REMOVED,
  RECOMMENDED_CART_REQUEST_VIEW,
  USER_SIGNED_IN,
} = ANALYTICS_EVENTS;

/**
 * Reference: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
 */
const GOOGLE_ANALYTICS_EVENTS = Object.freeze({
  [PRODUCT_LIST_VIEWED]: 'view_item_list',
  [PRODUCT_CLICKED]: 'select_item',
  [PRODUCT_VIEWED]: 'view_item',
  [RECOMMENDED_CART_REQUEST_VIEW]: 'view_promotion',
  [PRODUCT_ADDED]: 'add_to_cart',
  [PRODUCT_REMOVED]: 'remove_from_cart',
  [CART_VIEWED]: 'view_cart',
  [PAYMENT_INFO_ENTERED]: 'add_payment_info',
  [CHECKOUT_STARTED]: 'begin_checkout',
  [ORDER_COMPLETED]: 'purchase',
  [GROUP_ORDER_COMPLETED]: 'group_order_completed',
});

const mapProductToGoogleAnalyticsItem = product => {
  const itemListId = (source => source && `${source.type}/${source.id}`)(product?.sources?.[0]);
  const itemListName = product.sources?.[0]?.label;

  return {
    item_id: product.product_id,
    item_name: product.name,
    price: product.price,
    quantity: product.quantity,
    item_category: product.category,
    item_category2: product.analyticsCategory,
    item_category5: product.orderType,
    item_list_id: itemListId,
    item_list_name: itemListName,
  };
};

// Custom Google Analytics Plugin
// - used to transform event names and payloads to GA e-commerce event format
// - ideally this would have used an enrichment plugin but it is not possible to transform the event name
//
// @see: https://getanalytics.io/plugins/extending-plugins/

const originalGoogleAnalytics = googleAnalyticsPlugin({
  measurementIds: process.env.googleMeasurementIds,
});

// customTrack method used to transform event names and payload to GA4 format
// GA4 documentation: https://developers.google.com/analytics/devguides/collection/ga4/ecommerce
const customTrack = ({ payload, ...params }) => {
  const formattedEvent = snakeCase(payload.event);

  switch (payload.event) {
    case PRODUCT_LIST_VIEWED: {
      return originalGoogleAnalytics.track({
        payload: {
          ...payload,
          event: GOOGLE_ANALYTICS_EVENTS[payload.event],
          properties: {
            item_list_name: payload.properties.category,
            items: payload.properties.products.map(mapProductToGoogleAnalyticsItem),
          },
        },
        ...params,
      });
    }

    case PRODUCT_CLICKED:
    case PRODUCT_VIEWED: {
      return originalGoogleAnalytics.track({
        payload: {
          ...payload,
          event: GOOGLE_ANALYTICS_EVENTS[payload.event],
          properties: {
            items: [mapProductToGoogleAnalyticsItem(payload.properties)],
          },
        },
        ...params,
      });
    }

    case RECOMMENDED_CART_REQUEST_VIEW: {
      return originalGoogleAnalytics.track({
        payload: {
          ...payload,
          event: GOOGLE_ANALYTICS_EVENTS[payload.event],
          properties: {
            promotion_name: GA_PROMOTION_SLUGS.RECOMMENDATIONS_ENGINE,
            items: payload.properties.products.map(mapProductToGoogleAnalyticsItem),
          },
        },
        ...params,
      });
    }

    case RECOMMENDED_CART_ADJUSTED_ADDED:
    case RECOMMENDED_CART_ADJUSTED_REMOVED: {
      return originalGoogleAnalytics.track({
        payload: {
          event: formattedEvent,
          properties: {
            cartId: payload.properties.cartId,
            ...mapProductToGoogleAnalyticsItem(payload.properties.product),
          },
        },
        ...params,
      });
    }

    case PRODUCT_ADDED:
    case PRODUCT_REMOVED: {
      return originalGoogleAnalytics.track({
        payload: {
          ...payload,
          event: GOOGLE_ANALYTICS_EVENTS[payload.event],
          properties: {
            currency,
            value: payload.properties.price,
            items: [mapProductToGoogleAnalyticsItem(payload.properties)],
          },
        },
        ...params,
      });
    }

    case CART_VIEWED: {
      return originalGoogleAnalytics.track({
        payload: {
          ...payload,
          event: GOOGLE_ANALYTICS_EVENTS[payload.event],
          properties: {
            currency,
            value: payload.properties.total,
            items: payload.properties.products.map(mapProductToGoogleAnalyticsItem),
          },
        },
        ...params,
      });
    }

    case CHECKOUT_STARTED: {
      return originalGoogleAnalytics.track({
        payload: {
          ...payload,
          event: GOOGLE_ANALYTICS_EVENTS[payload.event],
          properties: {
            currency,
            value: payload.properties.total,
            coupon: payload.properties.coupon,
            items: payload.properties.products.map(mapProductToGoogleAnalyticsItem),
          },
        },
        ...params,
      });
    }

    case PAYMENT_INFO_ENTERED: {
      return originalGoogleAnalytics.track({
        payload: {
          ...payload,
          event: GOOGLE_ANALYTICS_EVENTS[payload.event],
          properties: {
            currency,
            value: payload.properties.total,
            coupon: payload.properties.coupon,
            payment_type: payload.properties.paymentType,
            items: payload.properties.products.map(mapProductToGoogleAnalyticsItem),
          },
        },
        ...params,
      });
    }

    case GROUP_ORDER_COMPLETED: {
      return originalGoogleAnalytics.track({
        payload: {
          ...payload,
          event: GOOGLE_ANALYTICS_EVENTS[GROUP_ORDER_COMPLETED],
          properties: {
            cart_id: payload.properties.cartId,
            group_id: payload.properties.groupId,
            group_member_count: payload.properties.groupMemberCount,
            restaurant_id: payload.properties.restaurantId,
            time_to_purchase_total_in_minutes: payload.properties.timeToPurchaseTotalInMinutes,
            time_to_purchase_total_in_seconds: payload.properties.timeToPurchaseTotalInSeconds,
            value: payload.properties.value,
          },
        },
        ...params,
      });
    }

    case ORDER_COMPLETED: {
      // Floodlight transactions counter
      params.instance.plugins['google-analytics'].trackFloodlightEvent({
        name: 'purchase',
        transactionId: payload.properties.cartId,
        value: payload.properties.total,
        activityGroupTagString: '2020nans',
        activityTagString: market === MARKET.NEW_ZEALAND ? 'nando0' : '20nancs',
        countingMethod: 'transactions',
      });

      // Floodlight checkout success counter
      params.instance.plugins['google-analytics'].trackFloodlightEvent({
        activityGroupTagString: market === MARKET.NEW_ZEALAND ? 'Nando0' : '2020nan',
        activityTagString: market === MARKET.NEW_ZEALAND ? 'nando006' : '20nancc',
        countingMethod: 'standard',
      });

      return originalGoogleAnalytics.track({
        payload: {
          ...payload,
          event: GOOGLE_ANALYTICS_EVENTS[payload.event],
          properties: {
            currency,
            value: payload.properties.total,
            transaction_id: payload.properties.cartId,
            coupon: payload.properties.coupon,
            shipping: payload.properties.shipping,
            payment_type: payload.properties.paymentType,
            items: payload.properties.products.map(mapProductToGoogleAnalyticsItem),
          },
        },
        ...params,
      });
    }

    case USER_SIGNED_IN: {
      return originalGoogleAnalytics.track({
        payload: {
          ...payload,
          event: formattedEvent,
          // @note: do not send Personally Identifiable Information to GA
          properties: {},
        },
        ...params,
      });
    }

    default:
      return originalGoogleAnalytics.track({
        payload: {
          ...payload,
          event: formattedEvent,
        },
        ...params,
      });
  }
};

const customIdentify = ({ payload, ...params }) => {
  return originalGoogleAnalytics.identify({
    payload: {
      ...payload,
      // @note: do not send Personally Identifiable Information as traits to GA
      traits: {},
    },
    ...params,
  });
};

const googleAnalyticsOverrides = {
  track: customTrack,
  identify: customIdentify,
};

const customGoogleAnalyticsPlugin = {
  ...originalGoogleAnalytics,
  ...googleAnalyticsOverrides,
  methods: {
    ...originalGoogleAnalytics.methods,

    trackFloodlightEvent(event) {
      const { gtagName } = this.instance.getState().plugins['google-analytics'].config;

      if (!window[gtagName]) {
        return;
      }

      window[gtagName]('event', event.name || 'conversion', {
        allow_custom_scripts: true,
        value: event.value,
        transaction_id: event.transactionId,
        send_to: `${googleFloodlightId}/${event.activityGroupTagString}/${event.activityTagString}+${event.countingMethod}`,
      });
    },
  },
};

export { customGoogleAnalyticsPlugin as googleAnalyticsPlugin };
