import { CONTEXT_TYPES } from '@nandosaus/constants';
import { types } from 'mobx-state-tree';

import { GeneralContext } from './general-context';
import { PresetContext } from './preset-context';
import { GroupContext } from './group-context';

/** Contexts represent a set of restrictions that are placed on what ordering settings can be changed
 *
 * They _must_ have the following properties
 * - type: unique string value for telling them apart
 * - availableOrderTypes: which ordering types a user can manually select
 *
 * They _may_ have the following properties
 * - fields: list of properties that can be set on the current fulfilment (settings). Should include `name: string` and `writable: boolean`
 * Defaults to the current fulfilment.fields
 *
 * They _may_ have the following actions
 * - init: a method called inside `switchContext` which allows the context to do some processing before it
 * becomes live. A fulfilment can be returned from this method which will be applied to the store
 * - reset: a method which allows the context to trigger some state cleanup, i.e if in a group context we should reset the GroupOrderStore.
 * - eject: called before switching to another context. Current context must return a `Result`.
 *      If it is a failed `Result` (i.e. `Result.fail('Stuck in the middle with you')`) it will prevent the context from being switched.
 *      Also a good place for clean up
 */

const Map = {
  [CONTEXT_TYPES.GENERAL]: GeneralContext,
  [CONTEXT_TYPES.PRESET]: PresetContext,
  [CONTEXT_TYPES.GROUP]: GroupContext,
};

const Context = types.union(...Object.values(Map));

export default Context;
export { Map, GeneralContext, PresetContext, GroupContext };
