import { isNumber, round } from 'lodash';

const CURRENCY_SYMBOL = '$';

const dollarsFromCentsFormatter = cents => {
  const dollars = cents / 100;
  const roundedDollars = round(dollars, 2);

  return roundedDollars;
};

const priceFormatter = price => {
  const amount = price / 100;
  const formattedAmount = amount.toFixed(2);

  // Unfortunately the nice solution below doesn't format the numbers properly on Android,
  // e.g. it formats 9.90 to 9.9
  // leaving it here for reference
  //
  // const formattedAmount = amount.toLocaleString(undefined, {
  //   minimumFractionDigits: 2,
  //   maximumFractionDigits: 2,
  // });

  return `${CURRENCY_SYMBOL}${formattedAmount}`;
};

const kilojoulesFormatter = kilojoules => (isNumber(kilojoules) ? `${kilojoules.toLocaleString()}kJ` : undefined);

const getSign = points => {
  const sign = Math.sign(points);

  if (sign === 1) {
    return '+';
  }

  if (sign === -1) {
    return '-';
  }

  return '';
};

const pointsFormatter = points => {
  const sign = getSign(points);
  const value = Math.abs(points);
  const suffix = value === 1 ? `point` : `points`;

  const formattedPoints = {
    sign,
    value,
    suffix,
  };

  formattedPoints.toString = () => {
    return `${sign}${value} ${suffix}`;
  };

  return formattedPoints;
};

export { dollarsFromCentsFormatter, kilojoulesFormatter, priceFormatter, pointsFormatter };
