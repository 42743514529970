export const BUTTON_VARIANTS = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  QUARTERNARY: 'quarternary',
  SELECTABLE: 'selectable',
  LINK: 'link',
  DESTRUCTIVE_LINK: 'destructiveLink',
  ACTION: 'action',
  ACTION_PRIMARY: 'actionPrimary',
  ACTION_SECONDARY: 'actionSecondary',
  INACTIVE: 'inactive',
  THIN_PRIMARY: 'thinPrimary',
};

export const BUTTON_THEME_COLORS = {
  GREEN: 'green',
  ORANGE: 'orange',
  PINK: 'pink',
};
