const MenuBlue = props => (
  <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Menu Blue</title>
    <path
      d="M20.2613 10.6802L20.5006 10.6932C22.8198 10.8917 24.3762 13.5858 21.2283 17.7629C18.5108 21.3723 17.5121 21.9081 17.291 21.9869L17.2646 21.9953C17.2539 21.9982 17.247 21.9995 17.2435 22C17.2349 21.9984 17.2294 21.9972 17.2223 21.9952L17.1958 21.9867C16.9739 21.9076 15.9741 21.3724 13.2587 17.7635C10.1138 13.5858 11.6702 10.8917 13.9888 10.6932C16.27 10.4975 17.2144 12.4325 17.2471 12.493C17.275 12.4325 18.22 10.4975 20.5006 10.6932L20.2613 10.6802ZM6.17609 2C8.23163 4.15006 9.51232 6.85917 9.83436 9.73854C9.83436 9.73854 9.94684 11.8254 10.1638 13.6874L10.2073 14.1121C10.4259 15.9484 11.0335 17.7262 11.9956 19.3391L12.0973 19.5008C12.1081 19.5185 12.1295 19.5387 12.1295 19.5538C12.1616 19.6044 12.1911 19.6524 12.2232 19.6978C12.3116 19.8317 12.3652 19.905 12.3652 19.905C3.17128 18.0026 1.35821 11.8355 1.07433 8.50058C0.975222 7.78357 0.975222 7.05746 1.07433 6.34045L1.08205 6.34951C1.23002 6.52264 3.47462 9.11956 4.97095 9.11956C4.97095 9.11956 4.03362 4.43298 6.17609 2Z"
      fill="#00AFF0"
    />
  </svg>
);

export { MenuBlue };
