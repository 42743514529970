import { types } from 'mobx-state-tree';

const DeliveryAddress = types
  .model('DeliveryAddress', {
    street: types.string,
    suburb: types.string,
    unit: types.maybeNull(types.string),
    postcode: types.maybeNull(types.string),
    state: types.string,
    longitude: types.number,
    latitude: types.number,
  })
  .views(self => ({
    get formattedStreet() {
      return self.unit ? `${self.unit}/${self.street}` : self.street;
    },
    get formattedSuburbStatePostcode() {
      return self.postcode ? `${self.suburb}, ${self.state} ${self.postcode}` : `${self.suburb}, ${self.state}`;
    },
    get formattedAddress() {
      return `${self.formattedStreet}, ${self.formattedSuburbStatePostcode}`;
    },

    /**
     * Determine whether the specified address is equal to this one.
     * @param {DeliveryAddress} toAddress
     */
    isEqual(toAddress) {
      const compareKeys = ['street', 'suburb', 'unit', 'postcode', 'state', 'longitude', 'latitude'];

      return compareKeys.every(key => self[key] === toAddress[key]);
    },
  }));

export default DeliveryAddress;
