import _ from 'lodash';

const multiplyObjectByQuantity = quantifiableItem => {
  return _.times(quantifiableItem.quantity, () => ({ ...quantifiableItem, quantity: 1 }));
};

const multiplyOrderItemsByQuantity = order => ({
  ...order,
  orderItems: _.flatMap(order.orderItems, multiplyObjectByQuantity),
});

const flattenItemRefundableChoices = orderItem => {
  const [refundableChoices, nonRefundableChoices] = _.partition(orderItem.choices, 'isRefundable');

  const refundableChoicesAsOrderItems = _.flatMap(refundableChoices, choice => ({
    uuid: choice.uuid,
    name: choice.name,
    price: choice.price,
    productId: choice.plu,
    productName: choice.name,
    quantity: choice.quantity,
  }));

  const processedOrderItem = {
    ...orderItem,
    choices: nonRefundableChoices,
  };

  return [processedOrderItem, ...refundableChoicesAsOrderItems];
};

const flattenOrderItemRefundableChoices = order => {
  const flattenedOrderItems = _.flatMap(order.orderItems, flattenItemRefundableChoices);

  const augmentedOrder = {
    ...order,
    orderItems: flattenedOrderItems,
  };

  return augmentedOrder;
};

const multiplyAndFlattenOrderRefundableChoices = _.flow(
  multiplyOrderItemsByQuantity,
  flattenOrderItemRefundableChoices,
  multiplyOrderItemsByQuantity
);

const augmentItemsWithChoiceLabels = orderItems =>
  _.map(orderItems, orderItem => ({
    ...orderItem,
    choicesLabel: _.truncate(
      _.join(
        _.map(orderItem.choices, item => `${item.name}${item.quantity > 1 ? ` x${item.quantity}` : ''}`),
        ', '
      ),
      {
        length: 60,
        separator: ' ',
      }
    ),
  }));

const augmentOrderItemsWithChoiceLabels = ({ orderItems, ...restOrder }) => {
  return {
    ...restOrder,
    orderItems: augmentItemsWithChoiceLabels(orderItems),
  };
};

export { multiplyAndFlattenOrderRefundableChoices, multiplyOrderItemsByQuantity, augmentOrderItemsWithChoiceLabels };
