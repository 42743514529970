import {
  cloneDeep,
  compact,
  filter,
  find,
  first,
  flatMap,
  get,
  groupBy,
  includes,
  isEmpty,
  lowerCase,
  map,
  mapValues,
  partition,
  pickBy,
  reduce,
  sortBy,
  uniqBy,
} from 'lodash';
import { flow, getEnv, getRoot, types } from 'mobx-state-tree';

import { BRAZE_CMS_TYPES } from './schemas';
import { mapBrazeCardsToAppContent } from './mapping';

const initialState = {};

const waitForCardRefresh = async braze => {
  return new Promise((resolve, reject) => {
    braze.requestContentCardsRefresh(
      () => {
        resolve(true);
      },
      () => {
        reject(new Error('Failed to refresh content cards'));
      }
    );
  });
};

const sortCardsWithinGroupsByDate = cards => {
  return mapValues(cards, cardGroup => sortBy(cardGroup, 'created').reverse());
};

const BrazeStore = types
  .model('BrazeStore', {
    contentCards: types.optional(types.frozen(), {}),
    menuCards: types.optional(types.frozen(), {}),
    cmsCards: types.optional(types.frozen(), {}),
    updated: false,
  })
  .actions(self => {
    const { getBraze, logger, platform } = getEnv(self);
    return {
      loadContentCards: flow(function*() {
        try {
          /**
           * @type {{ braze: import('braze-web-sdk').default, isInitialised: boolean }}}
           */
          const { braze, isInitialised } = yield getBraze();
          if (!isInitialised) {
            return;
          }

          const isWeb = platform === 'Web';
          const platformMatchedCards = isWeb
            ? yield self.loadWebContentCards(braze)
            : yield self.loadMobileContentCards(braze);
          if (isEmpty(platformMatchedCards)) {
            return;
          }
          const mappedCards = mapBrazeCardsToAppContent({ cards: platformMatchedCards, logger, braze, isWeb });
          const sortedCards = sortCardsWithinGroupsByDate(mappedCards);
          self.setContentCards(sortedCards);

          // group menu cards
          const menuCards = sortedCards['menu-content'];

          const groupedMenuCards = sortCardsWithinGroupsByDate(groupBy(menuCards, 'extras.handle'));
          self.setMenuCards(groupedMenuCards);

          // group cms cards
          const cmsCards = flatMap(pickBy(sortedCards, (_, k) => includes(BRAZE_CMS_TYPES, k)));
          const groupedCMSCards = sortCardsWithinGroupsByDate(groupBy(cmsCards, 'extras.cmsId'));
          self.setCMSCards(groupedCMSCards);
        } catch (error) {
          logger.error(error.message, { error });
        }
      }),

      loadWebContentCards: flow(function*(braze) {
        if (self.updated) {
          return self.contentCards;
        }
        self.updated = yield waitForCardRefresh(braze);
        const { cards } = braze.getCachedContentCards();
        const platformMatchedCards = filter(
          cards,
          card => !isEmpty(card.extras?.device) && lowerCase(card.extras?.device) === 'web'
        );
        return platformMatchedCards;
      }),

      loadMobileContentCards: flow(function*(braze) {
        const cards = yield braze.getContentCards();
        const platformMatchedCards = filter(
          cards,
          card => !isEmpty(card.extras?.device) && lowerCase(card.extras?.device) !== 'web'
        );
        return platformMatchedCards;
      }),

      setContentCards: contentCards => {
        self.contentCards = contentCards;
      },
      setMenuCards: menuCards => {
        self.menuCards = menuCards;
      },
      setCMSCards: cmsCards => {
        self.cmsCards = cmsCards;
      },
      clearSessionData() {
        self.contentCards = {};
        self.menuCards = {};
        self.cmsCards = {};
        self.updated = false;
      },
    };
  })
  .views(self => {
    const { FeatureFlagStore, MenuStore } = getRoot(self);
    const { logger, platform } = getEnv(self);
    return {
      getContentCardsByType: type => {
        return self.contentCards[type];
      },
      getContentCardsByTypeAndFeatureFlags: (type, cardType = 'others') => {
        if (!FeatureFlagStore.isActive(`show-braze-${type}`)) {
          logger.info(`${platform !== 'Web' ? 'Mobile' : 'Web'} Braze card type 'show-braze-${type}' is not enabled`);
          return null;
        }

        switch (cardType) {
          case 'menu':
            return self.menuCards[type];
          case 'cms':
            return self.cmsCards[type];
          default:
            return self.contentCards[type];
        }
      },
      getLatestContentCardByType: (type, cardType = 'others') => {
        const cards = self.getContentCardsByTypeAndFeatureFlags(type, cardType);
        return first(cards);
      },
      getLatestContentCardExtraByType: type => {
        const card = self.getLatestContentCardByType(type);
        return get(card, 'extras', null);
      },
      getLatestCMSContentCardExtraByType: type => {
        const card = self.getLatestContentCardByType(type, 'cms');
        return get(card, 'extras', null);
      },
      getLatestMenuContentCardExtraByType: type => {
        const card = self.getLatestContentCardByType(type, 'menu');
        return get(card, 'extras', null);
      },
      getLatestMenuContentCardsExtras: () => {
        const { menuCards } = self;
        const handledCards = reduce(
          menuCards,
          (result, cardGroup, cardType) => {
            const card = first(cardGroup);
            return { ...result, [cardType]: card.extras };
          },
          {}
        );
        return handledCards;
      },
      get brazeMergedCategories() {
        const menuCategories = get(MenuStore.filteredMenu, 'categories', []);

        const brazeMenuCategories = self.getLatestMenuContentCardsExtras();

        if (isEmpty(brazeMenuCategories)) return menuCategories;

        const menuCategoryHandles = map(menuCategories, c => c.handle);
        const [matchedBrazeCategories, unmatchedBrazeCategories] = partition(brazeMenuCategories, bmc =>
          includes(menuCategoryHandles, bmc.handle)
        );

        const mergedCategories = map(menuCategories, (category, index) => {
          const brazeCategory = find(matchedBrazeCategories, mc => mc.handle === category.handle);

          const order = index + 1; // aid for simplifying category list ordering

          if (!brazeCategory) return { order, ...category };

          const brazeCategoryProducts = compact(
            map(brazeCategory.products, plu => MenuStore.menu?.getProductByPlu(plu))
          );

          const mergedCategory = {
            order,
            ...category,
            ...brazeCategory,
            products: uniqBy([...brazeCategoryProducts, ...category.products], 'plu'),
          };

          return mergedCategory;
        });

        const populatedUnmatchedBrazeCategories = map(unmatchedBrazeCategories, category => {
          const products = compact(map(category.products, plu => MenuStore.menu?.getProductByPlu(plu)));
          if (isEmpty(products)) return null;
          return {
            ...category,
            products,
          };
        });

        const sortedMergedCategories = sortBy(
          compact([...mergedCategories, ...populatedUnmatchedBrazeCategories]),
          c => c.order
        );

        return sortedMergedCategories;
      },

      get brazeMergedCategoryNames() {
        const filteredCategories = filter(self.brazeMergedCategories, category => !isEmpty(get(category, 'products')));

        return map(filteredCategories, 'name');
      },

      get upsellCard() {
        const extras = self.getLatestContentCardExtraByType('hot-picks');
        if (!extras) {
          return null;
        }
        const products = compact(map(extras.products, plu => MenuStore.menu?.getProductByPlu(plu)));
        if (isEmpty(products)) {
          return null;
        }

        const productMappedExtras = { ...extras, plus: extras.products, products };
        return productMappedExtras;
      },

      get featuredProductsCard() {
        const extras = self.getLatestContentCardExtraByType('popular-this-week');
        if (!extras) {
          return null;
        }

        const products = compact(map(extras.favourites, plu => MenuStore.menu?.getProductByPlu(plu)));
        if (isEmpty(products)) {
          return null;
        }

        const productMappedExtras = { ...extras, plus: extras.favourites, favourites: products };
        return productMappedExtras;
      },

      get specialOfferCard() {
        const extras = self.getLatestContentCardExtraByType('special-offer-promotion-modal');
        if (!extras) {
          return null;
        }

        const product = MenuStore.menu?.getProductByPlu(extras.plu);
        if (!product) {
          return null;
        }

        const productMappedExtras = { ...extras, promotedProduct: product };
        return productMappedExtras;
      },

      getBrazeCmsContent: cmsBlock => {
        if (!cmsBlock.brazeId) return cmsBlock;

        const extras = self.getLatestCMSContentCardExtraByType(cmsBlock.brazeId);
        if (!extras) return cmsBlock;

        return { ...cloneDeep(cmsBlock), ...extras };
      },
    };
  });

BrazeStore.initialState = initialState;

export default BrazeStore;
