import { join, map, truncate } from 'lodash';
import { getEnv, getRoot, types } from 'mobx-state-tree';
import moment from 'moment';

import Address from '../address';
import PastOrderItem from '../past-order-item';
import Restaurant from '../restaurant';
import { formatChoicesForCart, formatOrderItems } from './utils';

// Meals do not typically have images associated with them, which looks bad.
// To remedy this at runtime, we attempt to load the item associated with the meal
// and replace the product with this new one.
export const attemptToExtractMealProduct = product => {
  if (product?.isMealProduct && product?.itemProduct) {
    return product.itemProduct;
  }

  return product;
};

// Sorts two products from a past order's formattedOrderItems() by their price in cents
export const sortByPriceDescending = (productOne, productTwo) => {
  if (!productTwo?.prices?.cents || !productOne?.prices?.cents) {
    return 0;
  }

  return productTwo.prices.cents - productOne.prices.cents;
};

export const isProductAvailable = product => product?.id && product?.isAvailable;

const PastOrder = types
  .model('PastOrder', {
    id: types.identifier,
    date: types.Date,
    orderItems: types.array(PastOrderItem),
    restaurant: types.maybe(types.reference(Restaurant)),
    deliveryAddress: types.maybeNull(Address),
    deliveryNotes: types.maybeNull(types.string),
    deliveryTrackingUrl: types.maybeNull(types.string),
    table: types.maybeNull(types.string),
    isRefundableAsGiftCard: types.maybeNull(types.boolean),
    isRefunded: types.maybeNull(types.boolean),
    isVisibleInHelpFlow: types.maybeNull(types.boolean),
  })
  .actions(self => {
    const { AlertsStore, CartStore } = getRoot(self);

    return {
      addOrderItemsToCart() {
        const validOrderItems = self.formattedForCart.filter(({ product }) => isProductAvailable(product));
        const invalidOrderItems = self.formattedForCart.filter(({ product }) => !isProductAvailable(product));

        if (invalidOrderItems.length > 0) {
          AlertsStore.add({
            body: `Some items were unable to be added as they're not available from your selected restaurant:\r\n\r\n
      ${invalidOrderItems.map(({ product: { name } }) => name).join('\r\n')}`,
            title: 'Your order has been updated',
            dismissText: 'Return to menu',
          });
        }

        if (validOrderItems.length > 0) {
          const reorderItems = validOrderItems.map(item => ({ ...item, isReorderItem: true }));
          CartStore.addOrderItems(reorderItems);
        }
      },
    };
  })
  .views(self => {
    const { MenuStore, CartStore } = getRoot(self);
    const { logger } = getEnv(self);

    return {
      get formattedDate() {
        return moment(self.date).fromNow();
      },
      get formattedOrderItems() {
        return formatOrderItems(self.orderItems, MenuStore, logger);
      },
      get productNamesJoined() {
        return truncate(
          join(
            map(self.formattedOrderItems, ({ product }) => product.name),
            ', '
          ),
          {
            length: 60,
            separator: ' ',
          }
        );
      },
      get formattedForCart() {
        const formattedForCart = map(self.formattedOrderItems, formattedOrderItem => ({
          product: formattedOrderItem.product,
          quantity: formattedOrderItem.productQuantity,
          choices: formatChoicesForCart(formattedOrderItem.productChoices),
        }));

        return formattedForCart;
      },
      get isAvailableAtCurrentRestaurant() {
        return self.formattedForCart.some(formattedOrderItem => isProductAvailable(formattedOrderItem.product));
      },
      get highestCostItemWithImage() {
        // It's not possible to make an order that doesn't contain a single item, not an edge case we care about
        const products = self.formattedOrderItems.map(({ product }) => product).map(attemptToExtractMealProduct);
        const sortedProducts = [...products].sort(sortByPriceDescending);
        const productsWithImages = sortedProducts.filter(({ image }) => image);

        return productsWithImages[0] ?? sortedProducts[0];
      },
      get isEligibleForDeliveryDiscount() {
        const products = self.formattedOrderItems.map(({ product }) => product).map(attemptToExtractMealProduct);
        return products.some(({ isEligibleForDeliveryDiscount }) => isEligibleForDeliveryDiscount);
      },
      get isInCart() {
        const isInCart = self.formattedOrderItems.every(({ product, productQuantity }) => {
          const item = attemptToExtractMealProduct(product);
          const quantity = parseInt(CartStore.productQuantityInCart(item.id), 10);
          return quantity >= productQuantity;
        });
        return isInCart;
      },
      get showcaseProduct() {
        return {
          ...self.highestCostItemWithImage,
          name: self.productNamesJoined,
          isEligibleForDeliveryDiscount: self.isEligibleForDeliveryDiscount,
          isInCart: self.isInCart,
        };
      },
    };
  });

export default PastOrder;
