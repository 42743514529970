import { propType as StyledSystemPropType } from '@styled-system/prop-types';
import { useRef } from 'react';
import styled from 'styled-components';

import { breakpoints, headingSize } from '../../constants';
import { headingStyles } from './font-styles';

const shouldForwardProp = (prop, defaultValidatorFn) => {
  return !['transform', 'color', 'fontWeight'].includes(prop) && defaultValidatorFn(prop);
};

const Heading1 = styled.h1.withConfig({
  shouldForwardProp,
})`
  ${headingStyles}
  font-size: ${headingSize.L3M2S1};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${headingSize.L2M1Sx};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${headingSize.L1MxSx};
    line-height: 1;
  }
`;

const Heading2 = styled.h2.withConfig({
  shouldForwardProp,
})`
  ${headingStyles}
  font-size: ${headingSize.LxM3S2};

  line-height: 1em;

  @media (min-width: ${breakpoints.md}) {
    font-size: ${headingSize.L3M2S1};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${headingSize.L2M1Sx};
  }
`;

const Heading3 = styled.h3.withConfig({
  shouldForwardProp,
})`
  ${headingStyles}
  font-size: ${headingSize.LxMxS3};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${headingSize.LxM3S2};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${headingSize.L3M2S1};
  }
`;

const H1 = ({ mt, mb, ...rest }) => {
  const headingRef = useRef();

  return <Heading1 ref={headingRef} mt={mt ?? '0.5rem'} mb={mb ?? '0.5rem'} {...rest} />;
};

const H2 = ({ mt, mb, ...rest }) => {
  const headingRef = useRef();

  return <Heading2 ref={headingRef} mt={mt ?? '0.5rem'} mb={mb ?? '0.5rem'} {...rest} />;
};

const H3 = ({ ...rest }) => {
  const headingRef = useRef();

  return <Heading3 ref={headingRef} {...rest} />;
};

const headingPropTypes = {
  color: StyledSystemPropType,
  m: StyledSystemPropType,
  mb: StyledSystemPropType,
  ml: StyledSystemPropType,
  mr: StyledSystemPropType,
  mt: StyledSystemPropType,
  mx: StyledSystemPropType,
  my: StyledSystemPropType,
  p: StyledSystemPropType,
  pb: StyledSystemPropType,
  pl: StyledSystemPropType,
  pr: StyledSystemPropType,
  pt: StyledSystemPropType,
  px: StyledSystemPropType,
  py: StyledSystemPropType,
};

const headingDefaultProps = {
  color: null,
  m: null,
  mb: null,
  ml: null,
  mr: null,
  mt: null,
  mx: null,
  my: null,
  p: null,
  pb: null,
  pl: null,
  pr: null,
  pt: null,
  px: null,
  py: null,
};

H1.propTypes = headingPropTypes;
H1.defaultProps = headingDefaultProps;

H2.propTypes = headingPropTypes;
H2.defaultProps = headingDefaultProps;

H3.propTypes = headingPropTypes;
H3.defaultProps = {
  ...headingDefaultProps,
  transform: 'none',
};

export { H1, H2, H3 };
