import { sleep } from '..';

const MAX_RETRIES = 50;
const SLEEP_DURATION = 500;

class BrazeError extends Error {
  constructor(message, { attempts, reason }) {
    super(message);
    this.attempts = attempts;
    this.reason = reason;
  }
}

const getBrazeError = (message, { attempts, reason }) => {
  return new BrazeError(message, { attempts, reason });
};

const getBrazeWithRetry = async (attempts = 0, error) => {
  if (attempts === MAX_RETRIES) {
    throw getBrazeError('getBrazeWithRetry() failed after 20 attempts', error);
  }

  try {
    if (typeof window === 'undefined') {
      throw getBrazeError('window is undefined', { reason: 'window is undefined', attempts });
    }

    const { braze } = window;

    if (!braze) {
      throw getBrazeError('braze not available on the window object', {
        attempts,
      });
    }

    try {
      if (braze.getUser()) {
        return { braze, isInitialised: true };
      }
    } catch (brazeError) {
      // throw an error that we would handle in the catch block below
      throw getBrazeError('braze.getUser() threw an error', { reason: 'braze.getUser() threw an error', attempts });
    }

    throw getBrazeError('braze.getUser() returned undefined', {
      reason: 'braze.getUser() returned undefined',
      attempts,
    });
  } catch (brazeError) {
    await sleep(SLEEP_DURATION);
    return getBrazeWithRetry(attempts + 1, { reason: brazeError.reason, attempts: brazeError.attempts });
  }
};

const getBraze = async () => {
  const braze = await getBrazeWithRetry();
  return braze;
};

export { getBraze };
