import { types } from 'mobx-state-tree';

import Image from '../image';

const variant = types.model('PerksShopProductVariant', {
  color: types.maybeNull(types.string),
  plu: types.number,
  size: types.maybeNull(types.string),
  stock: types.maybeNull(types.number),
});

const PerksShopProduct = types.model('PerksShopProduct', {
  category: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  hasStock: types.boolean,
  heading: types.string,
  id: types.identifier,
  image: types.maybeNull(Image),
  name: types.string,
  pointsPrice: types.number,
  variants: types.optional(types.array(variant), []),
});

export default PerksShopProduct;
