import { first, get, toUpper } from 'lodash';
import { flow, getEnv, types } from 'mobx-state-tree';
import { MAX_LOCATION_DISTANCE } from '@nandosaus/constants';
import gql from 'graphql-tag';

import Restaurant from '../../models/restaurant';

const initialState = {
  restaurants: {},
};

const RESTAURANTS_QUERY = gql`
  query getRestaurants {
    restaurants {
      id
      name
      longitude
      latitude
      phone
      status
      openNow
      nextOpen
      orderingEnabled
      tableOrderingEnabled
      deliveryEnabled
      pickUpEnabled
      dineInGuestCheckoutEnabled
      deliveryGuestCheckoutEnabled
      pickUpGuestCheckoutEnabled
      address {
        address1
        address2
        country
        postcode
        state
        suburb
      }
      openingHours {
        day
        openingTime
        closingTime
      }
      catering
      facilities
      deliveryServices {
        uberEats {
          live
          link
        }
        menulog {
          live
          link
        }
        doorDash {
          live
          link
        }
      }
      averageOrderTime
      thresholds {
        lowerThresholdAmount
        orderType
        time
      }
      utcOffset
      seoTitle
      isHoliday
    }
  }
`;

const CLOSEST_RESTAURANT_QUERY = gql`
  query nearbyRestaurants($latitude: Float!, $longitude: Float!) {
    nearbyRestaurants(latitude: $latitude, longitude: $longitude) {
      id
      name
      longitude
      latitude
      phone
      address {
        address1
        address2
        country
        postcode
        state
        suburb
      }
    }
  }
`;

const getClosestRestaurant = async ({ client, latitude, longitude }) => {
  try {
    const { data } = await client.query({ query: CLOSEST_RESTAURANT_QUERY, variables: { latitude, longitude } });
    return { data: data.nearbyRestaurants };
  } catch (error) {
    return { error };
  }
};

const getRestaurants = async client => {
  try {
    const { data } = await client.query({ query: RESTAURANTS_QUERY });
    return { data: data.restaurants };
  } catch (error) {
    return { error };
  }
};

const RestaurantStore = types
  .model('RestaurantStore', {
    loading: false,
    error: false,
    lastLoaded: 0,
    restaurants: types.optional(types.map(Restaurant), {}),
  })
  .actions(self => {
    const { getApiClient, logger } = getEnv(self);
    return {
      addRestaurant(restaurant) {
        self.restaurants.put(restaurant);
      },
      setError(displayError) {
        self.error = displayError;
      },
      loadRestaurants: flow(function*() {
        self.error = false;
        self.loading = true;
        try {
          const client = yield getApiClient();
          const result = yield getRestaurants(client);
          const { data, error } = result;
          if (error) {
            throw error;
          }

          const toMerge = data.map(value => [value.id, value]);
          self.restaurants.merge(toMerge);
          self.lastLoaded = Date.now();
        } catch (error) {
          self.error = true;
          logger.error('Failed to fetch restaurant data', { error });
        }
        self.loading = false;
      }),
      loadClosestRestaurants: flow(function*({ latitude, longitude }) {
        self.error = false;
        self.loading = true;
        try {
          const client = yield getApiClient();
          const result = yield getClosestRestaurant({ client, latitude, longitude });
          const { data, error } = result;
          if (error) {
            throw Error('There was an error fetching data');
          }
          self.closestRestaurant = first(data);
        } catch (error) {
          logger.error('Failed to fetch closest restaurant', { error });
          self.error = true;
        }
        self.loading = false;
      }),
    };
  })
  .views(self => ({
    get asArray() {
      return Array.from(self.restaurants.values());
    },
    get loaded() {
      return self.lastLoaded !== 0;
    },
    closestRestaurant({ latitude, longitude }) {
      let smallestDistance;
      let closestRestaurant;
      self.asArray.map(restaurant => {
        const distanceToRestaurant = restaurant.distanceToRestaurant({ latitude, longitude });
        if (!smallestDistance || distanceToRestaurant < smallestDistance) {
          smallestDistance = distanceToRestaurant;
          closestRestaurant = restaurant;
        }
        return null;
      });
      return closestRestaurant;
    },
    closestRestaurants({ latitude, longitude }, numberOfRestaurants) {
      const closestRestaurants = self.asArray.map(restaurant => {
        const distanceToRestaurant = restaurant.distanceToRestaurant({ latitude, longitude });
        return { restaurant, distanceToRestaurant };
      });

      const sortedClosestRestaurants = closestRestaurants
        .filter(restaurant => restaurant.distanceToRestaurant < MAX_LOCATION_DISTANCE)
        .sort((a, b) => a.distanceToRestaurant - b.distanceToRestaurant)
        .slice(0, numberOfRestaurants);

      return sortedClosestRestaurants;
    },
    getRestaurantById(id) {
      return self.asArray.find(restaurant => restaurant.id === id);
    },
    getRestaurantsByState(state) {
      return self.asArray.filter(restaurant => toUpper(get(restaurant, 'address.state')) === toUpper(state));
    },
  }));

RestaurantStore.initialState = initialState;

export default RestaurantStore;
