import Prices from '../../models/prices';

const calculateSubtotalPrices = ({ orderItems }) => {
  const subtotalPrices = orderItems.reduce(
    (accumulator, orderItem) => {
      return {
        cents: accumulator.cents + orderItem.totals.cents,
        points: accumulator.points + orderItem.totals.points,
      };
    },
    { cents: 0, points: 0 }
  );

  return Prices.create(subtotalPrices);
};

export { calculateSubtotalPrices };
