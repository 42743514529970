import { types } from 'mobx-state-tree';

const Address = types.model('Address', {
  address1: types.string,
  address2: types.maybeNull(types.string),
  country: types.maybeNull(types.string),
  postcode: types.string,
  state: types.string,
  suburb: types.string,
});

export default Address;
