import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { A, P } from '../typography';

const AlertLink = ({ children, onLinkClick, href }) => {
  if (onLinkClick) {
    return (
      <LinkButton type="button" onClick={onLinkClick}>
        <P variant={3}>{children}</P>
      </LinkButton>
    );
  }

  if (href) {
    return (
      <StyledA variant={3} href={href}>
        {children}
      </StyledA>
    );
  }

  return null;
};

const LinkButton = styled.button`
  color: inherit;
  text-decoration: underline;
`;

const StyledA = styled(A)`
  color: inherit;
  text-decoration: underline;
`;

AlertLink.propTypes = {
  children: PropTypes.string.isRequired,
  onLinkClick: PropTypes.func,
  href: PropTypes.string,
};

AlertLink.defaultProps = {
  href: null,
  onLinkClick: null,
};

export { AlertLink };
