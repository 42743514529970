import * as React from 'react';

const DigitalWallet = props => (
  <svg xmlns="http://www.w3.org/2000/svg" width={22} height={21} {...props}>
    <rect width={19.2} height={18.2} x={1.4} y={1.4} stroke="#000" strokeWidth={1.8} rx={2.1} fill="none" />
    <path
      stroke="#000"
      strokeWidth={1.8}
      fill="none"
      d="M4.5 4.5h13a3 3 0 0 1 3 3v3m-9.37-3.309c1.402.672 2.043 1.97 1.905 3.375l-.018.162-.02.137-.178 1.252 1.012-.197c.07.302.096.715.003 1.236-.096.537-.235.877-.42 1.146-.191.277-.472.542-.948.872-.111.08-.24.2-.34.341a1.002 1.002 0 0 0-.137.935c-1.428-.258-2.374-.998-2.82-1.877-.511-1.005-.472-2.394.57-3.864c.093-.132.198-.268.32-.419.086-.103.324-.377.577-.666.322-.388.533-.9.603-1.396a2.41 2.41 0 0 0-.02-.81Z"
    />
  </svg>
);
export { DigitalWallet };
