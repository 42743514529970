import { types } from 'mobx-state-tree';

const StoredPaymentMethod = types
  .model('StoredPaymentMethod', {
    id: types.identifier,
    expiryMonth: types.string,
    expiryYear: types.string,
    brand: types.string,
    lastFour: types.string,
    name: types.string,
  })
  .views(self => ({
    get formattedCard() {
      return `Ends in: ${self.lastFour} Expires: ${self.expiryMonth}/${self.expiryYear}`;
    },
  }));

export default StoredPaymentMethod;
