import { DEFAULT_SUB_CART_ID, getAllAsList } from '../models/sub-cart';

export const formatOrderItemForProduct = (orderItem, { subCart, orderType, group }) => {
  const drinkChoices = orderItem.drinks?.map(choice => choice.option.name);
  const sideChoices = orderItem.sides?.map(choice => choice.option.name);

  // In the future we can add lists like 'popular this week' to the sources list.
  // Could also include 'recs engine'
  const sources = [];
  if (group && subCart && subCart.id !== DEFAULT_SUB_CART_ID) {
    sources.push({
      type: 'group-order-invitee',
      id: `${group.id}/${subCart.id}`,
      label: `Group invitee cart`,
    });
  }

  return {
    product_id: orderItem?.product?.plu,
    name: orderItem?.product?.name,
    price: Number(orderItem?.totals?.dollarValue) || 0,
    quantity: orderItem.quantity,
    category: orderItem.product?.productCategory?.name,
    isRecommendedItem: orderItem.isRecommendedItem,
    drinkChoices,
    sideChoices,
    orderType,
    sources,
  };
};

export const formatSubCartsForProducts = (subCarts, { orderType, group }) => {
  return getAllAsList(subCarts).flatMap(subCart =>
    subCart.orderItems.map(item => formatOrderItemForProduct(item, { subCart, orderType, group }))
  );
};
