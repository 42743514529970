import { compact, get } from 'lodash';
import { getEnv, getRoot, types } from 'mobx-state-tree';
import { ORDER_STATUS } from '@nandosaus/constants';
import { v4 as uuidv4 } from 'uuid';

import { kilojoulesFormatter } from '../../util/formatter';
import Prices from '../../models/prices';

const OrderStore = types
  .model('OrderStore', {
    status: types.optional(types.enumeration(Object.values(ORDER_STATUS)), ORDER_STATUS.NEW),
  })
  .views(self => {
    const { CartStore, DeliveryStore, OrderingContextStore } = getRoot(self);
    const { logger } = getEnv(self);

    return {
      get isDelivery() {
        logger.info('Deprecated: use context store directly', 'get isDelivery');
        return OrderingContextStore.isDelivery;
      },

      /**
       * Get the discounted total of all items being ordered
       */
      get subTotal() {
        const { discountPrices, subtotalPrices } = CartStore;
        const totalPrices = {
          cents: subtotalPrices.cents - discountPrices.cents,
          points: subtotalPrices.points - discountPrices.points,
        };

        return Prices.create(totalPrices);
      },

      get surchargeTotal() {
        const { surchargePrices } = CartStore;
        return Prices.create(surchargePrices);
      },

      get subtotalWithDelivery() {
        const { deliveryCost, subTotal } = self;
        const totalPrices = {
          cents: subTotal.cents + get(deliveryCost, 'cents', 0),
          points: subTotal.points + get(deliveryCost, 'points', 0),
        };

        return Prices.create(totalPrices);
      },

      /**
       * Get the total order cost that should be paid
       */
      get payableTotal() {
        const { subtotalWithDelivery, surchargeTotal } = self;
        const totalPrices = {
          cents: subtotalWithDelivery.cents + surchargeTotal.cents,
          points: subtotalWithDelivery.points + surchargeTotal.points,
        };

        return Prices.create(totalPrices);
      },

      /**
       * Get the delivery cost. Will be null if not applicable.
       */
      get deliveryCost() {
        if (!self.isDelivery) {
          return null;
        }

        return get(DeliveryStore, 'deliveryCost');
      },

      /**
       * Get the formatted delivery cost (e.g. $5.00). Will be null if not applicable.
       */
      get formattedDeliveryCost() {
        if (!self.isDelivery) {
          return null;
        }

        return DeliveryStore.formattedDeliveryCost;
      },

      get kilojoulesTotal() {
        const { orderItems } = CartStore;

        const kilojoulesTotal = orderItems.reduce((accumulator, orderItem) => accumulator + orderItem.kilojoules, 0);
        return kilojoulesFormatter(kilojoulesTotal);
      },

      /**
       * Get an array of all errors with the current order which can be determined
       * without first validating via the API.
       */
      get clientSideErrors() {
        if (!self.restaurant) {
          if (self.isDelivery) {
            return [
              {
                id: uuidv4(),
                heading: 'No delivery address selected',
                message: 'Please select a delivery address to fulfill your order',
                type: 'standard',
              },
            ];
          }

          return [
            {
              id: uuidv4(),
              heading: 'No restaurant selected',
              message: 'Please select a restaurant to fulfill your order',
              type: 'standard',
            },
          ];
        }

        return [];
      },

      /**
       * Get an array of all errors returned when validating the current order via the API.
       */
      get validationErrors() {
        return CartStore.errors.map(validationError => ({
          id: validationError.id,
          message: validationError.toString(),
          type: validationError.path === '/orderItems' ? 'item' : 'standard',
        }));
      },

      /**
       * Get an array of all errors which prevent the current order from being placed.
       */
      get allErrors() {
        if (self.clientSideErrors.length > 0) {
          return self.clientSideErrors;
        }

        return self.validationErrors;
      },

      /**
       * Get all standard (non-item) errors which prevent the current order from being placed.
       */
      get standardErrors() {
        return self.allErrors.filter(error => error.type === 'standard');
      },

      /**
       * Get all order item errors which prevent the current order from being placed.
       */
      get itemErrors() {
        return self.allErrors.filter(error => error.type === 'item');
      },

      /**
       * Get the restaurant which is being ordered from.
       */
      get restaurant() {
        return OrderingContextStore.restaurant;
      },

      /**
       * Get whether the order is currently being validated against the Nando's API.
       */
      get isValidating() {
        return self.status === ORDER_STATUS.NEW && CartStore.loading;
      },

      /**
       * Get payment details for the current order.
       */
      get payment() {
        return CartStore.payment;
      },

      get orderSummaryForApplePay() {
        const { discountPrices, orderItems, splitAmountGiftCard } = CartStore;
        const toDollars = ({ cents }) => cents / 100;
        const items = orderItems.map(orderItem => {
          const { quantity, product, totals } = orderItem;
          const { name } = product;
          const itemName = quantity > 1 ? `${quantity} x ${name}` : name;
          return { name: itemName, price: toDollars(totals) };
        });
        const deliveryFee = self.isDelivery ? { name: 'Delivery Fee', price: toDollars(self.deliveryCost) } : null;
        const discount = discountPrices.cents > 0 ? toDollars(discountPrices) * -1 : null;
        return compact([
          ...items,
          deliveryFee,
          discount && { name: 'Offers', price: discount },
          { name: "Nando's", price: toDollars({ cents: self.payableTotal.cents - splitAmountGiftCard }) },
        ]);
      },

      get orderSummaryPriceAsString() {
        const { splitAmountGiftCard } = CartStore;
        return ((self.payableTotal.cents - splitAmountGiftCard) / 100).toString();
      },
      get orderSummaryForApplePayWeb() {
        return { price: self.orderSummaryPriceAsString };
      },
      get orderSummaryForGooglePay() {
        return { price: self.orderSummaryPriceAsString };
      },
    };
  })
  .actions(self => {
    const { CartStore, DeliveryStore, OrderingContextStore } = getRoot(self);

    return {
      clearSessionData() {
        self.setStatus(ORDER_STATUS.NEW);
        OrderingContextStore.reset();
        CartStore.clear();
        DeliveryStore.clearSessionData();
      },

      /**
       * Used by the UI to dismiss the current payment error so
       * that the user can attempt payment again.
       */
      dismissPaymentError() {
        self.setStatus(ORDER_STATUS.NEW);
        CartStore.payment.setErrorMessage(undefined);
      },

      /**
       * Used by the UI to notify state management that a payment challenge
       * has been cancelled by the user.
       */
      handlePaymentChallengeCancellation() {
        self.setStatus(ORDER_STATUS.NEW);
      },

      /**
       * Used by the UI to notify state management that an error
       * occurred whilst completing the payment challenge.
       */
      handlePaymentChallengeError() {
        self.setStatus(ORDER_STATUS.PAYMENT_ERROR);
      },

      handlePaymentError({ message }) {
        self.setStatus(ORDER_STATUS.PAYMENT_ERROR);
        CartStore.payment.setErrorMessage(message);
      },

      /**
       * Used by CartStore to update order status whilst submitting an order.
       * Should not be used by the UI directly.
       * TODO: Remove once createOrder has been moved into OrderStore.
       * @param {string} newStatus
       */
      setStatus(newStatus) {
        self.status = newStatus;
      },
    };
  });

export default OrderStore;
