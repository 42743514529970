import { applySnapshot, flow, getEnv, getRoot, getSnapshot, onSnapshot, types } from 'mobx-state-tree';
import {
  CONTEXT_TYPES,
  GROUP_CARTS_POLLING_INTERVAL,
  GROUP_ORDER_KEY,
  GROUP_ORDER_STATUS,
  ORDER_TYPES,
} from '@nandosaus/constants';
import { when } from 'mobx';
import gql from 'graphql-tag';
import { serializeError } from 'serialize-error';
import Result from '../../result';

const TTL_SECONDS = 60 * 60;

const CREATE_GROUP_FAILURE = 'Failed to create group';
const CREATE_GROUP_ERROR = 'Error occurred creating group';
const GROUP_NOT_FOUND_MESSAGE = "Whoops! Couldn't find your group, please check the join link.";
const RESTAURANT_NOT_FOUND_MESSAGE = "Whoops! We can't find the restaurant this group is a part of.";
const LOAD_GROUP_GENERIC_MESSAGE = 'Whoops! Something went wrong when loading your group. Please try again.';
const NO_GROUP_ERROR = 'Cannot join group when no group is loaded';
const NO_NAME_ERROR = 'Cannot join group when invitee name is empty';
const GROUP_ORDER_PLACED_ERROR = 'Cannot join group when order has already been submitted';
const GROUP_ORDER_PLACED_MESSAGE = 'Sorry! Your group is not accepting orders.';
const HOST_CANNOT_JOIN_GROUP_MESSAGE = 'Sorry! You are a host, you are already in a group.';

const initialState = {
  loading: false,
  isHost: false,
  inviteeName: null,
  groupCartsPollingId: null,
  group: undefined,
};

const GROUP_QUERY = gql`
  query group($id: String!) {
    group(id: $id) {
      id
      name
      restaurantId
      orderType
      tableNumber
      shareLink
      status
    }
  }
`;

const CREATE_GROUP_MUTATION = gql`
  mutation createGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      success
      group {
        id
        shareLink
        status
      }
    }
  }
`;

const GROUP_CARTS_QUERY = gql`
  query groupCarts($id: String!) {
    groupCarts(id: $id) {
      id
      name
      cartItems {
        choices
        product
        quantity
      }
    }
  }
`;

const loadGroup = async ({ client, id }) => {
  const result = await client.query({ query: GROUP_QUERY, variables: { id } });
  return result?.data?.group;
};

const createGroup = async ({ client, name, restaurantId, orderType, tableNumber }) => {
  const result = await client.mutate({
    mutation: CREATE_GROUP_MUTATION,
    variables: { input: { name, restaurantId, orderType, tableNumber } },
  });
  return result?.data?.createGroup;
};

const loadGroupCarts = async ({ client, id }) => {
  const result = await client.query({ query: GROUP_CARTS_QUERY, variables: { id } });
  return result?.data?.groupCarts;
};

const GroupOrder = types.model('GroupOrder', {
  id: types.string,
  name: types.string,
  shareLink: types.string,
  status: types.enumeration(Object.values(GROUP_ORDER_STATUS)),
  // @future: these will be rolled up into a Fulfilment once we get to Channel Rollout
  restaurantId: types.string,
  orderType: types.literal(ORDER_TYPES.DINE_IN),
  tableNumber: types.string,
});

const GroupOrderStore = types
  .model('GroupOrderStore', {
    loading: false,
    isHost: false,
    inviteeName: types.maybeNull(types.string),
    groupCartsPollingId: types.maybeNull(types.number),
    group: types.maybe(GroupOrder),
    requestTime: types.maybe(types.Date),
  })
  .volatile(() => ({
    invite: undefined,
  }))
  .actions(self => {
    const { getApiClient, logger, localStorage, platform } = getEnv(self);
    const { RestaurantStore, OrderingContextStore, CartStore } = getRoot(self);

    return {
      stopGroupCartsPolling() {
        if (self.groupCartsPollingId) {
          clearInterval(self.groupCartsPollingId);
          self.groupCartsPollingId = null;
        }
      },

      startGroupCartsPolling() {
        if (self.isHost) {
          // Stop if there is any interval going on already
          self.stopGroupCartsPolling();
          self.groupCartsPollingId = setInterval(() => {
            self.loadGroupCarts();
          }, GROUP_CARTS_POLLING_INTERVAL);
        }
      },

      reset() {
        self.stopGroupCartsPolling();
        self.invite = undefined;
        applySnapshot(self, initialState);
        OrderingContextStore.resetContext({ only: CONTEXT_TYPES.GROUP });
      },

      loadGroupCarts: flow(function*() {
        self.loading = true;

        try {
          const client = yield getApiClient();
          const groupCarts = yield loadGroupCarts({ client, id: self.id });

          groupCarts
            .map(({ cartItems, id, name }) => ({ orderItems: cartItems, id, name }))
            .filter(subcart => !CartStore.knownSubCartIds.includes(subcart.id))
            .forEach(CartStore.addSubCart);
        } catch (error) {
          logger.error(LOAD_GROUP_GENERIC_MESSAGE, { error: serializeError(error) });
          return { error: LOAD_GROUP_GENERIC_MESSAGE };
        } finally {
          self.loading = false;
        }

        return {};
      }),

      createGroup: flow(function*({ name }) {
        self.loading = true;
        self.requestTime = Date.now();

        try {
          const client = yield getApiClient();
          const orderSettings = {
            orderType: OrderingContextStore.orderType,
            restaurantId: OrderingContextStore.restaurantId,
            tableNumber: OrderingContextStore.fulfilmentOrEmpty.tableNumber,
          };
          const group = {
            name,
            ...orderSettings,
          };
          const { success, group: partialGroup = {} } = yield createGroup({
            client,
            ...group,
          });

          const { id } = partialGroup;

          if (!id || !success) {
            const reason = success ? 'No group returned' : 'Mutation failed';
            logger.error(CREATE_GROUP_FAILURE, { name, reason });
            return Result.fail(reason, { title: CREATE_GROUP_FAILURE });
          }

          self.isHost = true;
          self.group = { ...group, ...partialGroup };

          self.startGroupCartsPolling();

          const result = OrderingContextStore.switchContext(CONTEXT_TYPES.GROUP, orderSettings);
          if (!result.isOk) {
            self.isHost = false;
            self.group = undefined;
            return Result.fail(result.reason, { title: CREATE_GROUP_ERROR });
          }
        } catch (error) {
          logger.error(CREATE_GROUP_ERROR, { error: serializeError(error) });
          return Result.fail(error.message, { title: CREATE_GROUP_ERROR });
        } finally {
          self.loading = false;
        }

        return Result.ok();
      }),

      loadInvite: flow(function*({ id }) {
        self.loading = true;

        try {
          if (self.isHost) {
            logger.info(HOST_CANNOT_JOIN_GROUP_MESSAGE, { id });
            return { error: HOST_CANNOT_JOIN_GROUP_MESSAGE };
          }

          const client = yield getApiClient();
          const group = yield loadGroup({ client, id });

          if (!group?.id) {
            logger.info(GROUP_NOT_FOUND_MESSAGE, { id });
            return { error: GROUP_NOT_FOUND_MESSAGE };
          }

          if (group.status === GROUP_ORDER_STATUS.ORDER_PLACED) {
            logger.info(GROUP_ORDER_PLACED_MESSAGE, { id });
            return { error: GROUP_ORDER_PLACED_MESSAGE };
          }

          if (RestaurantStore.loading) {
            yield when(() => !RestaurantStore.loading);
          } else if (!RestaurantStore.loaded) {
            yield RestaurantStore.loadRestaurants();
          }

          const { restaurantId } = group;

          const restaurant = RestaurantStore.restaurants.get(restaurantId);
          if (!restaurant) {
            logger.error(RESTAURANT_NOT_FOUND_MESSAGE, { restaurantId });
            return { error: RESTAURANT_NOT_FOUND_MESSAGE };
          }

          self.invite = { group };
        } catch (error) {
          logger.error(LOAD_GROUP_GENERIC_MESSAGE, { error: serializeError(error) });
          return { error: LOAD_GROUP_GENERIC_MESSAGE };
        } finally {
          self.loading = false;
        }

        return {};
      }),

      leaveGroup: flow(function*() {
        if (!self.canLeaveGroup) {
          return;
        }

        yield when(() => !self.loading);

        self.reset();
      }),

      joinGroup({ inviteeName }) {
        if (!self.invite?.group) {
          logger.error(`Error: ${NO_GROUP_ERROR}`);
          return Result.fail('No group', { title: NO_GROUP_ERROR });
        }

        if (self.invite.group.status === GROUP_ORDER_STATUS.ORDER_PLACED) {
          self.reset();
          logger.error(`Error: ${GROUP_ORDER_PLACED_ERROR}`);
          return Result.fail('Group order placed', { title: GROUP_ORDER_PLACED_ERROR });
        }

        if (!inviteeName) {
          logger.error(`Error: ${NO_NAME_ERROR}`);
          return Result.fail('No invitee name supplied', { title: NO_NAME_ERROR });
        }

        self.inviteeName = inviteeName;
        self.group = self.invite.group;

        const { orderType, restaurantId, tableNumber } = self.invite.group;
        const result = OrderingContextStore.switchContext(CONTEXT_TYPES.GROUP, {
          orderType,
          restaurantId,
          tableNumber,
        });

        if (!result.isOk) {
          self.group = undefined;
          return result;
        }

        self.invite = undefined;

        return Result.ok();
      },

      afterAttach() {
        if (platform !== 'Web') {
          return Promise.resolve();
        }
        return self
          .loadPreviousGroup()
          .then(() => self.attachListeners())
          .catch();
      },

      storeGroup(snapshot) {
        if (!localStorage) {
          return;
        }

        if (snapshot.loading) {
          return;
        }

        if (!snapshot.group?.id) {
          localStorage.removeItem(GROUP_ORDER_KEY);
          return;
        }

        const now = new Date().getTime();
        const context = {
          value: snapshot,
          timestamp: now,
        };

        localStorage.setItem(GROUP_ORDER_KEY, context);
      },

      async loadPreviousGroup() {
        if (!localStorage) {
          return;
        }

        const previousGroup = await localStorage.getItem(GROUP_ORDER_KEY);
        if (!previousGroup) {
          return;
        }

        const now = new Date().getTime();
        const expiry = previousGroup.timestamp + TTL_SECONDS * 1000;
        if (previousGroup.timestamp === undefined || now > expiry) {
          localStorage.removeItem(GROUP_ORDER_KEY);
          return;
        }

        if (previousGroup.value === undefined) {
          localStorage.removeItem(GROUP_ORDER_KEY);
          return;
        }

        try {
          applySnapshot(self, previousGroup.value);
        } catch (error) {
          logger.error(`GroupOrder: Error reading previous group`, { error });
          localStorage.removeItem(GROUP_ORDER_KEY);
        }
      },

      attachListeners() {
        onSnapshot(self, self.storeGroup);
        if (self.id) self.storeGroup(getSnapshot(self));
      },
    };
  })
  .views(self => {
    return {
      get id() {
        return self.group?.id;
      },
      get name() {
        return self.group?.name;
      },
      get shareLink() {
        return self.group?.shareLink;
      },
      get status() {
        return self.group?.status;
      },

      get isInGroup() {
        return self.group !== undefined;
      },
      get isGroupInvitee() {
        return self.isInGroup && !self.isHost;
      },
      get canLeaveGroup() {
        return self.isGroupInvitee;
      },

      get hasInvite() {
        return self.invite !== undefined;
      },
    };
  });

GroupOrderStore.initialState = initialState;

export default GroupOrderStore;
