import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { snakeCase, startsWith } from 'lodash';

import { identifyWithRetry } from './helper';
import { navigation } from '../../../constants';

/* eslint-disable prefer-destructuring */
const market = process.env.market;
/* eslint-enable prefer-destructuring */

const {
  USER_SIGNED_UP,
  USER_SIGNED_IN,
  USER_SIGNED_OUT,
  PRODUCT_ADDED,
  CHECKOUT_STARTED,
  ORDER_COMPLETED,
  CART_VIEWED,
} = ANALYTICS_EVENTS;

let isMParticleLoaded = false;

/**
 * @type {typeof import("@mparticle/web-sdk") }
 */
let mParticle = null;
let brazeKit = null;

const pageTitleByPath = {
  [navigation.HOME]: 'home',
  [navigation.MENU]: 'menu',
};

const getPageTitleByPath = path => {
  // the analytics.js path regex sometimes returns a dodgy path for the homepage
  if (startsWith(path, '//')) {
    return pageTitleByPath[navigation.HOME];
  }

  return pageTitleByPath[path];
};

const formatEvent = event => snakeCase(event);

const NON_PRODUCTION_MPARTICLE_CONFIG = {
  isDevelopmentMode: true,
  logLevel: 'verbose',
};

export const mParticlePlugin = (userConfig = {}) => {
  return {
    name: 'mparticle',

    config: {
      ...userConfig,
    },

    async initialize() {
      if (typeof window === 'undefined') {
        return;
      }

      if (isMParticleLoaded || mParticle !== null) {
        return;
      }

      const mParticleModule = await import('@mparticle/web-sdk');
      const brazeKitModule = await import('@mparticle/web-braze-kit');

      mParticle = mParticleModule.default;
      brazeKit = brazeKitModule.default;

      const mParticleConfig = userConfig.appEnv !== 'production' ? NON_PRODUCTION_MPARTICLE_CONFIG : {};

      brazeKit.register(mParticleConfig);
      mParticle.init(userConfig.apiKey, mParticleConfig);

      mParticle.ready(() => {
        isMParticleLoaded = true;
      });
    },

    page({ payload }) {
      const pageTitle = getPageTitleByPath(payload.properties.path);

      // We only want to log certain page views. If the current page does not have a title
      // we return early.
      if (!pageTitle) {
        return null;
      }

      return mParticle.logPageView(`${pageTitle}_viewed`);
    },

    identify({ payload }) {
      const user = mParticle.Identity?.getCurrentUser()?.getUserIdentities()?.userIdentities;

      if (user?.customerid === payload.traits.memberNumber) {
        return;
      }

      identifyWithRetry(mParticle, {
        userIdentities: {
          customerid: payload.traits.memberNumber,
          email: payload.traits.email || null,
          other: payload.traits.email ? `${market}_${payload.traits.email}` : null,
        },
      });
    },

    reset() {
      if (mParticle === null) {
        return;
      }

      if (!mParticle?.Identity?.getCurrentUser()?.getUserIdentities()?.userIdentities) {
        return;
      }

      mParticle.logEvent(formatEvent(USER_SIGNED_OUT), mParticle.EventType.UserContent, {
        marketplace: market,
      });
      mParticle.Identity.logout({});
    },

    track({ payload }) {
      const formattedEvent = formatEvent(payload.event);

      switch (payload.event) {
        case CART_VIEWED: {
          return mParticle.logEvent(formattedEvent, mParticle.EventType.Navigation);
        }

        case PRODUCT_ADDED: {
          // @todo: send as ecommerce event
          // const product = mParticle.eCommerce.createProduct(
          //   payload.properties.name,
          //   payload.properties.product_id,
          //   payload.properties.price,
          //   payload.properties.quantity,
          //   undefined, // Variant
          //   payload.properties.category,
          //   undefined, // Brand
          //   undefined, // Position
          //   undefined // Coupon Code
          // );

          // return mParticle.eCommerce.logProductAction(mParticle.ProductActionType.AddToCart, product);

          return mParticle.logEvent(formattedEvent, mParticle.EventType.Unknown);
        }

        case CHECKOUT_STARTED: {
          // @todo: send as ecommerce event
          // const products = map(payload.properties.products, product => {
          //   return mParticle.eCommerce.createProduct(
          //     product.name,
          //     product.product_id,
          //     product.price,
          //     product.quantity,
          //     undefined, // Variant
          //     product.category,
          //     undefined, // Brand
          //     undefined, // Position
          //     undefined // Coupon Code
          //   );
          // });
          // const customAttributes = null;
          // const customFlags = null;
          // const transactionAttributes = {
          //   CouponCode: payload.properties.coupon,
          //   Revenue: payload.properties.total,
          // };

          // return mParticle.eCommerce.logProductAction(
          //   mParticle.ProductActionType.Checkout,
          //   products,
          //   customAttributes,
          //   customFlags,
          //   transactionAttributes
          // );

          return mParticle.logEvent(formattedEvent, mParticle.EventType.Navigation);
        }

        case ORDER_COMPLETED: {
          return mParticle.logEvent(formattedEvent, mParticle.EventType.Transaction);
        }

        case USER_SIGNED_IN: {
          return mParticle.Identity.login(
            {
              userIdentities: {
                customerid: payload.properties.memberNumber,
                email: payload.properties.email,
                other: payload.properties.email ? `${market}_${payload.properties.email}` : null,
              },
            },
            () => {
              return mParticle.logEvent(formattedEvent, mParticle.EventType.UserContent, {
                marketplace: market,
              });
            }
          );
        }

        case USER_SIGNED_UP: {
          return mParticle.Identity.login(
            {
              userIdentities: {
                email: payload.properties.email,
                other: payload.properties.email ? `${market}_${payload.properties.email}` : null,
              },
            },
            () => {
              return mParticle.logEvent(formattedEvent, mParticle.EventType.UserContent, {
                givenName: payload.properties.givenName,
                familyName: payload.properties.familyName,
                email: payload.properties.email,

                other: payload.properties.email ? `${market}_${payload.properties.email}` : null,
                mobile: payload.properties.mobile,
                country: market,
                marketplace: market,

                email_subscribe: payload.properties.emailCommunication,
                tc_checked: true,
              });
            }
          );
        }

        default:
          return () => {};
      }
    },

    loaded() {
      return !!isMParticleLoaded;
    },
  };
};
