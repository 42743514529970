import gql from 'graphql-tag';

const PRODUCT_FIELDS_FRAGMENT = gql`
  fragment ProductFields on Product {
    choices {
      id
      type
      name
      maximumOptionQuantity
      maximumOptionLimit
      minimumOptionLimit
      defaultSelectedOptions
      options {
        id
        description
        kilojoules
        name
        plu
        points
        prices {
          cents
          points
        }
        sortOrder
        size
        groupName
        suitability
        isAvailable
        image
        isEligibleForDeliveryDiscount
        hidesBastingIfSelected
      }
    }
    description
    disclaimer
    id
    image
    kilojoules
    isDetailedProduct
    byoType
    modifier
    longName
    shortName
    groupName
    name
    otherSizes
    points
    prices {
      cents
      points
    }
    size
    suitability
    isAvailable
    isEligibleForDeliveryDiscount
    hidesBastingIfProteinSelected
  }
`;

const PRODUCT_QUERY = gql`
  query getProduct($restaurantId: String!, $productId: String!, $orderType: OrderType) {
    product(input: { restaurantId: $restaurantId, productId: $productId, orderType: $orderType }) {
      ...ProductFields
    }
  }

  ${PRODUCT_FIELDS_FRAGMENT}
`;

const getProduct = async ({ client, restaurantId, productId, orderType }) => {
  try {
    const { data } = await client.query({ query: PRODUCT_QUERY, variables: { restaurantId, productId, orderType } });
    return { data: data.product };
  } catch (error) {
    return { error };
  }
};

export { getProduct, PRODUCT_FIELDS_FRAGMENT };
