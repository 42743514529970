import { types } from 'mobx-state-tree';

import Product from '../product';

const ProductCategory = types.model('ProductCategory', {
  name: types.optional(types.string, ''),
  handle: types.string,
  description: types.optional(types.union(types.string, types.literal(undefined), types.literal(null)), ''),
  products: types.array(types.safeReference(Product)),
});

export default ProductCategory;
