import { SURCHARGE_TYPE } from '@nandosaus/constants';
import { types } from 'mobx-state-tree';

import { priceFormatter } from '../../util/formatter';

const Surcharge = types
  .model('Surcharge', {
    type: types.enumeration([SURCHARGE_TYPE.HOLIDAY_SURCHARGE]),
    name: types.string,
    description: types.string,
    isPercentage: types.boolean,
    amount: types.number,
    surcharge: types.number,
  })
  .views(self => ({
    get formattedAmount() {
      return priceFormatter(self.amount);
    },
    get formattedSurcharge() {
      if (self.isPercentage) {
        return `${self.surcharge}%`;
      }
      return priceFormatter(self.surcharge);
    },
  }));

export default Surcharge;
