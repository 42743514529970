import { colors } from './colors';

// TODO: Update globals with actual Nandos values
const globalStyles = {
  baseFontColor: colors.grey800,
  baseFontColorInverted: colors.grey300,
  baseFontFamily: 'Barlow, Calibri, sans-serif',
  brandFontFamily: 'NandosHandAlt',
  baseFontSize: '16px',
  baseLineHeight: '1.33',
  boxShadowDark: '0 3px 5px 2px rgba(76,76,76, 0.04)',
  boxShadowLight: '0 5px 5px 3px rgba(43, 43, 43, 0.04)',
  boxShadowMiniCartFooter: '2px 0 6px 1px rgba(0, 0, 0, 0.2)',
  buttonHeight: '49px',
  buttonHeightMobile: '44px',
  easing: 'cubic-bezier(0.165, 0.84, 0.44, 1)',
  flyoutWidth: '584px',
  headerHeight: '68px',
  headingFontFamily: 'NandosHandAlt-Regular',
  logoWidth: '103px',
  overlayLight: 'rgba(33, 33, 33, 0.75)',
  overlayDark: 'rgba(255, 255, 255, 0.75)',
  tapHighlightColor: 'rgba(255, 255, 255, 0)',
  transitionSpeed: '260ms',
  takeoverSlideSpeedSeconds: 0.4,
};

export { globalStyles };
