import { get, has } from 'lodash';

import { validateApplePaySession } from './validate-apple-pay-session';

const APPLE_PAY_VERSION = 1;

const buildConfig = ({ orderSummary = null, allowedCardNetworks }) => {
  return {
    countryCode: process.env.adyen.countryCode,
    currencyCode: process.env.currencyCode,
    supportedNetworks: allowedCardNetworks,
    merchantCapabilities: ['supports3DS'],
    total: { label: 'Total', amount: get(orderSummary, 'price') },
  };
};

const buildApplePaySession = config => {
  return new window.ApplePaySession(APPLE_PAY_VERSION, config);
};

const payWithApplePay = async config => {
  const applePaySession = buildApplePaySession(config);
  let cancelled = false;
  let applePayError;

  const promises = Promise.all([
    new Promise((resolve, reject) => {
      applePaySession.onpaymentauthorized = details => {
        const paymentData = get(details, 'payment.token.paymentData');

        if (paymentData) {
          const tokenString = JSON.stringify(paymentData);
          const tokenBase64String = btoa(tokenString);
          resolve(tokenBase64String);
        } else {
          reject(new Error('Error retrieving apple pay token'));
        }
      };

      applePaySession.oncancel = () => {
        cancelled = true;
        resolve(false);
      };
    }),

    new Promise((resolve, reject) => {
      applePaySession.onvalidatemerchant = async event => {
        const validationUrl = event.validationURL;
        const response = await validateApplePaySession(validationUrl);

        if (!response) {
          reject(new Error('Error validating merchant'));
          return;
        }

        if (!cancelled) {
          applePaySession.completeMerchantValidation(response);
          resolve(true);
        }
      };
    }),
  ]);

  applePaySession.begin();

  const [token] = await promises.catch(error => {
    applePayError = error;
    applePaySession.abort();
  });

  // if the user cancels then just calmly close it and don't bother showing an error
  if (cancelled) {
    return { token: null };
  }

  if (applePayError) {
    throw applePayError;
  }

  // successful payment
  applePaySession.completePayment(window.ApplePaySession.STATUS_SUCCESS);
  return { token, applePaySession };
};

const supportsApplePay = () => {
  return { result: has(window, 'ApplePaySession') && window.ApplePaySession.canMakePayments() };
};

export { buildConfig, payWithApplePay, supportsApplePay };
