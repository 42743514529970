const RightArrow = props => (
  <svg viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Right Arrow</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.2423 11.7716L4.38202 0.988823C3.90107 0.507379 3.24639 0.236572 2.56343 0.236572C1.88046 0.236572 1.22578 0.507379 0.744836 0.988823C-0.248279 1.98073 -0.248279 3.58271 0.744836 4.57462L9.81218 13.5773L0.744836 22.5799C-0.248279 23.5718 -0.248279 25.1738 0.744836 26.1657C1.22822 26.6417 1.8826 26.9071 2.56343 26.9032C3.24426 26.9071 3.89864 26.6417 4.38202 26.1657L15.2423 15.3829C15.7272 14.9054 16 14.2553 16 13.5773C16 12.8992 15.7272 12.2492 15.2423 11.7716Z"
    />
  </svg>
);

export { RightArrow };
