import { flow, getEnv, types } from 'mobx-state-tree';
import { map } from 'lodash';
import gql from 'graphql-tag';
import moment from 'moment';

import Transaction from '../../models/transaction';

const initialState = {
  loading: true,
  error: null,
  transactions: [],
};

const TRANSACTIONS_QUERY = gql`
  query getOrders {
    transactions {
      id
      points
      restaurantId
      price
      date
      description
      transactionItems {
        productId
        quantity
        price
        productName
        points
      }
    }
  }
`;

const getTransactions = async ({ client }) => {
  try {
    const { data } = await client.query({ query: TRANSACTIONS_QUERY });
    return { data: data.transactions };
  } catch (error) {
    return { error };
  }
};

const TransactionStore = types
  .model('TransactionStore', {
    lastLoaded: 0,
    loading: false,
    error: types.maybeNull(types.string),
    transactions: types.array(Transaction),
  })
  .actions(self => {
    const { getApiClient, logger } = getEnv(self);
    return {
      clearSessionData() {
        self.loading = false;
        self.error = null;
        self.transactions = [];
      },
      loadTransactions: flow(function*() {
        self.error = null;
        self.loading = true;

        try {
          const client = yield getApiClient();
          const result = yield getTransactions({ client });
          const { data, error } = result;

          if (error) {
            throw error;
          }

          self.transactions = map(data, transaction => {
            return {
              id: transaction.id,
              restaurant: transaction.restaurantId,
              points: transaction.points,
              description: transaction.description,
              date: moment(transaction.date).toDate(),
              transactionItems: transaction.transactionItems,
              price: transaction.price,
            };
          });
          self.lastLoaded = Date.now();
        } catch (error) {
          self.setError('Error fetching points transactions');
          logger.error(`Error fetching transaction data`, { error });
        }
        self.loading = false;
      }),
      setError(displayError) {
        self.error = displayError;
      },
    };
  })
  .views(self => ({
    get loaded() {
      return self.lastLoaded !== 0;
    },
  }));

TransactionStore.initialState = initialState;

export default TransactionStore;
