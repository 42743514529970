import PropTypes from 'prop-types';

const Barcode = ({ stroke, ...rest }) => (
  <svg
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    strokeWidth="2"
    strokeLinecap="round"
    {...rest}
    stroke={stroke}
  >
    <title>Barcode</title>
    <g clipPath="url(#clip0)">
      <path d="M 10 8 V 16" />
      <path d="M 6 8 V 16" />
      <path d="M 14 8 V 16" />
      <path d="M 18 8 V 16" />
      <path d="M 6 4 H 2" />
      <path d="M 2 8 V 4" />
      <path d="M 22 8 V 4" />
      <path d="M 18 4 L 22 4" />
      <path d="M 2 16 L 2 20" />
      <path d="M 6 20 H 2" />
      <path d="M 22 20 H 18" />
      <path d="M 22 16 V 20" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

Barcode.propTypes = {
  stroke: PropTypes.string,
};

Barcode.defaultProps = {
  stroke: '#000000',
};

export { Barcode };
