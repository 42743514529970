import { types } from 'mobx-state-tree';

import Alert from '../../models/alert';

/**
 * Stack based global alerts store which can be used to inform users of important information.
 */
const AlertsStore = types
  .model('AlertsStore', {
    alerts: types.optional(types.array(Alert), []),
  })
  .actions(self => ({
    /**
     * Add a new alert to the store, the new alert will be pushed onto a stack
     * and become the current alert.
     * @param {Alert} alert
     */
    add(alert) {
      self.alerts.push(alert);
    },

    /**
     * Dismiss all alerts in the store.
     */
    dismissAll() {
      self.alerts.clear();
    },

    /**
     * Dismiss the current alert. If more alerts exist in the store then the next
     * one on the stack will become the current alert.
     */
    dismissCurrent() {
      self.alerts.pop();
    },
  }))
  .views(self => ({
    /**
     * Get the current alert. This will be the last alert added.
     */
    get current() {
      if (self.alerts.length === 0) {
        return null;
      }

      return self.alerts[self.alerts.length - 1];
    },
  }));

export default AlertsStore;
