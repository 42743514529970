/* eslint-disable no-unused-vars */
import React, { Context } from 'react';
import { Instance } from 'mobx-state-tree';
import RootStore from '../../root-store';

/**
 * @type {Context<Instance<typeof RootStore>>}
 */
const RootStoreContext = React.createContext(null);

export default RootStoreContext;
