import { resolveIdentifier } from 'mobx-state-tree';
import Product from '../../../models/product';

// Converts a recommendation item from API format into format accepted by OrderItem schema in mobX
export const mapRecommendationToOrderItem = ({ root, recommendation }) => {
  const menuProduct = resolveIdentifier(Product, root, recommendation.product);
  if (!menuProduct) {
    throw new Error('Unable to find product in Menu Store that matches ID of recommended product', {
      cause: recommendation,
    });
  }
  const mappedChoices = recommendation.choices.reduce((accumulator, currentChoice) => {
    const menuProductChoice = menuProduct.choices.find(choice => choice.id === currentChoice.id);
    const option = menuProductChoice.options.find(opt => opt.id === currentChoice.option);
    return {
      ...accumulator,
      [currentChoice.type]: [
        {
          option: option.id,
          quantity: currentChoice.quantity,
        },
      ],
    };
  }, {});
  const result = {
    product: menuProduct,
    quantity: recommendation.quantity,
    choices: mappedChoices,
    isRecommendedItem: true,
  };

  return result;
};
