import gql from 'graphql-tag';

const deliveryEstimateQuery = gql`
  query DeliveryEstimate(
    $street: String!
    $suburb: String!
    $state: String!
    $postcode: String!
    $longitude: String!
    $latitude: String!
    $unit: String
    $deliveryTime: String!
    $radius: Int!
    $orderTotal: Int
    $orderItems: [OrderItem]
  ) {
    deliveryEstimate(
      input: {
        deliveryAddress: {
          street: $street
          suburb: $suburb
          state: $state
          postcode: $postcode
          longitude: $longitude
          latitude: $latitude
          unit: $unit
        }
        deliveryTime: $deliveryTime
        radius: $radius
        orderTotal: $orderTotal
        orderItems: $orderItems
      }
    ) {
      fee {
        cents
        points
      }
      time
      restaurantId
      isDiscountActive
      isDiscountApplied
      isBestEstimate
      deliveryProvider
      deliveryProviderDescription
      sponsor {
        handle
        name
      }
    }
  }
`;

const getDeliveryEstimate = async ({ client, deliveryAddress, deliveryTime, radius, orderTotal, orderItems }) => {
  try {
    const { data } = await client.query({
      query: deliveryEstimateQuery,
      variables: { ...deliveryAddress, deliveryTime, radius, orderTotal, orderItems },
    });
    return { data: data.deliveryEstimate };
  } catch (error) {
    return { error };
  }
};

export { getDeliveryEstimate };
