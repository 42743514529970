import { CONTEXT_TYPES, ORDERING_CONTEXT_KEY, ORDER_TYPES } from '@nandosaus/constants';
import { applySnapshot, getEnv, getRoot, getSnapshot, getType, onSnapshot, types } from 'mobx-state-tree';
import { isEmpty, pick, trim } from 'lodash';
import Fulfilment, {
  Map as FulfilmentMap,
  DineInFulfilment,
  PickUpFulfilment,
  DeliveryFulfilment,
} from '../../models/ordering-context/fulfilments';
import Context, { Map as ContextMap, GeneralContext, PresetContext, GroupContext } from '../../models/ordering-context';
import Result from '../../result';

const logPrefix = message => `OrderingContext: ${message}`;

const hourAsSeconds = 60 * 60;
const TtlInSeconds = {
  [CONTEXT_TYPES.GENERAL]: 12 * hourAsSeconds,
  [CONTEXT_TYPES.PRESET]: 1 * hourAsSeconds,
  [CONTEXT_TYPES.GROUP]: 1 * hourAsSeconds,
};

const initialState = {
  context: { type: CONTEXT_TYPES.GENERAL },
  fulfilment: undefined,
};

const OrderingContextStore = types
  .model({
    context: types.optional(Context, { type: CONTEXT_TYPES.GENERAL }),
    fulfilment: types.maybe(Fulfilment),
  })
  .actions(self => {
    const { DeliveryStore, RestaurantStore } = getRoot(self);
    const { logger } = getEnv(self);

    const fulfilmentForType = type => {
      if (!type) {
        return undefined;
      }

      const fulfilment = FulfilmentMap[type]?.create({ type });
      if (fulfilment) {
        return fulfilment;
      }

      logger.error(logPrefix(`Couldn't find fulfilment for type`), { error: new Error(`No value for '${type}'`) });
      return undefined;
    };

    const fieldsForFulfilment = fulfilment => {
      return self.context.fields?.({ fulfilment }) || fulfilment?.fields || [];
    };

    return {
      switchContext(type, params = {}) {
        const result = self.context.eject?.(self.fulfilment) || Result.ok();

        if (!result.isOk) {
          return result;
        }

        const context = ContextMap[type]?.create({ type });
        if (!context) {
          const reason = `No value for '${type}'`;
          logger.error(logPrefix(`Couldn't find context for type`), { error: new Error(reason) });

          return Result.fail(reason, {
            title: 'Unable to change order settings',
            detail: 'Sorry for the inconvenience',
          });
        }

        self.context = context;

        if (!context.init) {
          return Result.ok();
        }

        try {
          const { fulfilment } = context.init(params);

          self.fulfilment = fulfilment;
        } catch (error) {
          logger.error(logPrefix(`Couldn't initialise ${type} context`), { error });
        }

        return Result.ok();
      },
      setOrderType(type) {
        if (self.fulfilment?.type === type) {
          return;
        }

        if (!self.context.availableOrderTypes?.includes(type)) {
          return;
        }

        const fulfilment = fulfilmentForType(type);
        self.fulfilment = fulfilment;
      },
      fieldsFor(type) {
        const fulfilment = fulfilmentForType(type);
        return fieldsForFulfilment(fulfilment);
      },
      fieldMapFor(type) {
        const fields = self.fieldsFor(type);
        return Object.fromEntries(fields.map(field => [field.name, field]));
      },

      updateSettings(settings) {
        if (settings.orderType) {
          self.setOrderType(settings.orderType);
        }

        // Lots of places use restaurant directly as an object so this puts it into a format we want
        if (settings.restaurant) {
          // eslint-disable-next-line no-param-reassign
          settings.restaurantId = settings.restaurant.id;
        }

        const writableFields = self
          .fieldsFor(self.orderType)
          .filter(({ writable }) => writable)
          .map(({ name }) => name);
        const valuesToSet = pick(settings, writableFields);

        if (Object.keys(valuesToSet).length < 1) {
          return;
        }

        Object.assign(self.fulfilment, valuesToSet || {});
      },

      validateSettings(settings, checkRequired = false) {
        // @cleanup: this is a lift-and-shift from OrderStore since this store is now responsible,
        // however each fulfilment should be called to validate its own settings.
        const { orderType, restaurantId, tableNumber } = settings;
        // Prefer restaurantId as that's how the setting should be passed around now
        const restaurant = restaurantId ? RestaurantStore.getRestaurantById(restaurantId) : settings.restaurant;

        const errors = {};

        if (!isEmpty(tableNumber)) {
          if (!/^[1-9][0-9]?$/.test(tableNumber.trim())) {
            errors.tableNumber = 'Please enter a valid table number';
          }
        }

        // getFormattedStatusMessage will only return an alert when ordering is not available for the given order type
        const formattedStatusMessage = restaurant?.getFormattedStatusMessage({ orderType });

        if (restaurant !== undefined && formattedStatusMessage.alert !== null) {
          errors.restaurant = formattedStatusMessage.alert;
        }

        if (checkRequired) {
          if (!orderType) {
            errors.orderType = 'Please select an order type to continue';
          }

          if (orderType === ORDER_TYPES.DELIVERY && DeliveryStore.deliveryAddress === undefined) {
            errors.deliveryAddress = 'Please set your delivery address to continue';
          }

          if (orderType !== ORDER_TYPES.DELIVERY && restaurant === undefined) {
            errors.restaurant = 'Please select a restaurant to continue';
          }

          if (orderType === ORDER_TYPES.DINE_IN && isEmpty(trim(tableNumber))) {
            errors.tableNumber = 'Please enter your table number to continue';
          }
        }

        return errors;
      },
      validateThenUpdateSettings(settings) {
        const errors = self.validateSettings(settings, true);

        if (isEmpty(errors)) {
          self.updateSettings(settings);

          return { errors, success: true };
        }

        return { errors, success: false };
      },

      reset() {
        self.context?.reset?.();
        applySnapshot(self, initialState);
      },
      // Allows the fulfilment to stay in place while the constraints are removed.
      // `only` allows us to call this without worrying about clearing a context that isn't our business
      resetContext({ only } = {}) {
        if (only && self.context?.type !== only) {
          return;
        }

        self.context = GeneralContext.create({ type: CONTEXT_TYPES.GENERAL });
      },
      afterAttach() {
        const { platform } = getEnv(self);
        if (platform !== 'Web') {
          return Promise.resolve();
        }

        return self.loadPreviousContext().then(() => self.attachListeners());
      },
      async loadPreviousContext() {
        const { deliveryAddress } = DeliveryStore;
        const { localStorage } = getEnv(self);

        if (!localStorage) {
          return;
        }

        const previousContext = await localStorage.getItem(ORDERING_CONTEXT_KEY);
        if (!previousContext) {
          return;
        }

        const contextType = previousContext.value?.context?.type;

        const now = new Date().getTime();
        const elapsed = now - previousContext.timestamp;
        const ttl = (TtlInSeconds[contextType] || 0) * 1000;

        if (elapsed > ttl) {
          return;
        }

        const fulfilmentType = previousContext.value?.fulfilment?.type;

        // If we need to further validate the previous context (e.g. check there is still a group, we should do so here)

        if (fulfilmentType === ORDER_TYPES.DELIVERY && !deliveryAddress) {
          // Delivery address is not always stored, we skip restoring state to avoid crashes around invalid cart state
          return;
        }

        try {
          applySnapshot(self, previousContext.value);
        } catch (error) {
          logger.error(logPrefix(`Error reading previous ordering context`), { error });
        }
      },
      attachListeners() {
        const { localStorage } = getEnv(self);

        if (!localStorage) {
          return;
        }

        const storeContext = snapshot => {
          const now = new Date().getTime();
          const context = {
            value: snapshot,
            timestamp: now,
          };

          localStorage.setItem(ORDERING_CONTEXT_KEY, context);
        };

        onSnapshot(self, storeContext);

        storeContext(getSnapshot(self));
      },
    };
  })
  .views(self => {
    const { RestaurantStore } = getRoot(self);

    return {
      get orderType() {
        return self.fulfilment?.type;
      },
      get availableOrderTypes() {
        return self.context.availableOrderTypes;
      },
      get restaurantId() {
        return self.fulfilment?.restaurantId;
      },
      get restaurant() {
        return RestaurantStore.getRestaurantById(self.restaurantId);
      },

      get settingErrors() {
        const errors = self.validateSettings(
          {
            orderType: self.orderType,
            restaurant: self.restaurant,
            tableNumber: self.fulfilmentOrEmpty.tableNumber,
          },
          true
        );

        return isEmpty(errors) ? undefined : errors;
      },

      get isValid() {
        const errors = self.settingErrors;

        return isEmpty(errors);
      },

      // Context flags
      get isGeneral() {
        return getType(self.context) === GeneralContext;
      },
      get isPreset() {
        return getType(self.context) === PresetContext;
      },
      get isGroup() {
        return getType(self.context) === GroupContext;
      },

      // Fulfilment flags
      get isDineIn() {
        return !!self.fulfilment && getType(self.fulfilment) === DineInFulfilment;
      },
      get isPickUp() {
        return !!self.fulfilment && getType(self.fulfilment) === PickUpFulfilment;
      },
      get isDelivery() {
        return !!self.fulfilment && getType(self.fulfilment) === DeliveryFulfilment;
      },

      /** Utility prop since we often want to destructure the fulfilment directly instead of
       * checking for it's existence
       */
      get fulfilmentOrEmpty() {
        return self.fulfilment || {};
      },
    };
  });

OrderingContextStore.initialState = initialState;

export default OrderingContextStore;
