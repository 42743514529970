const GoogleLogo = props => (
  <svg viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Google Pay</title>
    <g clipPath="url(#clip100000)">
      <path
        d="M23.1062 4H9.02733C4.62767 4 1.02795 7.6 1.02795 12C1.02795 16.4 4.62767 20 9.02733 20H23.1062C27.5059 20 31.1056 16.4 31.1056 12C31.1056 7.6 27.5059 4 23.1062 4Z"
        fill="white"
      />
      <path
        d="M23.1062 4.648C24.0942 4.648 25.0541 4.844 25.958 5.228C26.8339 5.6 27.6179 6.132 28.2978 6.808C28.9738 7.484 29.5057 8.272 29.8777 9.148C30.2617 10.052 30.4577 11.012 30.4577 12C30.4577 12.988 30.2617 13.948 29.8777 14.852C29.5057 15.728 28.9738 16.512 28.2978 17.192C27.6219 17.868 26.8339 18.4 25.958 18.772C25.0541 19.156 24.0942 19.352 23.1062 19.352H9.02733C8.03941 19.352 7.07948 19.156 6.17555 18.772C5.29962 18.4 4.51568 17.868 3.83573 17.192C3.15979 16.516 2.62783 15.728 2.25586 14.852C1.87189 13.948 1.6759 12.988 1.6759 12C1.6759 11.012 1.87189 10.052 2.25586 9.148C2.62783 8.272 3.15979 7.488 3.83573 6.808C4.51168 6.132 5.29962 5.6 6.17555 5.228C7.07948 4.844 8.03941 4.648 9.02733 4.648H23.1062ZM23.1062 4H9.02733C4.62767 4 1.02795 7.6 1.02795 12C1.02795 16.4 4.62767 20 9.02733 20H23.1062C27.5059 20 31.1056 16.4 31.1056 12C31.1056 7.6 27.5059 4 23.1062 4Z"
        fill="#3C4043"
      />
      <path
        d="M15.3709 12.568V14.988H14.603V9.012H16.6388C17.1548 9.012 17.5947 9.184 17.9547 9.528C18.3227 9.872 18.5067 10.292 18.5067 10.788C18.5067 11.296 18.3227 11.716 17.9547 12.056C17.5987 12.396 17.1588 12.564 16.6388 12.564H15.3709V12.568ZM15.3709 9.748V11.832H16.6548C16.9588 11.832 17.2148 11.728 17.4147 11.524C17.6187 11.32 17.7227 11.072 17.7227 10.792C17.7227 10.516 17.6187 10.272 17.4147 10.068C17.2148 9.856 16.9628 9.752 16.6548 9.752H15.3709V9.748Z"
        fill="#3C4043"
      />
      <path
        d="M20.5145 10.764C21.0825 10.764 21.5304 10.916 21.8584 11.22C22.1864 11.524 22.3504 11.94 22.3504 12.468V14.988H21.6184V14.42H21.5864C21.2705 14.888 20.8465 15.12 20.3185 15.12C19.8666 15.12 19.4906 14.988 19.1866 14.72C18.8826 14.452 18.7307 14.12 18.7307 13.72C18.7307 13.296 18.8906 12.96 19.2106 12.712C19.5306 12.46 19.9586 12.336 20.4905 12.336C20.9465 12.336 21.3225 12.42 21.6144 12.588V12.412C21.6144 12.144 21.5104 11.92 21.2985 11.732C21.0865 11.544 20.8385 11.452 20.5545 11.452C20.1265 11.452 19.7866 11.632 19.5386 11.996L18.8626 11.572C19.2346 11.032 19.7866 10.764 20.5145 10.764ZM19.5226 13.732C19.5226 13.932 19.6066 14.1 19.7786 14.232C19.9466 14.364 20.1465 14.432 20.3745 14.432C20.6985 14.432 20.9865 14.312 21.2385 14.072C21.4904 13.832 21.6184 13.552 21.6184 13.228C21.3784 13.04 21.0465 12.944 20.6185 12.944C20.3065 12.944 20.0465 13.02 19.8386 13.168C19.6266 13.324 19.5226 13.512 19.5226 13.732Z"
        fill="#3C4043"
      />
      <path
        d="M26.526 10.896L23.9662 16.784H23.1743L24.1262 14.724L22.4384 10.896H23.2743L24.4902 13.832H24.5062L25.6901 10.896H26.526Z"
        fill="#3C4043"
      />
      <path
        d="M12.3164 12.08C12.3164 11.8296 12.294 11.59 12.2524 11.3596H9.03345V12.6796L10.8873 12.68C10.8121 13.1192 10.5701 13.4936 10.1994 13.7432V14.5996H11.3029C11.9472 14.0032 12.3164 13.1216 12.3164 12.08Z"
        fill="#4285F4"
      />
      <path
        d="M10.1997 13.7432C9.89254 13.9504 9.49697 14.0716 9.03421 14.0716C8.14028 14.0716 7.38194 13.4692 7.11036 12.6572H5.97205V13.5404C6.536 14.6596 7.69511 15.4276 9.03421 15.4276C9.95973 15.4276 10.7373 15.1232 11.3032 14.5992L10.1997 13.7432Z"
        fill="#34A853"
      />
      <path
        d="M7.00316 12.002C7.00316 11.774 7.04115 11.5536 7.11035 11.3464V10.4632H5.97204C5.73886 10.926 5.60767 11.4484 5.60767 12.002C5.60767 12.5556 5.73926 13.078 5.97204 13.5408L7.11035 12.6576C7.04115 12.4504 7.00316 12.23 7.00316 12.002Z"
        fill="#FABB05"
      />
      <path
        d="M9.03421 9.932C9.53937 9.932 9.99173 10.106 10.3489 10.446L11.3268 9.4688C10.7329 8.9156 9.95853 8.576 9.03421 8.576C7.69551 8.576 6.536 9.344 5.97205 10.4632L7.11036 11.3464C7.38194 10.5344 8.14028 9.932 9.03421 9.932Z"
        fill="#E94235"
      />
    </g>
    <defs>
      <clipPath id="clip100000">
        <rect width="30.1176" height="16" fill="white" transform="translate(1 4)" />
      </clipPath>
    </defs>
  </svg>
);

export { GoogleLogo };
