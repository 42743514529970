const Amex = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <title>American Express</title>
    <path fill="#fff" d="M0 0h24v24H0z" />
    <path fill="#016FD0" d="M0 0h23.94v12.924l-1.185 1.851 1.185 1.648v7.517H0V11.757l.74-.851L0 10.09V0z" />
    <path
      fill="#fff"
      d="M4.647 16.682v-3.758h3.98l.427.556.441-.556H23.94v3.499s-.377.255-.814.26h-7.999l-.481-.593v.592h-1.578v-1.011s-.215.141-.681.141h-.537v.87H9.46l-.426-.568-.433.568H4.647zM0 10.091l.897-2.093H2.45l.51 1.172V7.998h1.93l.302.848.294-.848h8.66v.426s.456-.426 1.204-.426l2.81.01.5 1.157V7.998h1.615l.445.665v-.665h1.63v3.76h-1.63l-.426-.667v.666H17.92l-.239-.592h-.637l-.235.592H15.2c-.644 0-1.055-.417-1.055-.417v.417H11.72l-.481-.592v.592h-9.02l-.238-.592h-.636l-.236.592H0v-1.666z"
    />
    <path
      fill="#016FD0"
      d="M1.215 8.462l-1.21 2.814h.788l.223-.563h1.298l.223.563h.805l-1.21-2.814h-.917zm.448.655l.396.985h-.793l.397-.985zm1.762 2.159V8.46l1.12.005.652 1.814.635-1.819h1.111v2.815H6.24V9.202l-.746 2.074h-.617l-.748-2.074v2.074h-.704zm4 0V8.46H9.72v.63H8.136v.481h1.548v.593H8.136v.5H9.72v.61H7.425zm2.703-2.814v2.814h.704v-1h.296l.843 1h.86l-.926-1.037a.857.857 0 00.772-.864c0-.592-.465-.913-.983-.913h-1.566zm.704.63h.804c.193 0 .333.15.333.296 0 .187-.182.296-.323.296h-.815V9.09zm2.85 2.184h-.718V8.46h.719v2.815zm1.704 0h-.155c-.75 0-1.206-.591-1.206-1.396 0-.824.45-1.419 1.398-1.419h.778v.667h-.806c-.385 0-.657.3-.657.76 0 .544.311.773.76.773h.184l-.296.615zm1.531-2.814l-1.21 2.814h.788l.223-.563h1.299l.222.563h.805l-1.209-2.814h-.918zm.448.655l.396.985h-.793l.397-.985zm1.761 2.159V8.46h.895l1.142 1.769V8.46h.704v2.815H21L19.83 9.46v1.815h-.704zM5.13 16.2v-2.815h2.296v.63H5.84v.481h1.548v.593H5.84v.5h1.585v.61H5.129zm11.25 0v-2.815h2.296v.63H17.09v.481h1.54v.593h-1.54v.5h1.585v.61h-2.296zm-8.865 0l1.118-1.39-1.145-1.425h.887l.681.881.684-.88h.852l-1.13 1.407 1.12 1.407h-.886l-.662-.867-.646.867h-.873zm3.15-2.814V16.2h.723v-.889h.74c.627 0 1.102-.332 1.102-.978 0-.536-.372-.947-1.01-.947h-1.554zm.723.636h.78c.202 0 .347.125.347.324 0 .188-.144.324-.35.324h-.777v-.648zm2.148-.637v2.815h.703v-1h.297l.843 1h.86l-.926-1.037a.857.857 0 00.772-.864c0-.593-.465-.914-.983-.914h-1.566zm.703.63h.805c.193 0 .333.15.333.296 0 .187-.182.296-.323.296h-.815v-.592zM19 16.201v-.611h1.409c.208 0 .298-.113.298-.236 0-.119-.09-.238-.298-.238h-.637c-.553 0-.86-.337-.86-.843 0-.451.281-.887 1.103-.887h1.37l-.296.634h-1.185c-.227 0-.296.119-.296.232 0 .117.086.245.259.245h.667c.616 0 .884.35.884.808 0 .492-.298.896-.918.896H19zm2.583 0v-.611h1.408c.208 0 .299-.113.299-.236 0-.119-.09-.238-.3-.238h-.635c-.553 0-.861-.337-.861-.843 0-.451.282-.887 1.104-.887h1.37l-.297.634h-1.185c-.226 0-.296.119-.296.232 0 .117.086.245.26.245h.666c.617 0 .884.35.884.808 0 .492-.298.896-.918.896h-1.5z"
    />
  </svg>
);

export { Amex };
