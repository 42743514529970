import { CONNECTION_ERRORS } from '@nandosaus/constants';
import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { get } from 'lodash';
import gql from 'graphql-tag';

import Profile from '../../models/profile';

const initialState = {};

const MAX_RETRIES = 10;

const transformError = (error, defaultMessage) => {
  const transformedError = get(error, 'errors[0]', {});
  transformedError.message = transformedError.message || defaultMessage;
  return transformedError;
};

const PROFILE_QUERY = gql`
  query getProfile {
    profile {
      balances {
        credit
        points
      }
      addresses {
        id
        unit
        street
        suburb
        state
        postcode
        latitude
        longitude
      }
      restaurants
      barcode
      dob
      email
      encryptedMemberId
      familyName
      gender
      givenName
      id
      memberNumber
      memberType
      mobile
      postcode
      state
      smsCommunication
      emailCommunication
    }
  }
`;

const UPDATE_PROFILE_MUTATION = gql`
  mutation updateProfile($input: UpdateProfileInput!) {
    updateProfile(input: $input) {
      balances {
        credit
        points
      }
      barcode
      dob
      email
      encryptedMemberId
      familyName
      gender
      givenName
      id
      memberNumber
      memberType
      mobile
      postcode
      state
      emailCommunication
      smsCommunication
    }
  }
`;

const UPDATE_PROFILE_MEMBER_MUTATION = gql`
  mutation updateProfileMember($input: UpdateProfileInput!) {
    updateProfileMember(input: $input) {
      balances {
        credit
        points
      }
      barcode
      dob
      email
      encryptedMemberId
      familyName
      gender
      givenName
      id
      memberNumber
      memberType
      mobile
      postcode
      state
      emailCommunication
      smsCommunication
    }
  }
`;

const DELETE_ACCOUNT_MUTATION = gql`
  mutation {
    deleteAccount {
      success
    }
  }
`;

const WALLET_PASS_QUERY = gql`
  query getWalletPass($walletType: WalletType!) {
    digitalWallet(walletType: $walletType) {
      pass {
        __typename
        ... on ApplePass {
          filename
          mimeType
          encodedPkPass
        }
        ... on GooglePass {
          redirectUrl
        }
      }
    }
  }
`;

const getProfile = async client => {
  const { data } = await client.query({ query: PROFILE_QUERY });
  return data.profile;
};

const updateProfile = async ({ client, values }) => {
  const { data } = await client.mutate({ mutation: UPDATE_PROFILE_MUTATION, variables: { input: values } });
  return data.updateProfile;
};

const updateProfileMember = async ({ client, values }) => {
  const { data } = await client.mutate({ mutation: UPDATE_PROFILE_MEMBER_MUTATION, variables: { input: values } });
  return data.updateProfileMember;
};

const deleteAccount = async ({ client }) => {
  const { data } = await client.mutate({ mutation: DELETE_ACCOUNT_MUTATION });
  return data;
};

const getWalletPass = async ({ client, walletType }) => {
  const { data } = await client.query({ query: WALLET_PASS_QUERY, variables: { walletType } });

  return get(data, 'digitalWallet.pass', null);
};

const MemberStore = types
  .model('MemberStore', {
    loading: false,
    error: types.maybeNull(types.string),
    profile: types.maybeNull(Profile),
    hasNetworkError: false,
  })
  .actions(self => {
    const { getApiClient, logger } = getEnv(self);
    const { AuthStore } = getRoot(self);
    return {
      clearSessionData() {
        self.loading = false;
        self.error = null;
        self.profile = null;
        logger.clearPerson();
      },
      loadProfile: flow(function*(retries = 0) {
        if (self.loading) {
          return;
        }

        self.error = null;
        self.loading = true;

        try {
          const client = yield getApiClient();
          const data = yield getProfile(client);
          self.profile = data;
          logger.setPerson(data.email);
          self.hasNetworkError = false;
        } catch (error) {
          const transformedError = transformError(error, 'There was an error fetching profile');
          logger.error('Error fetching member profile', { error });
          self.error = transformedError.message;
          const code = get(transformedError, 'extensions.code');
          if (code === 'FORBIDDEN' || code === 'UNAUTHENTICATED') {
            self.hasNetworkError = false;
            AuthStore.signOut();
          }
          if (transformedError.message === CONNECTION_ERRORS.NETWORK_ERROR) {
            self.hasNetworkError = true;
            if (retries < MAX_RETRIES) {
              const backoff = 2 ** retries * 100;
              setTimeout(() => {
                self.loadProfile(retries + 1);
              }, backoff);
            }
          }
        }
        self.loading = false;
      }),
      updateProfile: flow(function*(values) {
        self.error = null;
        self.loading = true;
        try {
          const client = yield getApiClient();
          const data = yield updateProfile({ client, values });
          self.profile = data;
          logger.setPerson(data.email);
          return { data };
        } catch (error) {
          const transformedError = transformError(error, 'There was an error updating profile');
          const code = get(transformedError, 'extensions.code');
          if (code !== 'VALIDATION_ERROR') {
            logger.error('Error updating member profile', { error });
          }
          // @NOTE: don’t need to keep this error in state
          // self.error = err.message;
          return { error: transformedError.message };
        } finally {
          self.loading = false;
        }
      }),
      updateProfileMember: flow(function*(values) {
        self.error = null;
        self.loading = true;
        try {
          const client = yield getApiClient();
          const data = yield updateProfileMember({ client, values });
          self.profile = data;
          logger.setPerson(data.email);
          return { data };
        } catch (error) {
          const transformedError = transformError(error, 'There was an error updating profile');
          const code = get(transformedError, 'extensions.code');
          if (code !== 'VALIDATION_ERROR') {
            logger.error('Error updating member profile', { error });
          }
          // @NOTE: don’t need to keep this error in state
          // self.error = err.message;
          return { error: transformedError.message };
        } finally {
          self.loading = false;
        }
      }),
      deleteAccount: flow(function*() {
        self.loading = false;
        self.error = null;

        try {
          const client = yield getApiClient();
          const data = yield deleteAccount({ client });
          logger.info(`Member ${self.profile.id} deleted their account successfully.`);
          return { data };
        } catch (error) {
          const transformedError = transformError(error, 'There was an error deleting the member account');
          logger.error(`Member ${self.profile.id} account deletion failed.`);
          return { error: transformedError.message };
        }
      }),
      getWalletPass: flow(function*(walletType) {
        try {
          const client = yield getApiClient();
          const data = yield getWalletPass({ client, walletType });

          return { data };
        } catch (error) {
          const transformedError = transformError(error, 'There was an error fetching the digital wallet pass');
          logger.error('There was an error fetching the digital wallet pass', { error });
          return { error: transformedError.message };
        }
      }),
    };
  })
  .views(self => ({
    get isSignedIn() {
      return !!self.profile;
    },

    get shopperDetails() {
      const adyenGenderMapping = gender => {
        switch (gender) {
          case 'male':
            return 'MALE';
          case 'female':
            return 'FEMALE';
          default:
            return 'UNKNOWN';
        }
      };

      return {
        firstName: get(self.profile, 'givenName'),
        lastName: get(self.profile, 'familyName'),
        gender: adyenGenderMapping(get(self.profile, 'gender')),
      };
    },
  }));
MemberStore.initialState = initialState;

export default MemberStore;
