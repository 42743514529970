import gql from 'graphql-tag';

const RECOMMENDATIONS_QUERY = gql`
  query GetRecommendations($input: RecommendationsInput!) {
    getRecommendations(input: $input) {
      mealType
      totalKilojoules
      products {
        product
        quantity
        choices {
          id
          type
          option
          quantity
        }
      }
    }
  }
`;

const requestRecommendations = async ({ client, input }) => {
  const result = await client.query({
    query: RECOMMENDATIONS_QUERY,
    variables: {
      input,
    },
  });
  return result?.data?.getRecommendations?.products;
};

export { requestRecommendations };
