import gql from 'graphql-tag';

const getDocument = async ({ client, document, siteId }) => {
  // Note: siteId type has been removed to avoid differing GraphQL types between Craft CMS versions
  // the String type previously worked but more recent Craft CMS versions require [QueryArgument]
  const documentQuery = gql`
    query getContentFromCMS {
      globalSet(siteId: "${siteId}", handle: "documents") {
        ... on documents_GlobalSet {
          ${document} {
            filename
            url
            size
          }
        }
      }
    }
  `;

  try {
    const { data } = await client.query({
      query: documentQuery,
    });

    return { data: data.globalSet };
  } catch (error) {
    return { error };
  }
};

export { getDocument };
