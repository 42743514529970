import { layout, position, space } from 'styled-system';
import styled from 'styled-components';

import { Box } from '../../grid';

const LoadingIndicator = styled(Box)`
  animation: 500ms linear 0s infinite normal none running rotate;
  border: solid 2px;
  border-radius: 16px;
  border-color: ${({ color = 'black' }) => `${color} ${color} transparent transparent`};
  margin: 0 auto;

  ${layout}
  ${position}
  ${space}

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;

LoadingIndicator.defaultProps = {
  size: '1rem',
};

export { LoadingIndicator };
