import { isNil } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';

import { pStyles } from '../body';
import { subheadingStyles } from '../subheading';

const typography = {
  ul: css`
    ${pStyles(1)};
    list-style: initial;
    list-style-position: inside;

    li {
      margin-bottom: 0.5rem;
    }
  `,

  ol: css`
    ${pStyles(1)};
    list-style: decimal;
    list-style-position: inside;

    li {
      margin-bottom: 0.5rem;
    }
  `,
};

const StyledRichText = styled.div`
  table {
    border-collapse: collapse;

    td,
    th {
      border: 1px solid;
      padding: 0.625rem;
    }
  }

  ol {
    ${typography.ol};
  }

  ul {
    ${typography.ul};
  }

  h1 {
    ${subheadingStyles(1)}
  }

  h2 {
    ${subheadingStyles(2)}
  }

  h3,
  h4,
  h5 {
    ${subheadingStyles(3)}
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0.5rem;
  }

  p,
  ul,
  ol {
    ${pStyles(1)}

    &:not(:last-child) {
      margin-bottom: 1.25em;
    }
  }

  ${props =>
    props.columnCount > 1 &&
    `
      columns: ${props.columnCount};

      > * {
        break-inside: avoid;
      }
    `}
`;

const RichText = ({ children, className, color, size, keepMargins, columnCount }) => {
  if (isNil(children)) {
    return null;
  }

  return (
    <StyledRichText
      className={className}
      color={color}
      columnCount={columnCount}
      dangerouslySetInnerHTML={{ __html: children }}
      keepMargins={keepMargins}
      size={size}
    />
  );
};

RichText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  columnCount: PropTypes.number,
  keepMargins: PropTypes.bool,
  size: PropTypes.oneOf(['finePrint', 'small']),
};

RichText.defaultProps = {
  children: null,
  className: null,
  color: null,
  columnCount: 1,
  keepMargins: false,
  size: null,
};

export { RichText };
