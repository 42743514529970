import { types } from 'mobx-state-tree';

import Restaurant from '../../models/restaurant';

const initialState = {
  restaurant: undefined,
};

const RestaurantDetailsState = types
  .model('RestaurantDetailsState', {
    restaurant: types.maybe(types.reference(Restaurant)),
  })
  .actions(self => ({
    updateRestaurant(restaurant) {
      self.restaurant = restaurant;
    },
  }));

RestaurantDetailsState.initialState = initialState;

export default RestaurantDetailsState;
