import { isNil } from 'lodash';
import Analytics from 'analytics';

import { facebookPlugin } from './plugins/facebook';
import { googleAnalyticsPlugin } from './plugins/google-analytics';
import { mParticlePlugin } from './plugins/mparticle';
import { snapchatPlugin } from './plugins/snapchat';
import { tiktokPlugin } from './plugins/tiktok';

const isBrowser = typeof window !== 'undefined';
const isStorybook = isBrowser && !!window.STORYBOOK_ENV;

/* eslint-disable prefer-destructuring */
const tiktokPixelId = process.env.tiktokPixelId;
const snapchatPixelId = process.env.snapchatPixelId;
const mParticleApiKey = process.env.mParticle.apiKey;
const isMParticleEnabled = process.env.mParticle.enabled && !isStorybook;
const appEnv = process.env.web.environment;
/* eslint-enable prefer-destructuring */

// including this plugin breaks storybook
const mParticlePluginInstance = isStorybook
  ? null
  : mParticlePlugin({
      apiKey: mParticleApiKey,
      enabled: isMParticleEnabled,
      appEnv,
    });

const analytics = Analytics({
  app: 'nandos-web',
  debug: process.env.APP_ENV !== 'production',
  enabled: isBrowser,
  plugins: [
    mParticlePluginInstance,
    googleAnalyticsPlugin,
    facebookPlugin(),
    snapchatPlugin({
      snapchatPixelId,
      enabled: !isNil(snapchatPixelId),
    }),
    tiktokPlugin({
      tiktokPixelId,
      enabled: !isNil(tiktokPixelId),
    }),
  ],
});

export { analytics };
