import { isObject, startsWith } from 'lodash';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import { A } from '../typography';

const Link = ({
  as,
  children,
  download,
  href,
  isLoading,
  replace,
  scroll,
  target,
  shallow,
  to,
  disabled,
  prefetch,
  ...rest
}) => {
  // TODO: refactor this, should a link be disabled in the first place?
  // Disabled isn't a valid attribute on an anchor tag
  // See: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/a
  if (disabled) {
    return <div {...rest}>{children}</div>;
  }

  /* eslint-disable no-console */
  if (to) {
    console.warn(
      'Using `to` has been deprecated, use `href` instead. See https://nextjs.org/docs/api-reference/next/link',
      { to }
    );
  }

  if (href && to) {
    console.warn('Button with both "href" and "to" defined', { href, to });
  }

  if (to && !startsWith(to, '/')) {
    console.warn('Button "to" is absolute path', { to });
  }
  /* eslint-enable no-console */

  const isExternalLink = String(href).startsWith('http');
  const isMailToLink = String(href).startsWith('mailto');
  const isHrefObject = isObject(href);

  if ((!isExternalLink && !isMailToLink) || isHrefObject || to) {
    return (
      <NextLink href={to || href} replace={replace} scroll={scroll} shallow={shallow} passHref prefetch={prefetch}>
        <A {...rest} target={target}>
          {children}
        </A>
      </NextLink>
    );
  }

  return (
    <A
      download={download}
      href={href}
      target={target}
      rel={target === '_blank' ? 'noopener noreferrer' : undefined}
      {...rest}
    >
      {children}
    </A>
  );
};

Link.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  download: PropTypes.bool,
  href: PropTypes.string,
  isLoading: PropTypes.bool,
  prefetch: PropTypes.bool,
  shallow: PropTypes.bool,
  replace: PropTypes.bool,
  scroll: PropTypes.bool,
  target: PropTypes.string,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  as: undefined,
  disabled: false,
  download: false,
  href: undefined,
  isLoading: false,
  prefetch: undefined,
  replace: undefined,
  scroll: undefined,
  shallow: undefined,
  target: undefined,
  to: undefined,
  onClick: undefined,
};

export { Link };
