import { types } from 'mobx-state-tree';

import PastOrder from '../past-order';

const ActiveOrder = types.compose(
  PastOrder,
  types.model('ActiveOrder', {
    status: types.string,
    estimatedDeliveryTime: types.maybe(types.Date),
    pickupTime: types.maybe(types.Date),
    lastUpdatedTime: types.maybeNull(types.Date),
    orderType: types.string,
    driverName: types.maybeNull(types.string),
    driverPhone: types.maybeNull(types.string),
  })
);

export default ActiveOrder;
