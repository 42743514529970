import { ALERT_TYPES } from '@nandosaus/constants';
import { values } from 'lodash';
import { variant } from 'styled-system';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { AlertLink } from '../alert-link';
import { breakpoints, colors } from '../../constants';
import { Flex } from '../grid';
import { Icon } from '../icons';
import { P } from '../typography';

const ICON_NAMES = {
  [ALERT_TYPES.INFO]: 'info',
  [ALERT_TYPES.ERROR]: 'error',
  [ALERT_TYPES.SUCCESS]: 'tickCircle',
  [ALERT_TYPES.WARNING]: 'warning',
};

const renderIcon = (icon, type, iconFill) => {
  if (icon === null) {
    return null;
  }

  if (!icon) {
    return <Icon name={ICON_NAMES[type]} fill={iconFill} stroke="none" mr="0.5rem" minHeight="24px" minWidth="24px" />;
  }

  return icon;
};

const alertVariants = variant({
  variants: {
    [ALERT_TYPES.INFO]: {
      backgroundColor: colors.alertInfoLight,
      color: colors.alertInfo,
      iconFill: colors.alertInfoMedium,
    },
    [ALERT_TYPES.ERROR]: {
      backgroundColor: colors.alertErrorLight,
      color: colors.alertError,
      iconFill: colors.alertErrorMedium,
    },
    [ALERT_TYPES.SUCCESS]: {
      backgroundColor: colors.alertSuccessLight,
      color: colors.alertSuccess,
      iconFill: colors.alertSuccessMedium,
    },
    [ALERT_TYPES.WARNING]: {
      backgroundColor: colors.alertWarningLight,
      color: colors.alertWarning,
      iconFill: colors.alertWarningMedium,
    },
  },
});

const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const FADE_OUT_DURATION_SECONDS = 0.2;

const Alert = ({
  allowDismissal,
  onDismiss,
  heading,
  icon,
  children,
  linkText,
  onLinkClick,
  href,
  type,
  className,
  ...rest
}) => {
  const [isDismissed, setDismissed] = useState();
  const [isRemoved, setRemoved] = useState();

  useEffect(() => {
    if (!isDismissed) {
      return;
    }

    const removeAfterFadeOut = async () => {
      await sleep(FADE_OUT_DURATION_SECONDS * 1000);
      setRemoved(true);
    };
    removeAfterFadeOut();
  }, [isDismissed, isRemoved]);

  const { iconFill, color } = alertVariants({ variant: type });

  if (isRemoved) {
    return null;
  }

  return (
    <Wrapper {...{ isDismissed, className, variant: type }} {...rest}>
      {renderIcon(icon, type, iconFill)}

      <Flex justifyContent="center" flexDirection="column">
        {heading && (
          <P variant={1} fontWeight="bold" mb="0.25rem">
            {heading}
          </P>
        )}
        <Text variant={3}>{children}</Text>
        {linkText && (onLinkClick || href) && (
          <Flex mt="0.25rem">
            <AlertLink {...{ onLinkClick, href }}>{linkText}</AlertLink>
          </Flex>
        )}
      </Flex>

      {allowDismissal && (
        <Flex justifyContent="flex-end" alignItems="flex-start" flexGrow="1" ml="1">
          <button
            type="button"
            onClick={() => {
              setDismissed(true);
              if (onDismiss) {
                onDismiss();
              }
            }}
            aria-label="Dismiss Alert"
          >
            <Icon name="cross" height="16px" width="16px" fill={color} stroke="none" />
          </button>
        </Flex>
      )}
    </Wrapper>
  );
};

const Text = styled(P)`
  white-space: pre-line;
`;

const Wrapper = styled(Flex)`
  ${alertVariants}

  ${({ isDismissed }) => `opacity: ${isDismissed ? 0 : 1};`}
  transition: opacity ${FADE_OUT_DURATION_SECONDS}s linear;

  border-radius: 2px;

  padding: 1rem;
  @media (max-width: ${breakpoints.md}) {
    padding: 1rem 1.5rem;
  }
`;

Alert.propTypes = {
  allowDismissal: PropTypes.bool,
  onDismiss: PropTypes.func,
  className: PropTypes.string,
  heading: PropTypes.string,
  icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  linkText: PropTypes.string,
  onLinkClick: PropTypes.func,
  type: PropTypes.oneOf(values(ALERT_TYPES)),
  href: PropTypes.string,
};

Alert.defaultProps = {
  allowDismissal: false,
  onDismiss: null,
  className: null,
  heading: null,
  icon: undefined,
  type: ALERT_TYPES.WARNING,
  linkText: null,
  onLinkClick: undefined,
  href: null,
};

export { Alert };
