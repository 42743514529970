import styled, { css } from 'styled-components';

import { coreStyles } from './font-styles';
import { pStyles } from './body';

const aStyles = css`
  ${coreStyles}
  ${props => pStyles(props.variant)};
  color: ${({ theme }) => theme.colors.infoPrimary};
  cursor: pointer;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: none;
`;

const A = styled.a`
  ${aStyles}
`;

export { A, aStyles };
