import { DATE_TIME_FORMATS } from '@nandosaus/constants';
import { now } from 'mobx-utils';
import { types } from 'mobx-state-tree';
import moment from 'moment';

import { priceFormatter } from '../../util/formatter';

const GiftCard = types
  .model('GiftCard', {
    balance: types.number,
    cardNumber: types.string,
    expiryDate: types.maybeNull(types.Date),
    verificationCode: types.string,
  })
  .views(self => ({
    /**
     * Formatted balance for use in UI.
     */
    get formattedBalance() {
      return priceFormatter(self.balance * 100);
    },

    /**
     * Formatted expiry date for use in UI.
     */
    get formattedExpiryDate() {
      return self.expiryDate ? moment(self.expiryDate).format(DATE_TIME_FORMATS.DATE_SLASH) : null;
    },

    /**
     * Determine whether the gift card has expired. Recomputes based on current time every 60 seconds.
     */
    get hasExpired() {
      return self.expiryDate ? self.expiryDate <= now(60000) : false;
    },
  }));

export default GiftCard;
