import { createGlobalStyle } from 'styled-components';
import { forEach, toPairs } from 'lodash';

const createSpacing = props => {
  const { breakpoints, rem } = props.theme;
  let rems = ``;

  forEach(toPairs(rem), ([breakpoint, size], index) => {
    if (index === 0) {
      rems += `
      font-size: ${size};
    `;
    } else {
      rems += `
      @media (min-width: ${breakpoints[breakpoint]}) {
        font-size: ${size};
      }
    `;
    }
  });

  return `
    html {
      ${rems}
    }
  }`;
};

const SpacingStyles = createGlobalStyle`
  ${props => createSpacing(props)};
`;

export { SpacingStyles };
