import { layout } from 'styled-system';
import styled from 'styled-components';

import { Box } from './box';

const Flex = styled(Box)`
  display: flex;
  ${layout}
`;

export { Flex };
