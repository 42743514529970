import gql from 'graphql-tag';

const getFeatureFlags = async ({ client, logger, platform }) => {
  try {
    const { data } = await client.query({
      query: gql`
        query launchDarklyFeatureFlags($platform: String!) {
          launchDarklyFeatureFlags(platform: $platform) {
            name
            value
            type
          }
        }
      `,
      variables: {
        platform,
      },
    });
    const { launchDarklyFeatureFlags } = data;

    return launchDarklyFeatureFlags.reduce((acc, { name, value }) => {
      return {
        ...acc,
        [name]: JSON.parse(value),
      };
    }, {});
  } catch (error) {
    logger.error(`Error initialising server side feature flags`, { error: error.errors });
  }

  return {};
};

export { getFeatureFlags };
