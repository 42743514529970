import { flow, types, getEnv, getRoot, getSnapshot } from 'mobx-state-tree';
import schemas from '@nandosaus/constants/lib/schemas/forms';
import { isEqual } from 'lodash';
import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { when } from 'mobx';
import OrderItem from '../../models/order-item';
import RecommendationsInput from '../../models/recommendations-input';
import Surcharge from '../../models/surcharge';
import { calculateSubtotalPrices } from '../../util/payment/calculate-subtotal-prices';
import { mapRecommendationToOrderItem } from './map-recommendation-to-order-item';
import { requestRecommendations } from './utils/request-recommendations';
import { formatOrderItemForProduct } from '../../util/analytics';

const initialState = {};

const validateInput = ({ restaurantId, cartId, input }) =>
  schemas.schemas.recommendationEngineInput.validateSync({
    ...input,
    restaurantId,
    cartId,
  });

const RecommendationEngineStore = types
  .model('RecommendationEngineStore', {
    error: false,
    submitted: false,
    orderItems: types.optional(types.array(OrderItem), []),
    recommendationsInput: types.maybe(RecommendationsInput),
    surcharges: types.optional(types.array(Surcharge), []),
    addedRecommendationsToCart: false,
    requestTime: types.maybe(types.Date),
  })
  .actions(self => {
    const { CartStore, MenuStore } = getRoot(self);
    return {
      getRecommendations: flow(function*(input) {
        self.error = false;
        const { logger, getApiClient, analytics } = getEnv(self);
        try {
          const validatedInput = validateInput({
            restaurantId: CartStore.restaurantId,
            cartId: CartStore.cartId,
            input,
          });
          // If recommendations have already been generated and form input has not changed, prevent regeneration
          if (isEqual(validatedInput, self.recommendationsInput) && self.submitted) return;
          self.recommendationsInput = validatedInput;
          self.requestTime = Date.now();
          self.submitted = true;
          const client = yield getApiClient();
          yield when(() => !MenuStore.loading); // wait for menu to be loaded before getting recommendations
          const recommendations = yield requestRecommendations({ client, input: self.recommendationsInput });
          self.orderItems = recommendations.map(recommendation =>
            mapRecommendationToOrderItem({ recommendation, root: getRoot(self) })
          );

          analytics.track(ANALYTICS_EVENTS.RECOMMENDED_CART_REQUESTED, {
            cartId: CartStore.cartId,
            restaurantId: self.recommendationsInput.restaurantId,
            mealType: self.recommendationsInput.mealType,
            basting: self.recommendationsInput.basting,
            groupKidsNumber: self.recommendationsInput.group.kids,
            groupAdultsNumber: self.recommendationsInput.group.adults,
            subtotalPriceInCents: self.subtotalPrice.cents,
          });

          analytics.track(ANALYTICS_EVENTS.RECOMMENDED_CART_REQUEST_VIEW, {
            price: self.subtotalPrice.dollarValue,
            products: self.orderItems.map(item =>
              formatOrderItemForProduct(item, {
                orderType: CartStore.orderType,
                // Group is for tab ordering only
                group: undefined,
              })
            ),
          });
        } catch (error) {
          logger.error(error);
          self.error = true;
        }
      }),
      addRecommendationsToCart() {
        const { logger, analytics } = getEnv(self);
        try {
          /**
           * MobX doesn't allow the same node to be referenced in two different spots in the state tree
           * So we create a snapshot of each recommended order item and pass that to cart store,
           * where it is converted into a separate copy of the same order item
           */
          const orderItems = self.orderItems.map(item => ({ ...getSnapshot(item), isRecommendedItem: true }));
          CartStore.addOrderItems(orderItems);
          self.addedRecommendationsToCart = true;

          analytics.track(ANALYTICS_EVENTS.RECOMMENDED_CART_ACCEPTED, {
            cartId: CartStore.cartId,
            restaurantId: self.recommendationsInput?.restaurantId,
            mealType: self.recommendationsInput?.mealType,
            basting: self.recommendationsInput?.basting,
            groupKidsNumber: self.recommendationsInput?.group?.kids,
            groupAdultsNumber: self.recommendationsInput?.group?.adults,
            subtotalPriceInCents: self.subtotalPrice.cents,
          });
        } catch (error) {
          logger.error(error);
        }
      },
      setProp(field, newValue) {
        self[field] = newValue;
      },
    };
  })
  .views(self => {
    return {
      // Total value of all items in recommended cart
      get subtotalPrice() {
        return calculateSubtotalPrices({ orderItems: self.orderItems });
      },
    };
  });

RecommendationEngineStore.initialState = initialState;

export default RecommendationEngineStore;
