import { createGlobalStyle } from 'styled-components';

import { colors, globalStyles } from '../../constants';

const BaseStyles = createGlobalStyle`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  *:focus:not(.focus-visible) {
    outline: none;
  }

  ::selection {
    color: ${colors.white};
    background: ${colors.black};
  }

  ::-moz-selection {
    color: ${colors.white};
    background: ${colors.black};
  }

  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: inherit;
    padding: 0;
  }

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  figure {
    margin: 0;
  }

  img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
  }

  svg {
    display: inline-block;
    vertical-align: top;
  }

  a,
  input,
  select,
  textarea,
  button {
    -webkit-tap-highlight-color: ${globalStyles.tapHighlightColor};
    transition-duration: 0.5s;
    transition-property: background-color, color, opacity, border, border-color, background-position, box-shadow, border-bottom, text-decoration, left, top, transform;
    transition-timing-function: ${globalStyles.easing};
    transform-origin: center center;
  }

  /* This is to change default focus styling in firefox, which is dotted line so making it not very obvious */
  button:-moz-focusring,
  a:-moz-focusring {
    outline: 2px auto ${colors.blue500};
  }
`;

export { BaseStyles };
