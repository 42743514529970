import { types } from 'mobx-state-tree';

import CartItem from '../perks-shop-cart-item';
import PerksShopProduct from '../perks-shop-product';

const itemVariant = types.model('ItemVariant', {
  color: types.maybeNull(types.string),
  plu: types.number,
  size: types.maybeNull(types.string),
  stock: types.maybeNull(types.number),
});

const SelectedItem = types.model('SelectedItem', {
  cartItem: types.maybeNull(types.reference(CartItem)),
  product: types.reference(PerksShopProduct),
  quantity: types.maybeNull(types.number),
  selectedVariant: types.maybeNull(itemVariant),
});

export default SelectedItem;
