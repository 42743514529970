import { CONTEXT_TYPES, ORDER_TYPES } from '@nandosaus/constants';
import { types } from 'mobx-state-tree';

import { DineInFulfilment, PickUpFulfilment, DeliveryFulfilment } from './fulfilments';

export const PresetContext = types
  .model({
    type: types.literal(CONTEXT_TYPES.PRESET),
    availableOrderTypes: types.optional(
      types.array(types.enumeration([ORDER_TYPES.DINE_IN, ORDER_TYPES.PICK_UP, ORDER_TYPES.DELIVERY])),
      []
    ),
  })
  .actions(self => {
    // While checking this once on module load is fine in production it makes it very hard
    // to test, and since we run this seldomly it's ok to do the lookup here
    const allOptionsRestaurantIds = process.env.qrFlow?.showAllOrderOptionsForRestaurants || [];

    return {
      init({ orderType, showAllOrderTypes, restaurantId, tableNumber }) {
        const shouldShowAllOrderOptions = showAllOrderTypes || allOptionsRestaurantIds.includes(restaurantId);

        const defaultOrderType = shouldShowAllOrderOptions ? ORDER_TYPES.PICK_UP : ORDER_TYPES.DINE_IN;
        const selectedOrderType = orderType || defaultOrderType;

        self.availableOrderTypes =
          orderType && !shouldShowAllOrderOptions
            ? [selectedOrderType]
            : [ORDER_TYPES.DINE_IN, ORDER_TYPES.PICK_UP, ORDER_TYPES.DELIVERY];

        if (selectedOrderType === ORDER_TYPES.DELIVERY) {
          const fulfilment = DeliveryFulfilment.create({ type: selectedOrderType });
          return { fulfilment };
        }

        if (selectedOrderType === ORDER_TYPES.PICK_UP) {
          const fulfilment = PickUpFulfilment.create({ type: selectedOrderType, restaurantId });
          return { fulfilment };
        }

        const fulfilment = DineInFulfilment.create({
          type: ORDER_TYPES.DINE_IN,
          restaurantId,
          tableNumber,
        });

        return { fulfilment };
      },
    };
  });
