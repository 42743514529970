import { autorun, computed, reaction, when } from 'mobx';
import { clone, flow, getRoot, isStateTreeNode, types } from 'mobx-state-tree';
import { DATE_TIME_FORMATS, DELIVERABILITY_STATUS } from '@nandosaus/constants';
import { filter, get, isEmpty, isNil, map, reduce, reverse, set, take } from 'lodash';
import { now } from 'mobx-utils';
import moment from 'moment';

import { getDeliveryEstimate } from './get-delivery-estimate';
import { getDependencies } from '../../util/get-dependencies';
import DeliveryAddress from '../../models/delivery-address';
import DeliveryEstimate from '../../models/delivery-estimate';

const MAX_PREVIOUS_SEARCHES = 5;
const PREVIOUS_SEARCHES_KEY = 'PreviousSearches';

const initialState = {};

const roundToNearestFifteenMinutes = (date, direction = 'up') => {
  const roundedToHour = moment
    .utc(date)
    .startOf('hour')
    .toDate();

  const slots = [
    roundedToHour,
    moment
      .utc(roundedToHour)
      .add(15, 'minutes')
      .toDate(),
    moment
      .utc(roundedToHour)
      .add(30, 'minutes')
      .toDate(),
    moment
      .utc(roundedToHour)
      .add(45, 'minutes')
      .toDate(),
    moment
      .utc(roundedToHour)
      .add(60, 'minutes')
      .toDate(),
  ];

  const orderedSlots = direction === 'up' ? slots : reverse(slots);

  return orderedSlots.find(slot => (direction === 'up' ? slot >= date : slot <= date));
};

const DeliveryStore = types
  .model('DeliveryStore', {
    loading: false,
    error: types.maybeNull(types.string),
    deliverabilityStatus: types.maybe(types.enumeration(Object.values(DELIVERABILITY_STATUS))),
    deliveryAddress: types.maybe(DeliveryAddress),
    deliveryEstimates: types.optional(types.array(DeliveryEstimate), []),
    deliveryNotes: types.maybe(types.string),
    preferredDeliveryTime: types.optional(types.union(types.Date, types.enumeration(['ASAP'])), 'ASAP'),
    previousSearches: types.optional(types.array(DeliveryAddress), []),
  })
  .actions(self => {
    const { getApiClient, localStorage, logger } = getDependencies(self);
    const { CartStore, MemberStore } = getRoot(self);

    // Default to member's latest address if deliveryAddress not set
    autorun(() => {
      if (!MemberStore || !MemberStore.isSignedIn || self.deliveryAddress) {
        return;
      }

      if (isNil(MemberStore.profile.latestAddress)) {
        return;
      }

      self.setDeliveryAddress(MemberStore.profile.latestAddress, { saveToPreviousSearch: false });
    });

    reaction(
      () => get(CartStore, 'orderItemsToOrderInput'),
      () => {
        const { isDelivery } = CartStore;
        const hasOrderItems = !isEmpty(CartStore.orderItems);
        const hasDeliveryAddress = !!self.deliveryAddress;

        if (!isDelivery || !hasDeliveryAddress || !hasOrderItems) {
          return;
        }

        self.getDeliveryEstimate();
      }
    );

    const savePreviousSearch = async address => {
      const addressToAdd = isStateTreeNode(address) ? clone(address) : address;
      const newItems = [addressToAdd, ...self.previousSearches.filter(prev => !prev.isEqual(addressToAdd))];

      self.previousSearches = take(newItems, MAX_PREVIOUS_SEARCHES);
      await localStorage.setItem(PREVIOUS_SEARCHES_KEY, self.previousSearches);
    };

    return {
      /**
       * Runs immediately after the store has been initialized.
       * Rehydrates previous searches from local storage where available.
       */
      afterCreate: flow(function*() {
        const previousSearches = yield localStorage.getItem(PREVIOUS_SEARCHES_KEY);

        if (Array.isArray(previousSearches)) {
          self.previousSearches = previousSearches;
        }
      }),

      clearSessionData() {
        self.loading = false;
        self.error = null;
        self.deliverabilityStatus = undefined;
        self.deliveryAddress = undefined;
        self.deliveryEstimates = undefined;
        self.deliveryNotes = undefined;
        self.preferredDeliveryTime = undefined;
      },

      /**
       * Set the current delivery address. A delivery estimate is automatically loaded
       * for the new address and the address is saved to previous searches.
       */
      setDeliveryAddress: flow(function*(address, { saveToPreviousSearch = true } = {}) {
        // it is common for NZ to return the same value for both state and suburb but in some cases the suburb is missing
        if (address.suburb === undefined) {
          set(address, 'suburb', address.state);
        }

        self.deliveryAddress = isStateTreeNode(address) ? clone(address) : address;
        self.setPreferredDeliveryTime('ASAP');

        // The data returned from the Places API may be missing a postcode
        if (address.postcode === undefined) {
          self.deliverabilityStatus = DELIVERABILITY_STATUS.ADDRESS_MISSING_POSTCODE;
          return;
        }

        yield self.getDeliveryEstimate();
        if (saveToPreviousSearch) {
          yield savePreviousSearch(address);
        }
      }),

      setDeliveryStreet: flow(function*(street) {
        if (self.deliveryAddress === undefined) {
          logger.warn('Cannot set street without delivery address selected');
          return;
        }

        self.deliveryAddress.street = street;
        yield savePreviousSearch(self.deliveryAddress);
      }),

      setDeliveryUnit(unit) {
        if (self.deliveryAddress === undefined) {
          throw new Error('Cannot set unit before delivery address has been set');
        }

        self.deliveryAddress.unit = unit;
      },
      setDeliveryNotes(notes) {
        self.deliveryNotes = notes;
      },
      /** Use DeliveryStore values to get estimate from API, not called externally */
      getDeliveryEstimate: flow(function*() {
        if (!self.deliveryAddress) {
          logger.warn('delivery estimate called without delivery address');
          return;
        }

        yield when(() => !self.loading);

        self.error = null;
        self.loading = true;

        try {
          const client = yield getApiClient();
          const { data, error } = yield getDeliveryEstimate({
            client,
            deliveryAddress: {
              ...self.deliveryAddress,
              latitude: self.deliveryAddress.latitude.toString(),
              longitude: self.deliveryAddress.longitude.toString(),
            },
            deliveryTime: 'ASAP', // TODO Hardcoded for now use order time from cart
            radius: 5, // 5km delivery radius
            orderTotal: CartStore.subtotalPrices.cents,
            orderItems: CartStore.orderItemsToOrderInput,
          });

          if (error) {
            throw error;
          }

          self.deliveryEstimates = map(
            data,
            ({ fee, time, restaurantId, isDiscountActive, isDiscountApplied, sponsor, deliveryProvider }) => {
              const deliveryEstimate = {
                fee,
                minutes: moment(time).diff(moment.now(), 'minutes'),
                restaurant: restaurantId,
                isDiscountActive,
                isDiscountApplied,
                sponsor,
              };

              if (!deliveryProvider) {
                return deliveryEstimate;
              }

              return { ...deliveryEstimate, deliveryProvider };
            }
          );

          self.deliverabilityStatus =
            data.length > 0 ? DELIVERABILITY_STATUS.DELIVERABLE : DELIVERABILITY_STATUS.OUTSIDE_DELIVERY_AREA;
        } catch (error) {
          self.deliveryEstimates = undefined;

          const errorCode = get(error, 'errors[0].extensions.code', null);
          const errorMessage = get(error, 'errors[0].message', null);

          if (errorCode === 'OUTSIDE_DELIVERY_AREA') {
            self.deliverabilityStatus = DELIVERABILITY_STATUS.OUTSIDE_DELIVERY_AREA;
            return;
          }

          if (errorCode === 'OUTSIDE_DELIVERY_HOURS') {
            self.deliverabilityStatus = DELIVERABILITY_STATUS.OUTSIDE_DELIVERY_HOURS;
            return;
          }

          if (errorCode === 'INSUFFICIENT_COURIER_CAPACITY') {
            self.deliverabilityStatus = DELIVERABILITY_STATUS.COURIERS_UNAVAILABLE;
            return;
          }

          self.deliverabilityStatus = DELIVERABILITY_STATUS.UNKNOWN;
          self.error = 'Unable to get delivery estimate, please try again.';

          if (
            errorMessage === 'An unhandled exception occurred' ||
            errorMessage === "unsupported operand type(s) for +: 'NoneType' and 'datetime.timedelta'"
          ) {
            return;
          }

          logger.error(`Error fetching delivery estimate`, { error });
        } finally {
          self.loading = false;
        }
      }),
      setPreferredDeliveryTime(time) {
        self.preferredDeliveryTime = time;
      },
    };
  })
  .views(self => {
    const lastDeliverySlot = computed(
      () => {
        const restaurant = self.deliveryRestaurant;

        if (!restaurant) {
          return null;
        }

        const currentTime = new Date(now());
        const { closingTime = null } = restaurant.tradingHoursForPointInTime(currentTime);

        if (closingTime === null) {
          return null;
        }

        const lastOrders = moment(closingTime).subtract(30, 'minutes');
        const lastSlot = roundToNearestFifteenMinutes(lastOrders, 'down');

        if (lastSlot < currentTime) {
          return null;
        }

        return roundToNearestFifteenMinutes(lastOrders, 'down');
      },
      /*
        By default, MST evaluates equal dates as being different which results
        in any observers being notified the value has changed. Using a custom
        comparer allows us to get around the limitation, ensuring observers will
        only be notified when the last delivery slot value actually changes.
      */
      { equals: (a, b) => moment(a).isSame(b) }
    );

    return {
      /**
       * Get the earliest possible delivery slot based on the
       * shortest delivery time estimate.
       */
      get earliestDeliverySlot() {
        const { shortestDeliveryTime } = self;

        if (shortestDeliveryTime === null) {
          return null;
        }
        const minDeliveryTime = moment(shortestDeliveryTime.time).toDate();
        return roundToNearestFifteenMinutes(minDeliveryTime, 'up');
      },

      /**
       * Get the last possible delivery slot at the current point in time
       * based on the selected delivery restaurant.
       * @param {number} pollingInterval
       */
      get lastDeliverySlot() {
        return lastDeliverySlot.get();
      },

      /**
       * Get delivery slot boundaries for the current point in time
       * based on the selected delivery restaurant.
       * @param {number} pollingInterval
       */
      get deliveryBoundaries() {
        if (self.earliestDeliverySlot === null || self.lastDeliverySlot === null) {
          return null;
        }

        return {
          from: self.earliestDeliverySlot,
          to: self.lastDeliverySlot,
        };
      },

      get shortestDeliveryTime() {
        const nowMoment = moment();
        const enabledDeliveryEstimates = filter(self.deliveryEstimates, 'restaurant.deliveryEnabled');
        const shortestTime = reduce(
          enabledDeliveryEstimates,
          (memo, estimate) => {
            if (!memo) {
              return estimate;
            }
            const previousTime = moment(memo.time).diff(nowMoment);
            const nextTime = moment(estimate.time).diff(nowMoment);
            return previousTime > nextTime ? estimate : memo;
          },
          null
        );
        return shortestTime;
      },
      get formattedDeliveryAddress() {
        return self.deliveryAddress ? self.deliveryAddress.formattedAddress : 'Address';
      },
      get formattedDeliveryTimePreference() {
        if (self.preferredDeliveryTime === 'ASAP') {
          return 'ASAP';
        }

        return moment(self.preferredDeliveryTime).format(DATE_TIME_FORMATS.TIME_DEFAULT);
      },

      get formattedDeliveryCost() {
        if (self.deliveryCost === null) {
          return 'TBC';
        }

        return self.deliveryCost.formatted;
      },
      get deliveryCost() {
        if (self.shortestDeliveryTime === null) {
          return null;
        }

        return self.shortestDeliveryTime.fee;
      },
      get allowCheckout() {
        return (
          self.deliveryAddress !== undefined &&
          self.deliverabilityStatus === DELIVERABILITY_STATUS.DELIVERABLE &&
          !self.loading
        );
      },
      get isDeliveryDiscountActive() {
        return self.deliveryEstimates.some(estimate => estimate.isDiscountActive);
      },
      get deliveryRestaurant() {
        return self.shortestDeliveryTime?.restaurant;
      },
      get deliveryProvider() {
        return self.shortestDeliveryTime?.deliveryProvider;
      },

      get formattedDeliveryTime() {
        return self.shortestDeliveryTime ? self.shortestDeliveryTime.fromNow : undefined;
      },
    };
  });

DeliveryStore.initialState = initialState;

export default DeliveryStore;
