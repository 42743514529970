import { types } from 'mobx-state-tree';

import { priceFormatter } from '../../util/formatter';
import Option from '../option';

const OrderOption = types
  .model('OrderOption', {
    option: types.reference(Option),
    quantity: 1,
  })
  .actions(self => {
    return {
      updateQuantity(value) {
        self.quantity = value;
      },
    };
  })
  .views(self => ({
    get formattedPrice() {
      const { option, quantity } = self;
      return option.prices.cents > 0 ? priceFormatter(option.prices.cents * quantity) : null;
    },
  }));

export default OrderOption;
