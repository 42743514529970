const Visa = props => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <title>Visa</title>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="#FFFFFF" />
      </clipPath>
    </defs>
    <g clipPath="url(#clip0)">
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="3" y="9" width="18" height="7">
        <path
          d="M11.9124 9.26614L10.7097 15.0693H9.255L10.4583 9.26614H11.9124ZM18.0317 13.0131L18.7974 10.8341L19.2382 13.0131H18.0317ZM19.6548 15.0693H21L19.8258 9.26614H18.5843C18.3051 9.26614 18.0697 9.43361 17.9652 9.69173L15.7828 15.0693H17.3097L17.6131 14.2023H19.479L19.6548 15.0693ZM15.8583 13.1744C15.8648 11.643 13.8065 11.5584 13.8207 10.8741C13.8252 10.6661 14.0172 10.4448 14.4376 10.3882C14.6457 10.3601 15.2204 10.3384 15.8715 10.6477L16.1271 9.41726C15.7768 9.2861 15.3267 9.16 14.7662 9.16C13.3287 9.16 12.3167 9.9489 12.3087 11.0781C12.2991 11.914 13.0308 12.3798 13.5822 12.6577C14.1486 12.942 14.3386 13.1245 14.3365 13.3788C14.3323 13.7683 13.8845 13.94 13.4659 13.9467C12.7347 13.9585 12.3102 13.7427 11.9721 13.5801L11.7087 14.8517C12.0482 15.0128 12.6756 15.1531 13.3262 15.16C14.8543 15.16 15.8536 14.3813 15.8583 13.1744ZM9.83408 9.26614L7.4775 15.0693H5.94008L4.78042 10.438C4.71 10.1528 4.64892 10.0482 4.43483 9.92826C4.08525 9.73224 3.50792 9.54852 3 9.43438L3.03425 9.26614H5.50933C5.82458 9.26614 6.10842 9.48263 6.18 9.85773L6.7925 13.2159L8.30633 9.26614H9.83408Z"
          fill="#FFFFFF"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9124 9.26614L10.7097 15.0693H9.255L10.4583 9.26614H11.9124ZM18.0317 13.0131L18.7974 10.8341L19.2382 13.0131H18.0317ZM19.6548 15.0693H21L19.8258 9.26614H18.5843C18.3051 9.26614 18.0697 9.43361 17.9652 9.69173L15.7828 15.0693H17.3097L17.6131 14.2023H19.479L19.6548 15.0693ZM15.8583 13.1744C15.8648 11.643 13.8065 11.5584 13.8207 10.8741C13.8252 10.6661 14.0172 10.4448 14.4376 10.3882C14.6457 10.3601 15.2204 10.3384 15.8715 10.6477L16.1271 9.41726C15.7768 9.2861 15.3267 9.16 14.7662 9.16C13.3287 9.16 12.3167 9.9489 12.3087 11.0781C12.2991 11.914 13.0308 12.3798 13.5822 12.6577C14.1486 12.942 14.3386 13.1245 14.3365 13.3788C14.3323 13.7683 13.8845 13.94 13.4659 13.9467C12.7347 13.9585 12.3102 13.7427 11.9721 13.5801L11.7087 14.8517C12.0482 15.0128 12.6756 15.1531 13.3262 15.16C14.8543 15.16 15.8536 14.3813 15.8583 13.1744ZM9.83408 9.26614L7.4775 15.0693H5.94008L4.78042 10.438C4.71 10.1528 4.64892 10.0482 4.43483 9.92826C4.08525 9.73224 3.50792 9.54852 3 9.43438L3.03425 9.26614H5.50933C5.82458 9.26614 6.10842 9.48263 6.18 9.85773L6.7925 13.2159L8.30633 9.26614H9.83408Z"
          fill="#1A1F71"
        />
      </g>
      <path d="M24 4H0V7H24V4Z" fill="#1A1F71" />
      <path d="M24 17H0V20H24V17Z" fill="#F7B600" />
    </g>
  </svg>
);

export { Visa };
