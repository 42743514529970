const { chain } = require('lodash');
const cardValidator = require('card-validator');
const dayjs = require('dayjs');
const Yup = require('yup');

const { ENQUIRY_TOPICS, MARKET, RECOMMENDATION_ENGINE, BASTING_TYPES } = require('../../constants');

const getSchemasForMarket = market => {
  const CARD_TYPE_MAP = {
    visa: 'visa',
    mastercard: 'mc',
    'american-express': 'amex',
  };

  const phoneNumberRegexes = {
    Australia: /^(\+61|0)4[0-9]{8}$/,
    'New Zealand': /^(\+64|0)2[0-9]{7,9}$/,
    'Canada and USA': /^[?+]1([\d]{10})$/,
    China: /^[?+]86([\d]{5,12})$/,
    France: /^[?+]33([\d]{9})$/,
    Germany: /^[?+]49([\d]{6,13})$/,
    'Hong Kong': /^[?+]852([\d]{4,9})$/,
    India: /^[?+]91([\d]{7,10})$/,
    Ireland: /^[?+]353([\d]{7,11})$/,
    Italy: /^[?+]39([\d]{1,11})$/,
    Japan: /^[?+]81([\d]{9,10})$/,
    Singapore: /^[?+]65([\d]{8,12})$/,
    'South Africa': /^[?+]27([\d]{9})$/,
    'United Kingdom': /^[?+]44([\d]{7,10})$/,
  };

  const fields = {
    email: Yup.string().email('Please provide a valid email address'),
    mobile: Yup.string().matches(
      new RegExp(
        chain(phoneNumberRegexes)
          .map('source')
          .join('|')
      ),
      'Please provide a valid mobile number'
    ),
    name: Yup.string().min(2, 'Name must be at least 2 characters'),
    verificationCode: Yup.string().matches(/^\d{6}$/, 'Code must be 6 digits'),
    postcode: Yup.string()
      .nullable()
      .matches(
        /^\d{4}$/,
        `Please provide a valid ${market === MARKET.AUSTRALIA ? 'Australian' : 'New Zealand'} postcode`
      ),

    password: Yup.string().min(6, 'Password must be at least 6 characters'),
    checkbox: Yup.bool().oneOf([true], 'Field must be checked'),
  };

  const giftCardRegex = /^([a-z]{3,4}[0-9]{7})$/i;
  const giftCardErrorMessage = 'Incomplete or incorrect gift card number';

  const giftCardFields = {
    cardNumber: Yup.string()
      .required('Card number is required')
      .matches(giftCardRegex, giftCardErrorMessage),
    verificationCode: Yup.string().required('Verification code is required'),
  };

  const receiptFields = {
    receiptNumber: Yup.string()
      .matches(/^\d+$/, 'Please enter a valid receipt number (7 digits)')
      .required('Receipt number is required'),
    date: Yup.date()
      .nullable()
      .min(
        dayjs()
          .subtract(6, 'month')
          .format(),
        'Purchase must be within the last 6 months'
      )
      .max(
        dayjs()
          .add(1, 'day')
          .format(),
        'Purchase cannot be a future date'
      )
      .required('Date of purchase is required'),
    time: Yup.date().required('Time of purchase is required'),
    total: Yup.string()
      .matches(/^[$]{0,1}\d+\.\d\d[$]{0,1}$/, 'Please enter a valid total amount')
      .required('Total of purchase is required'),
  };

  const MAX_CVV_CCV_LENGTH = [3, 4];

  const checkoutSchema = {
    giftCardNumber: Yup.string().matches(giftCardRegex, giftCardErrorMessage),
    giftCardVerificationCode: Yup.string(),
    notes: Yup.string().max(200, 'Please enter no more than 200 characters'),
  };

  const schemas = {
    signUp: Yup.object().shape({
      givenName: fields.name.required('First name is required'),
      familyName: fields.name.required('Last name is required'),
      email: fields.email.required('Email is required'),
      mobile: fields.mobile.required('Mobile is required'),
      password: fields.password.required('Password is required'),
      // TODO: Add validation
      verifyPin: Yup.string(),
      memberNumber: Yup.string(),
      // TODO: change name
      checkAgree: fields.checkbox.required('Must Be checked'),
    }),
    signIn: Yup.object().shape({
      email: fields.email.required('Email is required'),
      password: fields.password.required('Password is required'),
      mobile: fields.mobile,
    }),
    updateDetails: Yup.object().shape({
      mobile: fields.mobile.required('Mobile is required'),
    }),
    confirmSignIn: Yup.object().shape({ code: fields.verificationCode.required() }),
    forgotPassword: Yup.object().shape({
      email: fields.email.required('Email is required'),
    }),
    confirmForgotPassword: Yup.object().shape({
      newPassword: fields.password.required('New password is required'),
      confirmPassword: fields.password.required('Confirm password is required'),
      code: fields.verificationCode.required('Verification code is required'),
      mobile: fields.mobile,
    }),
    addGiftCard: Yup.object().shape(giftCardFields),
    checkGiftCardBalance: Yup.object().shape(giftCardFields),
    addReceipt: Yup.object().shape({
      restaurant: Yup.object().shape({
        id: Yup.string().required('Please select a restaurant'),
      }),
      ...receiptFields,
    }),
    addReceiptWeb: Yup.object().shape({
      restaurantId: Yup.string().required('Restaurant is required'),
      state: Yup.string().required('Select the state/territory in order to select a restaurant'),
      ...receiptFields,
    }),
    profileEditEmail: Yup.object().shape({
      email: fields.email.required('Email is required'),
    }),
    profileEditGivenName: Yup.object().shape({
      givenName: fields.name.required('First name is required'),
    }),
    profileEditFamilyName: Yup.object().shape({
      familyName: fields.name.required('Last name is required'),
    }),
    profileEditMobile: Yup.object().shape({
      mobile: fields.mobile.required('Mobile is required'),
    }),
    profileEditPostcode: Yup.object().shape({
      postcode: fields.postcode,
    }),
    checkout: Yup.object().shape(checkoutSchema),
    guestCheckout: Yup.object().shape({
      ...checkoutSchema,
      givenName: fields.name.required('Given name is required'),
      surname: fields.name.required('Surname is required'),
      mobile: fields.mobile.required('Mobile is required'),
      email: fields.email.required('Email is required'),
    }),
    orderHelpGuestDetails: Yup.object().shape({
      guestGivenName: fields.name.required('Given name is required'),
      guestFamilyName: fields.name.required('Family name is required'),
      guestMobile: fields.mobile.required('Mobile is required'),
      guestEmail: fields.email.required('Email is required'),
    }),
    makeCreditCard: availableCardTypes =>
      Yup.object().shape({
        cardHolderName: Yup.string().required('Name is required'),
        creditCardNumber: Yup.string()
          .required('Card number is required')
          .test('test-creditCardNumber', 'Invalid credit card number', value => cardValidator.number(value).isValid)
          // eslint-disable-next-line func-names
          .test('test-creditCardValid', '', function(value) {
            const { path, createError } = this;
            const { card } = cardValidator.number(value);
            if (card && card.type) {
              if (availableCardTypes.indexOf(CARD_TYPE_MAP[card.type]) === -1) {
                return createError({ path, message: `${card.niceType} is not available at this store.` });
              }
            }
            return {};
          }),
        creditCardExpiryDate: Yup.string()
          .required('Expiry Date is required')
          .test(
            'test-creditCardExpiryDate',
            'Invalid Expiry Date',
            value => cardValidator.expirationDate(value).isValid
          ),
        creditCardSecurityCode: Yup.string()
          .required('required')
          .test(
            'test-creditCardSecurityCode',
            'Invalid number',
            value => cardValidator.cvv(value, MAX_CVV_CCV_LENGTH).isValid
          ),
      }),
    npsForm: Yup.object().shape({
      restaurantId: Yup.string().required('Restaurant is required'),
      state: Yup.string().required('Required field'),
      dateOfVisit: Yup.string()
        .nullable()
        .required('Date of visit is required'),
      timeOfVisit: Yup.string().required('Time of visit is required'),
      isPerksMember: Yup.boolean().required('required field'),
      mainsOrder: Yup.string().required('Order type is required'),
      experienceRating: Yup.string().required('Rating is required'),
      orderType: Yup.string().required('Order type is required'),
      experienceImprovements: Yup.object().shape({}),
      didWellToday: Yup.string(),
      comments: Yup.string(),
      name: Yup.string(),
      email: Yup.string().email('A valid email is required'),
      mobile: Yup.string(),
    }),
    orderDrivenNpsForm: Yup.object().shape({
      experienceRating: Yup.string().required('Rating is required'),
      experienceImprovements: Yup.object().shape({}),
      didWellToday: Yup.string(),
      comments: Yup.string(),
      name: Yup.string(),
      email: Yup.string().email('A valid email is required'),
      mobile: Yup.string(),
    }),
    perksShop: Yup.object().shape({
      givenName: Yup.string().required('First name is required'),
      familyName: Yup.string().required('Last name is required'),
      email: fields.email.required('Email is required'),
      mobile: fields.mobile.required('Mobile number is required'),
      postcode: fields.postcode.required('Postcode is required'),
      street: Yup.string().required('Street address is required'),
      suburb: Yup.string().required('Suburb is required'),
      state: Yup.string().required('State is required'),
    }),
    profile: Yup.object().shape({
      givenName: Yup.string().required('First name is required'),
      familyName: Yup.string().required('Last name is required'),
      email: fields.email.required('Email is required'),
      gender: Yup.string().nullable(),
      mobile: fields.mobile.required('Mobile number is required'),
      postcode: fields.postcode,
      state: Yup.string().nullable(),
    }),
    enquiry: Yup.object().shape({
      dateOfVisit: Yup.date().when('topic', (topic, schema) => {
        if (topic === ENQUIRY_TOPICS.RESTAURANT_FEEDBACK) {
          return schema.max(new Date()).required('Date of visit is required');
        }

        return schema.strip();
      }),
      givenName: fields.name.required('First name is required'),
      familyName: fields.name.required('Last name is required'),
      feedbackType: Yup.string().when('topic', (topic, schema) => {
        if (topic === ENQUIRY_TOPICS.RESTAURANT_FEEDBACK) {
          return schema.required('Type of feedback is required');
        }

        return schema.strip();
      }),
      email: fields.email.required('Email is required'),
      mobile: fields.mobile,
      message: Yup.string().required('Message is required'),
      orderType: Yup.string().when('topic', (topic, schema) => {
        if (topic === ENQUIRY_TOPICS.RESTAURANT_FEEDBACK) {
          return schema.required('Type of order is required');
        }

        return schema.strip();
      }),
      restaurant: Yup.string().when('topic', (topic, schema) => {
        if (topic === ENQUIRY_TOPICS.RESTAURANT_FEEDBACK) {
          return schema.required('Restaurant is required');
        }

        return schema.strip();
      }),
      topic: Yup.string().required('Topic is required'),
    }),
    streetNumber: Yup.object().shape({
      streetNumber: Yup.string().required('Your street number is required'),
    }),
    npsCompetitionForm: Yup.object().shape({
      givenName: fields.name.required('First name is required'),
      familyName: fields.name.required('Last name is required'),
      email: fields.email.required('Email is required'),
      mobile: fields.mobile.required('Mobile number is required'),
      response: Yup.string()
        .matches(/^\s*\S+(?:\s+\S+){0,24}\s*$/, 'Response must be 25 words or less')
        .required('Response is required'),
    }),
    createGiftCard: Yup.object().shape({
      sendersGivenName: fields.name.required('Sender’s given name is required'),
      sendersSurname: fields.name.required('Sender’s surname is required'),
      sendersEmail: fields.email.required('Sender’s email is required'),
      recipientsGivenName: fields.name.required('Recipient’s given name is required'),
      recipientsSurname: fields.name.required('Recipient’s surname is required'),
      recipientsEmail: fields.email.required('Recipient’s email is required'),
    }),
    orderHelpAddNote: Yup.object().shape({
      notes: Yup.string().required('Note is required'),
    }),
    recommendationEngineInput: Yup.object().shape({
      restaurantId: Yup.string().required(),
      cartId: Yup.string().required(),
      group: Yup.object().shape({
        kids: Yup.number()
          .required()
          .min(0)
          .max(RECOMMENDATION_ENGINE.maxGuests),
        adults: Yup.number()
          .required()
          .min(1)
          .max(RECOMMENDATION_ENGINE.maxGuests),
      }),
      mealType: Yup.mixed()
        .oneOf(Object.values(RECOMMENDATION_ENGINE.mealTypes).map(({ value }) => value))
        .required(),
      basting: Yup.mixed()
        .oneOf(Object.values(BASTING_TYPES))
        .required(),
    }),
  };

  return schemas;
};

const getSchema = (schema, market) => {
  const schemas = getSchemasForMarket(market);

  return schemas[schema];
};

const defaultSchemas = getSchemasForMarket(MARKET.AUSTRALIA);

module.exports = { getSchema, schemas: defaultSchemas };
