import { omit } from 'lodash';
import PropTypes from 'prop-types';

import { Link } from '../link';

const ButtonElement = ({ children, download, to, href, type, target, ...rest }) => {
  // Omit props used for button styles stopping DOM warning
  const props = omit(rest, ['textVariant']);

  if (href || download || to) {
    return (
      <Link
        href={href}
        download={download}
        to={to}
        target={target}
        rel={target ? 'noreferrer noopener' : null}
        {...props}
      >
        {children}
      </Link>
    );
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} {...props}>
      {children}
    </button>
  );
};

ButtonElement.propTypes = {
  analytics: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  download: PropTypes.bool,
  href: PropTypes.string,
  target: PropTypes.oneOf(['_self', '_blank', '_parent', '_top']),
  to: PropTypes.string,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
};

ButtonElement.defaultProps = {
  analytics: undefined,
  download: false,
  href: null,
  target: undefined,
  to: null,
  type: 'button',
};

export { ButtonElement };
