import { types } from 'mobx-state-tree';

const Image = types.model('Image', {
  alt: types.maybeNull(types.string),
  focalPoint: types.maybeNull(types.array(types.number)),
  hi: types.maybeNull(types.string),
  lo: types.maybeNull(types.string),
  srcset: types.maybeNull(types.string),
  url: types.maybeNull(types.string),
});

export default Image;
