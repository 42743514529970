import { GROUP_SESSION_ID_KEY } from '@nandosaus/constants';
import merge from 'lodash/merge';
import pRetry from 'p-retry';
import some from 'lodash/some';
import trimEnd from 'lodash/trimEnd';
import { localStorage } from '@utils/local-storage';

import { API, Auth } from '../amplify';

const MAX_RETRIES = 1;

const checkAuthenticated = async () => {
  try {
    await Auth.currentAuthenticatedUser();
    return true;
  } catch (error) {
    return false;
  }
};

const isNetworkError = error =>
  some(error.errors, { message: 'Network Error' }) || some(error.errors, { message: 'timeout of 0ms exceeded' });

const executeQuery = async ({ query, variables = {}, headers }) => {
  try {
    return await API.graphql({ query, variables }, headers);
  } catch (apiGqlError) {
    // The error thrown by API.graphql()/Apollo needs to be an instance of
    // 'Error' for p-retry to handle it correctly, hence the merge below.
    const error = merge(new Error(), apiGqlError);

    if (isNetworkError(apiGqlError)) {
      error.message = 'Network Error';
      throw error;
    }

    throw new pRetry.AbortError(error);
  }
};

const executeQueryWithRetry = ({ query, variables = {}, headers }) => {
  return pRetry(async () => executeQuery({ query, variables, headers }), {
    onFailedAttempt: error => {
      console.warn(`Query: Attempt ${error.attemptNumber} failed. Retries left: ${error.retriesLeft}`, error);
    },
    retries: MAX_RETRIES,
  });
};

const executeMutationWithRetry = ({ mutation, variables }) => {
  return pRetry(() => executeQuery({ query: mutation, variables }), {
    onFailedAttempt: error => {
      console.warn(`Mutation: Attempt ${error.attemptNumber} failed. Retries left: ${error.retriesLeft}`, error);
    },
    // Retries can result in duplicated mutations being processed by our API
    retries: 0,
  });
};

// @NOTE: use amplify instead of apollo client to utilise its built-in session management with AWS Cognito
const getClient = (isAuthenticated, groupSessionId) => {
  if (isAuthenticated) {
    API.configure({
      graphql_endpoint: `${trimEnd(process.env.api.baseUrl, '/')}/private/graphql`,
      graphql_headers: async () => {
        const currentSession = await Auth.currentSession();
        return { Authorization: currentSession.getIdToken().getJwtToken(), 'group-session-id': groupSessionId };
      },
    });
  } else {
    API.configure({
      graphql_endpoint: `${trimEnd(process.env.api.baseUrl, '/')}/public/graphql`,
      graphql_headers: async () => {
        return { Authorization: null, 'group-session-id': groupSessionId };
      },
    });
  }

  return {
    query: executeQueryWithRetry,
    mutate: executeMutationWithRetry,
  };
};

const getApiClient = async () => {
  const isAuthenticated = await checkAuthenticated();
  const groupSessionId = await localStorage.getItem(GROUP_SESSION_ID_KEY);

  return getClient(isAuthenticated, groupSessionId);
};

export { getApiClient };
