import { GROUP_SESSION_ID_KEY, ANALYTICS_EVENTS, SAVED_DIETARY_PREFERENCES_KEY } from '@nandosaus/constants';
import { RootStore, RootStoreContext, initialState } from '@nandosaus/state-management';
import { onSnapshot } from 'mobx-state-tree';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { v4 as uuid } from 'uuid';
import { CartPersistence } from '@hooks/use-cart-persistence';
import { GroupCartPolling } from '@hooks/use-group-cart-polling';
import { useServiceWorker } from '@hooks/use-service-worker';
import { Auth, Hub } from '../../lib/amplify';
import { getApiClient } from '../../lib/api-client';
import { analytics } from '../analytics';
import { ErrorBoundary } from '../components/error-boundary';
import { LivePerson } from '../components/live-person';
import { MarketProvider } from '../components/market-provider';
import { ThemeProvider } from '../components/theme-provider';
import { supportsApplePay } from '../utils/apple-pay';
import { getBraze } from '../utils/braze';
import { buildConfig, supportsGooglePay } from '../utils/google-pay';
import { localStorage } from '../utils/local-storage';
import { logger } from '../utils/logger';
import { FeatureProvider } from '../components/feature-provider';

const dependencies = {
  checkPlatformSpecificPaymentMethods: async ({ availableCreditCardTypesForGooglePay }) => {
    const config = buildConfig({ allowedCardNetworks: availableCreditCardTypesForGooglePay });
    const { result: googlePay } = await supportsGooglePay(config);
    const { result: applePay } = await supportsApplePay();

    return { googlePay, applePay, paypal: true };
  },
  platform: 'Web',
  Auth,
  getBraze,
  getApiClient,
  logger,
  localStorage,
  analytics,
  cmsContext: {
    siteId: process.env.cms.siteId || '1',
  },
  defaultMenuRestaurantId: process.env.menu.defaultRestaurantId,
};

const Store = RootStore.create(initialState, dependencies);
const { MemberStore, OfferStore, BrazeStore, DietaryPreferencesStore } = Store;

onSnapshot(DietaryPreferencesStore, dietaryPreferencesStoreSnapshot => {
  localStorage.setItem(SAVED_DIETARY_PREFERENCES_KEY, dietaryPreferencesStoreSnapshot);
});

if (process.env.APP_ENV !== 'production') {
  // eslint-disable-next-line global-require, import/no-extraneous-dependencies
  const makeInspectable = require('mobx-devtools-mst').default;
  makeInspectable(Store);
}

const queryClient = new QueryClient();

const NandosApp = ({ Component, pageProps }) => {
  const router = useRouter();
  const { featureFlags: serverSideFeatureFlags } = pageProps;

  useServiceWorker();

  useEffect(() => {
    const handleRouteChange = url => {
      analytics.page(url);
    };

    handleRouteChange();

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (process.env.web.environment !== 'production') {
      // eslint-disable-next-line no-underscore-dangle
      window.__NANDOS_APP_STATE__ = Store;
    }

    Hub.listen('auth', ({ payload: { event /* data */ } }) => {
      switch (event) {
        case 'signOut':
          Store.clearSessionData();
          analytics.reset();
          BrazeStore.clearSessionData();
          break;
        case 'signIn':
          Auth.currentAuthenticatedUser().then(() => {
            MemberStore.loadProfile().then(() => {
              analytics.track(ANALYTICS_EVENTS.USER_SIGNED_IN, {
                memberNumber: MemberStore.profile?.memberNumber,
                email: MemberStore.profile?.email,
                userId: MemberStore.profile?.encryptedMemberId,
              });

              analytics.identify(
                MemberStore.profile?.encryptedMemberId,
                {
                  memberNumber: MemberStore.profile?.memberNumber,
                  email: MemberStore.profile?.email,
                },
                {
                  plugins: {
                    // don't send to mparticle as it has a separate signIn implementation
                    mparticle: false,
                  },
                }
              );
            });
            OfferStore.loadOffers();
            OfferStore.loadActivatedPromoCodes();
            BrazeStore.loadContentCards();
          });
          break;
        default:
          break;
      }
    });

    Auth.currentAuthenticatedUser()
      .then(() => {
        MemberStore.loadProfile().then(() => {
          analytics.identify(MemberStore.profile?.encryptedMemberId, {
            memberNumber: MemberStore.profile?.memberNumber,
            email: MemberStore.profile?.email,
          });
        });
        OfferStore.loadOffers();
        OfferStore.loadActivatedPromoCodes();
        BrazeStore.loadContentCards();
      })
      .catch(error => logger.info('Auth', { location: '_app', error }));
  }, []);

  useEffect(() => {
    const writeSessionId = async () => {
      const groupSessionId = await localStorage.getItem(GROUP_SESSION_ID_KEY);
      if (!groupSessionId) {
        localStorage.setItem(GROUP_SESSION_ID_KEY, uuid());
      }
    };

    writeSessionId();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <QueryClientProvider client={queryClient}>
      <RootStoreContext.Provider value={Store}>
        <CartPersistence />
        <GroupCartPolling />
        <ThemeProvider>
          <FeatureProvider serverSideFeatureFlags={serverSideFeatureFlags}>
            <MarketProvider currentMarket={process.env.market}>
              <ErrorBoundary asPage>
                <Component {...pageProps} />
                <LivePerson />
              </ErrorBoundary>
            </MarketProvider>
          </FeatureProvider>
        </ThemeProvider>
      </RootStoreContext.Provider>
    </QueryClientProvider>
  );
};

NandosApp.propTypes = {
  Component: PropTypes.any.isRequired,
  pageProps: PropTypes.any.isRequired,
};

export default NandosApp;
