import { types } from 'mobx-state-tree';

const Alert = types.model('Alert', {
  body: types.string,
  dismissText: types.optional(types.string, 'Ok'),
  title: types.string,
  image: types.maybe(types.string),
});

export default Alert;
