import { types } from 'mobx-state-tree';

const initialState = {};

const ConnectivityStore = types
  .model('ConnectivityStore', {
    isConnected: true,
  })
  .actions(self => ({
    setIsConnected({ isConnected }) {
      self.isConnected = isConnected;
    },
  }))
  .views(self => ({
    get online() {
      return self.isConnected;
    },
    get offline() {
      return !self.isConnected;
    },
  }));

ConnectivityStore.initialState = initialState;

export default ConnectivityStore;
