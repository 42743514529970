import { BASTING_TYPES, RECOMMENDATION_ENGINE } from '@nandosaus/constants';
import { types } from 'mobx-state-tree';

const Group = types.model('Group', {
  kids: types.number,
  adults: types.number,
});

const RecommendationsInput = types.model('RecommendationsInput', {
  restaurantId: types.string,
  cartId: types.string,
  group: Group,
  mealType: types.enumeration(Object.values(RECOMMENDATION_ENGINE.mealTypes).map(({ value }) => value)),
  basting: types.enumeration(Object.values(BASTING_TYPES)),
});

export default RecommendationsInput;
