import { concat, filter, find, forEach, get, map, reduce, sum } from 'lodash';

import { kilojoulesFormatter, priceFormatter } from '../../util/formatter';

const maximumOptionLimitReachedForProductChoiceType = (builtProductChoices, productChoice) => {
  const productChoicesForType = filter(
    builtProductChoices,
    builtProductChoice => builtProductChoice.choiceType === productChoice.name
  );

  return productChoicesForType.length >= productChoice.maximumOptionLimit;
};

const buildChoicesFromProductForOrderItem = (orderItem, productForOrderItem, logger) => {
  if (!productForOrderItem.isDetailedProduct) {
    return [];
  }

  const productChoices = [];
  forEach(orderItem.choices, orderItemChoice => {
    let productChoiceFound = false;

    forEach(productForOrderItem.choices, productChoice => {
      const optionMatchingChoice = find(
        productChoice.options,
        productOption => productOption.plu === orderItemChoice.plu
      );

      if (optionMatchingChoice && !maximumOptionLimitReachedForProductChoiceType(productChoices, productChoice)) {
        const { name, id, plu, prices } = optionMatchingChoice;
        productChoices.push({
          name,
          id,
          plu,
          quantity: orderItemChoice.quantity,
          choiceType: productChoice.name,
          maximumOptionLimit: productChoice.maximumOptionLimit,
          prices,
        });
        productChoiceFound = true;
        return false;
      }

      return null;
    });

    if (!productChoiceFound) {
      logger.info(
        `Order history choice ${orderItemChoice.plu} (${orderItemChoice.name}) not found in options for product ${productForOrderItem.id} (${productForOrderItem.name})`
      );
    }
  });

  return productChoices;
};

const formatOrderItems = (orderItems, MenuStore, logger) =>
  map(orderItems, orderItem => {
    const productForOrderItem = MenuStore.getProductByPartialId(orderItem.productId);
    if (!productForOrderItem) {
      logger.info(
        `Unable to find menu product for orderItem ${orderItem.productId} (${orderItem.productName}), building order history from redcat data`
      );
      const extraAmountForSelections = sum(map(orderItem.choices, ({ price }) => price));
      return {
        nutrition: false,
        product: { name: orderItem.productName },
        productChoices: map(orderItem.choices, ({ name, quantity }) => ({ name, quantity })),
        productQuantity: orderItem.quantity,
        price: priceFormatter((extraAmountForSelections + orderItem.price) * 100 * orderItem.quantity),
      };
    }

    const productChoices = buildChoicesFromProductForOrderItem(orderItem, productForOrderItem, logger);

    const extraCentsForSelections = sum(map(productChoices, ({ prices }) => prices.cents));

    const nutrition = get(
      MenuStore.getProductNutrition([productForOrderItem.plu, ...map(productChoices, 'plu')]),
      '[0].nutrition',
      false
    );

    const kilojoules = get(nutrition, 'Energy (kJ)');

    return {
      product: productForOrderItem,
      formattedKilojoules: kilojoulesFormatter(kilojoules),
      productChoices,
      productQuantity: orderItem.quantity,
      price: priceFormatter((extraCentsForSelections + productForOrderItem.prices.cents) * orderItem.quantity),
    };
  });

const formatChoicesForCart = choicesForProduct =>
  reduce(
    choicesForProduct,
    (accumulator, value) => {
      if (!value.id) {
        return accumulator;
      }

      const choicesForType = accumulator[value.choiceType] || [];
      return {
        ...accumulator,
        [value.choiceType]: concat(choicesForType, { option: value.id, quantity: value.quantity }),
      };
    },
    {}
  );

export {
  maximumOptionLimitReachedForProductChoiceType,
  buildChoicesFromProductForOrderItem,
  formatOrderItems,
  formatChoicesForCart,
};
