import { assign, compact, get, groupBy, lowerCase, map, mapKeys, mapValues } from 'lodash';
import z from 'zod';
import {
  appBrazeContentCardLogClick,
  appBrazeContentCardLogImpression,
  brazeContentCardLogClick,
  brazeContentCardLogImpression,
} from './helpers';

import { CONTENT_CARD_MAPPINGS_AND_SCHEMAS } from './schemas';

const convertToZodSchema = mappingAndSchema => {
  const fieldSchemas = mapValues(mappingAndSchema, o => o.schema);

  return z.object(fieldSchemas);
};

const getExtraActions = ({ isWeb, braze, card }) => {
  if (isWeb) {
    return {
      brazeLogClick: () => brazeContentCardLogClick({ braze, card }),
      brazeLogImpression: () => brazeContentCardLogImpression({ braze, card }),
    };
  }

  return {
    brazeLogClick: () => appBrazeContentCardLogClick({ braze, card }),
    brazeLogImpression: () => appBrazeContentCardLogImpression({ braze, card }),
  };
};

const parseCards = ({ cards, logger, braze, isWeb }) => {
  const parsedCards = map(cards, card => {
    const mappingAndSchema = CONTENT_CARD_MAPPINGS_AND_SCHEMAS[card?.extras?.type];
    if (!mappingAndSchema) {
      logger.error(`Failed to validate card ${card?.extras?.type} with error: no mapping found`);
      return null;
    }

    const schema = convertToZodSchema(mappingAndSchema);

    const parsedExtrasResult = schema.safeParse(card.extras);
    if (!parsedExtrasResult.success) {
      logger.error(
        `Failed to validate card ${card?.extras?.type} with error: ${parsedExtrasResult.error}`,
        card.extras
      );
      return null;
    }

    const extraActions = getExtraActions({ isWeb, braze, card });

    return {
      ...card,
      extras: {
        ...extraActions,
        ...card.extras,
        ...parsedExtrasResult.data,
      },
    };
  });

  const successfullyParsedCards = compact(parsedCards);

  return successfullyParsedCards;
};

const mapKeysAndAugmentCmsCards = cards => {
  const formattedCards = map(cards, card => {
    const extras = mapKeys(card.extras, (value, key) =>
      get(CONTENT_CARD_MAPPINGS_AND_SCHEMAS, [card.extras.type, key, 'mapping'], key)
    );
    if (extras.button && extras.buttonLink) {
      // To match the CMS data structure
      assign(extras, {
        button: [
          {
            buttonText: extras.button,
            link: [extras.buttonLink],
          },
        ],
      });
    }
    if (extras.image) {
      assign(extras, {
        image: [
          {
            url: extras.image,
            lo: extras.image,
            hi: extras.image,
          },
        ],
      });
    }
    if (extras['internal-app-link']) {
      assign(extras, {
        internalLink: [
          {
            uri: lowerCase(extras['internal-app-link']),
          },
        ],
      });
    }
    assign(card, {
      extras,
    });
    return card;
  });
  return formattedCards;
};

const mapBrazeCardsToAppContent = ({ cards, logger, braze, isWeb }) => {
  const parsedCards = parseCards({ cards, logger, braze, isWeb });
  const formattedCards = mapKeysAndAugmentCmsCards(parsedCards);
  const cardsGroupedByType = groupBy(formattedCards, 'extras.type');

  return cardsGroupedByType;
};

export { mapBrazeCardsToAppContent, parseCards, mapKeysAndAugmentCmsCards };
