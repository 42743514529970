import { concat, filter, find, get, includes, intersection, intersectionWith, map, some, sortBy, uniq } from 'lodash';
import { PRODUCT_EXISTS_IN_CART_KEY } from '@nandosaus/constants';

import { priceFormatter } from '../../util/formatter';

const getAllProductExtraOptionPLUs = orderItem => uniq(map(get(orderItem, 'product.extraChoice.options'), 'plu'));

const getOrderItemsWithChoices = (orderItems, choicePLUs) =>
  filter(orderItems, orderItem => {
    const productOptionPLUs = getAllProductExtraOptionPLUs(orderItem);
    return some(intersection(choicePLUs, productOptionPLUs));
  });

const doesExtraExistInCartForOrderItem = ({ orderItem, offerFreeExtraPLU }) => {
  const productInCart = orderItem.product;
  const productExtraChoice = productInCart.extraChoice;
  const productExtraChoiceName = productExtraChoice.name;
  const productExtraPLUsInCart = map(orderItem.choices.get(productExtraChoiceName), 'plu');

  const productOptionsInCart = intersectionWith(
    productExtraChoice.options,
    productExtraPLUsInCart,
    (extraOption, extraPLU) => extraOption.plu === extraPLU
  );

  const productOptionPLUsInCart = map(productOptionsInCart, 'plu');
  return includes(productOptionPLUsInCart, offerFreeExtraPLU);
};

const getOrderItemsWithSomeOfferExtrasInCart = (offerChoicesPLUs, cart) => {
  const orderItemsForOfferFreeExtra = getOrderItemsWithChoices(cart.orderItems, offerChoicesPLUs);

  const orderItemsWithSomeOfferExtrasInCart = filter(orderItemsForOfferFreeExtra, orderItem =>
    some(offerChoicesPLUs, offerFreeExtraPLU => doesExtraExistInCartForOrderItem({ orderItem, offerFreeExtraPLU }))
  );

  return orderItemsWithSomeOfferExtrasInCart;
};

const getExtrasNotInCartForOfferAndOrderItem = (offerChoicesPLUs, cart, selectedOrderItemId, offerAmount) => {
  if (!selectedOrderItemId) {
    return [];
  }

  const selectedOrderItem = find(cart.orderItems, { id: selectedOrderItemId });
  const productExtraOptions = get(selectedOrderItem, 'product.extraChoice.options');
  const productOptionsInOffer = filter(productExtraOptions, productOption => {
    const offerSupportsProductOption = includes(offerChoicesPLUs, productOption.plu);
    const extraIsAlreadyInCartForOrderItem = doesExtraExistInCartForOrderItem({
      orderItem: selectedOrderItem,
      offerFreeExtraPLU: productOption.plu,
    });
    return offerSupportsProductOption && !extraIsAlreadyInCartForOrderItem;
  });

  const extras = map(productOptionsInOffer, choice => ({
    label: choice.name,
    value: choice.plu,
    text: `+ ${priceFormatter(offerAmount * 100)}`,
  }));
  return sortBy(extras, 'label');
};

const getProductsForExtraOffer = (offerChoicesPLUs, cart) => {
  const orderItemsForOfferFreeExtra = getOrderItemsWithChoices(cart.orderItems, offerChoicesPLUs);

  const orderItemsWithSomeOfferExtrasInCart = getOrderItemsWithSomeOfferExtrasInCart(offerChoicesPLUs, cart);

  const orderItemsThatHaveAddableExtras = filter(orderItemsForOfferFreeExtra, orderItem =>
    some(getExtrasNotInCartForOfferAndOrderItem(offerChoicesPLUs, cart, orderItem.id))
  );

  let productsApplicableToOffer = map(orderItemsThatHaveAddableExtras, orderItem => {
    let label = orderItem.product.name;

    const orderItemAlreadyHasApplicableExtra = includes(orderItemsWithSomeOfferExtrasInCart, orderItem);
    if (orderItemAlreadyHasApplicableExtra) {
      label = `Add another extra to ${orderItem.product.name}`;
    }

    return { label, value: orderItem.id, image: orderItem.product.image };
  });

  const cartIsAlreadyValidForOffer = some(orderItemsWithSomeOfferExtrasInCart);
  if (cartIsAlreadyValidForOffer) {
    productsApplicableToOffer = concat(
      { label: 'Apply offer to cheapest extra already in order', value: PRODUCT_EXISTS_IN_CART_KEY },
      productsApplicableToOffer
    );
  }

  return productsApplicableToOffer;
};

export { getProductsForExtraOffer, getExtrasNotInCartForOfferAndOrderItem, getOrderItemsWithSomeOfferExtrasInCart };
