import { PRODUCT_MODIFIERS } from '@nandosaus/constants';

const { ITEM, MEAL } = PRODUCT_MODIFIERS;

const mealRegex = new RegExp(`^${MEAL}$`, 'i');
const itemRegex = new RegExp(`^${ITEM}$`, 'i');

const isMealModifier = modifier => mealRegex.test(modifier);

const isItemModifier = modifier => itemRegex.test(modifier);

export { isMealModifier, isItemModifier };
