import styled, { css } from 'styled-components';

import { bodySize, breakpoints } from '../../constants';
import { coreStyles } from './font-styles';

const Body1 = css`
  font-size: ${bodySize.L3M2S1};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${bodySize.L2M1Sx};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${bodySize.L1MxSx};
  }
`;

const Body2 = css`
  font-size: ${bodySize.LxM3S2};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${bodySize.L3M2S1};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${bodySize.L2M1Sx};
  }
`;

const Body3 = css`
  font-size: ${bodySize.LxM3S2};

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${bodySize.L3M2S1};
  }
`;

const Body4 = css`
  font-size: ${bodySize.LxMxS3};

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${bodySize.LxM3S2};
  }
`;

const Body5 = css`
  font-size: ${bodySize.LxMxS3};
`;

const pStyles = variant => css`
  ${coreStyles}
  ${() => {
    switch (variant) {
      default:
      case 1:
        return Body1;
      case 2:
        return Body2;
      case 3:
        return Body3;
      case 4:
        return Body4;
      case 5:
        return Body5;
    }
  }};
`;

const P = styled.p`
  ${props => pStyles(props.variant)};
`;

export { P, pStyles };
