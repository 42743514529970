import gql from 'graphql-tag';

import { getApiClient } from '../../../lib/api-client';
import { logger } from '../logger';

const VALIDATE_APPLE_PAY_SESSION = gql`
  query validateApplePaySession($validationUrl: String!) {
    validateApplePaySession(validationUrl: $validationUrl) {
      epochTimestamp
      expiresAt
      merchantSessionIdentifier
      nonce
      merchantIdentifier
      domainName
      displayName
      signature
      operationalAnalyticsIdentifier
    }
  }
`;

const validateApplePaySession = async validationUrl => {
  try {
    const client = await getApiClient();
    const { data } = await client.query({
      query: VALIDATE_APPLE_PAY_SESSION,
      variables: { validationUrl },
    });

    return data.validateApplePaySession;
  } catch (error) {
    logger.warn('Error validating apple pay session', { error });
    return null;
  }
};

export { validateApplePaySession };
