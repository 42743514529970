import { types } from 'mobx-state-tree';

import { priceFormatter } from '../../util/formatter';

const Price = types
  .model('Price', {
    price: 0,
  })
  .views(self => ({
    get formattedPrice() {
      return priceFormatter(self.price);
    },
  }));

export default Price;
