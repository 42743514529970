import { colors, OFFER_TYPES, OFFER_TYPES_LABELS, PRODUCT_EXISTS_IN_CART_KEY } from '@nandosaus/constants';
import { compact, flatMap, get, includes, intersection, isEmpty, map, some, uniq } from 'lodash';
import { getRoot, types } from 'mobx-state-tree';
import moment from 'moment';

import { formatMonthDayDate } from '../../util/time';
import {
  getExtrasNotInCartForOfferAndOrderItem,
  getOrderItemsWithSomeOfferExtrasInCart,
  getProductsForExtraOffer,
} from './utils';

const Offer = types
  .model('Offer', {
    barcode: '',
    description: '',
    discountAmount: 0,
    discountLimit: 0,
    discountType: types.optional(types.maybeNull(types.string), ''),
    discountUsage: 0,
    expiryDate: '',
    hash: '',
    id: types.identifier,
    image: types.optional(types.maybeNull(types.string), ''),
    startDate: '',
    longDescription: types.optional(types.maybeNull(types.string), ''),
    minOrderValue: types.maybeNull(types.number),
    minimumOrderSpend: 0,
    name: '',
    // @NOTE: for products, the Product may not always have been loaded.
    plus: types.optional(types.maybeNull(types.array(types.string)), []),
    restaurantIds: types.optional(types.maybeNull(types.array(types.string)), []),
    type: 0,
    isPromoCode: types.maybeNull(types.boolean),
  })
  .views(self => ({
    get formattedExpiryDate() {
      return formatMonthDayDate(self.expiryDate);
    },
    get formattedStartDate() {
      const date = moment(self.startDate);
      return date.format('Mo MMM YYYY');
    },
    get isAvailableInRestaurant() {
      // eslint-disable-next-line no-bitwise
      return !!(self.type & OFFER_TYPES.RESTAURANT);
    },
    get isAvailableOnline() {
      // eslint-disable-next-line no-bitwise
      return !!(self.type & OFFER_TYPES.ONLINE);
    },
    get colors() {
      const { isAvailableInRestaurant, isAvailableOnline } = self;

      if (isAvailableOnline && !isAvailableInRestaurant) {
        return colors.offerOnline;
      }
      if (isAvailableInRestaurant && !isAvailableOnline) {
        return colors.offerInRestaurant;
      }
      return colors.offerBoth;
    },
    get formattedLabel() {
      const { isAvailableInRestaurant, isAvailableOnline } = self;

      if (!isAvailableInRestaurant && isAvailableOnline) {
        return OFFER_TYPES_LABELS[OFFER_TYPES.ONLINE];
      }
      if (isAvailableInRestaurant && !isAvailableOnline) {
        return OFFER_TYPES_LABELS[OFFER_TYPES.RESTAURANT];
      }
      return OFFER_TYPES_LABELS[OFFER_TYPES.BOTH];
    },
    get productOfferChoices() {
      const { MenuStore } = getRoot(self);
      return compact(map(self.plus, plu => MenuStore.getProductByPartialId(plu)));
    },
    get isMealProductOffer() {
      return some(self.productOfferChoices, 'isMealProduct');
    },
    get cartIsValidForOffer() {
      const { CartStore } = getRoot(self);
      const cartPLUs = map(CartStore.orderItems, ({ product }) => product.id);
      const offerMenuPLUs = map(self.productOfferChoices, ({ id }) => id);
      const cartHasOfferProductPLU = some(cartPLUs, cartPLU => includes(offerMenuPLUs, cartPLU));

      const cartHasOfferExtraPLU = some(getOrderItemsWithSomeOfferExtrasInCart(self.plus, CartStore));
      return cartHasOfferProductPLU || cartHasOfferExtraPLU;
    },
    get availableProductOfferChoicesLabels() {
      if (self.isAvailableInRestaurant && !self.isAvailableOnline) {
        return [];
      }

      const pluOptionsForOffer = map(self.productOfferChoices, product => ({
        label: product.name,
        value: product.id,
        image: product.image,
        disabled: !product.isAvailable,
      }));

      if (self.cartIsValidForOffer) {
        pluOptionsForOffer.unshift({ label: 'Apply offer to item in order', value: PRODUCT_EXISTS_IN_CART_KEY });
      }

      return pluOptionsForOffer;
    },

    get extrasInMenuApplicableForOffer() {
      const { MenuStore } = getRoot(self);

      const allMenuExtras = map(get(MenuStore, 'menu.products'), 'extraChoice');
      const allMenuExtraOptions = flatMap(allMenuExtras, 'options');
      const menuExtraOptionPLUs = uniq(map(allMenuExtraOptions, 'plu'));

      return intersection(menuExtraOptionPLUs, self.plus);
    },
    get isForExtras() {
      return !isEmpty(self.extrasInMenuApplicableForOffer);
    },
    get availableExtraOfferProductChoicesLabels() {
      if (self.isAvailableInRestaurant && !self.isAvailableOnline) {
        return [];
      }

      const { CartStore } = getRoot(self);
      return getProductsForExtraOffer(self.plus, CartStore);
    },
    getAvailableExtraOfferExtraChoicesLabels(selectedOrderItemId) {
      const { CartStore } = getRoot(self);
      return getExtrasNotInCartForOfferAndOrderItem(self.plus, CartStore, selectedOrderItemId, self.discountAmount);
    },
  }));

export default Offer;
