import { cloneDeep, get } from 'lodash';
import { types } from 'mobx-state-tree';

import CartItem from '../../models/perks-shop-cart-item';
import PerksShopFormDetails from '../../models/perks-shop-form-details';
import PerksShopProduct from '../../models/perks-shop-product';
import SelectedItem from '../../models/perks-shop-selected-item';

const initialState = {
  products: [],
  cartItems: [],
  cartItemsCount: 0,
  selectedProduct: null,
  isVisibleCart: false,
  formDetails: null,
};

const PerksShopStore = types
  .model('PerksShopStore', {
    products: types.optional(types.array(PerksShopProduct), []),
    cartItems: types.optional(types.array(CartItem), []),
    cartItemsCount: types.maybeNull(types.number),
    selectedItem: types.maybeNull(SelectedItem),
    isVisibleCart: types.boolean,
    formDetails: types.maybeNull(PerksShopFormDetails),
  })
  .actions(self => ({
    setProducts(products) {
      self.products = products;
    },

    addCartItem(productId, selectedVariant, quantity) {
      const item = { product: productId, selectedVariant, quantity };
      if (quantity > 0) {
        const itemIndex = self.cartItems.findIndex(
          cartItem => get(cartItem, 'selectedVariant.plu') === get(selectedVariant, 'plu')
        );
        if (itemIndex >= 0) {
          self.cartItems[itemIndex].quantity += quantity;
        } else {
          self.cartItems.push(item);
        }
        self.cartItemsCount = self.totalCartItems;
      }
    },

    clearCartItems() {
      self.cartItems.clear();
      self.cartItemsCount = 0;
    },

    removeCartItem(item) {
      const index = self.cartItems.findIndex(cartItem => cartItem.id === item.cartItem.id);
      self.cartItems.splice(index, 1);
      self.cartItemsCount = self.totalCartItems;
    },

    updateCartItem(selectedProduct, selectedVariant, quantity) {
      if (quantity > 0) {
        const updatedItem = { product: selectedProduct.product.id, selectedVariant, quantity };
        const index = self.cartItems.findIndex(item => item.id === selectedProduct.cartItem.id);
        self.cartItems[index] = updatedItem;
      } else {
        self.removeItem(selectedProduct);
      }
      self.cartItemsCount = self.totalCartItems;
    },

    setSelectedItem(product) {
      self.selectedItem = cloneDeep(product);
    },

    clearSelectedItem() {
      self.selectedItem = null;
    },

    setIsVisibleCart(value) {
      self.isVisibleCart = value;
    },

    setFormDetails(details) {
      self.formDetails = { ...details };
    },
  }))
  .views(self => ({
    get totalCost() {
      return self.cartItems.reduce((acc, { product, quantity }) => acc + product.pointsPrice * quantity, 0);
    },

    getProduct(productId) {
      return self.products.find(({ id }) => id === productId);
    },

    get totalCartItems() {
      return self.cartItems.reduce((acc, { quantity }) => acc + quantity, 0);
    },

    productQuantityInCart(productId) {
      const quantityInCart = self.cartItems
        .filter(({ product }) => product.id === productId)
        .reduce((acc, { quantity }) => acc + quantity, 0);
      return quantityInCart;
    },

    get totalCostFormatted() {
      return self.totalCost.toString();
    },
  }));

PerksShopStore.initialState = initialState;

export default PerksShopStore;
