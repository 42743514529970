import Alert from './models/alert';

/**
 * Result represents the result of doing an action that may fail.
 *
 * It exists so you can use potentially failed values as though they were real,
 * which allows us to move error handling logic to where the value is actually used.
 *
 * An OK Result (one that succeeded) can have a value, or it can be undefined.
 *
 * A FAIL Result's `reason` should never be shown to a user. Instead set the `title` and `detail` fields.
 *
 * @example
 * // Assume something went really well!
 * const winnings = "$10,000,000";
 * return Result.ok(winnings);
 *
 * @example
 * // Assume something didn't go so well...
 * const reasonWhyTheWorldBurned = "All the carrots";
 * return Result.fail(reasonWhyTheWorldBurned, {
 *   title: "You had a horrible error",
 *   detail: "Something bad happened. It happens, sorry."
 * });
 *
 * @example
 * // You probably don't want to do this exactly like this, see the next example
 * const result = doSomethingMaybeFail(...);
 * if (result.isOk) {
 *   return result.unwrap();
 * }
 *
 * if (!result.isOk) {
 *   const alert = result.toAlert();
 *   AlertsStore.add(alert);
 * }
 *
 * @example
 * const result = doSomethingMaybeFail(...);
 *
 * if (result.isOk) {
 *   return result.unwrap();
 * }
 *
 * // Fire off a side effect to handle a potential error
 * result.whenFail((_, result) => {
 *   AlertsStore.add(result.toAlert())
 * });
 */
export default class Result {
  constructor({ value, reason, title, detail }) {
    this.value = value;
    this.reason = reason;
    this.title = title;
    this.detail = detail;
  }

  static ok(value) {
    return new Result({ value });
  }

  static fail(reason, { title, detail } = {}) {
    if (reason === undefined) {
      throw new Error('Cannot create a failed Result without a reason!');
    }

    return new Result({ reason, title, detail });
  }

  clone() {
    return new Result({
      value: this.value,
      reason: this.reason,
      title: this.title,
      detail: this.detail,
    });
  }

  get isOk() {
    return this.reason === undefined;
  }

  /**
   * Alter the wrapped value of a Result, depending on what it is.
   * Takes in two functions which accept an underlying value and return the same type.
   * As in, for a Result<T>, the two arguments are:
   * Positive case:        (value: T) -> Result<V>
   * Negative case:        (reason: string, { title: string, detail: string }) -> Result
   *
   * @example
   * // Define fallback values in case a Result goes wrong
   * const gender = askUser("GenderForm");
   * return formGender.chain(
   *  (value)  => Result.ok(value),
   *  ()       => Result.ok(GENDER.UNSPECIFIED),
   * ).unwrap();
   *
   * @example
   * // Safely apply modifications to a value that may not exist
   * const oneMoreMiniPita = () => {
   *   const pitasResult = CartStore.getItemByName("CGMP").count;
   *
   *   const newPitas = pitasResult.chain(
   *     (value) => Result.ok(value + 1),
   *   );
   * }
   *
   * @example
   * // Fire off a side-effect to handle an error
   * getCartItems().chain(
   *   undefined,
   *   (reason) => AlertsStore.add(reason),
   * );
   *
   * // Note that this is just longhand for
   * getCartItems().whenFail(reason => AlertsStore.add(reason));
   */

  chain(okCase = value => Result.ok(value), failCase = reason => Result.fail(reason)) {
    if (this.isOk) {
      const okValue = okCase(this.value, this);

      if (okValue instanceof Result) {
        return okValue;
      }

      return Result.ok(okValue);
    }

    const failValue = failCase(this.reason, this);

    if (failValue === undefined) {
      return this.clone();
    }

    if (failValue instanceof Result) {
      return failValue;
    }

    return Result.fail(failValue);
  }

  /**
   * Attempt to unwrap the value of the Result.
   * If Result is ok, this will just return the value.
   * If Result is an error, this will throw the underlying error.
   *
   * @example
   * const result = Result.ok('Banana');
   * const favoriteFruit = result.unwrap(); // 'Banana'
   *
   * @example
   * try {
   *   return undefined.undefined.undefined;
   * } catch(error) {
   *   const result = Result.fail(error);
   *   // Uncaught TypeError: Cannot read properties of undefined (reading 'undefined')
   *   result.unwrap();
   * }
   *
   */
  unwrap() {
    if (this.isOk) {
      return this.value;
    }

    throw new Error(this.reason);
  }

  /**
   * Run a callback only for OK Results, otherwise pass through errors as usual.
   * This is a shorthand method for Result.chain(callback, undefined).
   *
   * @example
   * // Apply a transformation to a value, otherwise just pass through errors
   * const orderType = getRedCatSalesType().whenOk(saleType => SALES_TYPES[saleType]);
   */
  whenOk(callback) {
    return this.chain(callback, undefined);
  }

  /**
   * Run a callback only for failed Results, otherwise pass through the value as usual.
   * This is a shorthand method for Result.chain(undefined, callback).
   *
   * @example
   * // Provide a default value for something which otherwise fails
   * const gender = getUserGender()
   *   .whenFail(() => Result.ok(GENDERS.UNSPECIFIED))
   *   .unwrap();
   */
  whenFail(callback) {
    return this.chain(undefined, callback);
  }

  /**
   * Creates a new Alert object from the title and detail of a failed Result.
   *
   * NOTE: This method does NOT provide defaults for the title or detail field, you will need to provide them manually.
   */
  toAlert() {
    return Alert.create({
      title: this.title,
      body: this.detail,
    });
  }
}
