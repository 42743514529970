import { flexbox, layout, position, space, system } from 'styled-system';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import StyledSystemPropTypes from '@styled-system/prop-types';

import { Amex } from './amex';
import { ApplePay } from './apple-pay';
import { Barci } from './barci';
import { Barcode } from './barcode';
import { Bell } from './bell';
import { Card } from './card';
import { Chevron } from './chevron';
import { ChevronLeft } from './chevron-left';
import { ChevronRight } from './chevron-right';
import { Clock } from './clock';
import { Coupon } from './coupon';
import { Cross } from './cross';
import { Cutlery } from './cutlery';
import { Delivery } from './delivery';
import { DigitalWallet } from './digital-wallet';
import { Document } from './document';
import { Error } from './error';
import { ExternalLink } from './external-link';
import { Facebook } from './facebook';
import { Favourites } from './favourites';
import { Filter } from './filter';
import { Flame } from './flame';
import { FlameResponsiveIcon } from './flame-responsive';
import { FlameSolid } from './flame-solid';
import { GiftCard } from './gift-card';
import { GoogleLogo } from './google-logo';
import { Hamburger } from './hamburger';
import { Heart } from './heart';
import { HeartOutline } from './heart-outline';
import { Home } from './home';
import { Info } from './info';
import { Instagram } from './instagram';
import { LeftArrow } from './left-arrow';
import { Lifebuoy } from './lifebuoy';
import { LocationArrow } from './location-arrow';
import { LocationMarker } from './location-marker';
import { Lock } from './lock';
import { logger } from '../../utils/logger';
import { MarkerWithLine } from './marker-with-line';
import { Mastercard } from './mastercard';
import { MastercardWithBorder } from './mastercard-with-border';
import { MenuBlue } from './menu-blue';
import { MenuGreen } from './menu-green';
import { MenuOrange } from './menu-orange';
import { MenuPink } from './menu-pink';
import { MenuRed } from './menu-red';
import { MenuYellow } from './menu-yellow';
import { Minus } from './minus';
import { NoDairy } from './no-dairy';
import { NoGluten } from './no-gluten';
import { PaypalLogo } from './paypal-logo';
import { PeriPerksLogo } from './peri-perks-logo';
import { PerksShop } from './perks-shop';
import { Phone } from './phone';
import { PlayButton } from './play-button';
import { Plus } from './plus';
import { Receipt } from './receipt';
import { RightArrow } from './right-arrow';
import { Schedule } from './schedule';
import { Search } from './search';
import { Share } from './share';
import { ShoppingBag } from './shopping-bag';
import { Statement } from './statement';
import { Tick } from './tick';
import { TickCircle } from './tick-circle';
import { TickRound } from './tick-round';
import { Triangle } from './triangle';
import { Twitter } from './twitter';
import { User } from './user';
import { Vegan } from './vegan';
import { Vegetarian } from './vegetarian';
import { Visa } from './visa';
import { VisaWithBorder } from './visa-with-border';
import { Warning } from './warning';
import { Users } from './users';
import { Trash } from './trash';

const icons = {
  amex: Amex,
  applePay: ApplePay,
  barci: Barci,
  barcode: Barcode,
  bell: Bell,
  card: Card,
  chevron: Chevron,
  chevronRight: ChevronRight,
  chevronLeft: ChevronLeft,
  clock: Clock,
  coupon: Coupon,
  cross: Cross,
  cutlery: Cutlery,
  delivery: Delivery,
  digitalWallet: DigitalWallet,
  document: Document,
  error: Error,
  externalLink: ExternalLink,
  facebook: Facebook,
  favourites: Favourites,
  filter: Filter,
  flame: Flame,
  flameResponsive: FlameResponsiveIcon,
  flameSolid: FlameSolid,
  giftCard: GiftCard,
  googleLogo: GoogleLogo,
  hamburger: Hamburger,
  heart: Heart,
  heartOutline: HeartOutline,
  home: Home,
  info: Info,
  instagram: Instagram,
  leftArrow: LeftArrow,
  lifebuoy: Lifebuoy,
  locationArrow: LocationArrow,
  locationMarker: LocationMarker,
  lock: Lock,
  markerWithLine: MarkerWithLine,
  mc: Mastercard,
  mcWithBorder: MastercardWithBorder,
  menuBlue: MenuBlue,
  menuGreen: MenuGreen,
  menuOrange: MenuOrange,
  menuPink: MenuPink,
  menuRed: MenuRed,
  menuYellow: MenuYellow,
  minus: Minus,
  noDairy: NoDairy,
  noGluten: NoGluten,
  paypal: PaypalLogo,
  periPerksLogo: PeriPerksLogo,
  perksShop: PerksShop,
  phone: Phone,
  playButton: PlayButton,
  plus: Plus,
  receipt: Receipt,
  rightArrow: RightArrow,
  schedule: Schedule,
  search: Search,
  share: Share,
  shoppingBag: ShoppingBag,
  statement: Statement,
  tick: Tick,
  tickCircle: TickCircle,
  tickRound: TickRound,
  trash: Trash,
  triangle: Triangle,
  twitter: Twitter,
  user: User,
  vegan: Vegan,
  vegetarian: Vegetarian,
  visa: Visa,
  visaWithBorder: VisaWithBorder,
  warning: Warning,
  users: Users,
};

const iconNames = Object.keys(icons);

const Icon = ({ className, name, stroke, fill, ariaLabel, ...props }) => {
  const Component = icons[name];
  const width = '24px';
  const height = '24px';

  if (!Component) {
    logger.info('Missing icon', { name });
    return null;
  }

  return (
    <Component
      className={className}
      data-testid={name}
      aria-label={ariaLabel ?? name}
      stroke={stroke}
      fill={fill}
      {...props}
      width={width}
      height={height}
    />
  );
};

const StyledIcon = styled(Icon).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => !['maxHeight'].includes(prop) && defaultValidatorFn(prop),
})`
  ${flexbox}
  ${layout}
  ${position}
  ${space}
  ${system({
    opacity: true,
    stroke: {
      property: 'stroke',
      scale: 'colors',
    },
    fill: {
      property: 'fill',
      scale: 'colors',
    },
  })}
  ${({ rotate }) =>
    rotate &&
    css`
      transform: rotate(${rotate});
    `}
`;

Icon.propTypes = {
  className: PropTypes.string,
  fill: PropTypes.string,
  name: PropTypes.oneOf(iconNames).isRequired,
  stroke: PropTypes.string,
  ariaLabel: PropTypes.string,
  role: PropTypes.oneOf(['application', 'document', 'img', 'button']),
  ...StyledSystemPropTypes.space,
};

Icon.defaultProps = {
  className: undefined,
  fill: undefined,
  stroke: undefined,
  role: undefined,
  ariaLabel: undefined,
};

export { StyledIcon as Icon, iconNames };
