import { types } from 'mobx-state-tree';

import { dollarsFromCentsFormatter, priceFormatter } from '../../util/formatter';

const Prices = types
  .model('Prices', {
    cents: 0,
    points: 0,
  })
  .views(self => ({
    get dollarValue() {
      return dollarsFromCentsFormatter(self.cents);
    },
    get formattedPrice() {
      return priceFormatter(self.cents);
    },
    get formattedPoints() {
      return self.points.toLocaleString();
    },

    /**
     * Get the result of subtracting one set of prices from another.
     *
     * @param {Prices} prices
     */
    minus(prices) {
      return Prices.create({
        cents: self.cents - prices.cents,
        points: self.points - prices.points,
      });
    },

    /**
     * Get the result of adding one set of prices to another.
     *
     * @param {Prices} prices
     */
    plus(prices) {
      return Prices.create({
        cents: self.cents + prices.cents,
        points: self.points + prices.points,
      });
    },
  }));

export default Prices;
