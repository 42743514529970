import { useCallback, useEffect } from 'react';
import { onSnapshot } from 'mobx-state-tree';
import { isObject } from 'lodash';

import { useRootStore } from '@nandosaus/state-management';
import { PREVIOUS_CART_KEY } from '@nandosaus/constants';
import { localStorage } from '@utils/local-storage';

const setupCartPersistence = cartStore => {
  return onSnapshot(cartStore, cartStoreSnapshot => {
    if (cartStoreSnapshot.loading || cartStoreSnapshot.submitted) {
      return;
    }

    const now = new Date().getTime();
    const previousCart = {
      // When a page is reloaded, we want to forget the info about which items the user had deleted by clearing removedSubCarts
      value: { ...cartStoreSnapshot, removedSubCarts: {} },
      timestamp: now,
    };

    localStorage.setItem(PREVIOUS_CART_KEY, previousCart);
  });
};

const fetchPreviousCart = async () => localStorage.getItem(PREVIOUS_CART_KEY);

export const useCartPersistence = ({ attachListeners } = { attachListeners: false }) => {
  const { CartStore } = useRootStore();

  useEffect(() => {
    // We only want a single listener at a time for performance reasons. There are fancier ways of doing this but since
    // only the root page (_app.js) should attach the listeners, dumb simple is much easier
    if (!attachListeners) {
      return () => {};
    }

    const cleanup = setupCartPersistence(CartStore);

    return () => {
      cleanup();
    };
  }, [attachListeners, CartStore]);

  const loadPreviousCart = useCallback(async () => {
    const previousCartValues = await fetchPreviousCart();
    if (!isObject(previousCartValues)) {
      return {};
    }

    await CartStore.setPreviousCart(previousCartValues);
    return { orderType: CartStore.orderType || previousCartValues.value.orderType };
  }, [CartStore]);

  return { loadPreviousCart };
};

/** Helper component which calls the hook with listeners enabled. Only use once.
 * Used as this hook requires that the store context be present, thus needs to go in the render path
 */
export const CartPersistence = () => {
  useCartPersistence({ attachListeners: true });

  return null;
};
