import { createGlobalStyle } from 'styled-components';

import { bodySize, breakpoints, inputSize } from '../../constants';

// custom styling for any imported UI libraries
const UILibrariesStyles = createGlobalStyle`
  .rc-time-picker-panel-input-wrap {
    display: flex;
    padding: 0.2rem 0.5rem;
  }

  .rc-time-picker-panel *,
  .rc-time-picker-input{
    font-size: ${bodySize.L3M2S1};

    @media (min-width: ${breakpoints.md}) {
      font-size: ${bodySize.L2M1Sx};
    }

    @media (min-width: ${breakpoints.lg}) {
      font-size: ${bodySize.L1MxSx};
    }
  }

  .rc-time-picker-input,
  .rc-time-picker-panel-input-wrap {
    min-height: ${inputSize.sm};

    @media (min-width: ${breakpoints.md}) {
      min-height: ${inputSize.md};
    }

    @media (min-width: ${breakpoints.lg}) {
      min-height: ${inputSize.lg};
    }
  }

  .rc-time-picker-input {
    padding: 0.2rem 0.5rem;
    border-radius: 2px;
  }

  .MuiDrawer-paper {
    max-width: 100%;
  }
`;

export { UILibrariesStyles };
