import { variant } from 'styled-system';
import styled from 'styled-components';

import { coreStyles } from './font-styles';
import { labelSize } from '../../constants';

const labelVariants = variant({
  variants: {
    1: {
      fontSize: [labelSize.L3M2S1, null, null, labelSize.L2M1Sx, labelSize.L1MxSx],
      letterSpacing: ['0.6px', null, null, '0.65px', '0.8px'],
    },
    2: {
      fontSize: [labelSize.LxMxS3, null, null, labelSize.LxM3S2, labelSize.L3M2S1],
      letterSpacing: ['0.5px', null, null, '0.55px', '0.6px'],
    },
  },
});

const Label = styled.p`
  ${labelVariants};
  ${coreStyles};
`;

Label.defaultProps = {
  display: 'block',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  variant: 1,
};

export { Label };
