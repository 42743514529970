import { types } from 'mobx-state-tree';

const MenuPromotion = types.model('MenuPromotion', {
  enabled: types.boolean,
  name: types.string,
  description: types.string,
  plu: types.string,
});

export default MenuPromotion;
