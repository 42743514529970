import PropTypes from 'prop-types';
import React from 'react';

import { Flex } from '../grid';
import { Label } from '../typography';

const ButtonContent = ({ children, leftContent, leftText, rightText, disabled, labelProps }) => {
  return (
    <Flex as="span" justifyContent="space-between" width="100%" alignItems="center">
      {leftContent ||
        (leftText && (
          <Flex as="span" className="button-icon" flex={1}>
            {leftText && (
              <Label display="flex" alignItems="center" disabled={disabled}>
                {leftText}
              </Label>
            )}
            {leftContent}
          </Flex>
        ))}
      <Label as="span" display="flex" alignItems="center" justifyContent="center" width="100%" {...labelProps}>
        {children}
      </Label>
      {rightText && (
        <Flex as="span" flex={1} justifyContent="flex-end">
          <Label as="span" display="flex" alignItems="center" disabled={disabled}>
            {rightText}
          </Label>
        </Flex>
      )}
    </Flex>
  );
};

ButtonContent.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  leftContent: PropTypes.node,
  leftText: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rightText: PropTypes.string,
  labelProps: PropTypes.any,
};

ButtonContent.defaultProps = {
  leftContent: null,
  leftText: null,
  rightText: null,
  disabled: false,
  labelProps: null,
};

export { ButtonContent };
