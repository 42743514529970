const LeftArrow = props => (
  <svg viewBox="0 0 16 28" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Left Arrow</title>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.757658 16.2015L11.618 27.1788C12.0989 27.6689 12.7536 27.9446 13.4366 27.9446C14.1195 27.9446 14.7742 27.6689 15.2552 27.1788C16.2483 26.169 16.2483 24.5381 15.2552 23.5283L6.18782 14.3633L15.2552 5.19827C16.2483 4.18847 16.2483 2.5576 15.2552 1.5478C14.7718 1.06317 14.1174 0.793011 13.4366 0.796945C12.7557 0.79301 12.1014 1.06317 11.618 1.5478L0.757658 12.5251C0.272753 13.0112 1.22556e-06 13.673 1.16628e-06 14.3633C1.107e-06 15.0536 0.272753 15.7153 0.757658 16.2015Z"
    />
  </svg>
);

export { LeftArrow };
