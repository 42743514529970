import styled, { css } from 'styled-components';

import { breakpoints, subheadingSize } from '../../constants';
import { coreStyles } from './font-styles';

const Subheading1 = css`
  font-size: ${subheadingSize.L3M2S1};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${subheadingSize.L2M1Sx};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${subheadingSize.L1MxSx};
  }
`;

const Subheading2 = css`
  font-size: ${subheadingSize.LxM3S2};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${subheadingSize.L3M2S1};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${subheadingSize.L2M1Sx};
  }
`;

const Subheading3 = css`
  font-size: ${subheadingSize.LxMxS3};

  @media (min-width: ${breakpoints.md}) {
    font-size: ${subheadingSize.LxM3S2};
  }

  @media (min-width: ${breakpoints.lg}) {
    font-size: ${subheadingSize.L3M2S1};
  }
`;

const subheadingStyles = variant => css`
  ${coreStyles}
  font-weight: bold;
  ${() => {
    switch (variant) {
      default:
      case 1:
        return Subheading1;
      case 2:
        return Subheading2;
      case 3:
        return Subheading3;
    }
  }};
`;

const Subheading = styled.p`
  ${props => subheadingStyles(props.variant)};
`;

export { Subheading, subheadingStyles };
