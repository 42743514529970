import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { get } from 'lodash';
import { reaction } from 'mobx';
import gql from 'graphql-tag';

import Competition from '../../models/competition';

const COMPETITION_QUERY = gql`
  query getCompetition {
    competition {
      name
      handle
      startDate
      endDate
      entries
    }
  }
`;

const initialState = {
  loading: false,
  error: null,
  competition: null,
};

const getCompetition = async client => {
  const { data } = await client.query({ query: COMPETITION_QUERY });
  return data.competition;
};

const transformError = (error, defaultMessage) => {
  const err = get(error, 'errors[0]', {});
  err.message = err.message || defaultMessage;
  return err;
};

const CompetitionStore = types
  .model('CompetitionStore', {
    error: types.maybeNull(types.string),
    loading: false,
    competition: types.maybeNull(Competition),
  })
  .actions(self => {
    const { CartStore, MemberStore } = getRoot(self);
    reaction(
      () => MemberStore.isSignedIn,
      () => {
        self.loadCompetition();
      }
    );
    reaction(
      () => CartStore.submitted,
      () => {
        self.loadCompetition();
      }
    );

    const { getApiClient, logger } = getEnv(self);

    return {
      afterCreate: flow(function*() {
        yield self.loadCompetition();
      }),
      loadCompetition: flow(function*() {
        if (self.loading) {
          return;
        }

        self.error = null;
        self.loading = true;

        try {
          const client = yield getApiClient();
          const data = yield getCompetition(client);
          self.competition = data;
        } catch (error) {
          const err = transformError(error, 'There was an error fetching competition');
          logger.error('Error fetching competition', { error });
          self.error = err.message;
        }

        self.loading = false;
      }),
    };
  });

CompetitionStore.initialState = initialState;

export default CompetitionStore;
