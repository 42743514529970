import { types } from 'mobx-state-tree';
import Product from '../product';

const UpsellModal = types.model('UpsellModal', {
  heading: types.optional(types.string, ''),
  subheading: types.optional(types.string, ''),
  description: types.optional(types.string, ''),
  disclaimer: types.optional(types.string, ''),
  recommendedProducts: types.optional(types.array(types.safeReference(Product)), []),
  cta: types.optional(types.string, ''),
});

export default UpsellModal;
