import { getEnv, getPath } from 'mobx-state-tree';

const getDependencies = node => {
  const dependencies = getEnv(node);

  /**
   * Wraps the local storage dependency, namespacing its storage to
   * the specified MST node. If no local storage dependency is found
   * then a fallback one is returned in its place.
   */
  const wrapLocalStorage = localStorage => {
    if (localStorage === undefined) {
      return {
        getItem: () => Promise.resolve(),
        setItem: () => Promise.resolve(),
      };
    }

    const nodePath = getPath(node);
    const getKeyForNode = key => `${nodePath}/${key}`;

    return {
      getItem: key => localStorage.getItem(getKeyForNode(key)),
      setItem: (key, value) => localStorage.setItem(getKeyForNode(key), value),
    };
  };

  return {
    ...dependencies,
    localStorage: wrapLocalStorage(dependencies.localStorage),
  };
};

export { getDependencies };
