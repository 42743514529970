import {
  border,
  flexbox,
  layout,
  position,
  space,
  color as styledSystemColor,
  system,
  typography,
} from 'styled-system';
import { css } from 'styled-components';

import { fontWeight } from '../../constants/font-weight';
import { globalStyles } from '../../constants/global';

const textTransform = system({
  textTransform: true,
});

const textDecoration = system({
  textDecoration: true,
});

const whiteSpace = system({
  whiteSpace: true,
});

const transform = system({
  transform: true,
});

const coreStyles = css`
  color: ${({ color, theme }) => (color ? theme.colors[color] : '')};
  font-family: ${globalStyles.baseFontFamily};
  font-weight: ${fontWeight.normal};
  letter-spacing: normal;
  line-height: 1.3336;
  margin: 0;
  ${flexbox};
  ${layout};
  ${space};
  ${styledSystemColor};
  ${typography};
  ${position};
  ${border};
  ${textTransform};
  ${textDecoration};
  ${whiteSpace};
  ${transform};
`;

const headingStyles = css`
  ${coreStyles}
  font-family: ${globalStyles.brandFontFamily};
  line-height: 1.2;
  overflow-wrap: ${({ shouldWrapLongLines }) => (shouldWrapLongLines ? 'break-word' : undefined)};
`;

export { coreStyles, headingStyles };
