const yup = require('yup');

// New JSON Flag Template
// const newJsonFlag = Object.freeze({
//   launchDarklyKey: '',
//   yupSchema: yup.object({}).meta({
//     description: '',
//   }),
//   variants: [
//     {
//       description: '',
//       value: {},
//     },
//   ],
// });

// New Boolean Flag Template
// const newJsonFlag = Object.freeze({
//   launchDarklyKey: '',
//   yupSchema: yup.boolean().meta({
//     description: '',
//   }),
//   variants: [
//     {
//       description: 'off',
//       value: false,
//     },
//   ],
// });

/**
  This schema is to be used by other schemas when there is a need to target specific restaurants/entities.
 */
const allowAndDenySchema = yup.object({
  default: yup.boolean({ description: 'The default behavior for items not set in either allow/deny lists' }),
  allowList: yup.array(yup.string()).meta({ description: 'Items in this list will be assigned a truthy value' }),
  denyList: yup.array(yup.string()).meta({ description: 'Items in this list will be assigned a falsy value' }),
});

const variationSchema = yup
  .string()
  .nullable()
  .meta({
    description: "Use in case LD doesn't allow to save duplicate items. Example value: prod-variant, au-prod-variant",
  });

/**
  @type {(value: any, keySchema: yup.Schema, valueSchema: yup.Schema) => any}
 */
const mapRules = (value, valueSchema) => {
  return Object.keys(value).reduce((newMap, key) => ({ ...newMap, [key]: valueSchema }), {});
};

/**
  This schema is to be used by other schemas when there is a need to target specific restaurants/entities.

  This tries to emulate what zod achieves with `zod.record(keySchema, valueSchema)`

  TAGS: dynamic key schema, record schema

  Inspired by: https://github.com/jquense/yup/issues/130#issuecomment-1378428406

  @type {(keySchema: yup.Schema, valueSchema: yup.Schema) => ReturnType<yup.lazy>}
 */
const yupRecordSchema = valueSchema => yup.lazy(objectValue => yup.object(mapRules(objectValue, valueSchema)));

const integerStringSchema = yup.string().matches(/^[0-9]+$/, { message: 'Only numeric characters are allowed.' });
const restaurantIdSchema = yup
  .string()
  // explainer:
  // ^(?!^0) = negative look ahead to make sure it doesn't start with a 0
  // \d{1,3}$ matches digits up to 3 characters long, and must end in a digit
  .matches(/^(?!^0)\d{1,3}$/, { message: 'Restaurant ID must be a number between 1-999' });

// Copied over from yup 1.x.x repo
// https://github.com/jquense/yup/blob/5a22c16dbba610050e85f123d389ddacaa92a0ad/src/string.ts#L32C1-L37C3
const yearMonthDay = '^\\d{4}-\\d{2}-\\d{2}';
const hourMinuteSecond = '\\d{2}:\\d{2}:\\d{2}';
const zOrOffset = '(([+-]\\d{2}(:?\\d{2})?)|Z)';
const rIsoDateTime = new RegExp(`${yearMonthDay}T${hourMinuteSecond}(\\.\\d+)?${zOrOffset}$`);

module.exports = {
  allowAndDenySchema,
  integerStringSchema,
  restaurantIdSchema,
  rIsoDateTime,
  variationSchema,
  yupRecordSchema,
};
