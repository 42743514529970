import { border, color, flexbox, layout, position, space as styledSystemSpace, typography } from 'styled-system';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { breakpoints, grid } from '../../constants';

const StyledContainer = styled.div`
  ${styledSystemSpace}
  ${border}
  ${color}
  ${flexbox}
  ${layout}
  ${position}
  ${typography}

  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right:  1.5rem;
  width: 100%;

  @media (min-width: ${breakpoints.md}) {
    padding-left: 2rem;
    padding-right: 2rem;
  }
`;

const Container = ({ className, maxWidth, children, ...rest }) => (
  <StyledContainer className={className} maxWidth={maxWidth} {...rest}>
    {children}
  </StyledContainer>
);

Container.propTypes = {
  className: PropTypes.string,
  maxWidth: PropTypes.number,
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  className: null,
  maxWidth: grid.containerMaxWidth,
};

export { Container };
