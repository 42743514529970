import { useContext } from 'react';
import { isBoolean, isEmpty, isUndefined } from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
// eslint-disable-next-line import/no-extraneous-dependencies, no-unused-vars
import yup from 'yup'; // needed for type inference
// eslint-disable-next-line no-unused-vars
import { webFlagsData } from '@nandosaus/constants';

import { ServerSideFeatureFlagsContext } from '@components/feature-provider';
import { logger } from '../../utils/logger';
import { enableLogging } from './config';

/**
  @type {
   <Key extends string>(key: keyof webFlagsData | Key) =>
    Key extends keyof webFlagsData
      ? yup.InferType<(typeof webFlagsData)[Key]['yupSchema']>
      : unknown
  }
 */
const useFeature = key => {
  const serverSideFeatureFlags = useContext(ServerSideFeatureFlagsContext);
  const ldFlags = useFlags();

  const hasLaunchDarklyFlags = !isEmpty(ldFlags);
  const flags = hasLaunchDarklyFlags ? ldFlags : serverSideFeatureFlags;

  const flag = flags[key];

  if (isUndefined(flag)) {
    if (!isEmpty(flags) && enableLogging) {
      logger.warn('Feature flag is undefined', { key });
    }
    return false;
  }

  if (!isBoolean(flag) && isEmpty(flag)) {
    return false;
  }

  return flag;
};

export { useFeature };
