import { DATE_TIME_FORMATS } from '@nandosaus/constants';
import { get } from 'lodash';
import { getRoot, types } from 'mobx-state-tree';
import moment from 'moment';

import { pointsFormatter } from '../../util/formatter';
import Price from '../price';
import Restaurant from '../restaurant';
import TransactionItem from '../transaction-item';

const Transaction = types
  .compose(
    types.model('Transaction', {
      id: types.identifier,
      date: types.Date,
      description: types.string,
      points: types.number,
      restaurant: types.maybe(
        types.reference(Restaurant, {
          get(identifier, parent) {
            const { RestaurantStore } = getRoot(parent);

            return RestaurantStore.asArray.find(u => u.id === identifier.toString()) || undefined;
          },
          set(value) {
            return value.id;
          },
        })
      ),
      transactionItems: types.array(TransactionItem),
    }),
    Price
  )
  .views(self => ({
    get formattedDate() {
      return moment(self.date).format(`${DATE_TIME_FORMATS.DATE_STRING}, ${DATE_TIME_FORMATS.TIME_DEFAULT}`);
    },
    get formattedPoints() {
      return pointsFormatter(self.points);
    },
    get restaurantName() {
      return get(self, 'restaurant.name', '');
    },
  }));

export default Transaction;
