import React from 'react';

import { BaseStyles } from './base';
import { SpacingStyles } from './spacing';
import { TypographyStyles } from './typography';
import { UILibrariesStyles } from './ui-libraries';

const GlobalCSS = () => (
  <>
    <BaseStyles />
    <SpacingStyles />
    <UILibrariesStyles />
    <TypographyStyles />
  </>
);

export { GlobalCSS };
