import { applySnapshot, flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { get, indexOf, last, omit, values } from 'lodash';
import {
  ORDER_HELP_FLOW_STEPS,
  ORDER_HELP_FLOW_STEPS_BY_ISSUE_TYPE,
  ORDER_ISSUE_TYPES,
  ORDER_ITEM_ISSUES,
  REFUND_TYPES,
  SUPPORT_TICKET_FLOW,
} from '@nandosaus/constants';
import gql from 'graphql-tag';

import { augmentOrderItemsWithChoiceLabels, multiplyAndFlattenOrderRefundableChoices } from './transformers';
import PastOrder from '../../models/past-order';

const transformError = (error, defaultMessage) => {
  const err = get(error, 'errors[0]', {});
  err.message = err.message || defaultMessage;
  return err;
};

const VALIDATE_ORDER_REFUND_QUERY = gql`
  query validateOrderRefund($input: RefundOrderInput!) {
    validateOrderRefund(input: $input) {
      success
      refusalReasonCode
    }
  }
`;

const REFUND_ORDER_MUTATION = gql`
  mutation refundOrder($input: RefundOrderInput!) {
    refundOrder(input: $input) {
      refundValue
      refundValuePoints
      success
      refusalReasonCode
    }
  }
`;

const CREATE_TICKET_MUTATION = gql`
  mutation createTicket($input: CreateTicketInput!) {
    createTicket(input: $input) {
      success
      errorMessage
    }
  }
`;

const initialState = {
  loading: false,
  error: null,
  success: false,
  refusalReasonCode: null,
  currentStep: ORDER_HELP_FLOW_STEPS.SELECT_ISSUE,
  order: null,
  refundValue: null,
  refundValuePoints: null,
  orderIssue: null,
  orderItemUuids: null,
  orderItemIssue: null,
  notes: null,
  refundType: null,
  isSupportTicketFlow: false, // this breaks out of the chosen flow to submit a ticket, since order can't be found
  guestEmail: null,
  guestGivenName: null,
  guestFamilyName: null,
  guestMobile: null,
};

const OrderHelpStore = types.compose(
  types
    .model('OrderHelpStore', {
      loading: false,
      error: types.maybeNull(types.string),
      success: false,
      refusalReasonCode: types.maybeNull(types.string),
      currentStep: types.enumeration(values(ORDER_HELP_FLOW_STEPS)),
      order: types.maybeNull(types.reference(PastOrder)),
      orderIssue: types.maybeNull(types.enumeration(values(ORDER_ISSUE_TYPES))),
      orderItemUuids: types.maybeNull(types.array(types.string)),
      orderItemIssue: types.maybeNull(
        types.enumeration([...values(ORDER_ITEM_ISSUES.QUALITY), ...values(ORDER_ITEM_ISSUES.DELIVERY)])
      ),
      notes: types.maybeNull(types.string),
      refundType: types.maybeNull(types.enumeration(values(REFUND_TYPES))),
      isSupportTicketFlow: false,
      guestEmail: types.maybeNull(types.string),
      guestGivenName: types.maybeNull(types.string),
      guestFamilyName: types.maybeNull(types.string),
      guestMobile: types.maybeNull(types.string),
      refundValue: types.maybeNull(types.number),
      refundValuePoints: types.maybeNull(types.number),
    })
    .actions(self => {
      const { getApiClient, logger } = getEnv(self);
      const { MemberStore } = getRoot(self);

      return {
        goForward: () => {
          const orderIssueFlow = self.isSupportTicketFlow
            ? SUPPORT_TICKET_FLOW
            : ORDER_HELP_FLOW_STEPS_BY_ISSUE_TYPE[self.orderIssue];
          const newCurrentStepIndex = indexOf(orderIssueFlow, self.currentStep) + 1;
          self.currentStep = orderIssueFlow[newCurrentStepIndex];
        },
        goBack: () => {
          const orderIssueFlow = self.isSupportTicketFlow
            ? SUPPORT_TICKET_FLOW
            : ORDER_HELP_FLOW_STEPS_BY_ISSUE_TYPE[self.orderIssue];
          const isAtEndOfFlow = self.currentStep === last(orderIssueFlow);
          if (isAtEndOfFlow) {
            return;
          }

          const newCurrentStepIndex = indexOf(orderIssueFlow, self.currentStep) - 1;
          self.currentStep = orderIssueFlow[newCurrentStepIndex];
          self.error = null;
          self.refusalReasonCode = null;
          self.refundType = null;
          self.success = false;
          self.isSupportTicketFlow = false;
        },
        setCurrentStep: step => {
          self.currentStep = step;
        },
        resetStep: () => {
          self.currentStep = initialState.currentStep;
        },
        reset: () => {
          applySnapshot(self, initialState);
        },
        setOrderIssue: orderIssue => {
          self.orderIssue = orderIssue;

          // Since your flow is dictated by your orderIssue, and each flow may have different screens requiring different state selections,
          // this is a good time to reset any user-selected state.
          self.order = null;
          self.orderItemUuids = null;
          self.orderItemIssue = null;
          self.notes = null;
          self.refundType = null;
        },

        setOrderItemUuids: orderItemUuids => {
          self.orderItemUuids = orderItemUuids;
        },

        setOrderItemIssue: orderItemIssue => {
          self.orderItemIssue = orderItemIssue;
        },

        setOrder: order => {
          self.order = order;
        },

        setNotes: notes => {
          self.notes = notes;
        },

        setRefundType: refundType => {
          self.refundType = refundType;
        },

        setIsSupportTicketFlow: isSupportTicketFlow => {
          self.isSupportTicketFlow = isSupportTicketFlow;
          if (isSupportTicketFlow) {
            self.order = null;
            self.orderItemIssue = null;
            self.orderItemUuids = null;
            self.refundType = REFUND_TYPES.SUPPORT_TICKET;
          }
        },

        setGuestDetails: ({ guestEmail, guestGivenName, guestFamilyName, guestMobile }) => {
          self.guestEmail = guestEmail;
          self.guestGivenName = guestGivenName;
          self.guestFamilyName = guestFamilyName;
          self.guestMobile = guestMobile;
        },

        submitRequest: flow(function*() {
          if (self.loading) {
            return;
          }

          if (self.refundType === REFUND_TYPES.SUPPORT_TICKET) {
            yield self.createTicket();
            return;
          }
          yield self.refundOrder();
        }),
        validateOrderRefund: flow(function*() {
          self.error = null;
          self.loading = true;
          self.success = false;
          self.refusalReasonCode = null;

          try {
            const client = yield getApiClient();
            const response = yield client.query({
              query: VALIDATE_ORDER_REFUND_QUERY,
              variables: {
                input: {
                  ...self.userDetails,
                  orderIssue: self.orderIssue,
                  orderId: self.order.id,
                  refundType: REFUND_TYPES.REFUND,
                  chosenOrderItemUuids: self.orderItemUuids,
                  orderItemIssue: self.orderItemIssue,
                  message: self.notes,
                },
              },
            });

            self.success = get(response, 'data.validateOrderRefund.success');
            self.refusalReasonCode = get(response, 'data.validateOrderRefund.refusalReasonCode');
          } catch (error) {
            const transformedError = transformError(error, 'There was an error validating the order for refund.');
            logger.error('Error validating order for refund', { error: transformedError });
            self.error = transformedError.message;
          }
          self.loading = false;
        }),
        refundOrder: flow(function*() {
          self.error = null;
          self.loading = true;
          self.success = false;
          self.refusalReasonCode = null;

          try {
            const client = yield getApiClient();
            const response = yield client.mutate({
              mutation: REFUND_ORDER_MUTATION,
              variables: {
                input: {
                  ...self.userDetails,
                  orderIssue: self.orderIssue,
                  orderId: self.order.id,
                  refundType: self.refundType,
                  chosenOrderItemUuids: self.orderItemUuids,
                  orderItemIssue: self.orderItemIssue,
                  message: self.notes,
                },
              },
            });

            self.success = get(response, 'data.refundOrder.success');
            self.refundValue = get(response, 'data.refundOrder.refundValue');
            self.refundValuePoints = get(response, 'data.refundOrder.refundValuePoints');
            self.refusalReasonCode = get(response, 'data.refundOrder.refusalReasonCode');
          } catch (error) {
            const transformedError = transformError(error, 'There was an error refunding the order.');
            logger.error('Error refunding order', { error: transformedError });
            self.error = transformedError.message;
          }

          if (MemberStore.isSignedIn) {
            // reload member points
            MemberStore.loadProfile();
          }

          self.loading = false;
        }),
        createTicket: flow(function*() {
          self.error = null;
          self.loading = true;
          self.success = false;
          self.refusalReasonCode = null;

          try {
            const client = yield getApiClient();

            const response = yield client.mutate({
              mutation: CREATE_TICKET_MUTATION,
              variables: {
                input: {
                  ...omit(self.userDetails, 'mobile'),
                  message: self.notes,
                  orderId: get(self.order, 'id'),
                  orderIssue: self.orderIssue,
                  orderItemUuids: self.orderItemUuids,
                  orderItemIssue: self.orderItemIssue,
                },
              },
            });

            self.success = get(response, 'data.createTicket.success');
            self.error = get(response, 'data.createTicket.errorMessage');
          } catch (error) {
            const transformedError = transformError(error, 'There was an error creating the support ticket.');
            logger.error('Error creating the support ticket', { error: transformedError });
            self.error = transformedError.message;
          }
          self.loading = false;
        }),
      };
    })

    .views(self => ({
      get orderWithRefundableChoice() {
        const augmentedOrder = multiplyAndFlattenOrderRefundableChoices(self.order);
        const augmentedOrderWithLabels = augmentOrderItemsWithChoiceLabels(augmentedOrder);

        return augmentedOrderWithLabels;
      },

      get userDetails() {
        const { MemberStore } = getRoot(self);
        return MemberStore.isSignedIn
          ? {
              email: MemberStore.profile.email,
              familyName: MemberStore.profile.familyName,
              givenName: MemberStore.profile.givenName,
              mobile: MemberStore.profile.mobile,
            }
          : {
              email: self.guestEmail,
              givenName: self.guestGivenName,
              familyName: self.guestFamilyName,
              mobile: self.guestMobile,
            };
      },
    }))
);

OrderHelpStore.initialState = initialState;

export default OrderHelpStore;
