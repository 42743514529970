import { InMemoryCache } from '../../../lib/amplify';

const getStorage = () => {
  if (typeof window === 'undefined') {
    return InMemoryCache;
  }

  try {
    return window.localStorage || InMemoryCache;
  } catch (error) {
    return InMemoryCache;
  }
};

/**
 * Lightweight wrapper to handle JSON encoding/decoding for localStorage
 */
const localStorage = {
  /**
   * Get an item from local storage.
   * @param {string} key
   */
  getItem: async key => {
    const value = getStorage().getItem(key);
    if (value === null) {
      return null;
    }
    try {
      return JSON.parse(value);
    } catch (error) {
      return value;
    }
  },

  /**
   * Persist an item to local storage. Passed value must be JSON serializable.
   * @param {string} key
   * @param {any} value
   */
  setItem: async (key, value) => {
    const jsonValue = JSON.stringify(value);
    getStorage().setItem(key, jsonValue);
  },
  /**
   * Remove an item from local storage
   * @param {string} key
   */
  removeItem: async key => {
    getStorage().removeItem(key);
  },
};

export { localStorage };
