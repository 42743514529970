import { types } from 'mobx-state-tree';

const PerkShopFormDetails = types.model('PerkShopFormDetails', {
  givenName: types.string,
  familyName: types.string,
  mobile: types.string,
  email: types.string,
  street: types.string,
  suburb: types.string,
  state: types.string,
  postcode: types.string,
  itemNames: types.array(types.string),
});

export default PerkShopFormDetails;
