const ChevronRight = props => (
  <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      d="m17.526 10.921-6.787-6.47c-.3-.289-.71-.451-1.137-.451-.427 0-.836.162-1.136.451a1.476 1.476 0 0 0 0 2.152l5.667 5.401-5.667 5.402a1.476 1.476 0 0 0 0 2.151c.302.286.71.445 1.136.443.426.002.835-.157 1.137-.443l6.787-6.47c.303-.286.474-.676.474-1.083 0-.406-.17-.796-.474-1.083Z"
      clipRule="evenodd"
    />
  </svg>
);

export { ChevronRight };
