import { CONTEXT_TYPES, ORDER_TYPES } from '@nandosaus/constants';
import { getRoot, getType, types } from 'mobx-state-tree';
import { DineInFulfilment } from './fulfilments';
import Result from '../../result';

export const GroupContext = types
  .model({
    type: types.literal(CONTEXT_TYPES.GROUP),
  })
  .actions(self => {
    return {
      init({ restaurantId, tableNumber }) {
        const fulfilment = DineInFulfilment.create({
          type: ORDER_TYPES.DINE_IN,
          restaurantId,
          tableNumber,
        });

        return { fulfilment };
      },
      eject() {
        const { GroupOrderStore } = getRoot(self);

        if (GroupOrderStore.isInGroup) {
          return Result.fail('Cannot eject from group context when still in group', {
            title: 'Unable to change order details while in a group',
            detail: `You'll need to leave or finish the group order "${GroupOrderStore.name}" before changing your order details.`,
          });
        }

        self.reset();
        return Result.ok();
      },
      reset() {
        // This store access needs to be local to this function since it's not available until after `actions` is run
        const { GroupOrderStore } = getRoot(self);
        GroupOrderStore.reset();
      },
    };
  })
  .views(self => {
    return {
      get availableOrderTypes() {
        return [ORDER_TYPES.DINE_IN];
      },
      fields({ fulfilment }) {
        // This store access needs to be local to this function since it's not available until after `views` is run
        const { GroupOrderStore } = getRoot(self);

        // Prevent other order types from being used
        if (!fulfilment || getType(fulfilment) !== DineInFulfilment) {
          return [];
        }

        const fields = fulfilment.fields.map(field => {
          if (field.name === 'tableNumber' && GroupOrderStore.isHost) {
            return { ...field, writable: true };
          }

          return { ...field, writable: false };
        });

        return fields;
      },
    };
  });
