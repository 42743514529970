import { isEmpty, get, includes, filter, flatten, map, first, clamp } from 'lodash';
import { DISCOUNT_TYPES } from '@nandosaus/constants';
import Prices from '../../models/prices';

const calculateDiscountPrices = ({ orderItems, offer, subtotalPrices }) => {
  const discountPrices = {
    cents: 0,
    points: 0,
  };

  // Return early if there is no offer.
  if (!offer) {
    return Prices.create(discountPrices);
  }

  // @NOTE: if (a, b) returns less than 0, sort a to an index lower than b (i.e. a comes first).
  const createSortFunction = ({ path, direction = 'asc' /* or 'desc' */ }) => {
    const sortFunction = (a, b) => {
      const aValue = get(a, path);
      const bValue = get(b, path);

      if (aValue < bValue) {
        return direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    };
    return sortFunction;
  };
  const createPluFilterFunction = ({ path = 'plu' }) => {
    const filterFunction = obj => {
      if (isEmpty(offer.plus)) {
        return true;
      }
      return includes(offer.plus, get(obj, path));
    };
    return filterFunction;
  };

  const SORT_ORDER_ITEM_PRICE_ASC = createSortFunction({
    path: 'subtotalPrices.cents',
    direction: 'asc',
  });
  const SORT_PRICE_ASC = createSortFunction({
    path: 'prices.cents',
    direction: 'asc',
  });

  // Get sorted collections containing eligible: orderItems, products, options and products+options.

  // Order items.
  const eligibleOrderItems = filter(orderItems, createPluFilterFunction({ path: 'product.plu' })).sort(
    SORT_ORDER_ITEM_PRICE_ASC
  );

  // Products.
  const eligibleProducts = filter(map(orderItems, 'product'), createPluFilterFunction({ path: 'plu' })).sort(
    SORT_PRICE_ASC
  );

  // Options.
  const eligibleOptions = filter(flatten(map(orderItems, 'options')), createPluFilterFunction({ path: 'plu' })).sort(
    SORT_PRICE_ASC
  );

  // Products and Options (for price override).
  const eligibleProductsAndOptions = filter([].concat(eligibleProducts, eligibleOptions), productOrOption => {
    return productOrOption.prices.cents > 0;
  }).sort(SORT_PRICE_ASC);

  // @TODO: Handle dollars vs cents for offer (probably should be cents); do this in API.
  // @NOTE: Redcat does not currently support offers with point payments; discountAmountInPoints not calculated.
  const discountAmountAsPercentage = offer.discountAmount / 100;
  let discountAmountInCents;
  let orderItem;
  let product;
  let productOrOption;

  switch (offer.discountType) {
    case DISCOUNT_TYPES.DOLLARS_OFF_ORDER:
      discountAmountInCents = offer.discountAmount * 100;
      discountPrices.cents = clamp(discountAmountInCents, 0, subtotalPrices.cents);
      break;

    case DISCOUNT_TYPES.PERCENTAGE_OFF_ORDER:
      discountAmountInCents = subtotalPrices.cents * discountAmountAsPercentage;
      discountPrices.cents = clamp(discountAmountInCents, 0, subtotalPrices.cents);
      break;

    case DISCOUNT_TYPES.DOLLARS_OFF_PRODUCT:
      if (isEmpty(eligibleProducts)) {
        break;
      }

      product = first(eligibleProducts);
      discountAmountInCents = offer.discountAmount * 100;
      discountPrices.cents = clamp(discountAmountInCents, 0, product.prices.cents);
      break;

    case DISCOUNT_TYPES.PERCENTAGE_OFF_PRODUCT:
      if (isEmpty(eligibleProducts)) {
        break;
      }

      product = first(eligibleProducts);
      discountPrices.cents = product.prices.cents * discountAmountAsPercentage;
      break;

    case DISCOUNT_TYPES.PERCENTAGE_OFF_ORDER_ITEM:
      if (isEmpty(eligibleOrderItems)) {
        break;
      }

      orderItem = first(eligibleOrderItems);
      discountPrices.cents = orderItem.subtotalPrices.cents * discountAmountAsPercentage;
      break;

    case DISCOUNT_TYPES.PERCENTAGE_OFF_ENFORCED_PRODUCT:
      // @NOTE: This one is the same as PERCENTAGE_OFF_PRODUCT,
      // but in theory it forces the item into the cart.
      // @TODO: We have not implemented forcing the item into the cart.
      if (isEmpty(eligibleProducts)) {
        break;
      }

      product = first(eligibleProducts);
      discountAmountInCents = product.prices.cents * discountAmountAsPercentage;
      discountPrices.cents = clamp(discountAmountInCents, 0, product.prices.cents);
      break;

    case DISCOUNT_TYPES.PRICE_OVERRIDE:
      if (isEmpty(eligibleProductsAndOptions)) {
        break;
      }

      productOrOption = first(eligibleProductsAndOptions);
      discountPrices.cents = productOrOption.prices.cents - offer.discountAmount * 100;
      break;

    default:
      break;
  }

  // Respect the offer's discount limit (if applicable).
  if (offer.discountLimit) {
    const discountLimitInCents = offer.discountLimit * 100; // @TODO: Move this into offer.
    discountPrices.cents = clamp(discountPrices.cents, 0, discountLimitInCents);
  }

  // Ensure that the discountPrices.cents is an integer.
  // @TODO: Work out if this should be floor or round or ceil.
  discountPrices.cents = Math.round(discountPrices.cents);

  return Prices.create(discountPrices);
};

export { calculateDiscountPrices };
