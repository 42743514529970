import { get, toUpper } from 'lodash';

import { logger } from '../logger';

const buildConfig = ({ orderSummary = null, allowedCardNetworks }) => {
  const isTest = toUpper(process.env.adyen.environment) !== 'LIVE';
  const environment = isTest ? 'TEST' : 'PRODUCTION';
  const merchantId = isTest ? undefined : process.env.adyen.googlePayMerchantId; // merchantId should only be sent in production
  const gatewayMerchantId = process.env.adyen.googlePayGatewayMerchantId;

  return {
    environment,
    paymentRequest: {
      apiVersion: 2,
      apiVersionMinor: 0,
      allowedPaymentMethods: [
        {
          type: 'CARD',
          parameters: { allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'], allowedCardNetworks },
          tokenizationSpecification: {
            type: 'PAYMENT_GATEWAY',
            parameters: { gateway: 'adyen', gatewayMerchantId },
          },
        },
      ],
      merchantInfo: { merchantName: 'Nando’s', merchantId },
      transactionInfo: {
        countryCode: process.env.adyen.countryCode,
        currencyCode: process.env.currencyCode,
        totalPriceStatus: 'FINAL',
        totalPrice: get(orderSummary, 'price'),
        totalPriceLabel: 'Total',
      },
    },
  };
};

// Including this callback means it shows the total amount on the payment sheet, which is nice.
// It could be used to handle a Google Pay authorization failure by showing a
// message on the payment sheet for easy retry. But we're not doing that for consistency with other payment types.
const onPaymentAuthorized = () => new Promise(resolve => resolve({ transactionState: 'SUCCESS' }));

const buildPaymentsClient = config => {
  const PaymentsClient = get(window, 'google.payments.api.PaymentsClient');
  if (!PaymentsClient) {
    logger.warn('Google Pay not available - perhaps the Google Pay script failed to load.');
    return null;
  }

  return new PaymentsClient({
    environment: config.environment,
    merchantInfo: config.paymentRequest.merchantInfo,
    paymentDataCallbacks: { onPaymentAuthorized },
  });
};

const supportsGooglePay = config => {
  const client = buildPaymentsClient(config);
  return client ? client.isReadyToPay(config.paymentRequest) : { result: false };
};

const payWithGooglePay = config => {
  const client = buildPaymentsClient(config);
  return client.loadPaymentData({
    ...config.paymentRequest,
    callbackIntents: ['PAYMENT_AUTHORIZATION'],
  });
};

export { payWithGooglePay, supportsGooglePay, buildConfig };
