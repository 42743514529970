import { v4 as uuidv4 } from 'uuid';
import { types } from 'mobx-state-tree';

import OrderItem from '../order-item';

const SubCart = types.model('SubCart', {
  id: types.optional(types.identifier, uuidv4),
  name: types.maybeNull(types.string),
  orderItems: types.optional(types.array(OrderItem), []),
});

export const DEFAULT_SUB_CART_ID = 'DEFAULT_SUB_CART_ID';
export const DEFAULT_SUB_CART = { id: DEFAULT_SUB_CART_ID, name: 'You', orderItems: [] };

// Ideally this would be its own model, however it's not possible to have a model which is also a Map.
// So, instead we're just grouping related methods together so they are all in the one spot anyway.
export const SubCarts = types.map(SubCart);

export const getIds = subCarts => {
  return Array.from(subCarts.keys());
};

export const getAllAsList = subCarts => {
  return Array.from(subCarts.values());
};

export const getOrderItems = subCarts => {
  return getAllAsList(subCarts).flatMap(({ orderItems }) => orderItems);
};

export const getFilledAsList = subCarts => {
  return getAllAsList(subCarts).filter(({ orderItems }) => orderItems.length > 0);
};

export const sortByName = ({ name: nameA }, { name: nameB }) => {
  return nameA.localeCompare(nameB);
};

export const sortByDefaultFirst = ({ id: idA }, { id: idB }) => {
  if (idA === DEFAULT_SUB_CART_ID) return -1;
  if (idB === DEFAULT_SUB_CART_ID) return 1;
  return 0;
};

export const anyNonDefault = subCarts => {
  return getAllAsList(subCarts).some(({ id }) => id !== DEFAULT_SUB_CART_ID);
};

export default SubCart;
