const australianStates = [
  {
    text: 'ACT',
    value: 'act',
    longName: 'Australian Capital Territory',
  },
  {
    text: 'NSW',
    value: 'nsw',
    longName: 'New South Wales',
  },
  {
    text: 'NT',
    value: 'nt',
    longName: 'Northern Territory',
  },
  {
    text: 'QLD',
    value: 'qld',
    longName: 'Queensland',
  },
  {
    text: 'SA',
    value: 'sa',
    longName: 'South Australia',
  },
  {
    text: 'TAS',
    value: 'tas',
    longName: 'Tasmania',
  },
  {
    text: 'VIC',
    value: 'vic',
    longName: 'Victoria',
  },

  {
    text: 'WA',
    value: 'wa',
    longName: 'Western Australia',
  },
];

const nzRegions = [
  {
    longName: 'Auckland',
    value: 'auck',
  },
  {
    longName: 'Bay of Plenty',
    value: 'bop',
  },
  {
    longName: 'Christchurch',
    value: 'chch',
  },
  {
    longName: 'Dunedin',
    value: 'dun',
  },
  {
    longName: 'Hamilton',
    value: 'ham',
  },
  {
    longName: 'Wellington',
    value: 'wel',
  },
];

export { australianStates, nzRegions };
