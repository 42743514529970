import { colors } from '../../constants/colors';
import { fontSize } from '../../constants/font-size';
import { fontWeight } from '../../constants/font-weight';

const BASE_VARIANT_STYLES = {
  px: '2rem',
  py: ['1.5rem', '1.5rem', '1rem'],
  borderRadius: '4px',
  borderStyle: 'solid',
  fontSize: [fontSize[12], null, null, fontSize[11], fontSize[10]],
  fontWeight: fontWeight.bold,
  textTransform: 'uppercase',

  '& .button-icon svg path': {
    fill: 'currentColor',
  },
};

const COMMON_BUTTON_HEIGHT = ['44px', null, null, '55px'];
const COMMON_BORDER_WIDTH = ['1px', '1px', '1px', '1px'];

const buttonsTheme = {
  buttonVariants: {
    base: BASE_VARIANT_STYLES,
    primary: {
      backgroundColor: colors.black,
      borderColor: colors.black,
      borderWidth: COMMON_BORDER_WIDTH,
      color: colors.white,
      fill: colors.white,
      height: COMMON_BUTTON_HEIGHT,

      loadingIndicatorColor: colors.neonTeal400,

      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          color: colors.neonTeal400,
          fill: colors.neonTeal400,
        },
      },

      '&:active': {
        color: colors.neonTeal400,
        fill: colors.neonTeal400,
      },

      '&:disabled': {
        backgroundColor: colors.grey100,
        borderColor: colors.grey100,
        color: colors.grey300,
        fill: colors.grey300,
        cursor: 'auto',
      },

      '&.loading': {
        color: colors.white,
        cursor: 'auto',
      },
    },

    secondary: {
      backgroundColor: colors.white,
      borderColor: colors.black,
      borderWidth: COMMON_BORDER_WIDTH,
      color: colors.black,
      fill: colors.black,
      height: COMMON_BUTTON_HEIGHT,

      loadingIndicatorColor: colors.black,

      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          backgroundColor: colors.white,
          borderColor: colors.neonTeal400,
          color: colors.black,
          fill: colors.black,
        },
      },

      '&:active': {
        backgroundColor: colors.white,
        borderColor: colors.neonTeal400,
        color: colors.black,
        fill: colors.black,
      },

      '&:disabled': {
        backgroundColor: colors.white,
        borderColor: colors.grey300,
        color: colors.grey300,
        fill: colors.grey300,
        cursor: 'auto',
      },

      '&.loading': {
        backgroundColor: colors.white,
        borderColor: colors.black,
        color: colors.black,
        fill: colors.black,
        cursor: 'auto',
      },
    },

    tertiary: {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      borderWidth: COMMON_BORDER_WIDTH,
      color: 'currentColor',
      fill: 'currentColor',
      height: COMMON_BUTTON_HEIGHT,

      loadingIndicatorColor: colors.black,

      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          backgroundColor: colors.grey100,
          borderColor: colors.grey100,
          color: colors.black,
          fill: colors.black,
        },
      },

      '&:active': {
        backgroundColor: colors.grey100,
        borderColor: colors.grey100,
        color: colors.black,
        fill: colors.black,
      },

      '&:disabled': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: colors.grey300,
        fill: colors.grey300,
        cursor: 'auto',
      },

      '&.loading': {
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        color: colors.black,
        fill: colors.black,
        cursor: 'auto',
      },
    },

    quarternary: {
      backgroundColor: colors.white,
      borderColor: colors.white,
      borderWidth: COMMON_BORDER_WIDTH,
      color: colors.black,
      fill: colors.black,
      height: COMMON_BUTTON_HEIGHT,
      loadingIndicatorColor: colors.black,

      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          backgroundColor: colors.black,
          borderColor: colors.black,
          color: colors.white,
          fill: colors.white,
        },
      },

      '&:active': {
        backgroundColor: colors.black,
        borderColor: colors.black,
        color: colors.white,
        fill: colors.white,
      },

      '&:disabled': {
        backgroundColor: colors.white,
        borderColor: colors.grey300,
        color: colors.grey300,
        fill: colors.grey300,
        cursor: 'auto',
      },

      '&.loading': {
        backgroundColor: colors.white,
        borderColor: colors.black,
        color: colors.black,
        fill: colors.black,
        cursor: 'auto',
      },
    },

    selectable: {
      px: '2rem',
      py: '1rem',
      borderRadius: '2px',
      backgroundColor: colors.white,
      border: `1px solid`,
      borderColor: colors.grey200,
      fill: colors.greyPrimary,
      color: colors.greyPrimary,
      fontSize: [fontSize[9], null, null, fontSize[8], fontSize[7]],

      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          borderColor: colors.neonTeal400,
        },
      },

      '&:active': {
        backgroundColor: colors.neonTeal100,
        borderColor: colors.neonTeal400,
        color: colors.neonTeal800,
        fill: colors.neonTeal800,
      },

      '&:disabled': {
        backgroundColor: colors.white,
        borderColor: colors.grey100,
        color: colors.grey200,
        fill: colors.grey200,
        cursor: 'default',
      },
    },

    thinPrimary: {
      px: ['12px', '12px', '12px', '14px', '16px'],
      py: ['3px', '3px', '3px', '5px', '8px'],
      backgroundColor: colors.black,
      borderColor: colors.black,
      fontWeight: fontWeight.medium,
      borderWidth: COMMON_BORDER_WIDTH,
      color: colors.white,
      fill: colors.white,
      textTransform: 'unset',
      loadingIndicatorColor: colors.neonTeal400,
      fontSize: [fontSize[10], fontSize[10], fontSize[10], fontSize[10], fontSize[9]],

      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          color: colors.neonTeal400,
          fill: colors.neonTeal400,
        },
      },

      '&:active': {
        color: colors.neonTeal400,
        fill: colors.neonTeal400,
      },

      '&:disabled': {
        backgroundColor: colors.grey100,
        borderColor: colors.grey100,
        color: colors.grey300,
        fill: colors.grey300,
        cursor: 'auto',
      },

      '&.loading': {
        color: colors.white,
        cursor: 'auto',
      },
    },

    link: {
      padding: 'inherit',
      border: 'none',
      color: colors.infoPrimary,
      fill: colors.infoPrimary,
      textDecoration: 'none',
    },

    destructiveLink: {
      padding: 'inherit',
      border: 'none',
      color: colors.infoPrimary,
      fill: colors.infoPrimary,
      textDecoration: 'none',

      '&:hover': {
        color: colors.neonRed,
        fill: colors.neonRed,
      },

      '&:disabled': {
        color: colors.grey300,
        cursor: 'auto',
      },

      '&:active': {
        color: colors.neonRed,
        fill: colors.neonRed,
      },
    },

    action: {
      px: '1rem',
      py: ['1.5rem', '1.5rem', '1rem'],
      border: 'none',
      backgroundColor: colors.black,
      color: colors.white,
      fill: colors.white,
      display: 'flex',
      fontSize: [fontSize[12], null, null, fontSize[9]],
      fontWeight: fontWeight.bold,
      height: ['56px', null, null, '68px'],
      loadingIndicatorColor: colors.white,
      textTransform: 'uppercase',
      width: '100%',

      '@media (hover: hover) and (pointer: fine)': {
        '&:hover': {
          color: colors.neonTeal400,
          fill: colors.neonTeal400,
        },
      },

      '&:active': {
        color: colors.neonTeal400,
        fill: colors.neonTeal400,
      },

      '&:disabled': {
        backgroundColor: colors.grey100,
        color: colors.grey300,
        fill: colors.grey300,
        cursor: 'auto',
      },

      '&.loading': {
        cursor: 'auto',
      },
    },

    actionPrimary: {
      backgroundColor: colors.black,
      borderColor: colors.black,
      color: colors.white,
      fill: colors.white,
      display: 'flex',
      fontSize: [fontSize[12], null, null, fontSize[9]],
      fontWeight: fontWeight.bold,
      height: COMMON_BUTTON_HEIGHT,
      loadingIndicatorColor: colors.white,
      textTransform: 'uppercase',
      width: '100%',

      '&:disabled': {
        backgroundColor: colors.grey100,
        borderColor: colors.grey100,
        color: colors.grey300,
        fill: colors.grey300,
        cursor: 'auto',
      },

      '&.loading': {
        cursor: 'auto',
      },
    },

    actionSecondary: {
      backgroundColor: colors.white,
      borderColor: colors.black,
      borderWidth: '1px',
      color: colors.black,
      fill: colors.black,
      display: 'flex',
      fontSize: [fontSize[12], null, null, fontSize[9]],
      fontWeight: fontWeight.bold,
      height: COMMON_BUTTON_HEIGHT,
      loadingIndicatorColor: colors.white,
      textTransform: 'uppercase',
      width: '100%',

      '&:disabled': {
        backgroundColor: colors.grey100,
        borderColor: colors.grey100,
        color: colors.grey300,
        fill: colors.grey300,
        cursor: 'auto',
      },

      '&.loading': {
        cursor: 'auto',
      },
    },

    inactive: {
      backgroundColor: colors.grey100,
      borderColor: colors.grey100,
      color: colors.grey300,
      fill: colors.grey300,
      cursor: 'auto',
      display: 'flex',
      fontSize: [fontSize[12], null, null, fontSize[9]],
      fontWeight: fontWeight.bold,
      height: COMMON_BUTTON_HEIGHT,
      textTransform: 'uppercase',
      width: '100%',
    },
  },
};

export { buttonsTheme };
