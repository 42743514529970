import { ANALYTICS_EVENTS } from '@nandosaus/constants';

const { ORDER_COMPLETED, PRODUCT_ADDED } = ANALYTICS_EVENTS;

const SNAPCHAT_PIXEL_EVENTS = {
  [PRODUCT_ADDED]: 'ADD_CART',
  [ORDER_COMPLETED]: 'PURCHASE',
};

const currency = process.env.currencyCode;

let snapchatPixel;
let isPixedLoaded = false;

export const snapchatPlugin = (userConfig = {}) => {
  return {
    name: 'snapchat',

    config: userConfig,

    initialize: async () => {
      if (typeof window === 'undefined') {
        return;
      }

      const { default: ReactSnapchatPixel } = await import('react-snapchat-pixel');
      snapchatPixel = ReactSnapchatPixel;

      if (userConfig.enabled && !isPixedLoaded) {
        snapchatPixel.init(userConfig.snapchatPixelId);

        isPixedLoaded = true;
      }
    },

    page: () => {
      snapchatPixel.pageView();
    },

    track: ({ payload }) => {
      switch (payload.event) {
        case PRODUCT_ADDED: {
          return snapchatPixel.track(SNAPCHAT_PIXEL_EVENTS[payload.event]);
        }

        case ORDER_COMPLETED: {
          return snapchatPixel.track(SNAPCHAT_PIXEL_EVENTS[payload.event], {
            currency,
            price: payload.properties.total,
            transaction_id: payload.properties.cartId,
          });
        }

        default:
          return () => {};
      }
    },

    loaded: () => {
      return isPixedLoaded;
    },
  };
};
