import { useEffect } from 'react';
import { logger } from '@utils/logger';

const message = 'Service Worker: Failed to register';

export const useServiceWorker = () => {
  useEffect(() => {
    try {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/static/service-worker.js').then(
          registration => logger.info('Service Worker: Scope is: ', registration.scope),
          reason => logger.info(message, { reason })
        );
      }
    } catch (error) {
      logger.info(message, { error });
    }
  }, []);
};
