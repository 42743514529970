import { flow, types } from 'mobx-state-tree';
import { get } from 'lodash';
import moment from 'moment';

import { getDependencies } from '../../util/get-dependencies';
import { getGiftCard } from '../../helpers/get-gift-card';
import AlternativePaymentMethod from '../alternative-payment-method';
import CreditCard from '../credit-card';
import GiftCard from '../gift-card';
import StoredPaymentMethod from '../stored-payment-method';

const Payment = types
  .model('Payment', {
    errorMessage: types.maybe(types.string),
    storePaymentMethod: false,
    alternativePaymentMethod: types.maybe(AlternativePaymentMethod),
    creditCard: types.maybe(CreditCard),
    giftCard: types.maybe(GiftCard),
    paymentMethod: types.maybe(types.reference(StoredPaymentMethod)),
    useNewCreditCard: false,
  })
  .actions(self => ({
    setAlternativePaymentMethod(alternativePaymentMethod) {
      self.alternativePaymentMethod = alternativePaymentMethod;
    },
    setPaymentMethod(paymentMethod) {
      self.paymentMethod = paymentMethod;
    },
    setStorePaymentMethod(storePaymentMethod) {
      self.storePaymentMethod = storePaymentMethod;
    },
    setCreditCard(creditCard) {
      self.creditCard = creditCard;
    },
    setUseNewCreditCard(shouldUse) {
      self.useNewCreditCard = shouldUse;
    },
    setErrorMessage(message) {
      self.errorMessage = message;
    },
    setGiftCard: flow(function*(cardNumber, verificationCode) {
      const { getApiClient } = getDependencies(self);
      const apiClient = yield getApiClient();
      const giftCard = yield getGiftCard(cardNumber, verificationCode, { apiClient });

      if (giftCard === null) {
        return false;
      }

      self.giftCard = {
        balance: giftCard.balance,
        cardNumber: giftCard.cardNumber,
        expiryDate: giftCard.expiryDate ? moment(giftCard.expiryDate).toDate() : null,
        verificationCode,
      };

      return true;
    }),
    checkGiftCard: flow(function*(cardNumber, verificationCode) {
      const { getApiClient } = getDependencies(self);
      const apiClient = yield getApiClient();
      const giftCard = yield getGiftCard(cardNumber, verificationCode, { apiClient });

      if (giftCard === null) {
        return {
          success: false,
          error: 'The gift card details you entered are invalid, please check them and try again.',
        };
      }

      return {
        success: true,
        balance: giftCard.balance,
        cardNumber: giftCard.cardNumber,
        expiryDate: giftCard.expiryDate ? moment(giftCard.expiryDate).toDate() : null,
        verificationCode,
      };
    }),

    removeGiftCard() {
      self.giftCard = undefined;
    },
  }))
  .views(self => ({
    get browserInfo() {
      // for new credit card payments we use the information returned from the adyen component
      if (self.creditCard) {
        return get(self, 'creditCard.browserInfo');
      }

      // For all other payment methods we need to source the browser info ourselves. I have adapted the code from Adyen here to suit our use case
      // https://github.com/Adyen/adyen-3ds2-js-utils/blob/main/browser/index.js#L12
      const defaults = {
        acceptHeader: '*/*',
      };

      if (typeof window === 'undefined') {
        // if window isn't available (SSR) then we need to exit before we try to access the window object
        return defaults;
      }

      const { navigator } = window;

      return {
        ...defaults,
        screenWidth: window.screen?.width ?? '',
        screenHeight: window.screen?.height ?? '',
        colorDepth: window.screen?.colorDepth ?? '',
        userAgent: navigator?.userAgent ?? '',
        timeZoneOffset: new Date().getTimezoneOffset(),
        language: navigator?.language ?? navigator.browserLanguage,
        javaEnabled: false,
      };
    },
    get isPayingWithAlternativePaymentMethod() {
      return Boolean(get(self, 'alternativePaymentMethod.token'));
    },
    get isStoredPayment() {
      return !self.useNewCreditCard;
    },
    get hasCreditCard() {
      return !!self.creditCard;
    },
    get paymentMethodId() {
      if (!self.paymentMethod) {
        return null;
      }
      return self.paymentMethod.id;
    },
  }));

export default Payment;
