import { color, layout, position, space, variant as styledVariant, system, typography } from 'styled-system';
import { join, values } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css, ThemeProvider } from 'styled-components';
import StyledSystemPropTypes from '@styled-system/prop-types';

import { theme as appTheme, globalStyles } from '../../constants';
import { BUTTON_THEME_COLORS, BUTTON_VARIANTS } from './constants';
import { ButtonContent } from './button-content';
import { ButtonElement } from './button-element';
import { coreStyles } from '../typography/font-styles';
import { LoadingIndicator } from '../icons/loading-indicator';
import { pStyles } from '../typography/body';

const transition = system({
  transition: true,
});

const Button = ({
  children,
  className,
  disabled,
  isFullWidth,
  isLoading,
  loadingText,
  leftContent,
  leftText,
  onClick,
  px,
  py,
  rightText,
  textVariant,
  title,
  type,
  variant,
  themeColor,
  size,
  labelProps,
  ...rest
}) => {
  const classNames = [className];
  if (isLoading) {
    classNames.push('loading');
  }

  return (
    <ThemeProvider theme={appTheme}>
      <StyledButton
        {...rest}
        className={join(classNames, ' ')}
        disabled={disabled}
        isFullWidth={isFullWidth}
        onClick={!disabled && !isLoading ? onClick : null}
        px={size === 'narrow' ? 1 : px}
        py={py}
        textVariant={textVariant}
        title={title}
        type={type}
        variant={variant}
      >
        <ButtonContent
          disabled={disabled}
          isLoading={isLoading}
          leftContent={
            isLoading ? (
              <LoadingIndicator mr="0.5rem" color="currentColor" isLoading={isLoading} variant={variant} />
            ) : (
              leftContent
            )
          }
          leftText={leftText}
          rightText={rightText}
          variant={variant}
          labelProps={labelProps}
        >
          {isLoading ? loadingText : children}
        </ButtonContent>
      </StyledButton>
    </ThemeProvider>
  );
};

const variants = styledVariant({
  prop: 'variant',
  scale: 'buttonVariants',
  variants: {
    base: {},
    primary: {},
    secondary: {},
    tertiary: {},
    quaternary: {},
    selectable: {},
    thinPrimary: {},
    link: {},
    action: {},
    actionPrimary: {},
    actionSecondary: {},
    inactive: {},
  },
});

const StyledButton = styled(ButtonElement).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    return !['isFullWidth', 'variant', 'textVariant', 'transition'].includes(prop) && defaultValidatorFn(prop);
  },
})`
  ${({ textVariant, variant }) =>
    variant === 'link' &&
    css`
      ${coreStyles};
      ${pStyles(textVariant)};
    `}

  ${props => variants({ ...props, variant: 'base' })}
  ${variants};

  ${color};
  ${layout};
  ${position};
  ${space};
  ${typography};
  ${transition};
  font-family: ${globalStyles.baseFontFamily};
  align-items: center;
  display: inline-flex;
  justify-content: center;
  line-height: 1;
  position: relative;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;

  ${({ isFullWidth }) =>
    isFullWidth &&
    css`
      width: 100%;
    `}
`;

Button.propTypes = {
  ...StyledSystemPropTypes.color,
  ...StyledSystemPropTypes.space,
  ...StyledSystemPropTypes.typography,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  leftContent: PropTypes.node,
  onClick: PropTypes.func,
  textVariant: PropTypes.number,
  title: PropTypes.string,
  type: PropTypes.oneOf(['submit', 'reset', 'button']),
  variant: PropTypes.oneOf(values(BUTTON_VARIANTS)),
  themeColor: PropTypes.oneOf(values(BUTTON_THEME_COLORS)),
  transition: PropTypes.string,
  labelProps: PropTypes.any,
};

Button.defaultProps = {
  disabled: false,
  isLoading: false,
  loadingText: 'Loading',
  leftContent: undefined,
  onClick: undefined,
  textVariant: 1,
  title: null,
  type: 'button',
  variant: 'primary',
  themeColor: undefined,
  transition: 'all 1s cubic-bezier(0.165, 0.84, 0.44, 1)',
  labelProps: null,
};

export { Button };
