const Vegetarian = props => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5505_2041)">
      <path
        d="M15.9947 2.09947C18.8418 2.09947 21.6249 2.94373 23.9922 4.5255C26.3595 6.10726 28.2046 8.35548 29.2941 10.9859C30.3836 13.6162 30.6687 16.5106 30.1133 19.303C29.5578 22.0954 28.1868 24.6604 26.1736 26.6736C24.1604 28.6868 21.5954 30.0578 18.803 30.6133C16.0106 31.1687 13.1162 30.8836 10.4859 29.7941C7.85549 28.7046 5.60727 26.8595 4.0255 24.4922C2.44374 22.1249 1.59947 19.3418 1.59947 16.4947C1.60511 12.6786 3.12356 9.02036 5.82196 6.32195C8.52036 3.62355 12.1786 2.10511 15.9947 2.09947ZM15.9947 0.5C12.8312 0.5 9.73882 1.43807 7.10851 3.19559C4.4782 4.9531 2.42813 7.45113 1.21753 10.3738C0.00693027 13.2964 -0.309818 16.5124 0.30734 19.6151C0.924498 22.7177 2.44784 25.5677 4.68474 27.8046C6.92163 30.0415 9.77161 31.5648 12.8743 32.182C15.9769 32.7992 19.1929 32.4824 22.1156 31.2718C25.0382 30.0612 27.5362 28.0111 29.2938 25.3808C31.0513 22.7505 31.9893 19.6581 31.9893 16.4947C31.9893 12.2526 30.3042 8.18431 27.3046 5.18473C24.305 2.18515 20.2367 0.5 15.9947 0.5V0.5Z"
        fill="black"
      />
      <path
        d="M22.7231 8.73172L17.6368 24.2572L17.3543 24.4918H14.6218L14.3126 24.2572L9.22363 8.73172L9.4369 8.47314H12.4999L12.8091 8.70773L16.008 19.2136H16.0827L19.1936 8.70773L19.4975 8.47314H22.5365L22.7231 8.73172Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_5505_2041">
        <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export { Vegetarian };
