// @TODO: Functions duplicated in web & app - find a way to export these for both

import { DATE_TIME_FORMATS } from '@nandosaus/constants';
import moment from 'moment';

const convert12To24Hour = time => moment(time, DATE_TIME_FORMATS.TIME_DEFAULT).format(DATE_TIME_FORMATS.TIME_24_HOUR);

const convert24To12Hour = time => moment(time, DATE_TIME_FORMATS.TIME_24_HOUR).format(DATE_TIME_FORMATS.TIME_DEFAULT);

const convertTimeToMoment = time => moment(time, DATE_TIME_FORMATS.TIME_24_HOUR);

const formatDateString = string => moment(string).format(DATE_TIME_FORMATS.TIME_DEFAULT);

const formatDateISO = string => convertTimeToMoment(string).toISOString(true);

const format24Hour = time => time.format(DATE_TIME_FORMATS.TIME_24_HOUR);

// format: 'D MMM' e.g. 3 Mar
const formatDayMonthDate = time => moment(time).format(DATE_TIME_FORMATS.DATE_DAY_MONTH);

// format: 'MMMM D' e.g March 3
const formatMonthDayDate = time => moment(time).format(DATE_TIME_FORMATS.DATE_MONTH_DAY);

const getCurrentTime = () => format24Hour(moment());

const getCurrentDay = () => moment(new Date()).format(DATE_TIME_FORMATS.TIME_FULL_DAY);

const getNextDay = () =>
  moment(new Date())
    .add(1, 'day')
    .format(DATE_TIME_FORMATS.TIME_FULL_DAY);

const timeDifference = (startTime, endTime) => {
  return moment(startTime, DATE_TIME_FORMATS.TIME_24_HOUR).diff(
    moment(endTime, DATE_TIME_FORMATS.TIME_24_HOUR),
    DATE_TIME_FORMATS.TIME_MINUTES
  );
};

const addTime = (string, time) => moment(string).add(time, 'minutes');

const getElapsedTimeInMinutes = startTime => Math.ceil((Date.now() - startTime) / (1000 * 60));
const getElapsedTimeInSeconds = startTime => Math.ceil((Date.now() - startTime) / 1000);

export {
  addTime,
  convert12To24Hour,
  convert24To12Hour,
  convertTimeToMoment,
  format24Hour,
  formatDateISO,
  formatDateString,
  formatDayMonthDate,
  formatMonthDayDate,
  getCurrentDay,
  getCurrentTime,
  getElapsedTimeInMinutes,
  getElapsedTimeInSeconds,
  getNextDay,
  timeDifference,
};
