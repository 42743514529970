import { compact, concat, filter, find, flatMap, get, isEmpty, last, map, toUpper, uniq } from 'lodash';
import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { reaction } from 'mobx';
import gql from 'graphql-tag';

import Offer from '../../models/offer';

const initialState = {};

const OFFER_FIELDS_FRAGMENT = gql`
  fragment OfferFields on Offer {
    barcode
    description
    discountAmount
    discountLimit
    discountType
    discountUsage
    expiryDate
    hash
    id
    image
    longDescription
    minOrderValue
    minimumOrderSpend
    name
    plus
    restaurantIds
    startDate
    type
    isPromoCode
  }
`;

const OFFERS_QUERY = gql`
  query getOffers($restaurantId: String, $promoCode: String) {
    offers(restaurantId: $restaurantId, promoCode: $promoCode) {
      ...OfferFields
    }
  }

  ${OFFER_FIELDS_FRAGMENT}
`;

const GUEST_OFFER_QUERY = gql`
  query checkGuestOffer($restaurantId: String, $promoCode: String) {
    checkGuestOffer(restaurantId: $restaurantId, promoCode: $promoCode) {
      ...OfferFields
    }
  }

  ${OFFER_FIELDS_FRAGMENT}
`;

const UNLOCKED_OFFERS_QUERY = gql`
  query getUnlockedOffers($restaurantId: String, $promoCodes: [String]) {
    unlockedOffers(restaurantId: $restaurantId, promoCodes: $promoCodes) {
      ...OfferFields
    }
  }

  ${OFFER_FIELDS_FRAGMENT}
`;

const ACTIVATED_PROMO_CODES_QUERY = gql`
  query getActivatedPromoCodes {
    activatedPromoCodes
  }
`;

const STORE_ACTIVATED_PROMO_CODE_MUTATION = gql`
  mutation storeActivatedPromoCode($input: ActivatedPromoCodeInput!) {
    storeActivatedPromoCode(input: $input) {
      success
    }
  }
`;

const checkGuestOffer = async ({ client, restaurantId, promoCode }) => {
  const { data } = await client.query({ query: GUEST_OFFER_QUERY, variables: { restaurantId, promoCode } });
  return { data: data.checkGuestOffer };
};

const getOffers = async ({ client, restaurantId, promoCode }) => {
  const { data } = await client.query({ query: OFFERS_QUERY, variables: { restaurantId, promoCode } });
  return { data: data.offers };
};

const getUnlockedOffers = async ({ client, restaurantId, promoCodes }) => {
  const { data } = await client.query({ query: UNLOCKED_OFFERS_QUERY, variables: { restaurantId, promoCodes } });
  return { data: data.unlockedOffers };
};

const getActivatedPromoCodes = async ({ client }) => {
  const { data } = await client.query({ query: ACTIVATED_PROMO_CODES_QUERY, variables: {} });
  return { data: data.activatedPromoCodes };
};

const storeActivatedPromoCode = async ({ client, promoCode, expiryDate }) => {
  try {
    const { data } = await client.mutate({
      mutation: STORE_ACTIVATED_PROMO_CODE_MUTATION,
      variables: { input: { promoCode, expiryDate } },
    });
    return data;
  } catch (error) {
    return { error };
  }
};

const OfferStore = types
  .model('OfferStore', {
    loadingOffers: false,
    loadingPromoCodes: false,
    error: false,
    offer: types.optional(types.maybeNull(types.reference(Offer)), null),
    offers: types.optional(types.array(Offer), []),
    promoCode: types.optional(types.maybeNull(types.string), ''),
    activatedPromoCodes: types.optional(types.array(types.string), []),
    unlockedOffers: types.optional(types.array(Offer), []),
  })
  .actions(self => {
    const { getApiClient, logger } = getEnv(self);
    const { CartStore, MemberStore, MenuStore } = getRoot(self);

    reaction(
      () => CartStore.restaurantId,
      () => {
        self.loadOffers();
      }
    );

    return {
      clearOffer() {
        self.offer = null;
      },
      clearSessionData() {
        self.loadingOffers = false;
        self.loadingPromoCodes = false;
        self.error = false;
        self.offer = null;
        self.offers = [];
        self.activatedPromoCodes = [];
        self.unlockedOffers = [];
      },
      setError(displayError) {
        self.error = displayError;
      },
      // @NOTE: This function does nothing, but it allows us to force a re-render
      // of the offers carousel items within the apply event handler.
      triggerRender: () => {},
      loadOffers: flow(function*() {
        // As this may be triggered by an unauthenticated user changing store,
        // bail out early if required.
        if (!MemberStore.isSignedIn) return;

        const { restaurantId } = CartStore;
        self.error = false;
        self.loadingOffers = true;

        try {
          const client = yield getApiClient();
          const result = yield getOffers({ client, restaurantId });

          const unlockedOffersResult = yield getUnlockedOffers({
            client,
            restaurantId,
            promoCodes: self.activatedPromoCodes,
          });

          self.offers = result.data;
          self.unlockedOffers = unlockedOffersResult.data;

          const { menu } = MenuStore;
          if (menu) {
            const productChoicesForOffers = flatMap(self.allOffers, offer => offer.productOfferChoices);
            const productIds = uniq(map(productChoicesForOffers, 'id'));
            yield menu.loadProducts(productIds);
          }
        } catch (error) {
          logger.error('Failed to get offers', { error, restaurantId });
          self.error = true;
        }
        self.loadingOffers = false;
      }),
      checkPromoCode: flow(function*() {
        if (!self.promoCode) {
          return null;
        }

        const { restaurantId } = CartStore;

        self.error = false;

        if (!MemberStore.isSignedIn) {
          try {
            const client = yield getApiClient();
            const result = yield checkGuestOffer({ client, restaurantId, promoCode: self.promoCode });
            const guestOffer = get(result, 'data');
            self.addOffer(guestOffer);
            return last(self.offers);
          } catch (error) {
            logger.error('Failed to get offers', { error, restaurantId });
            self.error = true;
          }
        }

        try {
          const client = yield getApiClient();
          const result = yield getOffers({ client, restaurantId, promoCode: self.promoCode });
          const unlockedOffer = get(result, 'data[0]');
          if (unlockedOffer) {
            yield storeActivatedPromoCode({
              client,
              promoCode: self.promoCode,
              expiryDate: unlockedOffer.expiryDate,
            });
            self.activatedPromoCodes = uniq([...self.activatedPromoCodes, toUpper(self.promoCode)]);

            self.unlockedOffers = filter(self.unlockedOffers, offer => offer.id !== unlockedOffer.id);
            self.unlockedOffers = [unlockedOffer, ...self.unlockedOffers];
            return self.unlockedOffers[0];
          }
        } catch (error) {
          if (get(error, 'errors[0].extensions.code') !== 'VALIDATION_ERROR') {
            logger.error('Failed to get offers', { error, restaurantId });
          }

          self.error = true;
        }
        return null;
      }),
      loadActivatedPromoCodes: flow(function*() {
        // As this may be triggered by an unauthenticated user changing store,
        // bail out early if required.
        if (!MemberStore.isSignedIn) return;

        const { restaurantId } = CartStore;

        self.error = false;
        self.loadingPromoCodes = true;

        try {
          const client = yield getApiClient();
          const result = yield getActivatedPromoCodes({ client });
          const activatedPromoCodes = get(result, 'data');
          if (!isEmpty(activatedPromoCodes)) {
            self.activatedPromoCodes = activatedPromoCodes;

            const unlockedOffers = yield getUnlockedOffers({
              client,
              restaurantId,
              promoCodes: self.activatedPromoCodes,
            });

            self.unlockedOffers = unlockedOffers.data;
          }
        } catch (error) {
          logger.error('Failed to load activated promo codes', { error });
          self.error = true;
        }
        self.loadingPromoCodes = false;
      }),
      view: offer => {
        self.offer = offer;
      },
      updatePromoCode: promoCode => {
        self.promoCode = promoCode;
      },
      addOffer(offer) {
        self.offers = [...self.offers, offer];
      },
    };
  })
  .views(self => ({
    get instoreOffers() {
      return filter(self.allOffers, offer => offer.isAvailableInRestaurant);
    },
    get onlineOffers() {
      return filter(self.allOffers, offer => offer.isAvailableOnline);
    },
    get allOffers() {
      return compact(concat(self.unlockedOffers, self.offers));
    },
    getOfferByHash(hash) {
      return find(self.allOffers, { hash });
    },
    get loading() {
      return self.loadingOffers || self.loadingPromoCodes;
    },
  }));

OfferStore.initialState = initialState;

export default OfferStore;
