import { PropTypes } from 'prop-types';
import React from 'react';

import { Alert } from '../alert';
import { Box, Col, Container, Row } from '../grid';
import { Button } from '../button';
import { H1, Subheading } from '../typography';
import { logger } from '../../utils/logger';
import { navigation } from '../../constants';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logger.error(error, errorInfo);
  }

  render() {
    const { hasError } = this.state;
    const { component, children, asPage } = this.props;

    const colWidth = { xs: 12 / 12, md: 8 / 12, lg: 6 / 12 };
    const colOffset = [0, 0, 1 / 12, 3 / 12];
    const errorHeading = 'A critical error has occurred';
    const errorDescription = 'Our team has been notified and is working hard to fix this problem.';

    if (hasError) {
      if (asPage) {
        return (
          <Container>
            <Row flexWrap="wrap">
              <Col mb={4} width={colWidth} offset={colOffset} textAlign="center">
                <H1 my={2}>OOPS!</H1>
                <Subheading>
                  {errorHeading}. {errorDescription}
                </Subheading>
              </Col>
              <Col mb={4} width={colWidth} offset={colOffset} textAlign="center">
                <Button href={navigation.HOME}>Try Again</Button>
              </Col>
            </Row>
          </Container>
        );
      }

      if (component) {
        return component;
      }

      return (
        <Box p={1}>
          <Alert heading={errorHeading} type="error" linkText="try again" href={navigation.HOME}>
            {errorDescription}
          </Alert>
        </Box>
      );
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  asPage: PropTypes.bool,
  children: PropTypes.node.isRequired,
  component: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  asPage: false,
  component: undefined,
};

export { ErrorBoundary };
