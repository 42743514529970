import {
  border,
  boxShadow,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  system,
  typography,
  background,
} from 'styled-system';
import styled from 'styled-components';

const transform = system({
  transform: true,
});

const boxDecorationBreak = system({
  boxDecorationBreak: true,
});

const flexGap = system({
  gap: { property: 'gap', scale: 'space' },
  rowGap: { property: 'rowGap', scale: 'space' },
  columnGap: { property: 'columnGap', scale: 'space' },
});

const Box = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => {
    return (
      !['transform', 'display', 'height', 'width', 'order', 'overflow', 'opacity'].includes(prop) &&
      defaultValidatorFn(prop)
    );
  },
})`
  ${border}
  ${color}
  ${flexbox}
  ${grid}
  ${layout}
  ${position}
  ${space}
  ${typography}
  ${boxShadow}
  ${flexGap}
  ${transform}
  ${boxDecorationBreak}
  ${background}
`;

export { Box };
