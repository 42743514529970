import { Flex } from '@components/grid';
import { colors } from '@nandosaus/constants';
import { PropTypes } from 'prop-types';

export const FLAME_ICON_WIDTH = {
  _: '39px',
  md: '60px',
};

export const FlameResponsiveIcon = ({ fill, isResponsive }) => {
  return (
    <Flex
      width={isResponsive ? { _: FLAME_ICON_WIDTH._, md: FLAME_ICON_WIDTH.md } : '39px'}
      height={isResponsive ? { _: '57px', md: '87px' } : '57px'}
    >
      <svg width="100%" height="100%" viewBox="0 0 60 88" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M16.4916 0.386681C16.9031 1.21963 17.8803 3.14582 19.6804 4.65554L19.6804 4.60347C26.0065 9.86145 22.8176 17.358 21.789 19.3883C21.6861 19.7007 21.5833 19.6486 21.5833 19.3362C21.789 16.3689 20.3492 13.7659 19.1148 12.0479C15.4118 7.8832 16.0288 2.57317 16.2859 0.386681C16.2859 0.282563 16.4916 0.282563 16.4916 0.386681Z"
          fill={fill}
        />
        <path
          d="M23.795 82.7964C24.0521 83.0567 23.795 83.4732 23.4864 83.369L23.4349 83.369C-0.326362 77.5384 -8.0926 54.4762 9.85696 35.9952C13.3543 32.4031 17.4689 29.4357 22.3035 25.1669C25.0808 22.7201 30.5324 14.5468 26.6236 8.03941C26.5722 7.93529 26.6752 7.77911 26.8294 7.83117C41.1788 14.9633 43.6475 28.4986 40.8702 38.5981C39.3787 44.1164 37.1157 48.3332 35.0584 50.5717C34.9556 50.6759 35.0584 50.78 35.1613 50.78C39.5329 50.3635 43.0303 46.7194 44.0075 45.6782C44.1618 45.5741 44.3675 45.6261 44.3675 45.7823C44.6247 47.0317 45.1391 51.3527 43.0304 57.3395C39.173 68.2198 32.9497 69.4692 25.6978 73.2696C19.2689 76.6013 21.9949 80.8702 23.795 82.7964Z"
          fill={fill}
        />
        <path
          d="M49.3324 57.2604C49.4885 57.5689 49.8592 58.2823 50.542 58.8414L50.542 58.8221C52.9415 60.7695 51.7319 63.546 51.3418 64.298C51.3027 64.4137 51.2637 64.3944 51.2637 64.2787C51.3418 63.1797 50.7956 62.2156 50.3274 61.5793C48.9228 60.0369 49.1569 58.0702 49.2544 57.2604C49.2544 57.2218 49.3324 57.2218 49.3324 57.2604Z"
          fill={fill}
        />
        <path
          d="M52.1027 87.7825C52.2002 87.8789 52.1027 88.0331 51.9856 87.9946L51.9661 87.9946C42.9532 85.8351 40.0074 77.2935 46.8158 70.4487C48.1424 69.1183 49.7031 68.0193 51.5369 66.4382C52.5904 65.532 54.6582 62.5049 53.1756 60.0947C53.1561 60.0561 53.1951 59.9983 53.2537 60.0176C58.6965 62.6591 59.6329 67.6722 58.5795 71.4128C58.0137 73.4566 57.1553 75.0183 56.375 75.8474C56.336 75.886 56.375 75.9246 56.414 75.9246C58.0722 75.7703 59.3988 74.4206 59.7695 74.035C59.828 73.9964 59.906 74.0157 59.906 74.0736C60.0036 74.5363 60.1987 76.1367 59.3988 78.354C57.9357 82.3838 55.5751 82.8465 52.8244 84.254C50.3859 85.488 51.4199 87.0691 52.1027 87.7825Z"
          fill={fill}
        />
      </svg>
    </Flex>
  );
};

FlameResponsiveIcon.propTypes = {
  fill: PropTypes.string,
  isResponsive: PropTypes.bool,
};

FlameResponsiveIcon.defaultProps = {
  fill: colors.orange400,
  isResponsive: true,
};
