const VisaWithBorder = props => (
  <svg viewBox="0 0 24 24" {...props} xmlns="http://www.w3.org/2000/svg">
    <title>Visa with border</title>
    <path
      d="M21.8507 4.5H2.14931C2.06724 4.5 1.98503 4.5 1.9031 4.50042C1.83394 4.50099 1.76492 4.5017 1.69576 4.50354C1.54511 4.5075 1.39315 4.51613 1.24438 4.54231C1.0933 4.56877 0.952651 4.61208 0.815484 4.68029C0.680492 4.74722 0.5571 4.83482 0.449948 4.93925C0.34294 5.04383 0.253187 5.16411 0.184604 5.296C0.114715 5.42987 0.0703465 5.56714 0.0433772 5.71473C0.0164078 5.86007 0.00756302 6.00823 0.00350312 6.15512C0.00161816 6.22262 0.000748182 6.28998 0.000313193 6.35734C0 6.43743 0 6.51753 0 6.59777L0 17.3976C0 17.4779 0 17.5578 0.000313193 17.6381C0.000748182 17.7054 0.00161816 17.7729 0.00350312 17.8403C0.00756302 17.987 0.0164078 18.1353 0.0433772 18.2805C0.0703465 18.4281 0.114715 18.5654 0.184604 18.6994C0.253187 18.8312 0.34294 18.9516 0.449948 19.056C0.5571 19.1606 0.680492 19.2482 0.815484 19.315C0.952651 19.3833 1.0933 19.4266 1.24438 19.4532C1.39315 19.4791 1.54511 19.4879 1.69576 19.4919C1.76492 19.4934 1.83394 19.4944 1.9031 19.4947C1.98503 19.4953 2.06724 19.4953 2.14931 19.4953H21.8507C21.9326 19.4953 22.0149 19.4953 22.0968 19.4947C22.1658 19.4944 22.2348 19.4934 22.3043 19.4919C22.4546 19.4879 22.6066 19.4791 22.7556 19.4532C22.9066 19.4266 23.0472 19.3833 23.1845 19.315C23.3195 19.2482 23.4426 19.1606 23.5499 19.056C23.6568 18.9516 23.7465 18.8312 23.8153 18.6994C23.8853 18.5654 23.9297 18.4281 23.9565 18.2805C23.9835 18.1353 23.9922 17.987 23.9962 17.8403C23.9981 17.7729 23.999 17.7054 23.9994 17.6381C24 17.5578 24 17.4779 24 17.3976V6.59777C24 6.51753 24 6.43743 23.9994 6.35734C23.999 6.28998 23.9981 6.22262 23.9962 6.15512C23.9922 6.00823 23.9835 5.86007 23.9565 5.71473C23.9297 5.56714 23.8853 5.42987 23.8153 5.296C23.7465 5.16411 23.6568 5.04383 23.5499 4.93925C23.4426 4.83482 23.3195 4.74722 23.1845 4.68029C23.0472 4.61208 22.9066 4.56877 22.7556 4.54231C22.6066 4.51613 22.4546 4.5075 22.3043 4.50354C22.2348 4.5017 22.1658 4.50099 22.0968 4.50042C22.0149 4.5 21.9326 4.5 21.8507 4.5Z"
      fill="#3C4043"
    />
    <path
      d="M21.8508 5L22.0932 5.00042C22.1587 5.00085 22.2244 5.00156 22.2904 5.0034C22.4052 5.00637 22.5397 5.01245 22.6649 5.03439C22.7737 5.05349 22.865 5.08264 22.9526 5.12609C23.0392 5.16896 23.1183 5.22529 23.1875 5.29265C23.2569 5.36057 23.3147 5.43798 23.3592 5.52331C23.4035 5.60822 23.4331 5.6968 23.4527 5.80379C23.475 5.92478 23.4813 6.05624 23.4843 6.16903C23.4862 6.23257 23.4871 6.29625 23.4873 6.36148C23.4879 6.4403 23.4879 6.51898 23.4879 6.59795V17.3978C23.4879 17.4768 23.4879 17.5555 23.4873 17.6358C23.4871 17.6995 23.4862 17.7632 23.4843 17.827C23.4813 17.9395 23.475 18.071 23.4524 18.1933C23.4331 18.2988 23.4035 18.3874 23.3589 18.4727C23.3145 18.5579 23.2569 18.6352 23.1878 18.7027C23.1182 18.7706 23.0393 18.8267 22.9517 18.87C22.8647 18.9133 22.7737 18.9424 22.6659 18.9612C22.5381 18.9835 22.3981 18.9897 22.2927 18.9924C22.2265 18.9939 22.1605 18.9948 22.0928 18.9951C22.0123 18.9956 21.9314 18.9956 21.8508 18.9956H2.14936C2.14835 18.9956 2.14733 18.9956 2.14617 18.9956C2.06657 18.9956 1.98668 18.9956 1.90562 18.9951C1.83951 18.9948 1.77353 18.9939 1.70973 18.9925C1.60186 18.9897 1.46179 18.9835 1.33506 18.9614C1.22632 18.9424 1.13526 18.9133 1.0471 18.8694C0.960391 18.8265 0.881513 18.7705 0.811915 18.7024C0.742897 18.6352 0.685478 18.5581 0.641109 18.4727C0.596595 18.3876 0.566871 18.2987 0.547296 18.1918C0.524822 18.0697 0.518587 17.939 0.515397 17.827C0.513657 17.7631 0.512932 17.699 0.512497 17.6354L0.512207 17.4475L0.512207 6.54842L0.512497 6.36092C0.512932 6.29681 0.513657 6.23285 0.515397 6.16888C0.518587 6.05681 0.524822 5.92591 0.547586 5.80279C0.566871 5.69709 0.596595 5.60807 0.641254 5.52246C0.685333 5.43784 0.742897 5.36057 0.812205 5.29293C0.881368 5.22529 0.960681 5.16925 1.04782 5.12595C1.13497 5.0825 1.22632 5.05349 1.33506 5.03439C1.46034 5.01245 1.59475 5.00637 1.70988 5.0034C1.77542 5.00156 1.8411 5.00085 1.9062 5.00042L2.14936 5H21.8508Z"
      fill="#FFFFFE"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="6" y="10" width="12" height="4">
      <path
        d="M11.7845 10.297L11.0531 13.8259H10.1685L10.9002 10.297H11.7845ZM15.5057 12.5755L15.9713 11.2505L16.2393 12.5755H15.5057ZM16.4926 13.8259H17.3107L16.5966 10.297H15.8417C15.6719 10.297 15.5287 10.3988 15.4652 10.5558L14.1381 13.8259H15.0666L15.2511 13.2987H16.3858L16.4926 13.8259ZM14.184 12.6736C14.188 11.7423 12.9363 11.6909 12.9449 11.2748C12.9476 11.1483 13.0644 11.0137 13.32 10.9793C13.4466 10.9622 13.7961 10.949 14.192 11.1371L14.3474 10.3889C14.1345 10.3091 13.8608 10.2324 13.5199 10.2324C12.6458 10.2324 12.0303 10.7122 12.0254 11.3989C12.0196 11.9072 12.4646 12.1904 12.7999 12.3594C13.1443 12.5323 13.2598 12.6432 13.2586 12.7979C13.256 13.0348 12.9837 13.1392 12.7292 13.1433C12.2845 13.1504 12.0264 13.0192 11.8208 12.9203L11.6606 13.6936C11.8671 13.7915 12.2486 13.8769 12.6442 13.8811C13.5734 13.8811 14.1811 13.4075 14.184 12.6736ZM10.5206 10.297L9.08755 13.8259H8.15264L7.44743 11.0096C7.40461 10.8362 7.36747 10.7725 7.23728 10.6996C7.0247 10.5804 6.67361 10.4687 6.36475 10.3993L6.38557 10.297H7.89069C8.0824 10.297 8.255 10.4286 8.29853 10.6567L8.671 12.6988L9.59157 10.297H10.5206Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.7845 10.297L11.0531 13.8259H10.1685L10.9002 10.297H11.7845ZM15.5057 12.5755L15.9713 11.2505L16.2393 12.5755H15.5057ZM16.4926 13.8259H17.3107L16.5966 10.297H15.8417C15.6719 10.297 15.5287 10.3988 15.4652 10.5558L14.1381 13.8259H15.0666L15.2511 13.2987H16.3858L16.4926 13.8259ZM14.184 12.6736C14.188 11.7423 12.9363 11.6909 12.9449 11.2748C12.9476 11.1483 13.0644 11.0137 13.32 10.9793C13.4466 10.9622 13.7961 10.949 14.192 11.1371L14.3474 10.3889C14.1345 10.3091 13.8608 10.2324 13.5199 10.2324C12.6458 10.2324 12.0303 10.7122 12.0254 11.3989C12.0196 11.9072 12.4646 12.1904 12.7999 12.3594C13.1443 12.5323 13.2598 12.6432 13.2586 12.7979C13.256 13.0348 12.9837 13.1392 12.7292 13.1433C12.2845 13.1504 12.0264 13.0192 11.8208 12.9203L11.6606 13.6936C11.8671 13.7915 12.2486 13.8769 12.6442 13.8811C13.5734 13.8811 14.1811 13.4075 14.184 12.6736ZM10.5206 10.297L9.08755 13.8259H8.15264L7.44743 11.0096C7.40461 10.8362 7.36747 10.7725 7.23728 10.6996C7.0247 10.5804 6.67361 10.4687 6.36475 10.3993L6.38557 10.297H7.89069C8.0824 10.297 8.255 10.4286 8.29853 10.6567L8.671 12.6988L9.59157 10.297H10.5206Z"
        fill="#1A1F71"
      />
    </g>
    <path d="M19.1351 7.09473H4.54053V8.91905H19.1351V7.09473Z" fill="#1A1F71" />
    <path d="M19.1351 15H4.54053V16.8243H19.1351V15Z" fill="#F7B600" />
  </svg>
);

export { VisaWithBorder };
