/**
 * Single source of truth for spacing, in a format that is required
 * by Styled System but also in named form for wider usage
 *
 *
 */

const space = [0, '1rem', '2rem', '3rem', '4rem', '5rem', '6rem'];

export { space };
