import { ANALYTICS_EVENTS } from '@nandosaus/constants';
import { map } from 'lodash';

const { PRODUCT_VIEWED, PRODUCT_ADDED, CHECKOUT_STARTED, PAYMENT_INFO_ENTERED, ORDER_COMPLETED } = ANALYTICS_EVENTS;

const FACEBOOK_PIXEL_EVENTS = {
  [PRODUCT_VIEWED]: 'ViewContent',
  [PRODUCT_ADDED]: 'AddToCart',
  [PAYMENT_INFO_ENTERED]: 'AddPaymentInfo',
  [CHECKOUT_STARTED]: 'InitiateCheckout',
  [ORDER_COMPLETED]: 'Purchase',
};

const FACEBOOK_PIXEL_CONTENT_TYPE = {
  PRODUCT: 'product',
};

const currency = process.env.currencyCode;
/* eslint-disable prefer-destructuring */
const facebookPixelId = process.env.facebookPixelId;
const facebookAdditionalPixelId = process.env.facebookAdditionalPixelId;
/* eslint-enable prefer-destructuring */

let facebookPixel;
let isPixedLoaded = false;

export const facebookPlugin = (userConfig = {}) => {
  return {
    name: 'facebook',

    config: userConfig,

    async initialize() {
      if (typeof window === 'undefined') {
        return;
      }

      const { default: ReactFacebookPixel } = await import('react-facebook-pixel');
      facebookPixel = ReactFacebookPixel;

      if (!isPixedLoaded) {
        facebookPixel.init(facebookPixelId, undefined, {
          autoConfig: true,
        });

        // @note: NZ has multiple facebook tracking pixels so we need to support both
        if (facebookAdditionalPixelId) {
          facebookPixel.fbq('init', facebookAdditionalPixelId);
        }

        isPixedLoaded = true;
      }
    },

    page() {
      facebookPixel.pageView();
    },

    // @see: https://www.facebook.com/business/help/402791146561655?id=1205376682832142&locale=en_GB
    track({ payload }) {
      switch (payload.event) {
        case PRODUCT_VIEWED: {
          return facebookPixel.track(FACEBOOK_PIXEL_EVENTS[payload.event], {
            currency,
            content_ids: [payload.properties.product_id],
            content_name: payload.properties.name,
            content_type: FACEBOOK_PIXEL_CONTENT_TYPE.PRODUCT,
          });
        }

        case PRODUCT_ADDED: {
          return facebookPixel.track(FACEBOOK_PIXEL_EVENTS[payload.event], {
            currency,
            content_ids: [payload.properties.product_id],
            content_name: payload.properties.name,
            content_type: FACEBOOK_PIXEL_CONTENT_TYPE.PRODUCT,
            value: payload.properties.price,
          });
        }

        case CHECKOUT_STARTED: {
          return facebookPixel.track(FACEBOOK_PIXEL_EVENTS[payload.event], {
            currency,
            content_type: FACEBOOK_PIXEL_CONTENT_TYPE.PRODUCT,
            contents: map(payload.properties.products, product => ({
              id: product.product_id,
              quantity: product.quantity,
            })),
            value: payload.properties.total,
          });
        }

        case PAYMENT_INFO_ENTERED: {
          return facebookPixel.track(FACEBOOK_PIXEL_EVENTS[payload.event], {
            currency,
            content_type: FACEBOOK_PIXEL_CONTENT_TYPE.PRODUCT,
            contents: map(payload.properties.products, product => ({
              id: product.product_id,
              quantity: product.quantity,
            })),
            value: payload.properties.total,
          });
        }

        case ORDER_COMPLETED: {
          return facebookPixel.track(FACEBOOK_PIXEL_EVENTS[payload.event], {
            currency,
            content_type: FACEBOOK_PIXEL_CONTENT_TYPE.PRODUCT,
            contents: map(payload.properties.products, product => ({
              id: product.product_id,
              quantity: product.quantity,
            })),
            value: payload.properties.total,
          });
        }

        // do nothing if the event is not a standard facebook event
        default:
          return () => {};
      }
    },

    loaded() {
      return isPixedLoaded;
    },
  };
};
