import { flow, getEnv, types } from 'mobx-state-tree';
import gql from 'graphql-tag';
import moment from 'moment';
import { RECEIPT_ERRORS } from '@nandosaus/constants';

const initialState = {};

const ADD_RECEIPT_MUTATION = gql`
  mutation addReceipt($input: AddReceiptInput!) {
    addReceipt(input: $input)
  }
`;
const ADD_RECEIPT_MEMBER_MUTATION = gql`
  mutation addReceiptMember($input: AddReceiptInput!) {
    addReceiptMember(input: $input)
  }
`;

class ReceiptError extends Error {
  constructor(message, { code } = {}) {
    super(message);
    this.code = code;
  }
}

const addReceipt = ({ client, input }) =>
  client.mutate({
    mutation: ADD_RECEIPT_MUTATION,
    variables: { input },
  });

const addReceiptMember = ({ client, input }) =>
  client.mutate({
    mutation: ADD_RECEIPT_MEMBER_MUTATION,
    variables: { input },
  });

const ReceiptStore = types.model('ReceiptStore', {}).actions(self => {
  const { getApiClient, logger } = getEnv(self);
  return {
    addReceipt: flow(function*({ restaurantId, receiptNumber, date, time, total }) {
      try {
        const input = {
          restaurantId,
          receiptNumber,
          transactionDateTime: `${moment(date).format('YYYY-MM-DD')}T${moment(time).format('HH:mm:ss')}`,
          total,
        };
        const client = yield getApiClient();
        yield addReceipt({ client, input });
      } catch (error) {
        const message = error?.errors?.[0]?.message ?? error.message;
        const code = error?.errors?.[0]?.extensions?.code;
        logger.error('There was an error adding receipt', { message });

        if (error?.errors?.[0]?.extensions?.code === RECEIPT_ERRORS.TRANSACTION_ERROR) {
          throw new ReceiptError(RECEIPT_ERRORS.TRANSACTION_ERROR, { code });
        }

        throw new ReceiptError(message);
      }
    }),
    addReceiptMember: flow(function*({ restaurantId, receiptNumber, date, time, total, tokenReCaptchaV2 }) {
      try {
        const input = {
          restaurantId,
          receiptNumber,
          transactionDateTime: `${moment(date).format('YYYY-MM-DD')}T${moment(time).format('HH:mm:ss')}`,
          total,
          tokenReCaptchaV2,
        };
        const client = yield getApiClient();
        yield addReceiptMember({ client, input });
      } catch (error) {
        const message = error?.errors?.[0]?.message ?? error.message;
        const code = error?.errors?.[0]?.extensions?.code;
        logger.error('There was an error adding receipt', { message });

        if (error?.errors?.[0]?.extensions?.code === RECEIPT_ERRORS.TRANSACTION_ERROR) {
          throw new ReceiptError(RECEIPT_ERRORS.TRANSACTION_ERROR, { code });
        }

        throw new ReceiptError(message);
      }
    }),
  };
});

ReceiptStore.initialState = initialState;

export default ReceiptStore;
