import { types } from 'mobx-state-tree';

// @TODO: Rename this to DraftPaymentMethod.

const CreditCard = types.model('CreditCard', {
  brand: '',
  browserInfo: types.maybe(types.frozen()),
  encryptedCardNumber: types.string,
  encryptedExpiryMonth: types.string,
  encryptedExpiryYear: types.string,
  encryptedSecurityCode: types.string,
  expiryMonth: '',
  expiryYear: '',
  id: types.identifier,
  lastFour: '',
  name: '',
  riskData: types.maybe(types.frozen()),
});

export default CreditCard;
