import { ORDER_TYPES, SCHEDULE_TYPES } from '@nandosaus/constants';
import { types } from 'mobx-state-tree';

import Fulfilment from '../../models/ordering-context/fulfilments';
import Restaurant from '../../models/restaurant';
import { getAllAsList as getAllSubCartsAsList, SubCarts } from '../../models/sub-cart';
import { formatDateString } from '../../util/time';

const initialState = {};

/**
 * OrderConfirmationState exists to serve information for the checkout success screen.
 *
 * Any data being pulled into eg. web checkout success page must use this state to do it,
 * as places like the CartStore, OrderStore etc may be overridden at any point.
 */
const OrderConfirmationState = types
  .model('OrderConfirmationState', {
    deliveryTrackingUrl: types.maybe(types.string),
    estimatedDeliveryTime: types.maybe(types.string),
    orderId: types.maybe(types.string),
    orderReference: types.maybe(types.string),
    orderType: types.maybe(types.enumeration([ORDER_TYPES.PICK_UP, ORDER_TYPES.DINE_IN, ORDER_TYPES.DELIVERY])),
    orderPickupType: types.maybe(types.enumeration([SCHEDULE_TYPES.ASAP, SCHEDULE_TYPES.SCHEDULED])),
    pickupTime: types.maybe(types.string),
    restaurant: types.maybe(types.reference(Restaurant)),
    recipientsEmail: types.maybe(types.string),
    subCarts: SubCarts,
    fulfilment: types.maybe(Fulfilment),
  })
  .actions(self => ({
    update({
      deliveryTrackingUrl,
      estimatedDeliveryTime,
      orderId,
      orderReference,
      orderType,
      orderPickupType,
      pickupTime,
      restaurant,
      recipientsEmail,
      subCarts,
      fulfilment,
    }) {
      self.deliveryTrackingUrl = deliveryTrackingUrl || undefined;
      self.estimatedDeliveryTime = estimatedDeliveryTime || undefined;
      self.orderId = orderId;
      self.orderReference = orderReference;
      self.orderType = orderType;
      self.orderPickupType = orderPickupType || undefined;
      self.pickupTime = pickupTime || undefined;
      self.restaurant = restaurant;
      self.recipientsEmail = recipientsEmail;
      self.subCarts = subCarts || undefined;
      self.fulfilment = fulfilment || undefined;
    },
    reset() {
      self.update({});
    },
  }))
  .views(self => ({
    get formattedPickupTime() {
      const { pickupTime } = self;
      return formatDateString(pickupTime);
    },
    get formattedDeliveryTime() {
      const { estimatedDeliveryTime } = self;
      return formatDateString(estimatedDeliveryTime);
    },
    get subCartsAsArray() {
      return getAllSubCartsAsList(self.subCarts);
    },
    get tableNumber() {
      return self.fulfilment?.tableNumber;
    },
  }));

OrderConfirmationState.initialState = initialState;

export default OrderConfirmationState;
