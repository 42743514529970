import { camelCase, compact, filter, flattenDeep, get, includes, isArray, isEmpty, map, size } from 'lodash';
import { DIETARY_STATUS, DIETARY_TYPES } from '@nandosaus/constants';
import { getEnv, types } from 'mobx-state-tree';

const initialState = {
  vegetarian: DIETARY_STATUS.NO,
  vegan: DIETARY_STATUS.NO,
  noDairy: DIETARY_STATUS.NO,
  noGluten: DIETARY_STATUS.NO,
};

const dietaryEnumValues = [DIETARY_STATUS.NO, DIETARY_STATUS.YES];

const getValueFromArray = arr => {
  if (includes(arr, DIETARY_STATUS.YES)) {
    return DIETARY_STATUS.YES;
  }

  return DIETARY_STATUS.NO;
};

const DietaryPreferencesStore = types
  .model('DietaryPreferences', {
    vegetarian: types.enumeration(dietaryEnumValues),
    vegan: types.enumeration(dietaryEnumValues),
    noDairy: types.enumeration(dietaryEnumValues),
    noGluten: types.enumeration(dietaryEnumValues),
  })
  .actions(self => {
    const { logger } = getEnv(self);
    return {
      /**
       * Save the dietary preferences that are passed through as an object to the state store.
       * @param {preferences} preferences
       */
      saveDietaryPreferences(preferences) {
        const vegetarian = get(preferences, 'vegetarian', self.vegetarian);
        const vegan = get(preferences, 'vegan', self.vegan);
        const noDairy = get(preferences, 'noDairy', self.noDairy);
        const noGluten = get(preferences, 'noGluten', self.noGluten);

        self.vegetarian = isArray(vegetarian) ? getValueFromArray(vegetarian) : vegetarian;
        self.vegan = isArray(vegan) ? getValueFromArray(vegan) : vegan;
        self.noDairy = isArray(noDairy) ? getValueFromArray(noDairy) : noDairy;
        self.noGluten = isArray(noGluten) ? getValueFromArray(noGluten) : noGluten;
      },
      /**
       * Called when there are dietary preferences saved in localStorage.
       */
      setSavedDietaryPreferences(savedPreferences) {
        try {
          Object.assign(self, savedPreferences);
        } catch (error) {
          logger.error(`Error applying saved dietary preferences from local storage`, { error });
        }
      },
      /**
       * Clear all dietary preferences and reset back to initial state.
       */
      clearDietaryPreferences() {
        this.saveDietaryPreferences(initialState);
      },
    };
  })
  .views(self => ({
    /**
     * Get the current preferences as an array
     */
    get dietaryPreferencesList() {
      const arrayOfPreferences = [];

      if (self.vegetarian === DIETARY_STATUS.YES) {
        arrayOfPreferences.push(DIETARY_TYPES.VEGETARIAN);
      }

      if (self.vegan === DIETARY_STATUS.YES) {
        arrayOfPreferences.push(DIETARY_TYPES.VEGAN);
      }

      if (self.noDairy === DIETARY_STATUS.YES) {
        arrayOfPreferences.push(DIETARY_TYPES.NO_DAIRY);
      }

      if (self.noGluten === DIETARY_STATUS.YES) {
        arrayOfPreferences.push(DIETARY_TYPES.NO_GLUTEN);
      }

      return arrayOfPreferences;
    },

    getDietaryPreferencesOptionsList(product) {
      return filter(
        self.dietaryPreferencesList,
        item => !isEmpty(compact(product.suitability[camelCase(item)].standardOptions))
      );
    },

    getDietaryPreferencesWithNoOptionsList(product) {
      return filter(self.dietaryPreferencesList, item =>
        isEmpty(compact(product.suitability[camelCase(item)].standardOptions))
      );
    },

    getListOfProteinOptions(product) {
      return map([DIETARY_TYPES.VEGETARIAN, DIETARY_TYPES.VEGAN], type => {
        const dietaryType = camelCase(type);

        const options = [];
        if (self[dietaryType] === DIETARY_STATUS.YES) {
          options.push(product.suitability[dietaryType].standardOptions);
        }

        return options;
      });
    },

    getDietaryMessageTitle(product) {
      return size(product.dietaryPreferencesOptionsList) > 1
        ? product.dietaryPreferencesOptionsList.join(' & ')
        : product.dietaryPreferencesOptionsList;
    },

    getOptionsList(product) {
      return filter(flattenDeep(self.getListOfProteinOptions(product)), option => size(option) >= 1);
    },
  }));

DietaryPreferencesStore.initialState = initialState;

export default DietaryPreferencesStore;
