/**
 * Styled Components theme, plugs into <ThemeProvider />
 *
 * Grid uses - https://github.com/rebassjs/grid
 *
 * The 'space' values inside the theme object are used exclusively for the
 * Rebass package which has a ton of padding and margin helpers. While we
 * will typically use our 'spacing' object when creating custom components,
 * this will likely come in handy when using Rebass and any associated
 * Styled System packages
 */

import { breakpoints } from './breakpoints';
import { buttonsTheme } from '../components/button/theme';
import { colors } from './colors';
import { fontSize } from './font-size';
import { fontWeight } from './font-weight';
import { globalStyles } from './global';
import { rem } from './rem';
import { space } from './space';
import { zIndexes } from './z-indexes';

const theme = {
  breakpoints,
  fontSize,
  fontWeight,
  rem,
  space,
  colors,
  zIndexes,
  fonts: {
    body: globalStyles.baseFontFamily,
    brand: globalStyles.brandFontFamily,
    heading: globalStyles.headingFontFamily,
  },
  ...buttonsTheme,
};

export { theme };
