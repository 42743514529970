import { ORDER_TYPES, SCHEDULE_TYPES } from '@nandosaus/constants';
import { getRoot, isStateTreeNode, types } from 'mobx-state-tree';
import moment from 'moment';

/** Fulfilments represent how an order gets to a customer
 *
 * They _must_ have the following properties
 * - type: unique string value for telling them apart
 * - restaurantId: store that will be making the food
 * - fields: list of properties and if they can be written (settings)
 *
 * The `type` property should be the only required value on creation
 */

const DineInFulfilment = types
  .model(ORDER_TYPES.DINE_IN, {
    type: types.literal(ORDER_TYPES.DINE_IN),
    restaurantId: types.maybe(types.string),
    tableNumber: types.maybe(
      types.refinement(
        types.string,
        v => v.match(/^[1-9][0-9]?$/),
        () => 'Value must be an integer string'
      )
    ),
  })
  .views(() => ({
    get fields() {
      return [
        { name: 'restaurantId', writable: true },
        { name: 'tableNumber', writable: true },
      ];
    },
    get showMap() {
      return true;
    },
  }));

const PickUpFulfilment = types
  .model(ORDER_TYPES.PICK_UP, {
    type: types.literal(ORDER_TYPES.PICK_UP),
    restaurantId: types.maybe(types.string),
    schedule: types.optional(
      types.enumeration('schedule', [SCHEDULE_TYPES.ASAP, SCHEDULE_TYPES.SCHEDULED]),
      SCHEDULE_TYPES.ASAP
    ),
    // This is not a Date type because it comes directly from restaurant.availablePickupTimes and
    // we need to occasionally check that it's in the list. Uses moments default formatting so could
    // refine on that
    time: types.maybe(types.string),
  })
  .views(self => ({
    get fields() {
      return [
        { name: 'restaurantId', writable: true },
        { name: 'schedule', writable: true },
        { name: 'time', writable: true },
      ];
    },
    get isAsap() {
      return self.schedule === SCHEDULE_TYPES.ASAP;
    },
    // A formatted date or "ASAP" string.
    get formattedOrderTime() {
      const { isAsap, time } = self;

      if (isAsap) {
        return 'ASAP';
      }

      const now = moment(new Date());

      const orderMoment = moment(time);

      if (now.isSame(orderMoment, 'day')) {
        return orderMoment.format('LT');
      }

      return moment(time).format('MMM D, LT');
    },
    get showMap() {
      return true;
    },
  }));

const DeliveryFulfilment = types
  .model(ORDER_TYPES.DELIVERY, {
    type: types.literal(ORDER_TYPES.DELIVERY),
    // This is set false when we aren't sure the map will show the correct location
    showMap: types.optional(types.boolean, true),
  })
  .views(self => {
    return {
      get restaurantId() {
        // Since we create these in a detached state we need to skip looking up
        // stores until we're on the tree
        if (!isStateTreeNode(self)) {
          return undefined;
        }

        const { DeliveryStore } = getRoot(self);

        return DeliveryStore.deliveryRestaurant?.id;
      },
      get fields() {
        return [{ name: 'restaurantId', writable: false }];
      },
      // Proxy delivery store fields here like address etc so all fulfilment settings are updated via
      // fulfilment object for consistency + abstraction
    };
  });

const Fulfilment = types.union(DineInFulfilment, PickUpFulfilment, DeliveryFulfilment);

const Map = {
  [ORDER_TYPES.DINE_IN]: DineInFulfilment,
  [ORDER_TYPES.PICK_UP]: PickUpFulfilment,
  [ORDER_TYPES.DELIVERY]: DeliveryFulfilment,
};

export default Fulfilment;
export { Map, DineInFulfilment, PickUpFulfilment, DeliveryFulfilment };
