import { types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

import PerksShopProduct from '../perks-shop-product';

const ProductVariant = types.model('ProductVariant', {
  color: types.maybeNull(types.string),
  plu: types.number,
  size: types.maybeNull(types.string),
  stock: types.maybeNull(types.number),
});

const CartItem = types.model('CartItem', {
  id: types.optional(types.identifier, uuidv4),
  product: types.reference(PerksShopProduct),
  quantity: types.maybeNull(types.number),
  selectedVariant: types.maybeNull(ProductVariant),
});

export default CartItem;
