import { types } from 'mobx-state-tree';

const DeliveryService = types.model('DeliveryService', {
  live: types.boolean,
  link: types.string,
});

const DeliveryServices = types.model('DeliveryServices', {
  uberEats: DeliveryService,
  menulog: DeliveryService,
  doorDash: DeliveryService,
});

export default DeliveryServices;
