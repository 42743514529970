import { types } from 'mobx-state-tree';

import { dollarsFromCentsFormatter, priceFormatter } from '../../util/formatter';

const DeliveryFee = types
  .model('DeliveryFee', {
    cents: types.number,
    points: types.number,
  })
  .views(self => ({
    get dollarValue() {
      return dollarsFromCentsFormatter(self.cents);
    },
    get formatted() {
      return priceFormatter(self.cents);
    },
  }));

export default DeliveryFee;
