import {
  ANALYTICS_EVENTS,
  CANONICAL_CHOICE_MAPPING,
  CANONICAL_CHOICE_NAMES,
  CANONICAL_PROTEIN_MAPPING,
  KIDS_PRODUCT_DISCLAIMER,
  MENU_CATEGORIES,
  SIDE_AND_DRINK_OPTIONS_REQUIRED_PRODUCT_DISCLAIMER,
  SIDE_AND_SNACK_PRODUCT_DISCLAIMER,
  SIDE_NOT_INCLUDED_INITIALLY_DISCLAIMER,
  SIDE_NOT_INCLUDED_INITIALLY_DISCLAIMER_NANDOCAS,
  SIDE_NOT_INCLUDED_INITIALLY_DISCLAIMER_NANDOCAS_PLUS,
  SIDE_OPTIONS_REQUIRED_PRODUCT_DISCLAIMER,
} from '@nandosaus/constants';
import { applySnapshot, flow, getEnv, getRoot, getSnapshot, resolveIdentifier, types } from 'mobx-state-tree';
import {
  compact,
  every,
  filter,
  find,
  first,
  flatten,
  forEach,
  get,
  head,
  includes,
  isArray,
  isEmpty,
  map,
  reduce,
  size,
} from 'lodash';

import { kilojoulesFormatter } from '../../util/formatter';
import OrderItem from '../../models/order-item';
import Prices from '../../models/prices';
import Product from '../../models/product';

const initialState = {
  error: false,
  loading: false,
  orderItem: undefined,
  buildYourOwnChoiceStep: 0,
};

// Tear & Share PLU required for bespoke disclaimer logic
export const TEAR_AND_SHARE_PLU = '1001584';

const ProductDetailsState = types
  .model('ProductDetailsState', {
    error: false,
    loading: false,
    isResetting: false,
    orderItem: types.maybe(OrderItem),
    buildYourOwnChoiceStep: 0,
  })
  .actions(self => {
    const { CartStore, MenuStore } = getRoot(self);
    const { analytics, logger } = getEnv(self);

    return {
      setIsResetting(resetting) {
        self.isResetting = resetting;
      },

      reset() {
        applySnapshot(self, initialState);
      },

      setError(displayError) {
        self.error = displayError;
      },

      goToStep(step) {
        const { product } = self.orderItem;
        const totalByoSteps = size(product.byoChoices);
        if (step < totalByoSteps) {
          self.buildYourOwnChoiceStep = step;
        } else {
          self.buildYourOwnChoiceStep = totalByoSteps - 1;
        }
      },

      resetStep() {
        self.buildYourOwnChoiceStep = 0;
      },

      nextStep() {
        const nextStepReady = self.nextStepReady();
        if (nextStepReady) {
          self.buildYourOwnChoiceStep += 1;
        }
      },

      nextStepReady() {
        const { product } = self.orderItem;
        const stepChoice = product.byoChoices[self.buildYourOwnChoiceStep];
        if (isArray(stepChoice)) {
          return every(stepChoice, choice => {
            const options = self.getOptionsForChoice(choice.name);
            const hasValidOptionsSelected =
              options.length >= choice.minimumOptionLimit && options.length <= choice.maximumOptionLimit;
            const hasValidQuantitiesSelected = every(
              options,
              ({ quantity }) => quantity <= choice.maximumOptionQuantity
            );

            if (hasValidOptionsSelected && hasValidQuantitiesSelected) {
              return true;
            }

            return false;
          });
        }
        const options = self.getOptionsForChoice(stepChoice.name);
        const quantities = reduce(options, (acc, option) => acc + option.quantity, 0);
        return quantities >= stepChoice.minimumOptionLimit && quantities <= stepChoice.maximumOptionLimit;
      },

      previousStep() {
        self.buildYourOwnChoiceStep -= 1;
      },

      loadDetailedProduct: flow(function*() {
        const { product } = self.orderItem;
        const { menu } = MenuStore;

        /*
          If the product being viewed has only been partially loaded then load its full details.
        */
        if (!product.isDetailedProduct) {
          self.loading = true;

          try {
            yield menu.loadProduct(product.id);
          } catch (error) {
            logger.error('Failed to get detailed product data', {
              error,
              orderType: menu.orderType,
              productId: product.id,
              restaurantId: menu.restaurantId,
            });

            self.error = true;
            self.loading = false;

            return;
          }
        }

        const otherSizesIds = getSnapshot(product.otherSizes);
        const otherSizesToLoad = product.otherSizes
          .map((otherSize, index) => {
            if (otherSize === undefined || !otherSize.isDetailedProduct) {
              return otherSizesIds[index];
            }

            return undefined;
          })
          .filter(identifier => identifier !== undefined);

        if (otherSizesToLoad.length > 0) {
          self.loading = true;

          try {
            yield menu.loadProducts(otherSizesToLoad);
          } catch (error) {
            logger.error('Failed to get detailed product data', {
              error,
              orderType: menu.orderType,
              productIds: otherSizesToLoad,
              restaurantId: menu.restaurantId,
            });

            self.error = true;
          }
        }

        self.loading = false;
      }),
      viewProduct: flow(function*({ product: givenProduct, analyticsCategory }) {
        let product = givenProduct;

        if (!get(product, 'isAvailable')) {
          /**
           * Since menu cards are based on a particular product (such as "Chips Regular") which may not be available
           * we need to check for availability and open detail for an available otherSize if possible
           */
          const getFirstAvailableOtherSize = async () => {
            self.orderItem = { product };
            await self.loadDetailedProduct(); // ensure other sizes are loaded, particularly important for NZ

            const availableOtherSize = find(
              product.otherSizes,
              ({ isMealProduct, isAvailable }) => !isMealProduct && isAvailable
            );

            return availableOtherSize;
          };

          const firstAvailableOtherSize = yield getFirstAvailableOtherSize();
          product = firstAvailableOtherSize || product;
        }

        const cartOrderItemIds = map(CartStore.orderItems, 'id');
        const currentOrderItemId = get(self, 'orderItem.id');
        const currentProductId = get(self, 'orderItem.product.id');

        // If the product is already loaded and there is no order item id
        // we can re-use the data to allow the customer to resume editing
        // and adding the product to their order.

        analytics.track(ANALYTICS_EVENTS.PRODUCT_VIEWED, {
          product_id: product.plu,
          name: product.name,
          category: get(product, 'productCategory.name'),
          analyticsCategory,
        });

        const isEditingCartOrderItem = includes(cartOrderItemIds, currentOrderItemId);
        if (currentProductId && product.id === currentProductId && !isEditingCartOrderItem) {
          return;
        }

        const defaultOptionsByChoiceName = reduce(
          product.choices,
          (accumulator, choice) => {
            if (isEmpty(choice.defaultSelectedOptions)) {
              return accumulator;
            }
            const result = map(choice.defaultSelectedOptions.toJSON(), optionId => ({ option: optionId, quantity: 1 }));
            return {
              ...accumulator,
              [choice.name]: result,
            };
          },
          {}
        );

        self.orderItem = {
          choices: defaultOptionsByChoiceName,
          product,
        };

        self.loadDetailedProduct();
      }),
      editOrderItem: flow(function*({ orderItem }) {
        const choices = orderItem.choices.toJSON();

        self.orderItem = {
          ...orderItem,
          choices,
        };
        // NOTE: if a product is edited that has been added by the upsell journey it will not have its data loaded
        // so we need to check here to make sure it has all the data so it can be edited
        const { product } = self.orderItem;
        if (!product.isDetailedProduct) {
          yield self.loadDetailedProduct();
        }
      }),
      removeFromCart() {
        CartStore.removeOrderItem(self.orderItem);
      },
      /**
       * Adds or Updates the orderItem into the CartStore.
       */
      saveToCart() {
        /*
          @NOTE: We use getSnapshot to prevent choices/options having an issue being moved or duplicated.
        */
        const orderItem = getSnapshot(self.orderItem);

        if (self.isEditingCartItem) {
          CartStore.spliceOrderItemById(self.id, orderItem);
        } else {
          CartStore.addOrderItems([orderItem]);
        }
      },
      updateQuantity(value) {
        if (value >= 1 && value <= 9) {
          self.orderItem.quantity = value;
        }
      },
      updateOptionQuantityForChoice(choiceName, id, value) {
        const option = self.getOptionForChoice(choiceName, id);
        if (option) {
          option.updateQuantity(value);
        }
      },
      removeOptionForChoice(choiceName, optionId) {
        const optionIds = self.optionIdsForChoiceName(choiceName);
        self.updateChoice(
          choiceName,
          optionIds.filter(id => id !== optionId)
        );
      },
      updateSelectedOptionQuantityForChoice(choiceName, ids, value, whenValue = -1) {
        const options = self.getOptionsForChoice(choiceName);
        options.forEach(orderOption => {
          if (ids.includes(orderOption.option.id)) {
            if (whenValue >= 0) {
              if (orderOption.quantity === whenValue) {
                orderOption.updateQuantity(value);
              }
            } else {
              orderOption.updateQuantity(value);
            }
          } else {
            orderOption.updateQuantity(0);
          }
        });
      },
      updateChoice(choiceName, optionIdOrIds) {
        const isInputArray = isArray(optionIdOrIds);
        const orderOptions = map(isInputArray ? optionIdOrIds : [optionIdOrIds], optionId => ({
          option: optionId,
          quantity: self.getOptionQuantityForChoice(choiceName, optionId) || 1,
        }));
        self.orderItem.choices.set(choiceName, orderOptions);
      },

      setSize(sizeId) {
        if (self.product.id === sizeId) {
          return;
        }

        const productForSize = resolveIdentifier(Product, getRoot(self), sizeId);

        self.orderItem.migrateToProduct(productForSize);
      },

      updateChoiceAndMigrate(choiceName, selectedOptionIdOrIds) {
        const { DRINK, SIDE } = CANONICAL_CHOICE_NAMES;

        const selectedChoice = self.orderItem.choices.get(choiceName);
        const selectedOption = selectedChoice?.find(c => c.option.id === selectedOptionIdOrIds);

        if (selectedOption) {
          self.removeChoice(choiceName);
        } else {
          self.updateChoice(choiceName, selectedOptionIdOrIds);
        }

        const choicesKeys = [...self.orderItem.choices.keys()];
        const hasDrink = choicesKeys?.some(name => CANONICAL_CHOICE_MAPPING[name] === DRINK);
        const hasSide = choicesKeys?.some(name => CANONICAL_CHOICE_MAPPING[name] === SIDE);
        const isMeal = self.orderItem.isMealProduct;

        if (isMeal) {
          const shouldBeItem = !hasDrink || !hasSide;
          if (shouldBeItem) {
            self.orderItem.migrateToProduct(self.product.itemProduct);
          }
        } else {
          const shouldBeMeal = hasDrink && hasSide;
          if (shouldBeMeal) {
            self.orderItem.migrateToProduct(self.product.mealProduct);
          }
        }
      },

      saveMealChoices(optionsByChoiceId) {
        if (!self.product.isMealProduct) {
          self.orderItem.migrateToProduct(self.product.mealProduct);
        }

        forEach(optionsByChoiceId, (options, choiceId) => {
          const choice = self.product.choices.find(c => c.id === choiceId);

          if (!choice) return;

          const orderOptions = options.map(option => {
            return {
              id: option.id,
              option,
              quantity: 1,
            };
          });
          self.orderItem.choices.set(choice.name, orderOptions);
        });
      },
      removeChoice(choiceKey) {
        const { orderItem } = self;
        orderItem.choices.delete(choiceKey);
      },
    };
  })
  .views(self => ({
    /* @TODO: Remove these if we re-factor the app/web ProductDetails */
    get choices() {
      return get(self, 'orderItem.choices');
    },

    get favourited() {
      return false;
    },
    get id() {
      const { CartStore } = getRoot(self);
      const cartOrderItemIds = map(CartStore.orderItems, 'id');
      const currentOrderItemId = get(self, 'orderItem.id');
      const isEditingCartOrderItem = includes(cartOrderItemIds, currentOrderItemId);
      return isEditingCartOrderItem ? currentOrderItemId : null;
    },

    get isEditingCartItem() {
      return self.id !== null;
    },

    get formattedKilojoules() {
      // Convenience method.
      return kilojoulesFormatter(self.kilojoules);
    },

    get kilojoules() {
      let initialKilojoules = 0;

      const itemIsMealButBaseChoicesNotYetSelected =
        get(self, 'orderItem.isMealProduct') && self.orderItem.nutritionInformation.length === 1;
      if (itemIsMealButBaseChoicesNotYetSelected) {
        initialKilojoules = self.product.itemProduct.kilojoules;
      }

      const kilojoules = initialKilojoules + get(self, 'orderItem.kilojoules');

      if (!kilojoules) {
        return null;
      }
      return kilojoules;
    },
    get product() {
      return get(self, 'orderItem.product');
    },
    get quantity() {
      return get(self, 'orderItem.quantity');
    },
    /* End Remove. */

    get isValid() {
      return get(self, 'orderItem.isValid');
    },

    get missingRequiredChoices() {
      return get(self, 'orderItem.missingRequiredChoices');
    },

    get points() {
      return self.orderItem.points;
    },
    get formattedPoints() {
      return self.points.toLocaleString();
    },
    get ingredientChoicePrices() {
      return self.orderItem.calculateChoiceTotalPrice(self.orderItem.product.ingredientChoices);
    },
    get ingredientChoiceSelections() {
      const selections = map(self.product.ingredientChoices, choice => self.choices.get(choice.name) || []);
      const options = map(selections, selection => map(selection, 'option'));
      return flatten(options);
    },

    // @NOTE: chosenMealChoiceOptions returns an array of the user's chosen options for side and drink choices.
    // @TODO: Move this onto orderItem.
    get chosenMealChoiceOptions() {
      const { choices, product } = self.orderItem;
      return flatten(
        compact(
          product.mealChoices.map(choice => {
            return choices.get(choice.name);
          })
        )
      );
    },

    /**
     * Return true if at least one side is required to be selected for the product.
     */
    get sideOptionRequired() {
      const choices = get(self, 'orderItem.product.choices');

      const productSides = head(filter(choices, 'isSide'));

      return get(productSides, 'minimumOptionLimit') >= 1;
    },

    /**
     * Return true if Red Bull drink is required to be selected for the product.
     */
    get redBullOptionRequired() {
      const choices = get(self, 'orderItem.product.choices');
      const productSides = head(filter(choices, obj => /^Red Bull/.test(obj.name)));
      return get(productSides, 'minimumOptionLimit') >= 1;
    },

    /**
     * Return true is the meal is a kids meal. 205-1387 205-1386
     */
    get isKidsMeal() {
      const { MenuStore } = getRoot(self);
      const id = get(self, 'orderItem.product.id');

      const menuCategory = MenuStore.getProductCategory(id);

      return get(menuCategory, 'handle') === MENU_CATEGORIES.KIDS;
    },

    /**
     * Get the combined cost of the current product meal choices. This is only used in the Australian market UX.
     */
    get mealChoicePrices() {
      return self.orderItem.calculateChoiceTotalPrice(self.orderItem.product.mealChoices);
    },

    // @TODO: rename to prices (to indicate that it is a Prices model?)
    get totals() {
      const orderItems = [self.orderItem];
      const totals = reduce(
        orderItems,
        (memo, { totals: { cents, points } }) => {
          return {
            cents: memo.cents + cents,
            points: memo.points + points,
          };
        },
        { cents: 0, points: 0 }
      );

      // Make sure it's a Price model so that we can use our formatted views.
      return Prices.create(totals);
    },

    /**
     * Get option IDs for this choice
     */
    optionIdsForChoiceName(choiceName) {
      const { choices } = self;
      const options = choices.get(choiceName) || [];
      const optionIds = map(options, 'option.id');
      return optionIds;
    },
    getOptionsForChoice(choiceName) {
      const { choices } = self;
      const options = choices.get(choiceName) || [];
      return options;
    },
    getOptionForChoice(choiceName, optionId) {
      const options = self.getOptionsForChoice(choiceName);
      return find(options, orderOption => orderOption.option.id === optionId);
    },

    getOptionQuantityForChoice(choiceName, optionId) {
      const option = self.getOptionForChoice(choiceName, optionId);
      return option ? option.quantity : 0;
    },

    /**
     * Meal product choice pickers may render an option group if present, hiding the actual options by IDs inside a sub-picker.
     * Hence, they key by groupName if present, option ID if not.
     */
    valuesForMealChoice(choice) {
      const { choices } = self;
      const orderOptions = choices.get(choice.name) || [];
      const orderOptionIds = map(orderOptions, orderOption => orderOption.option.groupName || orderOption.option.id);
      return orderOptionIds;
    },

    get displayProduct() {
      const { product } = self;

      return get(product, 'isMealProduct') ? product.itemProduct : product;
    },

    get mealProduct() {
      const { product } = self;

      return get(product, 'isMealProduct') ? product : product.mealProduct;
    },

    get isAlcoholProduct() {
      const { MenuStore } = getRoot(self);
      const id = get(self, 'orderItem.product.id');

      const menuCategory = MenuStore.getProductCategory(id);

      return get(menuCategory, 'handle') === MENU_CATEGORIES.ALCOHOL;
    },

    get showSideNotIncludedInitiallyDisclaimer() {
      const { MenuStore } = getRoot(self);
      const id = get(self, 'orderItem.product.id');

      const menuCategory = MenuStore.getProductCategory(id);
      const isBwpCategory = get(menuCategory, 'handle') === MENU_CATEGORIES.BWP;

      return isBwpCategory;
    },

    get showSideNotIncludedInitiallyDisclaimerNandocas() {
      const productPlu = get(self, 'orderItem.product.plu');
      const isNandocasChoiceProduct = includes(SIDE_NOT_INCLUDED_INITIALLY_DISCLAIMER_NANDOCAS_PLUS, productPlu);

      return isNandocasChoiceProduct;
    },

    get isTearAndShareProduct() {
      const productPlu = get(self, 'orderItem.product.plu');

      return productPlu === TEAR_AND_SHARE_PLU;
    },

    get disclaimer() {
      const {
        redBullOptionRequired,
        sideOptionRequired,
        isKidsMeal,
        showSideNotIncludedInitiallyDisclaimer,
        showSideNotIncludedInitiallyDisclaimerNandocas,
        isTearAndShareProduct,
        product,
      } = self;

      if (isTearAndShareProduct) {
        return SIDE_AND_SNACK_PRODUCT_DISCLAIMER;
      }

      if (isKidsMeal) {
        return KIDS_PRODUCT_DISCLAIMER;
      }

      if (redBullOptionRequired) {
        return SIDE_AND_DRINK_OPTIONS_REQUIRED_PRODUCT_DISCLAIMER;
      }

      if (sideOptionRequired) {
        return SIDE_OPTIONS_REQUIRED_PRODUCT_DISCLAIMER;
      }

      if (showSideNotIncludedInitiallyDisclaimerNandocas) {
        return SIDE_NOT_INCLUDED_INITIALLY_DISCLAIMER_NANDOCAS;
      }

      if (showSideNotIncludedInitiallyDisclaimer) {
        return SIDE_NOT_INCLUDED_INITIALLY_DISCLAIMER;
      }

      return get(product, 'disclaimer');
    },

    get isLastStep() {
      const byoChoices = get(self.product, 'isByoProduct') ? get(self.product, 'byoChoices', []) : [];
      return self.buildYourOwnChoiceStep + 1 > size(byoChoices);
    },

    get isProteinThatHidesBastingSelected() {
      const { orderItem } = self;
      const proteinName = find(Object.keys(CANONICAL_PROTEIN_MAPPING), key => orderItem.choices.get(key));
      const orderProtein = first(orderItem.choices.get(proteinName));

      return (
        orderItem.product.hidesBastingIfProteinSelected && orderProtein && orderProtein.option.hidesBastingIfSelected
      );
    },
  }));

ProductDetailsState.initialState = initialState;

export default ProductDetailsState;
