import {
  BYO_EXTRA_INGREDIENTS_CANONICAL_NAMES,
  CANONICAL_BASTING_MAPPING,
  CANONICAL_CHOICE_MAPPING,
  CANONICAL_CHOICE_NAMES,
  CONTAINS_ATTRIBUTES,
  DIETARY,
  NUTRITION_DISCLAIMER_MESSAGES,
  NUTRITION_UNITS,
  NUTRITION_UNITS_100G,
  SUITABILITY_ATTRIBUTES,
} from '@nandosaus/constants';
import {
  compact,
  difference,
  filter,
  find,
  flatMap,
  forEach,
  get,
  includes,
  intersection,
  isEmpty,
  keyBy,
  keys,
  map,
  mapValues,
  omitBy,
  pickBy,
  reduce,
  reject,
  sumBy,
  toUpper,
  uniq,
} from 'lodash';
import { getEnv, getRoot, resolveIdentifier, types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

import { kilojoulesFormatter } from '../../util/formatter';
import Choice from '../choice';
import OrderOption from '../order-option';
import Prices from '../prices';
import Product from '../product';

// @NOTE: Organise arrays for drink & side of all potential drink/side choice names.
const { DRINK, SIDE, SIDE_1, SIDE_2, SIDE_3 } = CANONICAL_CHOICE_NAMES;
const DRINK_CHOICE_NAMES = keys(pickBy(CANONICAL_CHOICE_MAPPING, value => value === DRINK));
const SIDE_CHOICE_NAMES = keys(
  pickBy(CANONICAL_CHOICE_MAPPING, value => includes([SIDE, SIDE_1, SIDE_2, SIDE_3], value))
);

const OrderItem = types
  .model('OrderItem', {
    choices: types.map(types.array(OrderOption)),
    id: types.optional(types.identifier, uuidv4),
    notes: '',
    product: types.reference(Product, {
      get(identifier, parent) {
        return resolveIdentifier(Product, getRoot(parent), identifier);
      },
      set(value) {
        return value.id;
      },
    }),
    quantity: 1,
    isReorderItem: types.optional(types.boolean, false),
    isUpsellItem: types.optional(types.boolean, false),
    isRecommendedItem: types.optional(types.boolean, false),
  })
  .actions(self => ({
    /**
     * Migrate the order item to a different product, ensuring that choices (e.g. basting) are matched
     * to the same choices on the new product. Any choices which cannot be matched will be dropped.
     *
     * @param {Product} newProduct
     */
    migrateToProduct(newProduct) {
      const { logger } = getEnv(self);
      const migrationPath = `from ${self.product.name} (${self.product.id}) to ${newProduct.name} (${newProduct.id})`;
      const newChoices = {};

      self.choices.forEach((options, choiceName) => {
        const newChoice = newProduct.correspondingChoice(choiceName);

        if (newChoice === undefined) {
          logger.warn(`No ${choiceName} choice found when migrating ${migrationPath}`);
          return;
        }
        const newOptions = options.map(orderOption => {
          const newOption = newChoice.correspondingOption(orderOption.option);

          if (newOption === undefined) {
            logger.warn(
              `No ${orderOption.option.name} option found for ${newChoice.name} choice when migrating ${migrationPath}`
            );
            return undefined;
          }
          return {
            option: newOption,
            quantity: orderOption.quantity,
          };
        });

        newChoices[newChoice.name] = compact(newOptions);
      });
      self.choices = newChoices;
      self.product = newProduct;
    },
  }))
  .views(self => {
    const kilojoulesByoProduct = selfReference => {
      const { kilojoules = 0 } = selfReference.product || {};
      let ingredientChoiceKilojoules = 0;

      // plural quantity is used for extra ingredients in BYO products.
      // looping through the choices and adding the kilojoules for each extra ingredient found.
      self.choices.forEach((orderOptions, choiceName) => {
        if (!includes(BYO_EXTRA_INGREDIENTS_CANONICAL_NAMES, CANONICAL_CHOICE_MAPPING[choiceName])) return;
        forEach(orderOptions, ({ option, quantity }) => {
          const { kilojoules: optionKilojoules } = option;
          if (!optionKilojoules) return;
          ingredientChoiceKilojoules += optionKilojoules * quantity;
        });
      });

      // if we have the nutrition information we will use that and then exit early
      if (!isEmpty(selfReference.nutritionInformation)) {
        // we need to filter out the above stuff
        const nutritionKilojoules = selfReference.nutritionInformation.reduce((acc, nutrition) => {
          const { isMainProductPluWithChoices } = nutrition;
          const isMainProductPluWithoutChoices = nutrition.getIsMainProductPluWithoutChoices(self.product.plu);

          // if either of these then we include it, otherwise we don't
          if (isMainProductPluWithChoices || isMainProductPluWithoutChoices) {
            return acc + nutrition['Energy (kJ)'];
          }
          return acc;
        }, 0);

        // if so then we return what is calculated from the nutritional information
        return selfReference.quantity * (nutritionKilojoules + ingredientChoiceKilojoules);
      }

      return selfReference.quantity * (kilojoules + ingredientChoiceKilojoules);
    };

    return {
      /**
       * Used to determine if this orderItem meets all the requirements
       * to be able to migrate to it's corresponding meal product.
       * E.g. it is an item, it has a meal product and the user has chosen a drink and side.
       */
      get canMigrateToMealProduct() {
        const { product, hasDrink, hasSide } = self;
        const { isItemProduct } = product;
        const { mealProduct } = product;
        const hasMealChoices = hasDrink && hasSide;

        return isItemProduct && mealProduct && hasMealChoices;
      },

      get isMealProduct() {
        const { product } = self;

        const { isMealProduct } = product;

        return isMealProduct;
      },

      get isValid() {
        const { choices, product } = self;

        if (!product.isDetailedProduct) {
          return false; // Can't be considered valid until we have loaded the detailed product to see what choices are required.
        }

        // Ensure that we have valid options for choices (and are within the min and max limits) for all choices.
        const choiceValidArray = map(product.choices, choice => {
          const choiceOptionIds = map(choice.options, 'id');
          const usersChosenOptionIds = map(choices.get(choice.name) || [], 'option.id');
          const validOptionCount = intersection(choiceOptionIds, usersChosenOptionIds).length;

          const { ProductDetailsState } = getRoot(self);

          if (
            CANONICAL_BASTING_MAPPING[choice.name] &&
            product.hidesBastingIfProteinSelected &&
            ProductDetailsState &&
            !ProductDetailsState.isProteinThatHidesBastingSelected
          ) {
            return validOptionCount >= choice.minimumOptionLimit + 1 && validOptionCount <= choice.maximumOptionLimit;
          }

          return validOptionCount >= choice.minimumOptionLimit && validOptionCount <= choice.maximumOptionLimit;
        });
        const validChoiceCount = filter(choiceValidArray).length;

        if (validChoiceCount < product.choices.length) {
          return false;
        }

        return true;
      },

      get missingRequiredChoices() {
        const { choices, product } = self;

        if (!product.isDetailedProduct) {
          return []; // Can't be considered valid until we have loaded the detailed product to see what choices are required.
        }

        const requiredChoices = filter(product.initialChoices, initialChoice => initialChoice.required);
        const missingChoiceKeys = map(requiredChoices, requiredChoice => {
          const isChoiceSelected = includes(keys(choices.toJSON()), requiredChoice.name);
          return isChoiceSelected ? null : requiredChoice.name;
        });

        return compact(missingChoiceKeys);
      },

      // Determine what OrderItems should be created to replace self
      // if migrating to the new product.
      // Returns an array of orderItems (since it supports overflow).
      migrateTo(newProduct /* , menu = MenuStore.menu */) {
        const { MenuStore } = getRoot(self);
        const { logger } = getEnv(self);

        const { notes, quantity } = self;
        const overflowOrderItems = [];

        const newChoices = {};
        self.choices.forEach((options, choiceName) => {
          // Find the correspondingChoice because choices aren't 1:1 between item and meal products
          // e.g. a choice might be called "Add a side" vs "Side" respectively.
          const choiceCanonicalName = Choice.getCanonicalName(choiceName);
          const correspondingChoice = find(newProduct.choices, { canonicalName: choiceCanonicalName });
          if (!correspondingChoice) {
            logger.warn(
              `No corresponding choice ${choiceName} from product ${self.product.name}(${self.product.id}) in new product ${newProduct.name}(${newProduct.id})`
            );
            return;
          }
          // Map the options to the newProduct's correspondingChoice options.
          // This is done by plu, or name, as duplicate options exist with different plu.
          // @NOTE: Since some options may be converted to overflowProducts, they will return undefined
          // from the map iteration and the resulting array will need compacting before use.
          const newOptions = map(options, (orderOption, index) => {
            // If this option (e.g. a side) would exceed the meal product choice limit, add it as a new order item.
            const shouldOverflow = index >= correspondingChoice.maximumOptionLimit;

            // Return undefined early if this option becomes an overflow order item.
            if (shouldOverflow) {
              const overflowProduct = MenuStore.getProductByPartialId(orderOption.option.plu); // @TODO: Update getProductByPartialId to plu?

              if (overflowProduct) {
                overflowOrderItems.push(
                  OrderItem.create({
                    product: overflowProduct,
                  })
                );
              } else {
                logger.warn(
                  `No corresponding overflow product for option ${orderOption.option.name}(${orderOption.option.id}) from product ${self.product.name}(${self.product.id})`
                );
              }
              return undefined;
            }

            // Return the correspondingOption if found, otherwise the original option (for validateOrder to error).
            const correspondingOption = correspondingChoice.correspondingOption(orderOption.option);
            const finalOption = correspondingOption || orderOption.option;
            return {
              option: finalOption,
              quantity: orderOption.quantity,
            };
          });
          newChoices[correspondingChoice.name] = compact(newOptions);
        });

        const newOrderItem = OrderItem.create({
          choices: newChoices,
          notes,
          product: newProduct,
          quantity,
        });

        // Return an array of order items which get flattened before assiging to self.orderItems.
        return [newOrderItem, ...overflowOrderItems];
      },

      get kilojoules() {
        const { isByoProduct } = self.product;

        if (isByoProduct) {
          return kilojoulesByoProduct(self);
        }

        const { kilojoules = 0 } = self.product || {};
        // if we have the nutrition information we will use that and then exit early
        if (!isEmpty(self.nutritionInformation)) {
          const nutritionKilojoules = sumBy(self.nutritionInformation, 'Energy (kJ)');

          // This check is to see if the main product itself has nutritional information returned or not
          // this needs to handle the case where a main product doesn't have any choices as well
          if (
            self.nutritionInformation.every(nutrition => {
              const containsProductPlu =
                nutrition.getIsMainProductPluWithoutChoices(self.product.plu) || nutrition.isMainProductPluWithChoices;
              return !containsProductPlu;
            })
          ) {
            // because we don't have enough options to calculate the nutritional information we are using the default kilojoules
            return self.quantity * (kilojoules + nutritionKilojoules);
          }
          // if so then we return what is calculated from the nutritional information
          return self.quantity * nutritionKilojoules;
        }

        const selectedChoices = compact(
          flatMap(self.product.ingredientChoices, choice => self.choices.get(choice.name))
        );
        const ingredientChoiceKilojoules = sumBy(selectedChoices, 'option.kilojoules');

        // If the order item has no nutritional information
        // (eg: options have not been selected for a Burger, Pita or Wrap)
        // return the product's default kJ as sent by Redcat.
        return self.quantity * (kilojoules + ingredientChoiceKilojoules);
      },

      get formattedKilojoules() {
        return kilojoulesFormatter(self.kilojoules);
      },

      /**
       * Returns an array of all options (across all choices) chosen for this order item.
       */
      get options() {
        const options = [];
        self.choices.forEach(choiceOptions => {
          choiceOptions.forEach(orderOption => {
            options.push(orderOption.option);
          });
        });
        return options;
      },

      /**
       * Returns all the options chosen by the user which
       * map to a DRINK via their canonical choice name.
       */
      get drinks() {
        const { choices } = self;

        const drinks = [];

        choices.forEach((options, choiceName) => {
          if (includes(DRINK_CHOICE_NAMES, choiceName) && !isEmpty(options)) {
            drinks.push(...options);
          }
        });

        return drinks;
      },

      /**
       * Returns true if the user has chosen an option for any of
       * the choices mapped to DRINK as their canonical choice name.
       */
      get hasDrink() {
        const { drinks } = self;

        const hasDrink = !isEmpty(drinks);

        return hasDrink;
      },

      /**
       * Returns all the options chosen by the user which
       * map to a SIDE via their canonical choice name.
       */
      get sides() {
        const { choices } = self;

        const sides = [];

        choices.forEach((options, choiceName) => {
          if (includes(SIDE_CHOICE_NAMES, choiceName) && !isEmpty(options)) {
            sides.push(...options);
          }
        });

        return sides;
      },

      get hasSide() {
        const { sides } = self;

        const hasSides = !isEmpty(sides);

        return hasSides;
      },

      /**
       * Returns the number of points a user would earn if purchasing this order item,
       * considering the product, choices and quantity.
       * E.g. a product worth 5 points, with 1 choice worth 2 points, at a quantity of 2, equals 14 points!
       */
      get points() {
        const { choices, product, quantity } = self;

        let value = product.points;

        choices.forEach(options => {
          options.forEach(orderOption => {
            value += orderOption.option.points * orderOption.quantity;
          });
        });

        value *= quantity;

        return value;
      },

      calculateChoiceTotalPrice(productChoices) {
        const prices = {
          cents: 0,
          points: 0,
        };
        productChoices.forEach(choice => {
          const options = self.choices.get(choice.name) || [];
          options.forEach(orderOption => {
            prices.cents += orderOption.option.prices.cents * orderOption.quantity;
            prices.points += orderOption.option.prices.points * orderOption.quantity;
          });
        });

        return Prices.create(prices);
      },

      // Return the total for the product and choices,
      // but does not include any discount or quantity.
      get subtotalPrices() {
        const { product, choices } = self;

        // Start with the product price.
        const subtotalPrices = {
          cents: get(product, 'prices.cents', 0),
          points: get(product, 'prices.points', 0),
        };

        // Add the choice prices.
        if (product && choices.size > 0) {
          const choicePrices = self.calculateChoiceTotalPrice(product.choices);
          subtotalPrices.cents += choicePrices.cents;
          subtotalPrices.points += choicePrices.points;
        }

        return Prices.create(subtotalPrices);
      },

      get totals() {
        const { subtotalPrices, quantity } = self;

        // Start with the subtotalPrices and multiple them by the quantity.
        const totals = {
          cents: subtotalPrices.cents * quantity,
          points: subtotalPrices.points * quantity,
        };

        // @TODO: Discount?

        // Make sure it's a Price model so that we can use our formatted views.
        return Prices.create(totals);
      },

      // Dietary / Nutrition

      get dietaryDisclaimer() {
        const disclaimers = self.nutritionDataForOrderItemByKey('disclaimer');

        if (self.hasSide) {
          disclaimers.push(NUTRITION_DISCLAIMER_MESSAGES.SIDE_CHOICE);
        }

        return compact(uniq(disclaimers)).join(' ');
      },

      get showAllergenInfo() {
        const showAllergenInfo = self.nutritionDataForOrderItemByKey('showAllergens');

        // return true if any of the constituent PLUs have this set to true
        const [showAllergens = false] = filter(showAllergenInfo);

        return showAllergens;
      },

      get showNutritionalInfo() {
        const showNutritionInfo = self.nutritionDataForOrderItemByKey('showNutrition');

        // return true if any of the constituent PLUs have this set to true
        const [showNutrition = false] = filter(showNutritionInfo);

        return showNutrition;
      },

      nutritionDataForOrderItemByKey(key) {
        const { MenuStore } = getRoot(self);

        // Meal products do not contain nutritional information themselves.
        // If an order item is a meal product, we need to fetch the itemProduct and use that
        // to provide the PLU for the nutritional data look-up.
        const { isMealProduct } = self.product;

        let { options, product } = self;

        if (isMealProduct) {
          product = self.product.itemProduct;
          options = self.optionsFromProduct(self.product.itemProduct);
        }

        let PLUs = map([product, ...options], 'plu');

        // if the product has a flavour choice then use flavour as the base product(s)
        const isProductWithFlavourChoice = !isEmpty(
          self.product.choices.find(choice => choice.name.toLowerCase().includes('flavour'))
        );

        if (isProductWithFlavourChoice) {
          PLUs = map([...options], 'plu');
        }

        return map(MenuStore.getProductNutrition(PLUs), key);
      },

      get nutritionInformation() {
        return self.nutritionDataForOrderItemByKey('nutrition');
      },

      get allergenInformation() {
        const { FeatureFlagStore } = getRoot(self);

        const allergens = self.nutritionDataForOrderItemByKey('contains');

        if (FeatureFlagStore.isActive('allergen-information')) {
          return allergens;
        }

        return allergens.map(obj => omitBy(obj, value => value === 0));
      },

      // NOTE: suitability is being phased out.
      get suitabilityInformation() {
        const { FeatureFlagStore } = getRoot(self);

        if (!FeatureFlagStore.isActive('allergen-information')) {
          return [];
        }

        return self.nutritionDataForOrderItemByKey('suitability');
      },

      get definitelyContains() {
        // An ingredient is definitely in the order item if any
        // of the options has it set to `1`.
        return filter(CONTAINS_ATTRIBUTES, key => {
          const options = map(self.allergenInformation, key);

          return includes(options, 1);
        });
      },

      get formattedContainsString() {
        return self.definitelyContains.join(', ');
      },

      get formattedDoesNotContainString() {
        const remainingContains = difference(CONTAINS_ATTRIBUTES, self.definitelyContains);

        const remainingContainsWithoutPartials = reject(remainingContains, key => {
          const options = map(self.allergenInformation, key);

          return includes(options, DIETARY.PARTIAL);
        });

        return filter(remainingContainsWithoutPartials, key => {
          const options = map(self.allergenInformation, key);

          return includes(options, 0);
        }).join(', ');
      },

      get formattedTracesString() {
        const remainingContains = difference(CONTAINS_ATTRIBUTES, self.definitelyContains);

        return filter(remainingContains, key => {
          const options = map(self.allergenInformation, key);

          return includes(options, DIETARY.PARTIAL);
        }).join(', ');
      },

      get notSuitableFor() {
        // Use this as a convenience method to find the dietary restrictions this order item is not suitable for.
        // This will be the case if any option in this order item has a suitability of `0` for a given key.
        // The `suitableFor` array will be the difference of this and the source array, and will need to consider
        // partial values (eg Coeliac may be 0.5, and include a dietaryNote)

        return filter(SUITABILITY_ATTRIBUTES, key => {
          const options = map(self.suitabilityInformation, key);

          return includes(options, 0);
        });
      },

      get formattedSuitableFor() {
        const suitableFor = difference(SUITABILITY_ATTRIBUTES, self.notSuitableFor);

        return filter(suitableFor, key => {
          const options = map(self.suitabilityInformation, key);

          return options.length && compact(options).length === options.length;
        }).join(', ');
      },

      get formattedNotSuitableFor() {
        return self.notSuitableFor.join(', ');
      },

      get formattedNutritionDisclaimer() {
        const { choices } = self;

        const nutritionChoices = [];

        choices.forEach(options => {
          map(options, orderOption => {
            if (orderOption.option.isNutritionOption) {
              nutritionChoices.push(orderOption.option);
            }
          });
        });

        return nutritionChoices;
      },

      formatNutritionValues(nutritionUnits) {
        // Loop over each NUTRITION_UNIT, and create a reduced array containing the original
        // object's 'label' and 'unit' values, alongside the combined value of all options.
        return reduce(
          nutritionUnits,
          (acc, { label, unit, indentValue }, key) => {
            const value = reduce(
              map(self.nutritionInformation, key),
              (total, cur) => {
                if (!cur) return total; // cur may be `undefined`

                return total + cur;
              },
              0
            )
              .toFixed(1)
              .replace(/\.0+$/, ''); // removing trailing '.0'

            return [
              ...acc,
              {
                indentValue,
                label,
                unit,
                value,
              },
            ];
          },
          []
        );
      },

      get formattedNutrition() {
        const serving = this.formatNutritionValues(NUTRITION_UNITS);
        const per100g = this.formatNutritionValues(NUTRITION_UNITS_100G);

        const formattedNutrition = reduce(
          serving,
          (acc, servingItem) => {
            const isWeight = toUpper(servingItem.label) === 'WEIGHT';
            let item100g =
              find(per100g, item => item.label === servingItem.label && item.unit === servingItem.unit) || {};

            if (isWeight) {
              item100g = {
                ...servingItem,
                // override the value to 100
                value: '100',
              };
            }

            return [
              ...acc,
              {
                serving: servingItem,
                per100g: item100g,
              },
            ];
          },
          []
        );

        return formattedNutrition;
      },

      get formattedNutritionWeight() {
        const { formattedNutrition } = self;
        const weight = find(formattedNutrition, ({ serving }) => toUpper(serving.label) === 'WEIGHT') || {};
        return weight.serving;
      },

      get groupedMealOptions() {
        const { choices, product } = self;

        return mapValues(keyBy(product.mealChoices, 'id'), choice => {
          const chosenOptions = choices.get(choice.name);

          if (chosenOptions === undefined) {
            return [];
          }

          return chosenOptions.map(orderOption => orderOption.option);
        });
      },

      optionsFromProduct(itemProduct) {
        const itemOptions = [];

        self.choices.forEach((options, choiceName) => {
          // Find the correspondingChoice because choices aren't 1:1 between item and meal products
          // e.g. a choice might be called "Add a side" vs "Side" respectively.
          const canonicalName = Choice.getCanonicalName(choiceName);

          const correspondingChoice = find(itemProduct.choices, {
            canonicalName,
          });

          if (!correspondingChoice) {
            return;
          }

          // Map the options to the itemProduct's correspondingChoice options.
          // This is done by plu, or name, as duplicate options exist with different plu.
          const newOptions = map(options, orderOption => {
            // Return the correspondingOption if found
            const correspondingOption = correspondingChoice.correspondingOption(orderOption.option);

            return correspondingOption || orderOption.option;
          });

          itemOptions.push(...compact(newOptions));
        });

        return itemOptions;
      },
    };
  });

export default OrderItem;
