import { COGNITO_AUTH_FLOWS } from '@nandosaus/constants';
import { flow, getEnv, types } from 'mobx-state-tree';
import { get } from 'lodash';
import gql from 'graphql-tag';

const signUpMutation = gql`
  mutation signUp($input: SignUpInput!) {
    signUp(input: $input)
  }
`;

const signUpMemberMutation = gql`
  mutation signUpMember($input: SignUpInput!, $token: String!) {
    signUpMember(input: $input, token: $token)
  }
`;

const migrateMutation = gql`
  mutation migrate($input: MigrateInput!) {
    migrate(input: $input)
  }
`;

const migrateWithoutPasswordMutation = gql`
  mutation migrateWithoutPassword($input: MigrateWithoutPasswordInput!) {
    migrateWithoutPassword(input: $input)
  }
`;

const initialState = {};

const AuthStore = types.model('AuthStore', {}).actions(self => {
  const { Auth, getApiClient } = getEnv(self);

  return {
    signIn: flow(function*({ email, password }) {
      let user;
      try {
        user = yield Auth.signIn(email, password);
        return { user };
      } catch (error) {
        switch (error.code) {
          case 'UserNotFoundException': {
            try {
              const client = yield getApiClient();
              yield client.mutate({
                mutation: migrateMutation,
                variables: { input: { username: email, password } },
              });
              try {
                user = yield Auth.signIn(email, password);
                return { user };
              } catch (err) {
                throw new Error('Incorrect email or password');
              }
            } catch (graphQLError) {
              const message = get(graphQLError, 'errors[0].message', error.message);
              throw new Error(message);
            }
          }
          case 'NotAuthorizedException':
            throw new Error(error.message);
          default:
            throw new Error('Incorrect email or password');
        }
      }
    }),
    confirmSignIn: flow(function*({ user, code }) {
      yield Auth.confirmSignIn(user, code, 'SMS_MFA');
    }),
    signUpMember: flow(function*(input, token) {
      try {
        const client = yield getApiClient();
        yield client.mutate({
          mutation: signUpMemberMutation,
          variables: { input, token },
        });
      } catch (error) {
        throw new Error(get(error, 'errors[0].message', error.message));
      }
    }),
    // kept for backwards compatibility with mobile devices
    signUp: flow(function*(input) {
      try {
        const client = yield getApiClient();
        yield client.mutate({
          mutation: signUpMutation,
          variables: { input },
        });
      } catch (error) {
        throw new Error(get(error, 'errors[0].message', error.message));
      }
    }),
    signOut: flow(function*() {
      yield Auth.signOut();
    }),
    forgotPassword: flow(function*(email) {
      yield Auth.forgotPassword(email);
    }),
    forgotPasswordWithMigration: flow(function*(email) {
      let success;
      try {
        const client = yield getApiClient();
        const result = yield client.mutate({
          mutation: migrateWithoutPasswordMutation,
          variables: { input: { username: email } },
        });
        success = get(result, 'data.migrateWithoutPassword');
      } catch (error) {
        const message = get(error, 'errors[0].message', error.message);
        throw new Error(message);
      }

      // retry
      if (success) {
        yield Auth.forgotPassword(email);
      }
    }),
    forgotPasswordSubmit: flow(function*({ username, code, newPassword, mobile }) {
      yield Auth.forgotPasswordSubmit(username, code, newPassword, {
        mobile,
        flow: COGNITO_AUTH_FLOWS.FORGOT_PASSWORD,
      });
    }),
  };
});

AuthStore.initialState = initialState;

export default AuthStore;
