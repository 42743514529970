const REGISTRATION_PAGE = 'peri-perks/registration';

const navigation = {
  ACCOUNT: '/account',
  ACCOUNT_DELETED: '/account/deleted',
  ADD_RECEIPT_SUCCESS: '/account/add-receipt/success',
  ADD_RECEIPT: '/account/add-receipt',
  APP: '/app',
  CAREERS: '/careers',
  CHECKOUT_FAILURE: '/checkout/failure',
  CHECKOUT_SUCCESS: '/checkout/success',
  CHECKOUT: '/checkout',
  COMMUNICATION_PREFERENCES: '/account/communication-preferences',
  CONTACT: process.env.contactUsUrl,
  CREATE_ACCOUNT: '/create-account',
  DIGITAL_WALLET: '/account/digital-wallet',
  EVENTS: '/events',
  ORDERS: '/account/orders',
  ORDER_HISTORY: '/account/my-favourites',
  FORGOT_PASSWORD: '/forgot-password',
  HELP: '/help',
  ORDER_HELP: '/order-help',
  HOME: '/',
  MENU: '/menu',
  MENU_CATEGORIES: '/menu/categories',
  NO_CHUCKIN_OUR_CHICKEN: '/changing-lives/no-chuckin-our-chicken',
  NUTRITIONAL_INFORMATION: '/menu-item',
  PRODUCTS: '/peri-products',
  NEWS: '/news-and-events',
  OFFERS: '/account/offers',
  OUR_STORY: '/our-story',
  CHANGING_LIVES: '/changing-lives',
  PAYMENT_METHODS: '/account/payment-methods',
  PERI_PERKS: '/peri-perks',
  PERI_PERKS_TERMS_CONDITIONS: '/policies-and-terms/peri-perks-terms-and-conditions',
  PERKS_SHOP: '/peri-perks/perks-shop',
  PERKS_SHOP_CHECKOUT: '/peri-perks/perks-shop/checkout',
  PERKS_SHOP_SUCCESS: '/peri-perks/perks-shop/checkout/success',
  PERI_PERKS_REGISTRATION: '/peri-perks/registration',
  PERI_PERKS_REDEEM: '/peri-perks/redeem',
  POINTS_ACTIVITY: '/account/points',
  PRIVACY_POLICY: '/privacy-policy',
  RECIPES: '/recipes',
  RESTAURANTS: '/restaurants',
  SIGN_IN_HELP: '/sign-in-help',
  SIGN_IN: '/sign-in',
  SIGN_OUT: '/sign-out',
  TERMS_CONDITIONS: '/policies-and-terms',
  GIFT_CARDS: '/gift-cards',
  GIFT_CARDS_CHECK_BALANCE: '/gift-cards/check-balance',
  CREATE_GIFT_CARD: '/gift-cards/create-gift-card',
  CREATE_GIFT_CARD_SUCCESS: '/gift-cards/create-gift-card/success',
  SPIN_TO_WIN: '/spin-to-win',
  SPIN_TO_WIN_INFO: '/spin-to-win/info',
  WELCOME: '/welcome',
};

export { REGISTRATION_PAGE, navigation };
