import { types } from 'mobx-state-tree';
import moment from 'moment';

// Support API dates stored in ISO string format
// e.g. "2020-01-01T00:00:00.000Z"
// Date string is coverted to instance of Date

// References:
// - https://github.com/mobxjs/mobx-state-tree/issues/1085
// - https://spectrum.chat/mobx-state-tree/general/types-date-and-utc-string~b57a8143-fe91-4b96-9fa2-fb82b10b1d76

export const DateTime = types.custom({
  name: 'DateTime',
  fromSnapshot: date => {
    if (!date || !moment(date).isValid()) {
      return undefined;
    }

    return new Date(date);
  },
  toSnapshot: date => (date ? date.toISOString() : undefined),
  isTargetType: maybeDate => maybeDate instanceof Date,
  getValidationMessage: snapshot => {
    if (snapshot === undefined) {
      return '';
    }

    if (!moment(snapshot).isValid()) {
      const message = `"${snapshot}" is not in valid date format`;
      return message;
    }

    return '';
  },
});
