const NoGluten = props => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5505_1974)">
      <rect width="32" height="32" transform="translate(0 0.5)" fill="white" />
      <path
        d="M15.92 14.4341H15.9946C21.0596 9.63571 15.968 5.28516 15.968 5.28516H15.944C15.944 5.28516 10.8577 9.62504 15.92 14.4341Z"
        fill="black"
      />
      <path
        d="M10.1485 16.0148C10.391 16.5264 10.7121 16.9968 11.1002 17.409C11.6965 18.0768 12.4247 18.6138 13.2389 18.9861C14.053 19.3584 14.9355 19.558 15.8307 19.5723C16.7258 19.5866 17.6142 19.4152 18.4398 19.0691C19.2655 18.7229 20.0104 18.2094 20.6277 17.561C21.1704 17.0327 21.5971 16.3972 21.8806 15.6949C22.1976 14.9691 22.3783 14.191 22.4138 13.3997C22.4446 13.0754 22.4517 12.7493 22.4351 12.424L22.4164 12.4054C22.4164 12.4054 16.2612 12.0828 15.9413 18.2674C15.6294 12.0828 9.46342 12.4027 9.46342 12.4027L9.44743 12.4214C9.43094 12.6788 9.43094 12.937 9.44743 13.1944C9.463 14.1757 9.70282 15.1405 10.1485 16.0148Z"
        fill="black"
      />
      <path
        d="M23.8535 18.4033C22.054 18.4036 20.3049 18.9975 18.8773 20.093C17.4496 21.1886 16.4234 22.7245 15.9575 24.4626C15.492 22.7242 14.4658 21.1881 13.0381 20.0925C11.6104 18.9969 9.86107 18.4031 8.06145 18.4033C7.79487 18.4033 7.5283 18.4033 7.26172 18.4433C7.53243 20.5665 8.56797 22.5182 10.1743 23.9328C11.7807 25.3474 13.8477 26.1278 15.9881 26.1278C18.1286 26.1278 20.1956 25.3474 21.8019 23.9328C23.4083 22.5182 24.4439 20.5665 24.7146 18.4433C24.4213 18.4193 24.1414 18.4033 23.8535 18.4033Z"
        fill="black"
      />
      <path
        d="M15.9947 2.09947C18.8418 2.09947 21.6249 2.94373 23.9922 4.5255C26.3595 6.10726 28.2046 8.35548 29.2941 10.9859C30.3836 13.6162 30.6687 16.5106 30.1133 19.303C29.5578 22.0954 28.1868 24.6604 26.1736 26.6736C24.1604 28.6868 21.5954 30.0578 18.803 30.6133C16.0106 31.1687 13.1163 30.8836 10.4859 29.7941C7.85549 28.7046 5.60727 26.8595 4.0255 24.4922C2.44374 22.1249 1.59947 19.3418 1.59947 16.4947C1.60512 12.6786 3.12356 9.02036 5.82196 6.32195C8.52036 3.62355 12.1786 2.10511 15.9947 2.09947ZM15.9947 0.5C12.8312 0.5 9.73882 1.43807 7.10851 3.19559C4.4782 4.9531 2.42813 7.45113 1.21753 10.3738C0.00693027 13.2964 -0.309818 16.5124 0.30734 19.6151C0.924498 22.7177 2.44784 25.5677 4.68474 27.8046C6.92163 30.0415 9.77161 31.5648 12.8743 32.182C15.9769 32.7992 19.1929 32.4824 22.1156 31.2718C25.0382 30.0612 27.5362 28.0111 29.2938 25.3808C31.0513 22.7505 31.9893 19.6581 31.9893 16.4947C31.9893 12.2526 30.3042 8.18431 27.3046 5.18473C24.305 2.18515 20.2367 0.5 15.9947 0.5V0.5Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0585 27.4642L4.93901 6.34468L6.05859 5.2251L27.1781 26.3446L26.0585 27.4642Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_5505_1974">
        <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export { NoGluten };
