import { useEffect } from 'react';
import { useRootStore } from '@nandosaus/state-management';
import { useRouter } from 'next/router';
import { navigation } from '@constants/navigation';

const BLACKLIST_PATH = [navigation.CHECKOUT, navigation.CHECKOUT_SUCCESS, navigation.CHECKOUT_FAILURE];
const useGroupCartPolling = () => {
  const router = useRouter();
  const { GroupOrderStore } = useRootStore();

  useEffect(() => {
    if (!GroupOrderStore.isInGroup || GroupOrderStore.isGroupInvitee) {
      return undefined;
    }

    const isBlacklisted = BLACKLIST_PATH.includes(router.pathname);
    if (isBlacklisted) {
      GroupOrderStore.stopGroupCartsPolling();
      return undefined;
    }

    GroupOrderStore.startGroupCartsPolling();

    return () => {
      GroupOrderStore.stopGroupCartsPolling();
    };
  }, [router.pathname, GroupOrderStore.isInGroup, GroupOrderStore.isGroupInvitee, GroupOrderStore]);
};

// Helper component, used as this hook requires that the store context be present, thus needs to go in the render path
export const GroupCartPolling = () => {
  useGroupCartPolling();

  return null;
};
