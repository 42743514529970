import { types } from 'mobx-state-tree';

import { pointsFormatter } from '../../util/formatter';
import Price from '../price';

const TransactionItem = types
  .compose(
    types.model('TransactionItem', {
      productId: types.maybeNull(types.string),
      productName: types.string,
      quantity: types.number,
      points: types.number,
    }),
    Price
  )
  .views(self => ({
    get formattedPoints() {
      return pointsFormatter(self.points);
    },
  }));

export default TransactionItem;
