import { MARKET } from '@nandosaus/constants';
import PropTypes from 'prop-types';
import React from 'react';

const MarketContext = React.createContext(MARKET.AUSTRALIA);

const MarketProvider = ({ children, currentMarket }) => {
  return <MarketContext.Provider value={currentMarket}>{children}</MarketContext.Provider>;
};

MarketProvider.propTypes = {
  children: PropTypes.node.isRequired,
  currentMarket: PropTypes.string.isRequired,
};

export { MarketProvider, MarketContext };
