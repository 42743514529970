import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';

import { GlobalCSS } from '../global-css';
import { theme } from '../../constants';

const ThemeProvider = ({ children }) => (
  <StyledThemeProvider theme={theme}>
    <GlobalCSS />
    {children}
  </StyledThemeProvider>
);

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ThemeProvider };
