const ApplePay = props => (
  <svg viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Apple Pay</title>
    <path
      d="M26.3148 4H5.29332C5.20575 4 5.11803 4 5.03062 4.00045C4.95682 4.00106 4.88318 4.00181 4.80938 4.00377C4.64863 4.008 4.4865 4.01721 4.32776 4.04515C4.16655 4.07338 4.01648 4.11959 3.87012 4.19237C3.72609 4.26379 3.59443 4.35725 3.4801 4.46868C3.36592 4.58027 3.27015 4.70861 3.19697 4.84934C3.1224 4.99218 3.07506 5.13864 3.04628 5.29613C3.01751 5.4512 3.00807 5.60929 3.00374 5.76602C3.00173 5.83804 3.0008 5.90991 3.00033 5.98179C3 6.06725 3 6.15271 3 6.23832V17.7618C3 17.8474 3 17.9328 3.00033 18.0184C3.0008 18.0902 3.00173 18.1623 3.00374 18.2341C3.00807 18.3907 3.01751 18.549 3.04628 18.7039C3.07506 18.8614 3.1224 19.0078 3.19697 19.1508C3.27015 19.2914 3.36592 19.4199 3.4801 19.5313C3.59443 19.6429 3.72609 19.7364 3.87012 19.8076C4.01648 19.8806 4.16655 19.9268 4.32776 19.9552C4.4865 19.9828 4.64863 19.9921 4.80938 19.9964C4.88318 19.998 4.95682 19.9991 5.03062 19.9994C5.11803 20 5.20575 20 5.29332 20H26.3148C26.4022 20 26.4899 20 26.5773 19.9994C26.651 19.9991 26.7246 19.998 26.7987 19.9964C26.9592 19.9921 27.1213 19.9828 27.2803 19.9552C27.4414 19.9268 27.5915 19.8806 27.738 19.8076C27.882 19.7364 28.0134 19.6429 28.1279 19.5313C28.2419 19.4199 28.3376 19.2914 28.411 19.1508C28.4857 19.0078 28.533 18.8614 28.5617 18.7039C28.5904 18.549 28.5997 18.3907 28.6041 18.2341C28.6061 18.1623 28.607 18.0902 28.6075 18.0184C28.6081 17.9328 28.6081 17.8474 28.6081 17.7618V6.23832C28.6081 6.15271 28.6081 6.06725 28.6075 5.98179C28.607 5.90991 28.6061 5.83804 28.6041 5.76602C28.5997 5.60929 28.5904 5.4512 28.5617 5.29613C28.533 5.13864 28.4857 4.99218 28.411 4.84934C28.3376 4.70861 28.2419 4.58027 28.1279 4.46868C28.0134 4.35725 27.882 4.26379 27.738 4.19237C27.5915 4.11959 27.4414 4.07338 27.2803 4.04515C27.1213 4.01721 26.9592 4.008 26.7987 4.00377C26.7246 4.00181 26.651 4.00106 26.5773 4.00045C26.4899 4 26.4022 4 26.3148 4Z"
      fill="#3C4043"
    />
    <path
      d="M26.3149 4.53334L26.5736 4.53379C26.6436 4.53425 26.7136 4.535 26.784 4.53696C26.9066 4.54014 27.05 4.54663 27.1837 4.57003C27.2997 4.59042 27.3972 4.62152 27.4906 4.66788C27.583 4.71363 27.6674 4.77372 27.7412 4.84559C27.8153 4.91807 27.8769 5.00066 27.9244 5.09171C27.9718 5.18231 28.0033 5.27683 28.0242 5.39098C28.048 5.52008 28.0547 5.66035 28.0579 5.7807C28.0599 5.84849 28.0609 5.91644 28.0612 5.98605C28.0618 6.07015 28.0618 6.1541 28.0618 6.23836V17.7619C28.0618 17.8461 28.0618 17.9301 28.0612 18.0158C28.0609 18.0838 28.0599 18.1517 28.0579 18.2198C28.0547 18.3399 28.048 18.4801 28.0239 18.6106C28.0033 18.7232 27.9718 18.8178 27.9241 18.9088C27.8768 18.9997 27.8153 19.0821 27.7415 19.1542C27.6673 19.2266 27.5831 19.2864 27.4897 19.3326C27.3969 19.3788 27.2997 19.4099 27.1847 19.43C27.0483 19.4537 26.899 19.4604 26.7865 19.4632C26.7158 19.4649 26.6454 19.4658 26.5732 19.4661C26.4873 19.4667 26.401 19.4667 26.3149 19.4667H5.29348C5.2924 19.4667 5.29132 19.4667 5.29008 19.4667C5.20514 19.4667 5.1199 19.4667 5.03341 19.4661C4.96286 19.4658 4.89247 19.4649 4.8244 19.4634C4.70929 19.4604 4.55984 19.4537 4.42462 19.4302C4.30859 19.4099 4.21143 19.3788 4.11736 19.332C4.02485 19.2863 3.94068 19.2265 3.86642 19.1539C3.79278 19.0821 3.73151 18.9999 3.68417 18.9088C3.63667 18.8179 3.60496 18.7231 3.58407 18.6091C3.56009 18.4788 3.55344 18.3393 3.55003 18.2198C3.54818 18.1516 3.5474 18.0832 3.54694 18.0154L3.54663 17.8149V6.18551L3.54694 5.98544C3.5474 5.91704 3.54818 5.84879 3.55003 5.78054C3.55344 5.66096 3.56009 5.52129 3.58438 5.38993C3.60496 5.27713 3.63667 5.18216 3.68432 5.09081C3.73136 5.00051 3.79278 4.91807 3.86673 4.8459C3.94053 4.77372 4.02515 4.71393 4.11814 4.66772C4.21112 4.62137 4.30859 4.59042 4.42462 4.57003C4.55829 4.54663 4.70171 4.54014 4.82455 4.53696C4.89448 4.535 4.96456 4.53425 5.03403 4.53379L5.29348 4.53334H26.3149Z"
      fill="#FFFFFE"
    />
    <path
      d="M9.99081 9.28649C10.21 9.01243 10.359 8.64432 10.3197 8.26802C9.9987 8.28411 9.60697 8.47991 9.38016 8.75414C9.17656 8.98923 8.99647 9.3728 9.04335 9.73317C9.40383 9.76441 9.76369 9.55298 9.99081 9.28665V9.28649ZM10.3157 9.80354C9.79231 9.7723 9.34736 10.1003 9.0975 10.1003C8.84748 10.1003 8.46473 9.81916 8.05103 9.82674C7.51232 9.83463 7.01245 10.1392 6.73907 10.6233C6.17685 11.5921 6.5907 13.0293 7.13745 13.8182C7.40309 14.2086 7.72304 14.6384 8.14463 14.6229C8.54301 14.6073 8.69912 14.3649 9.18336 14.3649C9.6673 14.3649 9.80794 14.6229 10.2297 14.615C10.6671 14.6073 10.9404 14.2245 11.2059 13.8338C11.5105 13.3889 11.6352 12.9592 11.643 12.9355C11.6352 12.9278 10.7998 12.6073 10.7921 11.6467C10.7842 10.8423 11.448 10.4596 11.4791 10.436C11.1043 9.88165 10.5185 9.81916 10.3157 9.80339"
      fill="black"
    />
    <path
      d="M14.8716 8.71483C16.0087 8.71483 16.8009 9.49852 16.8009 10.6395C16.8009 11.7846 15.9925 12.5723 14.8431 12.5723H13.5838V14.5742H12.6741V8.71483H14.8716ZM13.5838 11.8089H14.6276C15.4197 11.8089 15.8706 11.3827 15.8706 10.6437C15.8706 9.90467 15.4197 9.48228 14.6316 9.48228H13.5838V11.8091V11.8089ZM17.0385 13.3604C17.0385 12.6132 17.6112 12.1543 18.6268 12.0974L19.7964 12.0284V11.6996C19.7964 11.2244 19.4755 10.9402 18.9394 10.9402C18.4317 10.9402 18.1148 11.1838 18.0378 11.5655H17.2091C17.2579 10.794 17.9159 10.2254 18.9719 10.2254C20.0077 10.2254 20.6697 10.7737 20.6697 11.6304V14.5743H19.8289V13.8719H19.8088C19.5609 14.347 19.0207 14.6475 18.4601 14.6475C17.6235 14.6475 17.0385 14.1278 17.0385 13.3604ZM19.7964 12.9746V12.6375L18.7444 12.7024C18.2205 12.7391 17.9239 12.9705 17.9239 13.3359C17.9239 13.7095 18.2327 13.9531 18.7038 13.9531C19.3171 13.9531 19.7964 13.5308 19.7964 12.9746ZM21.4636 16.1459V15.4352C21.5284 15.4515 21.6746 15.4515 21.7478 15.4515C22.1539 15.4515 22.3733 15.281 22.5073 14.8424C22.5073 14.8343 22.5846 14.5825 22.5846 14.5785L21.041 10.3026H21.9914L23.072 13.7786H23.088L24.1687 10.3026H25.0947L23.4943 14.7977C23.1289 15.8332 22.7064 16.1662 21.821 16.1662C21.7478 16.1662 21.5284 16.158 21.4636 16.1459Z"
      fill="black"
    />
  </svg>
);

export { ApplePay };
