import {
  BYO_EXTRA_INGREDIENTS_CANONICAL_NAMES,
  CANONICAL_BASTING_MAPPING,
  CANONICAL_CHOICE_NAMES,
  DIETARY_PREFERENCES_OPTIONS,
  DIETARY_TYPES,
  PRODUCT_ID_TO_PLU_REG_EX,
} from '@nandosaus/constants';
import {
  camelCase,
  compact,
  difference,
  filter,
  find,
  flatMap,
  forEach,
  get,
  includes,
  isEmpty,
  map,
  mapValues,
  omit,
  size,
  snakeCase,
  some,
  sumBy,
} from 'lodash';
import { getRoot, resolveIdentifier, types } from 'mobx-state-tree';

import { kilojoulesFormatter } from '../../util/formatter';
import Choice from '../choice';
import Prices from '../prices';
import { isMealModifier, isItemModifier } from '../../helpers/check-product-modifier';

const ProductSuitabilityOptions = types.model('ProductSuitabilityOptions', {
  range: types.maybe(types.array(types.number)),
  standardOptions: types.maybe(types.array(types.string)),
});

const ProductSuitability = types.model('ProductSuitability', {
  vegetarian: types.maybe(ProductSuitabilityOptions),
  vegan: types.maybe(ProductSuitabilityOptions),
  noDairy: types.maybe(ProductSuitabilityOptions),
  noGluten: types.maybe(ProductSuitabilityOptions),
});

const { ADD, DRINK, PROTEIN, REMOVE, SIDE } = CANONICAL_CHOICE_NAMES;

const Product = types
  .model('Product', {
    byoType: types.maybeNull(types.string),
    id: types.identifier,
    name: '',
    longName: types.string,
    shortName: types.optional(types.string, ''),
    groupName: types.maybeNull(types.string),
    description: types.optional(types.maybeNull(types.string), ''),
    image: '',
    modifier: '',
    points: 0,
    prices: types.compose(Prices),
    kilojoules: types.maybe(types.union(types.number, types.null)),
    // @NOTE The following may be loaded via detailed product.
    choices: types.optional(types.array(Choice), []),
    otherSizes: types.late(() =>
      types.array(
        /*
          The reference resolver is overridden to ensure that `undefined` is returned if the
          referenced product hasn't been loaded yet. Without this override an error would be
          thrown if `otherSizes` was accessed before all of the associated products have loaded.
        */
        types.reference(Product, {
          get(identifier, parent) {
            return resolveIdentifier(Product, getRoot(parent), identifier);
          },
          set(value) {
            return value.id;
          },
        })
      )
    ),
    isDetailedProduct: false, // Used to indicate if the full product has been loaded.
    size: types.maybeNull(types.string),
    suitability: types.maybe(ProductSuitability),
    disclaimer: types.maybeNull(types.string),
    isAvailable: types.optional(types.boolean, true),
    isEligibleForDeliveryDiscount: types.optional(types.boolean, false),
    hidesBastingIfProteinSelected: types.optional(types.boolean, false),
  })
  .actions(self => ({
    // Used to update self attributes when loading detailed product over the top of the menu product.
    update(attributes) {
      const filteredAttributes = omit(attributes, ['id']);

      forEach(filteredAttributes, (value, key) => {
        self[key] = value;
      });
    },
  }))
  .views(self => {
    const { DietaryPreferencesStore, ProductDetailsState } = getRoot(self);
    return {
      get formattedKilojoules() {
        return self.kilojoules ? kilojoulesFormatter(self.kilojoules) : undefined;
      },
      get isByoProduct() {
        return !!self.byoType;
      },
      get isItemProduct() {
        return isItemModifier(self.modifier);
      },
      get isMealProduct() {
        return isMealModifier(self.modifier);
      },
      get itemProduct() {
        const { otherSizes } = self;

        // Try to find the corresponding "item product" for this meal product.
        if (self.isMealProduct) {
          return find(otherSizes, otherSize => otherSize !== undefined && otherSize.isItemProduct);
        }

        return undefined; // to match find's return type.
      },
      get mealProduct() {
        const { otherSizes } = self;

        // Try to find the corresponding "meal product" for this item product.
        if (self.isItemProduct) {
          return find(otherSizes, otherSize => otherSize !== undefined && otherSize.isMealProduct);
        }

        return undefined; // to match find's return type.
      },

      get ingredientChoices() {
        return self.choices.filter(choice => choice.canonicalName === ADD || choice.canonicalName === REMOVE);
      },

      get extraChoice() {
        return self.choices.find(choice => choice.canonicalName === ADD);
      },

      get removeChoice() {
        return self.choices.find(choice => choice.canonicalName === REMOVE);
      },

      get plu() {
        const matches = self.id.match(PRODUCT_ID_TO_PLU_REG_EX);
        return matches ? matches[1] : undefined;
      },

      get mealChoices() {
        return self.choices.filter(choice => choice.canonicalName === DRINK || choice.canonicalName === SIDE);
      },

      get byoChoices() {
        const { initialChoices } = self;
        const byoExtraChoices = self.choices.filter(choice =>
          includes(BYO_EXTRA_INGREDIENTS_CANONICAL_NAMES, choice.canonicalName)
        );
        return difference(initialChoices, byoExtraChoices).concat([byoExtraChoices]);
      },

      /**
       * Get an array of all sizes the product is available in (excluding meal upgrades).
       *
       * @returns {Product[]} Undefined if other sizes have not yet loaded, otherwise an array of products
       */
      get sizes() {
        if (!self.isDetailedProduct || self.otherSizes.some(product => product === undefined)) {
          return undefined;
        }

        const sizes = self.otherSizes.filter(product => !product.isMealProduct);

        if (sizes.length === 1) {
          return [];
        }

        return sizes;
      },

      /**
       * Get whether the product has other sizes (excluding meal upgrades).
       *
       * @returns {boolean} Undefined if other sizes have not yet loaded, otherwise true/false.
       */
      get hasOtherSizes() {
        if (self.sizes === undefined) {
          return undefined;
        }

        return self.sizes.length > 0;
      },

      get isAnySizeAvailable() {
        // Note: this doesn't work as well for NZ as it does for AU, since otherSizes aren't resolvable in the menu until they get retrieved when detail is opened.
        // This may cause the menu item card to disable, even though some sizes are actually available. This is acceptable since it's difficult to fix.

        return (
          self.isAvailable ||
          some(self.otherSizes, product => !get(product, 'isMealProduct') && get(product, 'isAvailable'))
        );
      },

      // @NOTE initialChoices is used to render the choices that aren't ingredient or meal related,
      // they are the choices that appear on the Product Details Screen itself.
      // @TODO: Move to ProductDetailsState since it's kinda apps specific?
      get initialChoices() {
        const { choices, ingredientChoices, mealChoices } = self;

        let initialChoices;
        if (self.hasMealUpgrade || self.isMealUpgrade) {
          initialChoices = difference(choices, ingredientChoices, mealChoices);
        } else {
          initialChoices = difference(choices, ingredientChoices);
        }

        if (ProductDetailsState && ProductDetailsState.isProteinThatHidesBastingSelected) {
          initialChoices = filter(initialChoices, choice => !CANONICAL_BASTING_MAPPING[choice.name]);
          Object.keys(CANONICAL_BASTING_MAPPING).forEach(key => ProductDetailsState.removeChoice(key));
        }

        return initialChoices;
      },

      /**
       * Determine whether the product has variable pricing, so that we can display 'from' above the price
       */
      get hasVariablePrice() {
        /*
          If full product details have not been loaded then the meal choices are unknown.
        */
        if (!self.isDetailedProduct) {
          return false;
        }

        const hasProteinChoices = self.choices.filter(choice => choice.canonicalName === PROTEIN).length > 0;
        return self.hasOtherSizes || hasProteinChoices;
      },

      /**
       * Returns boolean type to determine if an item has other size that is eligible for free delivery.
       */
      get hasOtherSizeEligibleForDeliveryDiscount() {
        const { otherSizes } = self;

        const findOtherSizeEligibleForDeliveryDiscount = find(otherSizes, { isEligibleForDeliveryDiscount: true });

        if (isEmpty(otherSizes) || isEmpty(findOtherSizeEligibleForDeliveryDiscount)) {
          return false;
        }

        return findOtherSizeEligibleForDeliveryDiscount.isEligibleForDeliveryDiscount;
      },

      mealUpgradePrices(selectedMealOptionsIds = []) {
        // @NOTE: The current product could be either an item or a meal,
        // depending on which variant of the product the user is viewing or editing.
        const itemProduct = self.isMealProduct ? self.itemProduct : self;
        const mealProduct = self.isMealProduct ? self : self.mealProduct;

        const basePrices = mealProduct.prices.minus(itemProduct.prices);
        const allOptions = mealProduct.choices.map(choice => choice.options);
        const selectedOptions = flatMap(allOptions).filter(option => includes(selectedMealOptionsIds, option.id));
        const selectedOptionsPrices = selectedOptions.reduce((acc, value) => acc.plus(value.prices), Prices.create());

        return basePrices.plus(selectedOptionsPrices);
      },

      mealUpgradeKilojoules(selectedMealOptionsIds = []) {
        const mealProduct = self.isMealProduct ? self : self.mealProduct;

        const { product, orderItem, kilojoules } = ProductDetailsState;
        const initialKilojoules = kilojoules;
        const allOptions = flatMap(map(mealProduct.choices, 'options'));

        const selectedOptions = allOptions.filter(option => includes(selectedMealOptionsIds, option.id));
        const selectedOptionsKilojoules = sumBy(selectedOptions, 'kilojoules');

        let previousOptionsKilojoules = 0;

        if (get(product, 'isMealProduct')) {
          // user has already "made it a meal", so we need to consider existing selection kJ when calculating new selection kJ
          const previouslySelectedIds = flatMap(mapValues(orderItem.groupedMealOptions, options => map(options, 'id')));
          const previouslySelectedOptions = allOptions.filter(option => includes(previouslySelectedIds, option.id));
          previousOptionsKilojoules = sumBy(previouslySelectedOptions, 'kilojoules');
        }

        return kilojoulesFormatter(initialKilojoules - previousOptionsKilojoules + selectedOptionsKilojoules);
      },

      get productCategory() {
        const rootStore = getRoot(self);

        /*
          Reliance upon MenuStore can cause issues when using the model in isolation (e.g. in tests). Checking
          for the existence of MenuStore and returning undefined helps to avoid these issues and simplifies tests.
        */
        if (rootStore.MenuStore === undefined) {
          return undefined;
        }

        return rootStore.MenuStore.getProductCategory(self.id);
      },

      /**
       * Determine whether the specified set of options satisfies the required
       * meal choices of the product.
       *
       * @param {string[]} selectedOptions
       */
      mealChoicesSatisfied(selectedOptions) {
        /*
          If full product details have not been loaded then the meal choices are unknown. Return
          false in this scenario because it's not possible to determine whether the choices have
          been satisfied.
        */
        if (!self.isDetailedProduct) {
          return false;
        }

        return self.mealChoices.length === selectedOptions.length;
      },

      missingRequiredMealChoices(selectedOptions) {
        /*
          If full product details have not been loaded then the meal choices are unknown. Return
          false in this scenario because it's not possible to determine whether the choices have
          been satisfied.
        */
        if (!self.isDetailedProduct) {
          return false;
        }

        const missingChoiceKeys = map(self.mealChoices, mealChoice => {
          const isChoiceSelected = includes(selectedOptions, mealChoice.id);
          return isChoiceSelected ? null : mealChoice.id;
        });

        return compact(missingChoiceKeys);
      },

      /**
       * Find a corresponding choice in the product which matches the given choice name.
       *
       * @param {string} choiceName
       */
      correspondingChoice(choiceName) {
        const choiceCanonicalName = Choice.getCanonicalName(choiceName);
        const correspondingChoice = find(self.choices, { canonicalName: choiceCanonicalName });

        return correspondingChoice;
      },

      get hasMealUpgrade() {
        return self.mealProduct !== undefined;
      },

      get isMealUpgrade() {
        return self.isMealProduct && self.itemProduct !== undefined;
      },

      formatDietaryPreferencesList(dietaryPreferencesList) {
        const list = map(dietaryPreferencesList, option => {
          const numberOfOptions = size(get(self.suitability, `${camelCase(option)}.range`));
          const isDairyOrGluten = option === DIETARY_TYPES.NO_DAIRY || option === DIETARY_TYPES.NO_GLUTEN;

          if (numberOfOptions > 1 && !isDairyOrGluten) {
            if (option === DIETARY_TYPES.VEGETARIAN) {
              return 'VGO';
            }
            if (option === DIETARY_TYPES.VEGETARIAN) {
              return 'VG';
            }
          }

          if (numberOfOptions > 0) {
            return get(DIETARY_PREFERENCES_OPTIONS[snakeCase(option).toUpperCase()], 'code', option);
          }

          return null;
        });

        return compact(list);
      },

      get dietaryPreferencesList() {
        return DietaryPreferencesStore ? DietaryPreferencesStore.dietaryPreferencesList : [];
      },

      get dietaryPreferencesOptionsList() {
        return DietaryPreferencesStore ? DietaryPreferencesStore.getDietaryPreferencesOptionsList(self) : [];
      },

      get dietaryPreferencesWithNoOptionsList() {
        return DietaryPreferencesStore ? DietaryPreferencesStore.getDietaryPreferencesWithNoOptionsList(self) : [];
      },

      get formattedDietaryPreferencesList() {
        return self.formatDietaryPreferencesList(self.dietaryPreferencesList);
      },

      get formattedSuitability() {
        return self.formatDietaryPreferencesList(Object.values(DIETARY_TYPES));
      },

      get formattedDietaryPreferencesWithNoOptionsList() {
        return self.formatDietaryPreferencesList(self.dietaryPreferencesWithNoOptionsList);
      },

      get dietaryMesssageTitle() {
        return DietaryPreferencesStore ? DietaryPreferencesStore.getDietaryMessageTitle(self) : '';
      },

      get dietaryOptions() {
        return DietaryPreferencesStore ? DietaryPreferencesStore.getOptionsList(self) : [];
      },
    };
  });

export default Product;
