import { types } from 'mobx-state-tree';

import Price from '../price';

const OrderChoice = types.model('OrderChoice', {
  name: types.maybeNull(types.string),
  plu: types.maybeNull(types.string),
  price: types.number,
  quantity: types.number,
  isRefundable: types.maybeNull(types.boolean),
  uuid: types.maybeNull(types.string),
});

const PastOrderItem = types.compose(
  types.model('PastOrderItem', {
    productId: types.maybeNull(types.string),
    productName: types.string,
    name: types.string,
    quantity: types.number,
    choices: types.maybeNull(types.array(OrderChoice)),
    price: types.number,
    uuid: types.maybeNull(types.string),
  }),
  Price
);

export default PastOrderItem;
