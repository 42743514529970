import { isEmpty } from 'lodash';
import { observer } from 'mobx-react';
import { useFlags, useLDClient as useLaunchDarklyClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import { useRootStore } from '@nandosaus/state-management';
import { v4 as uuid } from 'uuid';
import PropTypes from 'prop-types';
import { localStorage } from '@utils/local-storage';
import React, { useEffect, createContext, useCallback } from 'react';

import { logger } from '../../utils/logger';

const LAUNCH_DARKLY_CONTEXT_KEY = 'LAUNCH_DARKLY_CONTEXT_KEY';

const getLaunchDarklyContextKey = async () => {
  try {
    const savedLaunchDarklyContextKey = await localStorage.getItem(LAUNCH_DARKLY_CONTEXT_KEY);
    if (savedLaunchDarklyContextKey) {
      return savedLaunchDarklyContextKey;
    }

    const newLaunchDarklyContextKey = uuid();
    await localStorage.setItem(LAUNCH_DARKLY_CONTEXT_KEY, newLaunchDarklyContextKey);
    return newLaunchDarklyContextKey;
  } catch (error) {
    logger.error('Unable to store launch darkly context key in session storage', { error });
  }
  return undefined;
};

const ServerSideFeatureFlagsContext = createContext({});

const FeatureProvider = observer(({ children, serverSideFeatureFlags }) => {
  const { FeatureFlagStore, MemberStore } = useRootStore();
  const client = useLaunchDarklyClient();
  const flags = useFlags();
  const hasClient = !!client;

  client?.on('error', () => {
    logger.error('LaunchDarkly client failed to initialize, using fallback values from the API.');
    FeatureFlagStore.setHasError(true);
  });

  useEffect(() => {
    FeatureFlagStore.setServerSideFeatureFlags(serverSideFeatureFlags?.flags);
  }, [FeatureFlagStore, serverSideFeatureFlags]);

  useEffect(() => {
    if (isEmpty(flags)) {
      return;
    }

    FeatureFlagStore.set(flags);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flags]);

  const identifyCallback = useCallback(() => {
    const identify = async () => {
      await client?.identify({
        kind: 'user',
        key: await getLaunchDarklyContextKey(),
        email: MemberStore?.profile?.email,
        custom: {
          state: MemberStore?.profile?.state,
        },
      });
    };

    identify();
  }, [MemberStore, client]);

  useEffect(
    () => {
      if (hasClient) {
        identifyCallback();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasClient, MemberStore.isSignedIn]
  );

  return (
    <ServerSideFeatureFlagsContext.Provider value={serverSideFeatureFlags?.flags}>
      {children}
    </ServerSideFeatureFlagsContext.Provider>
  );
});

FeatureProvider.propTypes = {
  children: PropTypes.node.isRequired,
  featureFlags: PropTypes.object,
};

const LaunchDarklyFeatureProvider = withLDProvider({
  clientSideID: process.env.launchDarkly.clientKey,
  deferInitialization: false,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(FeatureProvider);

export { LaunchDarklyFeatureProvider as FeatureProvider, ServerSideFeatureFlagsContext, getLaunchDarklyContextKey };
