import gql from 'graphql-tag';

const query = gql`
  query getGiftCard($cardNumber: String!, $verificationCode: String!) {
    getGiftCard(cardNumber: $cardNumber, verificationCode: $verificationCode) {
      balance
      cardNumber
      expiryDate
    }
  }
`;

const getGiftCard = async (cardNumber, verificationCode, { apiClient }) => {
  const response = await apiClient.query({
    query,
    variables: { cardNumber, verificationCode },
  });

  return response.data.getGiftCard;
};

export { getGiftCard };
