const template = ({ message, variables = {} }) => {
  const variableKeys = Object.keys(variables);

  const result = variableKeys.reduce((accum, current) => {
    const variableRegex = `\\$\\{${current}\\}`;
    const regex = new RegExp(variableRegex, 'g');
    return accum.replace(regex, variables[current]);
  }, message);

  return result;
};

export { template };
