import { includes } from 'lodash';
import { PAYMENT_METHODS } from '@nandosaus/constants';
import { types } from 'mobx-state-tree';

import AlternativePaymentMethod from '../../models/alternative-payment-method-type';

const AlternativePaymentMethodStore = types
  .model('AlternativePaymentMethodStore', {
    applePay: types.optional(AlternativePaymentMethod, { type: PAYMENT_METHODS.APPLE_PAY }),
    googlePay: types.optional(AlternativePaymentMethod, { type: PAYMENT_METHODS.GOOGLE_PAY }),
    paypal: types.optional(AlternativePaymentMethod, { type: PAYMENT_METHODS.PAYPAL }),
  })
  .actions(self => ({
    setAcceptsApplePay(isAccepted) {
      self.applePay.isAccepted = isAccepted;
    },
    setApplePayIsAvailableOnPlatform(isAvailable) {
      self.applePay.isAvailableOnPlatform = isAvailable;
    },
    setAcceptsGooglePay(isAccepted) {
      self.googlePay.isAccepted = isAccepted;
    },
    setGooglePayIsAvailableOnPlatform(isAvailable) {
      self.googlePay.isAvailableOnPlatform = isAvailable;
    },
    setAcceptsPaypal(isAvailable) {
      self.paypal.isAccepted = isAvailable;
    },
    setPaypalIsAvailableOnPlatform(isAvailable) {
      self.paypal.isAvailableOnPlatform = isAvailable;
    },
    setSupportedAlternativePaymentMethods(methods = []) {
      self.setAcceptsApplePay(includes(methods, PAYMENT_METHODS.APPLE_PAY));
      self.setAcceptsGooglePay(includes(methods, PAYMENT_METHODS.GOOGLE_PAY));
      self.setAcceptsPaypal(includes(methods, PAYMENT_METHODS.PAYPAL));
    },
  }))
  .views(self => ({
    get isApplePaySupported() {
      return self.applePay.isAccepted && self.applePay.isAvailableOnPlatform;
    },
    get isGooglePaySupported() {
      return self.googlePay.isAccepted && self.googlePay.isAvailableOnPlatform;
    },
    get isPaypalSupported() {
      return self.paypal.isAccepted && self.paypal.isAvailableOnPlatform;
    },
  }));

export default AlternativePaymentMethodStore;
