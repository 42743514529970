import { createGlobalStyle } from 'styled-components';

import { fontWeight, globalStyles } from '../../constants';

const TypographyStyles = createGlobalStyle`
  html {
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    color: ${({ theme }) => theme.colors.black};
    font-family: ${globalStyles.baseFontFamily};
  }

  body {
    margin: 0;
  }

  b,
  strong {
    font-weight: ${fontWeight.bold};
  }

  i,
  em {
    font-style: italic;
  }

  a {
    color: inherit;

    &[href*="mailto:"] {
      word-wrap: break-word;
    }
  }
`;

export { TypographyStyles };
