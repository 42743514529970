import { isEmpty, isNaN, split } from 'lodash';
import z from 'zod';

// using regex to validate url because Zod URL validation fails in react native.
// constructr URL is unavailable in react native as well.
// use case are images and links in nandos, so the below regex should be sufficient
const isValidURL = url => {
  const regex = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  );
  return regex.test(url);
};

const arrayParser = z.string().transform(val => split(val, ','));

const booleanParser = z
  .string()
  .refine(val => val === 'true' || val === 'false', {
    message: 'String should only contain "true" or "false"',
  })
  .transform(val => val === 'true');

const urlParser = z.string().refine(val => isValidURL(val), {
  message: 'Must be a valid URL',
});

const optionalUrlParser = z.string().refine(val => isEmpty(val) || isValidURL(val), {
  message: 'Must be a valid URL or an empty string',
});

const numberParser = z
  .string()
  .refine(val => !isNaN(Number(val)), {
    message: 'String should only contain numbers',
  })
  .transform(val => Number(val));

const CONTENT_CARD_MAPPINGS_AND_SCHEMAS = {
  'popular-this-week': {
    'category-header': { mapping: 'title', schema: z.string() },
    'fav-products': { mapping: 'favourites', schema: arrayParser },
  },
  'app-home-screen': {
    greeting: { mapping: 'heading', schema: z.string() },
    subheader: { mapping: 'subheading', schema: z.optional(z.string()) },
    cta: { mapping: 'cta', schema: z.optional(z.string()) },
    'top-tile-image': { mapping: 'image', schema: urlParser },
  },
  'menu-hero-image': {
    image: { mapping: 'image', schema: urlParser },
    header: { mapping: 'heading', schema: z.string() },
    'keep-border': { mapping: 'border', schema: booleanParser },
  },
  'hero-banner': {
    heading: { mapping: 'heading', schema: z.string() },
    body: { mapping: 'body', schema: z.optional(z.string()) },
    image: { mapping: 'image', schema: urlParser },
    cta: { mapping: 'button', schema: z.optional(z.string()) },
    'cta-link': { mapping: 'buttonLink', schema: z.optional(optionalUrlParser) },
    'cms-id': { mapping: 'cmsId', schema: z.string() },
  },
  'app-content-block': {
    heading: { mapping: 'heading', schema: z.string() },
    body: { mapping: 'body', schema: z.optional(z.string()) },
    image: { mapping: 'image', schema: urlParser },
    'internal-app-link': { mapping: 'internalAppLink', schema: z.optional(z.string()) },
    'external-link': { mapping: 'externalLink', schema: z.optional(z.string()) },
    'cms-id': { mapping: 'cmsId', schema: z.string() },
  },
  'content-image-block': {
    heading: { mapping: 'heading', schema: z.string() },
    body: { mapping: 'body', schema: z.optional(z.string()) },
    cta: { mapping: 'button', schema: z.optional(z.string()) },
    'cta-link': { mapping: 'buttonLink', schema: z.optional(optionalUrlParser) },
    image: { mapping: 'image', schema: urlParser },
    'cms-id': { mapping: 'cmsId', schema: z.string() },
  },
  'page-header-block': {
    heading: { mapping: 'heading', schema: z.string() },
    subheading: { mapping: 'subheading', schema: z.optional(z.string()) },
    body: { mapping: 'body', schema: z.optional(z.string()) },
    'cms-id': { mapping: 'cmsId', schema: z.string() },
  },
  'special-offer-promotion-modal': {
    heading: { mapping: 'heading', schema: z.string() },
    subheading: { mapping: 'subheading', schema: z.optional(z.string()) },
    plu: { mapping: 'plu', schema: z.string() },
    body: { mapping: 'body', schema: z.optional(z.string()) },
    cta: { mapping: 'cta', schema: z.string() },
    'cta-dismiss': { mapping: 'ctaDismiss', schema: z.string() },
  },
  'perks-signed-in': {
    heading: { mapping: 'heading', schema: z.string() },
    subheading: { mapping: 'subheading', schema: z.optional(z.string()) },
  },
  'app-perks-header-image': {
    image: { mapping: 'image', schema: urlParser },
  },
  'post-order-content': {
    heading: { mapping: 'title', schema: z.string() },
    body: { mapping: 'description', schema: z.string() },
    cta: { mapping: 'buttonText', schema: z.optional(z.string()) },
    'cta-link': { mapping: 'href', schema: z.optional(optionalUrlParser) },
    order: { mapping: 'order', schema: numberParser },
  },
  'menu-content': {
    name: { mapping: 'name', schema: z.string() },
    description: { mapping: 'description', schema: z.string() },
    products: { mapping: 'products', schema: z.optional(arrayParser) },
    'category-order': { mapping: 'order', schema: numberParser },
    'category-handle': { mapping: 'handle', schema: z.string() },
  },
  'hot-picks': {
    heading: { mapping: 'heading', schema: z.string() },
    products: { mapping: 'products', schema: z.optional(arrayParser) },
    cta: { mapping: 'cta', schema: z.string() },
    'new-products': { mapping: 'newProducts', schema: z.optional(arrayParser) },
  },
  'welcome-page-header': {
    heading: { mapping: 'heading', schema: z.string() },
    headingHighlight: { mapping: 'headingHighlight', schema: z.optional(z.string()) },
  },
  'order-at-table-promotions': {
    heading: { mapping: 'header', schema: z.string() },
    body: { mapping: 'description', schema: z.optional(z.string()) },
    disclaimer: { mapping: 'disclaimer', schema: z.optional(z.string()) },
    image: { mapping: 'image', schema: urlParser },
    cta: { mapping: 'buttonText', schema: z.optional(z.string()) },
    'cta-link': { mapping: 'buttonLink', schema: z.optional(z.string()) },
    'text-width': { mapping: 'textWidth', schema: z.optional(z.string()) },
  },
};

const BRAZE_CMS_TYPES = ['content-image-block', 'page-header-block', 'app-content-block', 'hero-banner'];

export { CONTENT_CARD_MAPPINGS_AND_SCHEMAS, BRAZE_CMS_TYPES };
