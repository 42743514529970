import { logger } from '../../../../utils/logger';

const identifyWithRetry = (mParticle, identifyRequest, maxRetries = 3) => {
  let retries = 0;

  const codes = mParticle.Identity.HTTPCodes;
  const retry = () => {
    mParticle.Identity.identify(identifyRequest, res => {
      switch (res.httpCode) {
        case codes.noHttpCoverage:
          retries += 1;
          if (retries < maxRetries) {
            retry();
          } else {
            logger.error(`mParticle IDSync failed after ${retries} attempts`);
          }
          break;
        case codes.activeIdentityRequest:
        case 429:
          logger.error(
            `mParticle IDSync too frequent, should call once in entire session and retry once a time. check the implemntation`
          );
          break;
        case codes.validationIssue:
        case 400:
          logger.error(`mParticle IDSync failed, reason: ${res.body}`);
          break;
        case 200:
          break;
        default:
          logger.error(`mParticle IDSync failed, unknown error, reason: ${res.body}`);
          break;
      }
    });
  };

  retry();
};

export { identifyWithRetry };
