import { CONTEXT_TYPES, ORDER_TYPES } from '@nandosaus/constants';
import { types } from 'mobx-state-tree';

export const GeneralContext = types
  .model({
    type: types.literal(CONTEXT_TYPES.GENERAL),
  })
  .views(() => {
    return {
      get availableOrderTypes() {
        return [ORDER_TYPES.PICK_UP, ORDER_TYPES.DINE_IN, ORDER_TYPES.DELIVERY];
      },
    };
  });
