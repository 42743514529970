const Vegan = props => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5505_2031)">
      <path
        d="M20.3904 5.26367L20.3504 5.45028C20.7743 9.281 20.1425 17.0278 11.1748 25.9954C11.2228 26.0168 11.2628 26.0647 11.3134 26.0647C23.0748 26.0914 24.3064 13.7328 20.3904 5.26367Z"
        fill="black"
      />
      <path
        d="M20.396 5.24512C19.5003 6.56468 17.4264 8.7826 12.636 12.0002C11.5684 12.712 10.5601 13.509 9.62097 14.3834C9.10614 14.8673 8.66513 15.4242 8.31207 16.0362C7.66728 17.1754 7.3382 18.466 7.35882 19.7749C7.37944 21.0837 7.74901 22.3634 8.42936 23.4817C8.55199 23.6843 8.69594 23.8816 8.82656 24.0735C17.9115 17.8782 19.9935 9.85958 20.396 5.24512Z"
        fill="black"
      />
      <path
        d="M15.9947 2.09947C18.8418 2.09947 21.6249 2.94373 23.9922 4.5255C26.3595 6.10726 28.2046 8.35548 29.2941 10.9859C30.3836 13.6162 30.6687 16.5106 30.1133 19.303C29.5578 22.0954 28.1868 24.6604 26.1736 26.6736C24.1604 28.6868 21.5954 30.0578 18.803 30.6133C16.0106 31.1687 13.1162 30.8836 10.4859 29.7941C7.85549 28.7046 5.60727 26.8595 4.0255 24.4922C2.44374 22.1249 1.59947 19.3418 1.59947 16.4947C1.60511 12.6786 3.12356 9.02036 5.82196 6.32195C8.52036 3.62355 12.1786 2.10511 15.9947 2.09947ZM15.9947 0.5C12.8312 0.5 9.73882 1.43807 7.10851 3.19559C4.4782 4.9531 2.42813 7.45113 1.21753 10.3738C0.00693027 13.2964 -0.309818 16.5124 0.30734 19.6151C0.924498 22.7177 2.44784 25.5677 4.68474 27.8046C6.92163 30.0415 9.77161 31.5648 12.8743 32.182C15.9769 32.7992 19.1929 32.4824 22.1156 31.2718C25.0382 30.0612 27.5362 28.0111 29.2938 25.3808C31.0513 22.7505 31.9893 19.6581 31.9893 16.4947C31.9893 12.2526 30.3042 8.18431 27.3046 5.18473C24.305 2.18515 20.2367 0.5 15.9947 0.5V0.5Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_5505_2031">
        <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export { Vegan };
