import { concat, find, forEach, get, includes, isEmpty, map, partition, reject, size } from 'lodash';
import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
import { ORDER_TYPES, ORDER_UPDATE_STATUS } from '@nandosaus/constants';
import gql from 'graphql-tag';
import moment from 'moment';

import ActiveOrder from '../../models/active-order';
import PastOrder from '../../models/past-order';

const initialState = {
  loading: true,
  error: null,
  orders: [],
  activeOrders: [],
};

const ACTIVE_ORDER_FIELDS_FRAGMENT = gql`
  fragment ActiveOrderFields on ActiveOrder {
    id
    status
    estimatedDeliveryTime
    pickupTime
    orderType
    driverName
    driverPhone
    lastUpdatedTime
  }
`;

const ACTIVE_ORDERS_QUERY = gql`
  query getActiveOrders {
    activeOrders {
      ...ActiveOrderFields
    }
  }

  ${ACTIVE_ORDER_FIELDS_FRAGMENT}
`;

const ORDERS_QUERY = gql`
  query getOrders($email: String) {
    activeOrders {
      ...ActiveOrderFields
    }
    orders(email: $email) {
      id
      date
      restaurantId
      orderItems {
        uuid
        productId
        productName
        name
        quantity
        price
        choices {
          uuid
          plu
          name
          price
          quantity
          isRefundable
        }
      }
      deliveryAddress {
        address1
        suburb
        state
        postcode
      }
      deliveryTrackingUrl
      deliveryNotes
      table
      isRefunded
      isVisibleInHelpFlow
      isRefundableAsGiftCard
    }
  }

  ${ACTIVE_ORDER_FIELDS_FRAGMENT}
`;

const getOrders = async ({ client, email }) => {
  try {
    return await client.query({ query: ORDERS_QUERY, variables: { email } });
  } catch (error) {
    return { error };
  }
};

const getActiveOrders = async ({ client }) => {
  try {
    return await client.query({ query: ACTIVE_ORDERS_QUERY });
  } catch (error) {
    return { error };
  }
};

const errorMessage = `Error fetching past order data`;

const PastOrdersStore = types
  .model('PastOrdersStore', {
    loading: false,
    error: types.maybeNull(types.string),
    orders: types.array(PastOrder),
    activeOrders: types.array(ActiveOrder),
    currentActiveOrder: types.maybe(types.reference(ActiveOrder)),
    activeOrderCount: types.maybe(types.number),
  })
  .actions(self => {
    const { getApiClient, logger } = getEnv(self);
    return {
      clearSessionData() {
        self.loading = false;
        self.error = null;
        self.orders = [];
        self.currentActiveOrder = undefined;
        self.activeOrders = [];
      },
      loadOrders: flow(function*(userEmail) {
        self.error = null;
        self.loading = true;
        const { MenuStore, RestaurantStore, MemberStore } = getRoot(self);
        try {
          const client = yield getApiClient();
          const result = yield getOrders({
            client,
            email: userEmail || get(MemberStore, 'profile.email'),
          });

          const { data, error } = result;
          if (error) {
            throw error;
          }

          const orderIsComplete = order => {
            const isDeliveryAndIsComplete =
              order.orderType === ORDER_TYPES.DELIVERY && order.status === ORDER_UPDATE_STATUS.DROPPED_OFF;

            const isNotDeliveryAndIsComplete =
              order.orderType !== ORDER_TYPES.DELIVERY && order.status === ORDER_UPDATE_STATUS.SERVED_BY_KITCHEN;

            const minutesSinceUpdated = moment().diff(moment(order.lastUpdatedTime), 'minutes');

            return (isDeliveryAndIsComplete || isNotDeliveryAndIsComplete) && minutesSinceUpdated > 10;
          };

          const activeOrderIds = map(reject(data.activeOrders, orderIsComplete), 'id');

          const allOrders = map(
            data.orders,
            ({
              id,
              date,
              orderItems,
              restaurantId,
              deliveryAddress,
              deliveryNotes,
              table,
              deliveryTrackingUrl,
              isRefunded,
              isVisibleInHelpFlow,
              isRefundableAsGiftCard,
            }) => ({
              id,
              date: moment(date).toDate(),
              orderItems,
              restaurantId,
              deliveryAddress,
              deliveryNotes,
              table,
              deliveryTrackingUrl,
              isRefunded,
              isVisibleInHelpFlow,
              isRefundableAsGiftCard,
            })
          );
          const [activeOrders, orders] = partition(allOrders, order => includes(activeOrderIds, order.id));

          const activeOrdersMerged = map(activeOrders, order => {
            const activeOrder = find(data.activeOrders, ({ id }) => id === order.id);
            return {
              ...order,
              ...activeOrder,
              restaurant: RestaurantStore.getRestaurantById(order.restaurantId),
              estimatedDeliveryTime: activeOrder.estimatedDeliveryTime
                ? moment(activeOrder.estimatedDeliveryTime).toDate()
                : undefined,
              lastUpdatedTime: activeOrder.lastUpdatedTime ? moment(activeOrder.lastUpdatedTime).toDate() : undefined,
              pickupTime: activeOrder.pickupTime ? moment(activeOrder.pickupTime).toDate() : undefined,
            };
          });
          self.orders = orders;
          self.activeOrders = activeOrdersMerged;
          self.activeOrderCount = size(activeOrdersMerged);

          const productsWithoutDetails = [];
          forEach(concat(self.orders, self.activeOrders), order => {
            forEach(order.formattedOrderItems, formattedOrderItem => {
              const product = get(formattedOrderItem, 'product') || {};
              if (product.id && !product.isDetailedProduct) {
                productsWithoutDetails.push(product.id);
              }
            });
          });

          if (MenuStore.menu) {
            yield MenuStore.menu.loadProducts(productsWithoutDetails);
          }

          self.loading = false;
          return { data: { activeOrders: activeOrdersMerged, orders }, error: null };
        } catch (error) {
          self.setError(errorMessage);
          logger.error(errorMessage, { error });
        }
        self.loading = false;
        return { data: null, error: errorMessage };
      }),
      loadActiveOrderCount: flow(function*() {
        self.error = null;
        self.loading = true;
        try {
          const client = yield getApiClient();
          const result = yield getActiveOrders({ client });

          const { data, error } = result;
          if (error) {
            throw error;
          }

          const { activeOrders } = data;
          self.activeOrderCount = size(activeOrders);
        } catch (error) {
          self.setError(`Error fetching active order data`);
          logger.error(`Error fetching active order data`, { error });
        }
        self.loading = false;
      }),
      setError(displayError) {
        self.error = displayError;
      },
      setCurrentActiveOrder(activeOrder) {
        self.currentActiveOrder = activeOrder;
      },
      setCurrentActiveOrderStatus(status) {
        if (!self.currentActiveOrder) {
          return;
        }
        self.currentActiveOrder.status = status;
      },
      setCurrentActiveOrderDriverDetails(driverName, driverPhone) {
        if (!self.currentActiveOrder) {
          return;
        }
        self.currentActiveOrder.driverName = driverName;
        self.currentActiveOrder.driverPhone = driverPhone;
      },
    };
  })
  .views(self => ({
    get recentOrders() {
      if (isEmpty(self.orders)) {
        return [];
      }

      return self.orders.filter(order => moment().diff(order.date, 'days') <= 7);
    },

    getOrderById(id) {
      return self.orders.find(order => order.id === id);
    },
  }));

PastOrdersStore.initialState = initialState;

export default PastOrdersStore;
