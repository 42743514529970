const yup = require('yup');
const { isEmpty } = require('lodash');
const {
  yupRecordSchema,
  integerStringSchema,
  variationSchema,
  rIsoDateTime,
  restaurantIdSchema,
} = require('../common');

const condenseChoices = Object.freeze({
  launchDarklyKey: 'condense-choices',
  yupSchema: yup.boolean().meta({
    description: 'Transforms the Redcat menu data to allow for different sizes in products.',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const dynamicNutritionData = Object.freeze({
  launchDarklyKey: 'dynamic-nutrition-data',
  yupSchema: yup.boolean().meta({
    description: 'Uses the nutrition CSV uploaded in the CMS and combines this with the menu',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const enableDeliveryBestEstimateApi = Object.freeze({
  launchDarklyKey: 'enable-delivery-best-estimate-api',
  yupSchema: yup.boolean().meta({
    description: "Enables using Redcat's best estimate logic",
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const holidaySurcharges = Object.freeze({
  launchDarklyKey: 'holiday-surcharges',
  yupSchema: yup.boolean().meta({
    description: "Enables Redcat's calculate surcharges logic",
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const orderTypeRedcatWebhook = Object.freeze({
  launchDarklyKey: 'order-type-redcat-webhook',
  yupSchema: yup.boolean().meta({
    description: "When Nando's makes a change in Redcat this processes that and updates the restaurants status",
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const outOfStockProducts = Object.freeze({
  launchDarklyKey: 'out-of-stock-products',
  yupSchema: yup.boolean().meta({
    description: 'Marks products as out of stock for the front end to be able to handle',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const paymentAccountReference = Object.freeze({
  launchDarklyKey: 'payment-account-reference',
  yupSchema: yup.boolean().meta({
    description: "Send the PAR from Adyen to Redcat so that Nando's can report on it",
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const paymentReportCardType = Object.freeze({
  launchDarklyKey: 'payment-report-card-type',
  yupSchema: yup.boolean().meta({
    description: 'Enables card type reporting to Redcat in order creation',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const perksShopOrdering = Object.freeze({
  launchDarklyKey: 'perks-shop-ordering',
  yupSchema: yup.boolean().meta({
    description: 'Enables the purchasing of perks products using points. Only in AU at the moment',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const removeKjFromDescription = Object.freeze({
  launchDarklyKey: 'remove-kj-from-description',
  yupSchema: yup.boolean().meta({
    description: 'Removes kJ description from the Redcat data',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const restaurantWaitTimeThresholds = Object.freeze({
  launchDarklyKey: 'restaurant-wait-time-thresholds',
  yupSchema: yup.boolean().meta({
    description: 'Adds time to the estimate for orders depending on the order total',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const saveOrderAddressesAndRestaurants = Object.freeze({
  launchDarklyKey: 'save-order-addresses-and-restaurants',
  yupSchema: yup.boolean().meta({
    description: "Saves people's addresses in Dynamodb.",
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const showCmsFeaturedProducts = Object.freeze({
  launchDarklyKey: 'show-cms-featured-products',
  yupSchema: yup.boolean().meta({
    description:
      'If enabled, will use CMS to pull items from Popular this week. If disabled, will serve from config file.',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const showCmsMenuPromotionModal = Object.freeze({
  launchDarklyKey: 'show-cms-menu-promotion-modal',
  yupSchema: yup.boolean().meta({
    description:
      'If enabled, will use CMS to pull items from Menu Promotion Modal. If disabled, will serve from config file.',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const showCmsUpsellModal = Object.freeze({
  launchDarklyKey: 'show-cms-upsell-modal',
  yupSchema: yup.boolean().meta({
    description:
      'If enabled, will use CMS to pull items from Recommended Products Upsell Modal or Braze overrides. If disabled, will serve from config file.',
  }),
  variants: [
    {
      description: 'off',
      value: false,
    },
  ],
});

const recommendationsEngineCategorySchema = yup.object({
  blacklistedProducts: yup.object({
    balanced: yup.array(yup.string()),
    healthy: yup.array(yup.string()),
    indulgent: yup.array(yup.string()),
  }),
  handle: yup.string(),
  modifier: yup.object({
    balanced: yup.number(),
    healthy: yup.number(),
    indulgent: yup.number(),
  }),
});

const recommendationsEngine = Object.freeze({
  launchDarklyKey: 'recommendations-engine',
  yupSchema: yup
    .object({
      categories: yup.object({
        desserts: recommendationsEngineCategorySchema,
        drinks: recommendationsEngineCategorySchema,
        kids: recommendationsEngineCategorySchema,
        mains: recommendationsEngineCategorySchema,
        sides: recommendationsEngineCategorySchema,
        snacks: recommendationsEngineCategorySchema,
      }),
      excludedCategories: yup.object({
        balanced: yup.array(yup.string()),
        healthy: yup.array(yup.string()),
        indulgent: yup.array(yup.string()),
      }),
      excludedMenuCategories: yup.object({
        balanced: yup.array(yup.string()),
        healthy: yup.array(yup.string()),
        indulgent: yup.array(yup.string()),
      }),
      kilojoulesThresholdPerPerson: yup.object({
        balanced: yup.number().integer(),
        healthy: yup.number().integer(),
        indulgent: yup.number().integer(),
      }),
      maxTries: yup.number().integer(),
    })
    .meta({
      description: 'Recommendations config',
    }),
  variants: [
    {
      description: 'Off',
      value: {},
    },
  ],
});

const hideBastingWhenProteinSelected = Object.freeze({
  launchDarklyKey: 'hide-basting-when-protein-selected',
  yupSchema: yup
    .object({
      productsThatCanHideBasting: yup.array(integerStringSchema),
      proteinChoicesThatCanHideBasting: yup.array(integerStringSchema),
    })
    .meta({
      description:
        'Controls which items and options should trigger a behaviour which hides the basting option for an item when combined with an specific protein. For the feature to work properly there are some changes to be made on the redcat menu items, which is to set the `NumChoicesMin` to `0` for the products that should hide basting.',
    }),
  variants: [
    {
      description: 'off',
      value: {
        productsThatCanHideBasting: [],
        proteinChoicesThatCanHideBasting: [],
      },
    },
  ],
});

const deliveryDiscountCampaign = Object.freeze({
  launchDarklyKey: 'delivery-discount-campaign',
  yupSchema: yup
    .object({
      endDate: yup
        .string()
        .matches(rIsoDateTime, { message: 'Invalid date time string' })
        .required(),
      fee: yup
        .object({
          cents: yup.number().integer(),
          points: yup.number().integer(),
        })
        .required(),
      restaurantIdBlacklist: yup.array(integerStringSchema),
      startDate: yup
        .string()
        .matches(rIsoDateTime, { message: 'Invalid date time string' })
        .required(),
    })
    .meta({
      description:
        'This flag control the delivery discount feature on the API, historically used to control the CCA Free Delivery campaign, where we can change the delivery fee for specific amount - date, discount etc - higher risk flag to manage',
    }),
  variants: [
    {
      description: 'Disabled',
      value: {
        endDate: '2022-04-15T19:00:00.000Z',
        fee: {
          cents: 0,
          points: 50,
        },
        restaurantIdBlacklist: [],
        startDate: '2022-04-08T19:00:00.000Z',
      },
    },
  ],
});

const defaultSelectedOptions = Object.freeze({
  launchDarklyKey: 'default-selected-options',
  yupSchema: yup
    .object({
      enabled: yup.boolean().required(),
      choiceNames: yup.array(yup.string()).required(),
      optionPlus: yup.array(integerStringSchema).required(),
      productPlus: yup.array(integerStringSchema).required(),
      restaurantIds: yup.array(integerStringSchema).required(),
      targets: variationSchema,
    })
    .meta({
      description:
        'Controls what choices has default options and what those default options should be - higher risk flag to manage.',
    }),
  variants: [
    {
      description: 'Inactive',
      value: {
        choiceNames: [],
        enabled: false,
        optionPlus: [],
        productPlus: [],
        restaurantIds: [],
        targets: 'inactive',
      },
    },
  ],
});

const automatedRefunds = Object.freeze({
  launchDarklyKey: 'automated-refunds',
  yupSchema: yup
    .object({
      defaultValues: yup.object({
        enabled: yup.boolean().required(),
        postOrderRefundWindowStartInHours: yup
          .number()
          .integer()
          .required(),
        automatedRefundThresholdInCents: yup.number().integer(),
        postOrderRefundWindowEndInHours: yup.number().integer(),
        enabledRefundAsGiftCard: yup.boolean(),
        enabledPaypal: yup.boolean(),
        lowerRefundCountThreshold: yup.number().integer(),
        upperRefundCountThreshold: yup.number().integer(),
        enabledGuest: yup.boolean(),
      }),
      restaurantOverrides: yupRecordSchema(
        yup.object({
          enabled: yup.boolean().required(),
          postOrderRefundWindowStartInHours: yup.number().integer(),
          automatedRefundThresholdInCents: yup.number().integer(),
          postOrderRefundWindowEndInHours: yup.number(),
          enabledRefundAsGiftCard: yup.boolean(),
          enabledPaypal: yup.boolean(),
          lowerRefundCountThreshold: yup.number().integer(),
          upperRefundCountThreshold: yup.number().integer(),
          enabledGuest: yup.boolean(),
        })
      ),
    })
    .meta({
      description: 'Enables automated refunds and has config related to this - higher risk flag to manage',
    }),
  variants: [
    {
      description: 'Inactive',
      value: {
        defaultValues: {
          enabled: false,
          postOrderRefundWindowStartInHours: 1,
        },
        restaurantOverrides: {
          '1': {
            enabled: false,
          },
        },
      },
    },
  ],
});

const menuCacheRegeneration = Object.freeze({
  launchDarklyKey: 'menu-cache-regeneration-enabled',
  yupSchema: yup
    .object({
      maxRetries: yup
        .number()
        .integer()
        .required(),
      nonRetryErrorMessages: yup.array(yup.string()),
      restaurantIds: yup.array(integerStringSchema),
    })
    .meta({ description: 'Controls whether we use the long lived menu cache.' }),
  variants: [
    {
      description: 'Off Variation',
      value: {
        maxRetries: 10,
        nonRetryErrorMessages: ['REDCAT: L_MAIN MENU menu(s) could be found, but is unavailable at this location'],
      },
    },
  ],
});

const publicHolidayOverride = Object.freeze({
  launchDarklyKey: 'public-holiday-override',
  yupSchema: yup.boolean().meta({
    description: 'Controls whether to display digital wallet item in the web page drawer. Web and Mobile.',
  }),
  variants: [{ description: 'default', value: false }],
});

const zenDeskContactData = Object.freeze({
  launchDarklyKey: 'zendesk-contact-data',
  yupSchema: yup.object({
    contactDataByRestaurantId: yupRecordSchema(
      yup.object({
        areaManagerEmail: yup
          .string()
          .meta({ description: 'Default email for the area manager' })
          .email()
          .nullable(),
        restaurantEmail: yup
          .string()
          .meta({ description: 'Default email for the restaurant' })
          .email()
          .nullable(),
        restaurantId: integerStringSchema.meta({ description: 'Default email for the restaurant' }),
      })
    ),
    fallbackContactData: yup.object({
      areaManagerEmail: yup
        .string()
        .meta({ description: 'Default email for the area manager' })
        .email(),
      restaurantEmail: yup
        .string()
        .meta({ description: 'Default email for the restaurant' })
        .email(),
    }),
  }),
  variants: [
    {
      description: 'fallback',
      value: {
        contactDataByRestaurantId: {},
        fallbackContactData: {
          areaManagerEmail: 'caitlin.hanning@nandos.com.au',
          restaurantEmail: 'caitlin.hanning@nandos.com.au',
        },
      },
    },
    {
      // This variant is here to test coverage
      description: 'fallback',
      value: {
        contactDataByRestaurantId: {
          '193': {
            areaManagerEmail: 'Katie.Aukuso@Nandos.com.au',
            restaurantEmail: 'VIC.WATERGARDENSTC@NANDOS.COM.AU',
            restaurantId: '193',
          },
          '201': {
            areaManagerEmail: null,
            restaurantEmail: null,
            restaurantId: '201',
          },
          '203': {
            areaManagerEmail: 'David.Kiogaard@nandos.com.au',
            restaurantEmail: 'QLD.FESTIVALTOWERS@NANDOS.COM.AU',
            restaurantId: '203',
          },
        },
        fallbackContactData: {
          areaManagerEmail: 'caitlin.hanning@nandos.com.au',
          restaurantEmail: 'caitlin.hanning@nandos.com.au',
        },
      },
    },
  ],
});

const competition = Object.freeze({
  launchDarklyKey: 'competition',
  yupSchema: yup.object({
    name: yup
      .string({
        description: 'Name of the competition. To be used as competition tittle.',
      })
      .required(),
    handle: yup
      .string({
        description: 'Handle for the competition menu entry.',
      })
      .required(),
    plus: yup
      .array(integerStringSchema)
      .meta({
        description:
          'List of product for which the competition applies to. These PLUs will also display in the menu under the handle.',
      })
      .required(),
    restaurantIdBlacklist: yup
      .array(yup.number())
      .meta({
        description: 'List of restaurant IDs to exclude from the competition.',
      })
      .required(),
    startDate: yup
      .string()
      .matches(rIsoDateTime, { message: 'Invalid date time string' })
      .meta({ description: 'Start Time of the competition.' })
      .required(),
    endDate: yup
      .string()
      .matches(rIsoDateTime, { message: 'Invalid date time string' })
      .meta({ description: 'End Time of the competition.' })
      .required(),
  }),
  variants: [
    {
      description: 'disabled',
      value: {
        name: 'Spin to Win',
        handle: 'spin-to-win-march-2024',
        plus: ['4011', '4001'],
        restaurantIdBlacklist: [],
        startDate: '2000-02-26T00:00:00.000Z',
        endDate: '2000-02-26T13:59:59.000Z',
      },
    },
  ],
});

const adyenMerchantMapping = Object.freeze({
  launchDarklyKey: 'adyen-merchant-mapping',
  yupSchema: yup.lazy(value => {
    // off variation is an empty object in Launch Darkly
    if (isEmpty(value)) {
      return yup.object({});
    }

    return yup.object({
      companyMerchantId: yup
        .string({
          description: 'The company merchant ID',
        })
        .required(),
      franchiseMerchants: yup.array(
        yup
          .object({
            merchantId: yup.string().required(),
            restaurantId: restaurantIdSchema.required(),
          })
          .meta({
            description: 'A list of the franchise restaurants and their merchantId',
          })
      ),
    });
  }),
  variants: [
    {
      description: 'On',
      value: {
        companyMerchantId: 'merchantAccount',
        franchiseMerchants: [
          {
            merchantId: 'merchant_1',
            restaurantId: '123',
          },
        ],
      },
    },
    {
      description: 'Company only',
      value: {
        companyMerchantId: 'merchant_1',
      },
    },
    {
      description: 'Empty array',
      value: {
        companyMerchantId: 'merchant_1',
        franchiseMerchants: [],
      },
    },
    {
      description: 'Off',
      value: {},
    },
  ],
});

module.exports = {
  apiFlagsData: Object.freeze({
    [adyenMerchantMapping.launchDarklyKey]: adyenMerchantMapping,
    [automatedRefunds.launchDarklyKey]: automatedRefunds,
    [competition.launchDarklyKey]: competition,
    [condenseChoices.launchDarklyKey]: condenseChoices,
    [defaultSelectedOptions.launchDarklyKey]: defaultSelectedOptions,
    [deliveryDiscountCampaign.launchDarklyKey]: deliveryDiscountCampaign,
    [dynamicNutritionData.launchDarklyKey]: dynamicNutritionData,
    [enableDeliveryBestEstimateApi.launchDarklyKey]: enableDeliveryBestEstimateApi,
    [hideBastingWhenProteinSelected.launchDarklyKey]: hideBastingWhenProteinSelected,
    [holidaySurcharges.launchDarklyKey]: holidaySurcharges,
    [menuCacheRegeneration.launchDarklyKey]: menuCacheRegeneration,
    [orderTypeRedcatWebhook.launchDarklyKey]: orderTypeRedcatWebhook,
    [outOfStockProducts.launchDarklyKey]: outOfStockProducts,
    [paymentAccountReference.launchDarklyKey]: paymentAccountReference,
    [paymentReportCardType.launchDarklyKey]: paymentReportCardType,
    [perksShopOrdering.launchDarklyKey]: perksShopOrdering,
    [publicHolidayOverride.launchDarklyKey]: publicHolidayOverride,
    [recommendationsEngine.launchDarklyKey]: recommendationsEngine,
    [removeKjFromDescription.launchDarklyKey]: removeKjFromDescription,
    [restaurantWaitTimeThresholds.launchDarklyKey]: restaurantWaitTimeThresholds,
    [saveOrderAddressesAndRestaurants.launchDarklyKey]: saveOrderAddressesAndRestaurants,
    [showCmsFeaturedProducts.launchDarklyKey]: showCmsFeaturedProducts,
    [showCmsMenuPromotionModal.launchDarklyKey]: showCmsMenuPromotionModal,
    [showCmsUpsellModal.launchDarklyKey]: showCmsUpsellModal,
    [zenDeskContactData.launchDarklyKey]: zenDeskContactData,
  }),
};
