const MenuOrange = props => (
  <svg viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Menu Orange</title>
    <path
      d="M5.09892 8C6.72669 9.80122 7.74085 12.0708 7.99588 14.483C7.99588 14.483 8.05773 15.6971 8.17776 17.0045L8.21551 17.3984C8.22868 17.5299 8.24242 17.6612 8.25673 17.7912L8.29802 18.2062C8.47673 19.7235 8.95524 21.192 9.70733 22.5259L9.78792 22.6614C9.79641 22.6762 9.81337 22.6931 9.81337 22.7058C9.83882 22.7481 9.86215 22.7883 9.8876 22.8264C9.95759 22.9386 10 23 10 23C2.71942 21.4062 1.28367 16.2397 1.05887 13.4459C0.980378 12.8452 0.980378 12.2369 1.05887 11.6362L1.06639 11.6456C1.19577 11.8055 2.96417 13.9644 4.14457 13.9644C4.14457 13.9644 3.40231 10.0382 5.09892 8ZM16.0988 2.50969C20.3684 3.74514 21.683 7.27896 21.405 10.1245C21.254 11.6745 20.836 12.9248 20.3823 13.6209C20.3741 13.633 20.3745 13.6488 20.3832 13.6605C20.3918 13.6722 20.407 13.6773 20.421 13.6732C21.5867 13.3488 22.3889 12.2099 22.6004 11.8796C22.6105 11.8602 22.6315 11.8488 22.6535 11.851C22.6754 11.8532 22.6937 11.8684 22.6997 11.8895C22.8268 12.2188 23.1833 13.3507 22.8824 15.0693C22.3393 18.1752 20.6871 18.8161 18.878 20.1768C17.2675 21.3876 18.2108 22.3963 18.8025 22.8341C18.836 22.8582 18.8493 22.9015 18.835 22.94C18.8206 22.9786 18.7822 23.003 18.741 22.9997C11.9544 22.5669 8.72845 16.7347 12.7855 10.9035C13.5679 9.7755 14.5578 8.77176 15.6798 7.39531C16.3222 6.60651 17.4264 4.14151 16.0532 2.57871C16.0413 2.56404 16.0403 2.54342 16.0507 2.52768C16.0611 2.51194 16.0806 2.50467 16.0988 2.50969ZM12.9066 1.01393C13.1877 1.41745 13.5527 1.75637 13.977 2.00781C15.9628 3.12594 15.4465 5.2902 15.2568 5.89166C15.231 5.97448 15.2012 5.97054 15.1933 5.88377C15.1232 5.14173 14.681 4.54881 14.2828 4.14528L14.1756 4.03996C12.9583 3.09438 12.8778 1.63018 12.847 1.03463C12.8461 1.02006 12.855 1.00665 12.8689 1.00183C12.8828 0.997006 12.8982 1.00195 12.9066 1.01393Z"
      fill="#FF8201"
    />
  </svg>
);

export { MenuOrange };
