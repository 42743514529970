const NoDairy = props => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_5505_1011)">
      <path
        d="M15.9947 2.09947C18.8418 2.09947 21.6249 2.94373 23.9922 4.5255C26.3595 6.10726 28.2046 8.35548 29.2941 10.9859C30.3836 13.6162 30.6687 16.5106 30.1133 19.303C29.5578 22.0954 28.1868 24.6604 26.1736 26.6736C24.1604 28.6868 21.5954 30.0578 18.803 30.6133C16.0106 31.1687 13.1163 30.8836 10.4859 29.7941C7.85549 28.7046 5.60727 26.8595 4.0255 24.4922C2.44374 22.1249 1.59947 19.3418 1.59947 16.4947C1.60512 12.6786 3.12356 9.02036 5.82196 6.32195C8.52036 3.62355 12.1786 2.10511 15.9947 2.09947ZM15.9947 0.5C12.8312 0.5 9.73882 1.43807 7.10851 3.19559C4.4782 4.9531 2.42813 7.45113 1.21753 10.3738C0.00693027 13.2964 -0.309818 16.5124 0.30734 19.6151C0.924498 22.7177 2.44784 25.5677 4.68474 27.8046C6.92163 30.0415 9.77161 31.5648 12.8743 32.182C15.9769 32.7992 19.1929 32.4824 22.1156 31.2718C25.0382 30.0612 27.5362 28.0111 29.2938 25.3808C31.0513 22.7505 31.9893 19.6581 31.9893 16.4947C31.9893 12.2526 30.3042 8.18431 27.3046 5.18473C24.305 2.18515 20.2367 0.5 15.9947 0.5V0.5Z"
        fill="black"
      />
      <path
        d="M20.7157 14.9646C20.7153 14.6038 20.6313 14.248 20.4705 13.925L18.3778 9.39316V7.75903H13.5954V9.3585L11.4361 13.9436C11.2715 14.275 11.1857 14.6399 11.1855 15.01L11.2309 26.3262C11.2316 26.6884 11.376 27.0356 11.6324 27.2915C11.8887 27.5473 12.2362 27.6911 12.5984 27.6911H19.3961C19.7584 27.6911 20.1058 27.5473 20.3622 27.2915C20.6186 27.0356 20.763 26.6884 20.7637 26.3262L20.7157 14.9646ZM18.2632 14.9246L15.1949 14.9326L13.5714 26.0916H12.7957V14.9166L13.5821 12.5441L18.2632 14.0956V14.9246Z"
        fill="black"
      />
      <path
        d="M18.6872 5.37036H13.2623C12.9988 5.37036 12.7852 5.584 12.7852 5.84754V6.48466C12.7852 6.74819 12.9988 6.96183 13.2623 6.96183H18.6872C18.9507 6.96183 19.1644 6.74819 19.1644 6.48466V5.84754C19.1644 5.584 18.9507 5.37036 18.6872 5.37036Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.0585 27.4642L4.93901 6.34468L6.05859 5.2251L27.1781 26.3446L26.0585 27.4642Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_5505_1011">
        <rect width="32" height="32" fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export { NoDairy };
