import { forIn, isFunction } from 'lodash';
import { types } from 'mobx-state-tree';

import AlertsStore from '../stores/alerts';
import AuthStore from '../stores/authentication';
import BrazeStore from '../stores/braze';
import CartStore from '../stores/cart';
import CompetitionStore from '../stores/competition';
import ConnectivityStore from '../stores/connectivity';
import DeliveryStore from '../stores/delivery';
import DietaryPreferencesStore from '../stores/dietary-preferences';
import FeatureFlagStore from '../stores/feature-flag';
import GroupOrderStore from '../stores/group-order';
import LocationStore from '../stores/location';
import MemberStore from '../stores/member';
import MenuStore from '../stores/menu';
import OfferStore from '../stores/offer';
import OrderConfirmationState from '../stores/order-confirmation-state';
import OrderingContextStore from '../stores/ordering-context';
import OrderHelpStore from '../stores/order-help';
import OrderStore from '../stores/order';
import PastOrdersStore from '../stores/past-orders';
import PaymentStore from '../stores/payments';
import PerksShopStore from '../stores/perks-shop';
import ProductDetailsState from '../stores/product-details-state';
import ReceiptStore from '../stores/receipt';
import RecommendationEngineStore from '../stores/recommendation-engine';
import RestaurantDetailsState from '../stores/restaurant-details-state';
import RestaurantStore from '../stores/restaurant';
import TransactionStore from '../stores/transaction';

// For increased type inference we separate these these into compose groups
const RootStore = types
  .compose(
    types.compose(
      types.model({ AlertsStore: types.optional(AlertsStore, {}) }),
      types.model({ AuthStore }),
      types.model({ BrazeStore }),
      types.model({ CartStore }),
      types.model({ CompetitionStore }),
      types.model({ ConnectivityStore }),
      types.model({ DeliveryStore }),
      types.model({ DietaryPreferencesStore }),
      types.model({ FeatureFlagStore: types.optional(FeatureFlagStore, {}) })
    ),
    types.compose(
      types.model({ GroupOrderStore }),
      types.model({ LocationStore: types.optional(LocationStore, {}) }),
      types.model({ MemberStore }),
      types.model({ MenuStore }),
      types.model({ OfferStore }),
      types.model({ OrderConfirmationState }),
      types.model({ OrderingContextStore }),
      types.model({ OrderStore: types.optional(OrderStore, {}) })
    ),
    types.compose(
      types.model({ PastOrdersStore }),
      types.model({ PaymentStore }),
      types.model({ ProductDetailsState }),
      types.model({ PerksShopStore }),
      types.model({ ReceiptStore }),
      types.model({ RecommendationEngineStore }),
      types.model({ RestaurantDetailsState }),
      types.model({ RestaurantStore })
    ),
    types.compose(types.model({ TransactionStore }), types.model({ OrderHelpStore }))
  )
  .actions(self => ({
    clearSessionData() {
      forIn(self, store => {
        if (isFunction(store.clearSessionData)) {
          store.clearSessionData();
        }
      });
    },
  }));

const initialState = {
  AuthStore: AuthStore.initialState,
  BrazeStore: BrazeStore.initialState,
  CartStore: CartStore.initialState,
  CompetitionStore: CompetitionStore.initialState,
  ConnectivityStore: ConnectivityStore.initialState,
  DeliveryStore: DeliveryStore.initialState,
  DietaryPreferencesStore: DietaryPreferencesStore.initialState,
  GroupOrderStore: GroupOrderStore.initialState,
  MemberStore: MemberStore.initialState,
  MenuStore: MenuStore.initialState,
  OfferStore: OfferStore.initialState,
  OrderConfirmationState: OrderConfirmationState.initialState,
  OrderingContextStore: OrderingContextStore.initialState,
  OrderHelpStore: OrderHelpStore.initialState,
  PastOrdersStore: PastOrdersStore.initialState,
  PaymentStore: {}, // @TODO: Fix this - should always come from the file.
  PerksShopStore: PerksShopStore.initialState,
  ProductDetailsState: ProductDetailsState.initialState,
  ReceiptStore: ReceiptStore.initialState,
  RecommendationEngineStore: RecommendationEngineStore.initialState,
  RestaurantDetailsState: RestaurantDetailsState.initialState,
  RestaurantStore: RestaurantStore.initialState,
  TransactionStore: TransactionStore.initialState,
};

export default RootStore;

export { initialState };
