// logContentCardClicked the braze sdk in mobile does not return anything
const appBrazeContentCardLogClick = ({ braze, card }) => {
  if (braze && card) {
    braze.logContentCardClicked(card.id);
    console.debug('braze logContentCardClicked called', { card });
  }
};

// logContentCardImpression the braze sdk in mobile does not return anything
const appBrazeContentCardLogImpression = ({ braze, card }) => {
  if (braze && card) {
    braze.logContentCardImpression(card.id);
    console.debug('braze logContentCardImpression called', { card });
  }
};

const brazeContentCardLogClick = ({ braze, card }) => {
  if (braze) {
    const clickResult = braze.logContentCardClick(card);
    console.debug('braze clickResult', { clickResult, card });
    return clickResult;
  }

  return undefined;
};

const brazeContentCardLogImpression = ({ braze, card }) => {
  if (braze) {
    const impressionResult = braze.logContentCardImpressions([card]);
    console.debug('braze impressionResult', { impressionResult, card });
    return impressionResult;
  }

  return undefined;
};

/**
  brazeContentCardLogDismissal

  Logging a dismissal results in the content card being removed,
  which in most cases is not desired behaviour.
*/

export {
  brazeContentCardLogImpression,
  brazeContentCardLogClick,
  appBrazeContentCardLogClick,
  appBrazeContentCardLogImpression,
};
