import { types } from 'mobx-state-tree';

import { DateTime } from '../date-time';

const Competition = types
  .model('Competition', {
    name: types.string,
    handle: types.string,
    entries: types.integer,
    startDate: DateTime,
    endDate: DateTime,
  })
  .actions(self => ({
    setEntries(entries) {
      self.entries = entries;
    },
  }));

export default Competition;
