const Twitter = props => (
  <svg viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" {...props}>
    <title>Twitter</title>
    <path
      d="M20,2.8 C19.2483371,3.12609476 18.4534278,3.34166339 17.64,3.44 C18.4981729,2.92732082 19.1412717,2.12078257 19.45,1.17 C18.6435521,1.65006315 17.7607621,1.98829688 16.84,2.17 C15.5741776,0.797282829 13.5407201,0.4532581 11.8937898,1.33318946 C10.2468594,2.21312082 9.40251721,4.09471281 9.84,5.91 C6.54236057,5.74320703 3.47071868,4.18375807 1.39,1.62 C1.02913744,2.25016856 0.83951928,2.96382236 0.84,3.69 C0.837401051,5.05971145 1.52061253,6.3397945 2.66,7.1 C2.00797741,7.08225944 1.36988565,6.90729879 0.8,6.59 L0.8,6.64 C0.810074881,8.58798037 2.18943105,10.2599272 4.1,10.64 C3.74325548,10.7485665 3.372875,10.8058071 3,10.81 C2.74189022,10.8069845 2.48442225,10.7835783 2.23,10.74 C2.77097576,12.4061432 4.30855746,13.5462821 6.06,13.58 C4.61720259,14.7152666 2.83588349,15.3348559 1,15.34 C0.665735253,15.3410961 0.331735618,15.3210561 8.8817842e-16,15.28 C1.87442836,16.4902569 4.05881285,17.132723 6.29,17.13 C9.40026304,17.1622997 12.392978,15.943081 14.5952038,13.7464971 C16.7974296,11.5499132 18.0243212,8.56033566 18,5.45 C18,5.28 18,5.1 18,4.92 C18.7846953,4.33481081 19.4614745,3.61742488 20,2.8 L20,2.8 Z"
      id="Path"
    />
  </svg>
);

export { Twitter };
