import { get } from 'lodash';
import { types } from 'mobx-state-tree';

import Location from '../../models/location';

const LocationStore = types
  .model('LocationStore', {
    currentLocation: types.maybe(Location),
  })
  .actions(self => ({
    setCurrentLocation(newLocation) {
      self.currentLocation = newLocation;
    },
  }))
  .views(self => ({
    get latitude() {
      return get(self.currentLocation, 'latitude');
    },
    get longitude() {
      return get(self.currentLocation, 'longitude');
    },
  }));

export default LocationStore;
