import { filter, floor, head, isEmpty, isNil } from 'lodash';
import { types } from 'mobx-state-tree';

import DeliveryAddress from '../delivery-address';
import Restaurant from '../restaurant';

const Profile = types
  .model('Profile', {
    balances: types.frozen({
      credit: types.number,
      points: types.number,
    }),
    barcode: types.string,
    dob: types.maybeNull(types.string),
    email: types.string,
    encryptedMemberId: types.string,
    familyName: types.string,
    gender: types.maybeNull(types.string),
    givenName: types.string,
    id: types.identifier,
    memberNumber: types.string,
    memberType: types.string,
    mobile: types.maybeNull(types.string),
    postcode: types.maybeNull(types.string),
    state: types.maybeNull(types.string),
    emailCommunication: types.maybeNull(types.boolean),
    smsCommunication: types.maybeNull(types.boolean),
    restaurants: types.optional(types.array(types.safeReference(Restaurant)), []),
    addresses: types.optional(types.array(DeliveryAddress), []),
  })
  .views(self => ({
    get fullName() {
      return `${self.givenName} ${self.familyName}`;
    },

    get pointsInDollars() {
      return floor(self.balances.points / 10, 2).toFixed(2);
    },

    get latestAddress() {
      if (isNil(self.addresses)) {
        return null;
      }

      return head(self.addresses);
    },

    get availableRestaurants() {
      const availableRestaurants = filter(self.restaurants, 'orderingEnabled');

      return availableRestaurants;
    },

    get hasAvailableRestaurants() {
      const hasAvailableRestaurants = !isEmpty(self.availableRestaurants);

      return hasAvailableRestaurants;
    },
  }));

export default Profile;
