import gql from 'graphql-tag';

const CALCULATE_SURCHARGE_QUERY = gql`
  query CalculateSurcharges($input: CalculateSurchargesInput!) {
    calculateSurcharges(input: $input) {
      surcharges {
        type
        name
        description
        amount
        surcharge
        isPercentage
      }
      message
      success
    }
  }
`;

const calculateSurcharges = async ({ client, input }) => {
  const response = await client.query({
    query: CALCULATE_SURCHARGE_QUERY,
    variables: {
      input,
    },
  });

  return response.data.calculateSurcharges.surcharges;
};

export { calculateSurcharges };
