import { types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';
import { template } from '../../util/template';

const ValidationError = types
  .model('ValidationError', {
    message: '', // needed?
    // code?
    id: types.optional(types.string, uuidv4),
    path: '',
    extensions: types.frozen({}),
    template: '',
  })
  .views(self => ({
    toString() {
      return template({
        message: self.template,
        variables: self.extensions,
      });
    },
  }));

export default ValidationError;
