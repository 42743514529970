// Greys & Neutrals in figma
const baseColors = {
  black: '#000000',
  white: '#FFFFFF',
  grey50: '#F6F6F6',
  grey100: '#E9E9E9',
  grey200: '#D3D3D3',
  grey300: '#B9B9B9',
  grey400: '#949494',
  grey500: '#7F7F7F',
  grey600: '#666',
  grey700: '#4C4C4C',
  grey800: '#2D2D2D',
  background: '#FFFFFF',
  backgroundDark: '#EDE5CE',
  backgroundLight: '#F4EEDF',
};

const secondaryColors = {
  blue100: '#CBF3FF',
  blue200: '#ABEFFF',
  blue300: '#75E0FF',
  blue400: '#45D0FF',
  blue500: '#1FC0FE',
  blue600: '#00AEEF',
  blue700: '#0893CB',
  blue900: '#175572',
  green50: '#E9FCC8',
  green100: '#DBF6A9',
  green300: '#B3E758',
  green400: '#9ADA2B',
  green500: '#8DC922',
  green600: '#78B400',
  green700: '#709C02',
  green900: '#475C05',
  neonTeal50: '#D8FAF5',
  neonTeal200: '#8AF0E1',
  neonTeal400: '#14E0C2',
  neonTeal500: '#11C6AC',
  neonTeal600: '#0DAD96',
  neonTeal700: '#0A937F',
  neonTeal900: '#045146',
  orange100: '#FFE5C3',
  orange200: '#FFDCA7',
  orange300: '#FEC472',
  orange400: '#FFAD43',
  orange500: '#FF971E',
  orange600: '#FF8201',
  orange700: '#DC6D02',
  orange800: '#B2580B',
  orange900: '#87430F',
  pink50: '#FFDFED',
  pink100: '#FFCFE4',
  pink200: '#FFA0CB',
  pink300: '#FF6DB2',
  pink400: '#FD419B',
  pink500: '#F41F8B',
  pink600: '#E7007D',
  pink700: '#CA0D71',
  pink800: '#AA1764',
  pink900: '#861E54',
  red300: '#F36B7C',
  red500: '#DE2036',
  red600: '#CE0924',
  yellow50: '#FFFBD4',
  yellow100: '#FFFBB9',
  yellow300: '#FFF66E',
  yellow400: '#FFF045',
  yellow500: '#FFE923',
  yellow600: '#FFDF05',
};

const brandColors = {
  neonPink: '#FF00B5',
  neonRed: '#FE4047',
  neonPurple: '#9F51DA',
  neonYellow: '#E6EB23',
  neonLavender: '#C48CFA',
  neonGreen: '#B3E758',
  neonTeal100: '#F1FEFC',
  neonTeal800: '#067969',
  periRed: '#CE0A24',
  perksBlue: '#8AD7E7',
  perksRed: '#FE4050',
  perksYellow: '#FFFE16',
  modalRed: '#400F15',
};

const semanticColors = {
  error50: '#FFE8E8',
  error300: '#F37A7D',
  error400: '#E74E54',
  error500: '#D42E37',
  error600: '#BB1825',
  error800: '#700312',
  info50: '#ECF9FF',
  info400: '#56B1E7',
  info600: '#207BBB',
  info800: '#083F70',
  info900: '#042445',
  success50: '#F3FFE7',
  success300: '#B3F37A',
  success400: '#95E74F',
  success600: '#5CBB19',
  success800: '#2A7004',
  success900: '#174501',
  warning50: '#FFF6D4',
  warning300: '#F3D465',
  warning400: '#E7BF39',
  warning600: '#BB8F06',
  warning800: '#705200',
  warning900: '#453100',
};

const primaryColors = {
  bluePrimary: secondaryColors.blue600,
  errorPrimary: semanticColors.error600,
  greenPrimary: secondaryColors.green600,
  greyPrimary: baseColors.grey500,
  infoPrimary: semanticColors.info600,
  orangePrimary: secondaryColors.orange600,
  pinkPrimary: secondaryColors.pink600,
  redPrimary: secondaryColors.red600,
  successPrimary: semanticColors.success600,
  warningPrimary: semanticColors.warning600,
  yellowPrimary: secondaryColors.yellow600,
};

const alertColors = {
  alertError: semanticColors.error800,
  alertErrorMedium: semanticColors.error400,
  alertErrorLight: semanticColors.error50,
  alertInfo: semanticColors.info800,
  alertInfoMedium: semanticColors.info400,
  alertInfoLight: semanticColors.info50,
  alertSuccess: semanticColors.success800,
  alertSuccessMedium: semanticColors.success400,
  alertSuccessLight: semanticColors.success50,
  alertWarning: semanticColors.warning800,
  alertWarningMedium: semanticColors.warning400,
  alertWarningLight: semanticColors.warning50,
};

const bastingColors = {
  extraHot: '#46230F',
  hot: brandColors.periRed,
  mild: '#FAA500',
  lemonAndHerb: '#96C31E',
  bbq: '#5A642D',
  plain: '#ACB196',
};

const offerColors = {
  // @NOTE: These are the offer colors for the placeholder shown when there is not an offer selected.
  offerNotSelected: {
    labelColor: baseColors.white,
    contrastColor: '#e6017c',
  },
  offerOnline: {
    backgroundColor: secondaryColors.neonTeal200,
    decalColor: secondaryColors.neonTeal400,
    labelColor: secondaryColors.neonTeal600,
    textColor: secondaryColors.neonTeal900,
    expiryColor: secondaryColors.neonTeal900,
    contrastColor: baseColors.white,
  },
  offerInRestaurant: {
    backgroundColor: secondaryColors.orange300,
    decalColor: secondaryColors.orange500,
    labelColor: secondaryColors.orange600,
    textColor: secondaryColors.orange900,
    expiryColor: secondaryColors.orange900,
    contrastColor: baseColors.white,
  },
  offerBoth: {
    backgroundColor: secondaryColors.pink100,
    decalColor: secondaryColors.pink500,
    labelColor: secondaryColors.pink600,
    textColor: secondaryColors.pink900,
    expiryColor: secondaryColors.pink900,
    contrastColor: baseColors.white,
  },
};

const colors = {
  ...baseColors,
  ...brandColors,
  ...semanticColors,
  ...offerColors,
  ...alertColors,
  ...secondaryColors,
  ...primaryColors,
  ...bastingColors,
};

module.exports = { colors };
