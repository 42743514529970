const lodash = require('lodash');
const constants = require('../../constants');

const { omitBy } = lodash;
const { BASTINGS: CONSTANT_BASTINGS, BASTING_TYPES, BASTING_TYPES_BY_NAME: CONSTANT_BASTING_TYPES_BY_NAME } = constants;

const CONSTANT_BASTING_TYPES_BY_NAME_WITHOUT_PLAIN = omitBy(
  CONSTANT_BASTING_TYPES_BY_NAME,
  value => value === BASTING_TYPES.PLAIN
);

/**
 * All bastings in the BASTINGS array in @nandosaus/constants have the same shape except plain, which has a different name in AU vs NZ.
 * The different shape of the plain basting object makes data transformation cumbersome.
 * This hook returns versions of the BASTINGS and BASTING_TYPES_BY_NAME constants with a consistent shape, including the correct values for the plain basting
 * depending on current market.
 */

/**
 * @param {('au'|'nz')} market
 */

const getBastingsForMarket = market => {
  const { name: plainName, description: plainDescription } = CONSTANT_BASTINGS[BASTING_TYPES.PLAIN];
  const plainNameForMarket = plainName[market];

  const BASTINGS = {
    ...CONSTANT_BASTINGS,
    [BASTING_TYPES.PLAIN]: {
      description: plainDescription,
      name: plainNameForMarket,
    },
  };

  const BASTING_TYPES_BY_NAME = {
    ...CONSTANT_BASTING_TYPES_BY_NAME_WITHOUT_PLAIN,
    [plainNameForMarket]: BASTING_TYPES.PLAIN,
  };

  return { BASTINGS, BASTING_TYPES_BY_NAME };
};

module.exports = getBastingsForMarket;
