import {
  DATE_TIME_FORMATS,
  DEFAULT_RESTAURANT_CLOSING_OFFSET_IN_MINUTES,
  ORDER_TYPES,
  ORDER_TYPES_LABELS,
  PICKUP_TIME_INTERVAL,
  RESTAURANT_CLOSING_OFFSET_IN_MINUTES_BY_ORDER_TYPE,
} from '@nandosaus/constants';
import { find, isEmpty, isNil, keys, map, pickBy, startCase } from 'lodash';
import { types } from 'mobx-state-tree';
import { now } from 'mobx-utils';
import getDistance from 'geolib/es/getDistance';
import moment from 'moment';

import { convert24To12Hour, convertTimeToMoment, getCurrentDay, getNextDay } from '../../util/time';
import Address from '../address';
import DeliveryServices from '../delivery-services';
import OpeningHours from '../opening-hours';
import Threshold from '../threshold';

const Restaurant = types
  .model('Restaurant', {
    id: types.identifier,
    name: types.string,
    longitude: types.number,
    latitude: types.number,
    phone: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    // @deprecated: api responses are cached and this state can also become stale client-side
    // @use: isOpen
    openNow: types.maybeNull(types.boolean),
    nextOpen: types.maybeNull(types.string),
    orderingEnabled: types.maybeNull(types.boolean),
    tableOrderingEnabled: types.maybeNull(types.boolean),
    deliveryEnabled: types.maybeNull(types.boolean),
    pickUpEnabled: types.maybeNull(types.boolean),
    address: Address,
    openingHours: types.array(OpeningHours),
    facilities: types.frozen({}), // E.g. { facilityName: boolean }
    deliveryServices: types.maybeNull(DeliveryServices),
    catering: types.maybeNull(types.string),
    utcOffset: types.number,
    thresholds: types.optional(types.array(Threshold), []),
    averageOrderTime: types.maybeNull(types.number),
    dineInGuestCheckoutEnabled: false,
    deliveryGuestCheckoutEnabled: false,
    pickUpGuestCheckoutEnabled: false,
    seoTitle: types.maybeNull(types.string),
    isHoliday: types.optional(types.boolean, false),
  })
  .views(self => ({
    get isOpen() {
      const currentTime = now();

      if (self.isClosed) {
        return false;
      }

      const tradingHours = self.tradingHoursForPointInTime(currentTime);

      if (tradingHours === null) {
        return false;
      }

      const momentTime = moment(currentTime);
      const openingTime = moment(tradingHours.openingTime);
      const closingTime = moment(tradingHours.closingTime);

      if (momentTime.isSame(openingTime) || momentTime.isBetween(openingTime, closingTime)) {
        return true;
      }

      return false;
    },

    get isOpenText() {
      return self.isOpen ? 'Open now' : this.nextOpenTimeMessage();
    },

    /**
     * Returns an array of facilities,
     * Each Word In Uppercase.
     */
    get formattedFacilities() {
      return map(keys(pickBy(self.facilities)), startCase);
    },

    get formattedDeliveryServices() {
      return pickBy(self.deliveryServices, ['live', true]);
    },

    getFormattedStatusMessage({ orderType } = {}) {
      const statusMessages = self.getStatusMessages({ orderType });

      if (!self.orderingEnabled) {
        return statusMessages.restaurantNotTradingOnline;
      }

      if (!self.getIsOrderingEnabledForOrderType({ orderType })) {
        return statusMessages.restaurantNotTradingForOrderType;
      }

      if (self.isClosed) {
        // Restaurant opening hours are 'Closed', eg, for Sunday or Public Holiday.
        return statusMessages.restaurantOpeningHoursClosed;
      }

      const availablePickupTimes = self.availablePickupTimes({ orderType });

      if (isEmpty(availablePickupTimes)) {
        const { orderEndTime } = self.activeTradingHours({ orderType });
        const orderTime = moment();
        if (orderTime.isBefore(orderEndTime)) {
          return statusMessages.restaurantOpen;
        }
        return statusMessages.restaurantOffline;
      }

      if (self.isOpen) {
        return statusMessages.restaurantOpen;
      }

      return statusMessages.restaurantClosedMessage;
    },

    getIsAcceptingOrders({ orderType } = {}) {
      const hasAvailablePickupTimes = !isEmpty(self.availablePickupTimes({ orderType }));
      const { orderEndTime } = self.activeTradingHours({ orderType });

      if (!self.orderingEnabled) {
        return false;
      }

      const orderTypeEnabled = self.getIsOrderingEnabledForOrderType({ orderType });
      if (!orderTypeEnabled) {
        return false;
      }

      if (self.isClosed) {
        return false;
      }

      const isBeforeOrderEndTime = moment().isBefore(orderEndTime);

      // restaurants can accept orders up until 15 before closing for non-delivery orders
      // and 30 minutes for delivery orders
      if (!hasAvailablePickupTimes && isBeforeOrderEndTime) {
        return true;
      }

      if (!hasAvailablePickupTimes) {
        return false;
      }

      if (self.isOpen) {
        return true;
      }

      return false;
    },

    getIsOrderingEnabledForOrderType({ orderType }) {
      if (ORDER_TYPES.PICK_UP === orderType && !self.pickUpEnabled) {
        return false;
      }

      if (ORDER_TYPES.DINE_IN === orderType && !self.tableOrderingEnabled) {
        return false;
      }

      if (ORDER_TYPES.DELIVERY === orderType && !self.deliveryEnabled) {
        return false;
      }

      return true;
    },

    getStatusMessages({ orderType } = {}) {
      return {
        restaurantClosedMessage: {
          alert: `Restaurant taking orders from ${self.formattedOpeningHour}`,
          disableOptions: isEmpty(self.availablePickupTimes({ orderType })),
          fontColour: 'errorPrimary',
          subtitle: `Closed, opens at ${self.formattedOpeningHour}`,
        },
        restaurantOpen: {
          alert: null,
          disableOptions: false,
          fontColour: 'grey500',
          subtitle: `Open until ${self.formattedOpeningHour}`,
        },
        restaurantOpeningHoursClosed: {
          alert: 'This restaurant is currently closed',
          disableOptions: true,
          fontColour: 'errorPrimary',
          subtitle: 'Not taking online orders',
        },
        restaurantOffline: {
          alert: 'Sorry we’re no longer taking online orders today.',
          disableOptions: false,
          fontColour: 'errorPrimary',
          subtitle: 'Not taking online orders',
        },
        restaurantNotTradingOnline: {
          alert: 'Sorry we’re not currently taking online orders.',
          disableOptions: true,
          fontColour: 'errorPrimary',
          subtitle: 'Not taking online orders',
        },
        restaurantNotTradingForOrderType: {
          alert: `Sorry we’re not currently taking orders for ${ORDER_TYPES_LABELS[orderType]}.`,
          disableOptions: true,
          fontColour: 'errorPrimary',
          subtitle: `Not taking orders for ${ORDER_TYPES_LABELS[orderType]}`,
        },
      };
    },

    get isClosed() {
      const { closingTime, openingTime } = self.todaysOpeningHours;

      if (closingTime === 'Closed' && openingTime === 'Closed') {
        return true;
      }

      return false;
    },
    get formattedAddress() {
      const { address } = self;
      return `${address.address1}, ${address.suburb} ${address.state} ${address.postcode}`;
    },
    get formattedName() {
      const { name } = self;
      return name ? startCase(name.toLocaleLowerCase()) : null;
    },
    // TODO: need to account for public holidays.
    get todaysOpeningHours() {
      return find(self.openingHours, ['day', getCurrentDay()]) || {};
    },

    activeTradingHours({ orderType } = {}) {
      // Return the hours that a store is accepting orders, which may be shorter than the actual trading hours.
      // Update: The orders are now allowed from 30 min after open till closing, 15 offset for closing now.

      // https://app.clickup.com/t/1vxf2q8

      const currentTime = now();
      const tradingHours = self.tradingHoursForPointInTime(currentTime);
      const openingOffset = 30;
      const closingOffset =
        RESTAURANT_CLOSING_OFFSET_IN_MINUTES_BY_ORDER_TYPE[orderType] || DEFAULT_RESTAURANT_CLOSING_OFFSET_IN_MINUTES;

      if (tradingHours === null) {
        return null;
      }

      const openingTime = moment(tradingHours.openingTime);
      const closingTime = moment(tradingHours.closingTime);

      const orderStartTime = openingTime.add(openingOffset, DATE_TIME_FORMATS.TIME_MINUTES);
      const orderEndTime = closingTime.subtract(closingOffset, DATE_TIME_FORMATS.TIME_MINUTES);

      return { orderStartTime, orderEndTime };
    },

    availablePickupTimes({ orderType } = {}) {
      const currentTime = now();
      const activeTrading = self.activeTradingHours({ orderType });

      if (!activeTrading) {
        return [];
      }

      const availablePickupTimes = [];

      const { orderStartTime, orderEndTime } = activeTrading;

      for (
        let startTime = orderStartTime;
        startTime.isSameOrBefore(orderEndTime);
        startTime.add(PICKUP_TIME_INTERVAL, DATE_TIME_FORMATS.TIME_MINUTES)
      ) {
        const difference = startTime.diff(currentTime, 'm');

        if (difference >= PICKUP_TIME_INTERVAL) {
          availablePickupTimes.push({
            value: startTime.format(),
            text: convert24To12Hour(startTime),
          });
        }
      }

      return availablePickupTimes;
    },

    distanceToRestaurant({ latitude, longitude }) {
      return getDistance({ latitude, longitude }, { latitude: self.latitude, longitude: self.longitude });
    },

    formattedDistanceToRestaurant({ latitude, longitude }) {
      const distanceInKm = self.distanceToRestaurant({ latitude, longitude }) / 1000;

      if (distanceInKm > 90) {
        return '>90km';
      }

      return `${distanceInKm.toFixed(1)}km`;
    },

    formattedTradingHours({ closingTime, openingTime }) {
      const time =
        openingTime !== 'Closed' && closingTime !== 'Closed'
          ? `${convert24To12Hour(openingTime)} - ${convert24To12Hour(closingTime)}`
          : 'Closed';
      return time;
    },

    get formattedOpeningHour() {
      const { closingTime, openingTime } = self.todaysOpeningHours;

      if (self.isOpen)
        return moment(closingTime, DATE_TIME_FORMATS.TIME_24_HOUR).format(DATE_TIME_FORMATS.TIME_DEFAULT);

      const openFrom = moment().isBefore(convertTimeToMoment(openingTime))
        ? openingTime
        : find(self.openingHours, ['day', getNextDay()]).openingTime;

      return moment(openFrom, DATE_TIME_FORMATS.TIME_24_HOUR).format(DATE_TIME_FORMATS.TIME_DEFAULT);
    },

    /**
     * Get the trading hours in hh:mm format for a given weekday
     * @param {string} weekdayName
     */
    tradingHoursForWeekday(weekdayName) {
      const hoursForWeekday = self.openingHours.find(hours => {
        return hours.day.toLowerCase() === weekdayName.toLowerCase();
      });

      if (hoursForWeekday === undefined) {
        return null;
      }

      const { closingTime, openingTime } = hoursForWeekday;

      return {
        closingTime,
        openingTime,
      };
    },

    /**
     * Get the trading hours in DateTime format for a given date.
     * @param {Date} date
     */
    tradingHoursForDate(date) {
      const localDate = moment(date)
        .utcOffset(self.utcOffsetAs('hours'))
        .startOf('day');

      const hours = self.tradingHoursForWeekday(localDate.format('dddd'));

      if (hours === null) {
        return {};
      }

      const openingHour = moment.duration(hours.openingTime);
      const closingHour = moment.duration(hours.closingTime);

      const openingTime = moment(localDate)
        .add(openingHour)
        .toDate();

      const closingTime =
        closingHour <= openingHour
          ? moment(localDate)
              .add(1, 'days')
              .add(closingHour)
              .toDate()
          : moment(localDate)
              .add(closingHour)
              .toDate();

      return { openingTime, closingTime };
    },

    /**
     * Provides the trading hours for a given point in time, taking into
     * account that hours may apply to the previous day when the restaurant
     * closes after midnight.
     * @param {Date} dateTime
     */
    tradingHoursForPointInTime(dateTime) {
      const prevDay = moment(dateTime).subtract(1, 'day');
      const hours = self.tradingHoursForDate(dateTime);
      const prevHours = self.tradingHoursForDate(prevDay);

      if (prevHours !== null && dateTime <= prevHours.closingTime) {
        return prevHours;
      }

      return hours;
    },

    nextOpenTimeMessage() {
      const nextOpenMoment = moment(self.nextOpen);

      if (isNil(nextOpenMoment)) {
        return 'Currently closed';
      }

      const currentTime = moment();
      const tomorrowTime = moment().add(1, 'day');

      if (nextOpenMoment.isSame(currentTime, 'day')) {
        const formattedTime = nextOpenMoment.format('h:mm A');
        return `Currently closed, opens at ${formattedTime}`;
      }

      // if the restaurant still doesn't open tomorrow, simply say `Currently Closed`
      if (!nextOpenMoment.isSame(tomorrowTime, 'day')) {
        return 'Currently closed';
      }

      // But, if the restaurant opens tomorrow, say `Currently Closed, Opens at hh:mm AM/PM tomorrow`
      const tomorrowOpenTime = nextOpenMoment.add(1, 'day');
      const formattedTime = tomorrowOpenTime.format('h:mm A');
      return `Currently closed, opens at ${formattedTime} tomorrow`;
    },

    /**
     * Get the restaurant's UTC offset as a specific unit of time.
     * @param {moment.unitOfTime.Base} units
     */
    utcOffsetAs(units) {
      return moment.duration(self.utcOffset, 'seconds').as(units);
    },

    get seoTitleString() {
      return !isNil(self.seoTitle) ? self.seoTitle : self.name;
    },
  }));

export default Restaurant;
