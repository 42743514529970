import { map, reduce, uniqWith } from 'lodash';

const findUniqueNonNullGroupNames = (item1, item2) => {
  if (!item1.groupName || !item2.groupName) {
    return null;
  }
  return item1.groupName === item2.groupName;
};

const byGroupName = (accumulator, item) => {
  const group = accumulator[item.groupName] || [];
  group.push(item);
  return { ...accumulator, [item.groupName]: group };
};

const groupMenuItemsByGroupName = ({ items, uniqueItemsOnly, otherSizesAsIds }) => {
  const itemsByGroup = reduce(items, byGroupName, {});

  const itemsWithGroupedOtherSizes = map(items, item => {
    if (!item.groupName) {
      return item;
    }

    const otherSizes = otherSizesAsIds ? map(itemsByGroup[item.groupName], 'id') : itemsByGroup[item.groupName];
    return { ...item, otherSizes };
  });

  return uniqueItemsOnly
    ? uniqWith(itemsWithGroupedOtherSizes, findUniqueNonNullGroupNames)
    : itemsWithGroupedOtherSizes;
};

export { groupMenuItemsByGroupName, findUniqueNonNullGroupNames };
