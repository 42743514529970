import { types } from 'mobx-state-tree';
import moment from 'moment';

import { DELIVERY_PROVIDER } from '@nandosaus/constants';
import DeliveryFee from '../delivery-fee';
import DeliverySponsor from '../delivery-sponsor';
import Restaurant from '../restaurant';

const DeliveryEstimate = types
  .model('DeliveryEstimate', {
    fee: DeliveryFee,
    isDiscountActive: types.optional(types.boolean, false),
    isDiscountApplied: types.optional(types.boolean, false),
    minutes: types.number,
    restaurant: types.safeReference(Restaurant),
    sponsor: types.maybeNull(DeliverySponsor, null),
    deliveryProvider: types.maybe(types.enumeration(Object.keys(DELIVERY_PROVIDER))),
  })
  .views(self => ({
    get time() {
      return moment()
        .add(self.minutes, 'm')
        .format();
    },

    get fromNow() {
      return moment(self.time).fromNow(true);
    },

    get fromNowInMinutes() {
      return moment(self.time).diff(moment.now(), 'm');
    },
  }));

export default DeliveryEstimate;
